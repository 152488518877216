/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CorporateCreationDto,
  CorporateEditDto,
  GetCorporateDto,
  GetCorporateInvitationDto,
  GetCorporateMemberDto,
  GetCorporateMembersAndInvitations,
  GetCreatedCorporateDto,
  MemberInvitationDto,
} from '../models/index';
import {
    CorporateCreationDtoFromJSON,
    CorporateCreationDtoToJSON,
    CorporateEditDtoFromJSON,
    CorporateEditDtoToJSON,
    GetCorporateDtoFromJSON,
    GetCorporateDtoToJSON,
    GetCorporateInvitationDtoFromJSON,
    GetCorporateInvitationDtoToJSON,
    GetCorporateMemberDtoFromJSON,
    GetCorporateMemberDtoToJSON,
    GetCorporateMembersAndInvitationsFromJSON,
    GetCorporateMembersAndInvitationsToJSON,
    GetCreatedCorporateDtoFromJSON,
    GetCreatedCorporateDtoToJSON,
    MemberInvitationDtoFromJSON,
    MemberInvitationDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface AcceptCorporateInvitationRequest {
    invitationId: string;
}

export interface ActiveCorporateMemberRequest {
    userId: string;
}

export interface CancelCorporateInvitationRequest {
    invitationId: string;
}

export interface ChangeActiveStatusRequest {
    id: string;
}

export interface CreateCorporateRequest {
    corporateCreationDto: CorporateCreationDto;
}

export interface DeactiveCorporateMemberRequest {
    userId: string;
}

export interface DeclineCorporateInvitationRequest {
    invitationId: string;
}

export interface EditCorporateRequest {
    corporateEditDto: CorporateEditDto;
}

export interface GetCorporateRequest {
    id: string;
}

export interface InviteTakerForCorporateRequest {
    memberInvitationDto: MemberInvitationDto;
}

/**
 * 
 */
export class CorporateControllerApi extends runtime.BaseAPI {

    /**
     */
    async acceptCorporateInvitationRaw(requestParameters: AcceptCorporateInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCorporateInvitationDto>> {
        if (requestParameters['invitationId'] == null) {
            throw new runtime.RequiredError(
                'invitationId',
                'Required parameter "invitationId" was null or undefined when calling acceptCorporateInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate/invitation/{invitationId}/accept`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters['invitationId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCorporateInvitationDtoFromJSON(jsonValue));
    }

    /**
     */
    async acceptCorporateInvitation(requestParameters: AcceptCorporateInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCorporateInvitationDto> {
        const response = await this.acceptCorporateInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async activeCorporateMemberRaw(requestParameters: ActiveCorporateMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCorporateMemberDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling activeCorporateMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate/member/{userId}/activate`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCorporateMemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async activeCorporateMember(requestParameters: ActiveCorporateMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCorporateMemberDto> {
        const response = await this.activeCorporateMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async cancelCorporateInvitationRaw(requestParameters: CancelCorporateInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCorporateInvitationDto>> {
        if (requestParameters['invitationId'] == null) {
            throw new runtime.RequiredError(
                'invitationId',
                'Required parameter "invitationId" was null or undefined when calling cancelCorporateInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate/invitation/{invitationId}/cancel`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters['invitationId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCorporateInvitationDtoFromJSON(jsonValue));
    }

    /**
     */
    async cancelCorporateInvitation(requestParameters: CancelCorporateInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCorporateInvitationDto> {
        const response = await this.cancelCorporateInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeActiveStatusRaw(requestParameters: ChangeActiveStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCorporateDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling changeActiveStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate/changeActiveStatus/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCorporateDtoFromJSON(jsonValue));
    }

    /**
     */
    async changeActiveStatus(requestParameters: ChangeActiveStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCorporateDto> {
        const response = await this.changeActiveStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createCorporateRaw(requestParameters: CreateCorporateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCreatedCorporateDto>> {
        if (requestParameters['corporateCreationDto'] == null) {
            throw new runtime.RequiredError(
                'corporateCreationDto',
                'Required parameter "corporateCreationDto" was null or undefined when calling createCorporate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CorporateCreationDtoToJSON(requestParameters['corporateCreationDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCreatedCorporateDtoFromJSON(jsonValue));
    }

    /**
     */
    async createCorporate(requestParameters: CreateCorporateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCreatedCorporateDto> {
        const response = await this.createCorporateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deactiveCorporateMemberRaw(requestParameters: DeactiveCorporateMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCorporateMemberDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling deactiveCorporateMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate/member/{userId}/deactive`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCorporateMemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async deactiveCorporateMember(requestParameters: DeactiveCorporateMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCorporateMemberDto> {
        const response = await this.deactiveCorporateMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async declineCorporateInvitationRaw(requestParameters: DeclineCorporateInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCorporateInvitationDto>> {
        if (requestParameters['invitationId'] == null) {
            throw new runtime.RequiredError(
                'invitationId',
                'Required parameter "invitationId" was null or undefined when calling declineCorporateInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate/invitation/{invitationId}/decline`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters['invitationId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCorporateInvitationDtoFromJSON(jsonValue));
    }

    /**
     */
    async declineCorporateInvitation(requestParameters: DeclineCorporateInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCorporateInvitationDto> {
        const response = await this.declineCorporateInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async editCorporateRaw(requestParameters: EditCorporateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCorporateDto>> {
        if (requestParameters['corporateEditDto'] == null) {
            throw new runtime.RequiredError(
                'corporateEditDto',
                'Required parameter "corporateEditDto" was null or undefined when calling editCorporate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CorporateEditDtoToJSON(requestParameters['corporateEditDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCorporateDtoFromJSON(jsonValue));
    }

    /**
     */
    async editCorporate(requestParameters: EditCorporateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCorporateDto> {
        const response = await this.editCorporateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllCorporatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetCorporateDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCorporateDtoFromJSON));
    }

    /**
     */
    async getAllCorporates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetCorporateDto>> {
        const response = await this.getAllCorporatesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllCorporatesForMemberRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetCorporateDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate/forMember`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCorporateDtoFromJSON));
    }

    /**
     */
    async getAllCorporatesForMember(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetCorporateDto>> {
        const response = await this.getAllCorporatesForMemberRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCorporateRaw(requestParameters: GetCorporateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCorporateDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getCorporate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCorporateDtoFromJSON(jsonValue));
    }

    /**
     */
    async getCorporate(requestParameters: GetCorporateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCorporateDto> {
        const response = await this.getCorporateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCorporateMembersAndInvitationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCorporateMembersAndInvitations>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate/membersAndInvitations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCorporateMembersAndInvitationsFromJSON(jsonValue));
    }

    /**
     */
    async getCorporateMembersAndInvitations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCorporateMembersAndInvitations> {
        const response = await this.getCorporateMembersAndInvitationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserInvitationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetCorporateInvitationDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate/invitation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCorporateInvitationDtoFromJSON));
    }

    /**
     */
    async getUserInvitations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetCorporateInvitationDto>> {
        const response = await this.getUserInvitationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async hasInvitationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate/hasInvitations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async hasInvitations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.hasInvitationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async inviteTakerForCorporateRaw(requestParameters: InviteTakerForCorporateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['memberInvitationDto'] == null) {
            throw new runtime.RequiredError(
                'memberInvitationDto',
                'Required parameter "memberInvitationDto" was null or undefined when calling inviteTakerForCorporate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/corporate/invitation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MemberInvitationDtoToJSON(requestParameters['memberInvitationDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async inviteTakerForCorporate(requestParameters: InviteTakerForCorporateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.inviteTakerForCorporateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
