import React from 'react'
import { NavigationRoute } from '../../../enumeration/NavigationRoute'
import { Button } from '@mui/material'
import { COLORS } from '../../../util/Consts'
import copy_link_icon from '../../svg/resources/copy_link_icon.svg'

interface CopyInterviewLinkInterface {
  interviewID?: string
  iconicStyle?: boolean
}

function CopyInterviewLink (props: CopyInterviewLinkInterface) {
  if (!props.interviewID) {
    return <></>
  }

  const link = window.location.host + NavigationRoute.INSTANT_INTERVIEW_JOIN.replace(':id', props.interviewID)

  const handleCopy = () => {
    if (link) {
      void navigator.clipboard.writeText(link)
    }
  }

  return (
    <div>
      {!props.iconicStyle &&
        <Button
          variant="outlined"
          size="small"
          onClick={handleCopy}
          style={{
            color: COLORS.MAIN_COLOR,
            borderColor: COLORS.MAIN_COLOR
          }}>
          Copy
        </Button>}
      {props.iconicStyle &&
        <Button
          variant="outlined"
          size="small"
          onClick={handleCopy}
          style={{
            color: 'white',
            borderColor: 'white'
          }}>
          <div className={'d-flex flex-row gap-2 align-items-center'}>
            Copy
            <img
              src={copy_link_icon}
              alt={copy_link_icon}
              height={15}
              width={15}
              style={{ cursor: 'pointer', userSelect: 'none' }}
            />
          </div>
        </Button>}
    </div>
  )
}

export default CopyInterviewLink
