import React, { type CSSProperties, useLayoutEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { GiverHomePageRouteType } from '../enumeration/GiverHomePageRouteTypes'
import SVGHandler from '../components/svg/SVGHandler'
import giver_page_arrow from '../components/svg/resources/giver_page_arrow.svg'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import { useNavigate } from 'react-router'
import { Badge } from '@mui/material'
import { VerificationRequestDtoTypeEnum } from '../api/ic'
import { useSelector } from 'react-redux'
import { GetAccessToken } from '../features/AccessTokenSlice'
import { isCorporateTaker, isGiver, isPublicTaker, isTaker } from '../shared/role_validation_functions'

interface InterviewBarProps {
  setExplore: React.Dispatch<React.SetStateAction<boolean>>
  setUpcoming: React.Dispatch<React.SetStateAction<boolean>>
  setHistory: React.Dispatch<React.SetStateAction<boolean>>
  Explore: boolean
  Upcoming: boolean
  History: boolean
  isUpcomingBlank: boolean
  isHistoryBlank: boolean
}
function InterviewBar (props: InterviewBarProps) {
  const [activeButton, setActiveButton] = useState(0)
  const navigate = useNavigate()
  const jwtToken = useSelector(GetAccessToken)
  const isGiverMode: boolean = (isGiver(jwtToken) || (jwtToken?.role === VerificationRequestDtoTypeEnum.RoleTaker && !jwtToken.takerModeEnabled))
  const isUserCorporateTaker = isCorporateTaker(jwtToken)
  const isUserPublicTaker = isPublicTaker(jwtToken)
  const isUserTaker = isTaker(jwtToken)
  useLayoutEffect(() => {
    if (window.location.href.includes(NavigationRoute.HOME_PAGE + NavigationRoute.INTERVIEW_HISTORY)) {
      setActiveButton(GiverHomePageRouteType.History)
    } else if (window.location.href.includes(NavigationRoute.HOME_PAGE + NavigationRoute.TAKE_INTERVIEW)) {
      if (isUserCorporateTaker) {
        setActiveButton(GiverHomePageRouteType.TakeInterview)
      } else if (isUserPublicTaker && !isGiverMode) {
        setActiveButton(GiverHomePageRouteType.TakeInterview)
      } else {
        navigate(NavigationRoute.HOME_PAGE)
        setActiveButton(GiverHomePageRouteType.Explore)
      }
    } else if (window.location.href.includes(NavigationRoute.HOME_PAGE + NavigationRoute.UPCOMINGS)) {
      if (isUserCorporateTaker) {
        setActiveButton(GiverHomePageRouteType.Upcoming)
      } else if (isUserPublicTaker && !isGiverMode) {
        setActiveButton(GiverHomePageRouteType.Upcoming)
      } else if (isUserTaker) {
        setActiveButton(GiverHomePageRouteType.Upcoming)
      } else {
        navigate(NavigationRoute.HOME_PAGE)
        setActiveButton(GiverHomePageRouteType.Explore)
      }
    } else {
      if (isUserCorporateTaker) {
        navigate(NavigationRoute.HOME_PAGE + NavigationRoute.TAKE_INTERVIEW)
        setActiveButton(GiverHomePageRouteType.TakeInterview)
      } else if (isUserPublicTaker && !isGiverMode) {
        navigate(NavigationRoute.HOME_PAGE + NavigationRoute.TAKE_INTERVIEW)
        setActiveButton(GiverHomePageRouteType.TakeInterview)
      } else {
        navigate(NavigationRoute.HOME_PAGE)
        setActiveButton(GiverHomePageRouteType.Explore)
      }
    }
  }, [window.location.href,
    activeButton,
    jwtToken])

  const buttonStyle = (index: number): CSSProperties => {
    return {
      textDecoration: 'none',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      fontFamily: 'Inter,sans-serif',
      marginLeft: '40px',
      color: index === activeButton ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.85)',
      cursor: index === activeButton ? 'not-allowed' : 'pointer'
    }
  }

  return (
    <div style={{ width: '100%' }} className={'container-md'}>
      <div
        className={'container-md'}>
        <Navbar
          style={{ borderRadius: '10px', background: '#F2F2F2' }}>
          <Container>
            <Nav className="me-auto">
              {(!(isUserCorporateTaker || (isUserPublicTaker && !isGiverMode))) && (
                <Nav.Link
                  onClick={() => {
                    navigate(NavigationRoute.HOME_PAGE)
                  }}
                  style={buttonStyle(GiverHomePageRouteType.Explore)}>
                  Explore
                </Nav.Link>
              )}
              {(isUserCorporateTaker || (isUserPublicTaker && !isGiverMode)) &&
                <div style={{ position: 'relative' }}>
                  <Badge
                    style={{ position: 'absolute', top: '12px', right: '2px' }}
                    color='secondary'
                    variant="dot"/>
                  <Nav.Link
                    onClick={() => {
                      navigate(NavigationRoute.HOME_PAGE + NavigationRoute.TAKE_INTERVIEW)
                    }}
                    // TODO this switching mechanism does not work
                    style={buttonStyle(GiverHomePageRouteType.TakeInterview)}>
                    <div style={{ whiteSpace: 'nowrap' }}>{jwtToken.corporate ? 'Create Interview' : 'Take'}</div>
                  </Nav.Link>
                </div>}
              {isUserTaker &&
              <Nav.Link
                onClick={() => {
                  navigate(NavigationRoute.HOME_PAGE + NavigationRoute.UPCOMINGS)
                }}
                style={buttonStyle(GiverHomePageRouteType.Upcoming)}>
                Upcomings
              </Nav.Link>
              }
              <Nav.Link
                onClick={() => {
                  navigate(NavigationRoute.HOME_PAGE + '/history')
                }}
                style={buttonStyle(GiverHomePageRouteType.History)}>
                History
                {(props.isHistoryBlank && props.History) && <div
                  className={'d-flex flex-row'}
                  style={{ position: 'absolute' }}>
                  <div
                    style={{ paddingTop: '40px', position: 'absolute' }}
                    className={'d-flex flex-row'}>
                    <SVGHandler svg={giver_page_arrow} inner_style={{ position: 'absolute' }}/>
                  </div>
                  <div
                    style={{
                      paddingTop: '110px',
                      paddingLeft: '220px',
                      color: '#000',
                      textAlign: 'center',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '36px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: 'normal',
                      overflow: 'hidden'
                    }}>
                    <p style={{ marginRight: '8px' }}>
                      Make your History today
                    </p>
                    <p>Explore world of interviews…</p>
                  </div>
                </div>}
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </div>
    </div>
  )
}

export default InterviewBar
