/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Questions
 * @export
 * @interface QuestionSearchResponse
 */
export interface QuestionSearchResponse {
    /**
     * Question id
     * @type {string}
     * @memberof QuestionSearchResponse
     */
    id: string;
    /**
     * content
     * @type {string}
     * @memberof QuestionSearchResponse
     */
    content: string;
    /**
     * Date of question creation
     * @type {Date}
     * @memberof QuestionSearchResponse
     */
    createdAt?: Date;
}

/**
 * Check if a given object implements the QuestionSearchResponse interface.
 */
export function instanceOfQuestionSearchResponse(value: object): value is QuestionSearchResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function QuestionSearchResponseFromJSON(json: any): QuestionSearchResponse {
    return QuestionSearchResponseFromJSONTyped(json, false);
}

export function QuestionSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionSearchResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'content': json['content'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
    };
}

export function QuestionSearchResponseToJSON(value?: QuestionSearchResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'content': value['content'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
    };
}

