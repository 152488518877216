/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetInterviewDto } from './GetInterviewDto';
import {
    GetInterviewDtoFromJSON,
    GetInterviewDtoFromJSONTyped,
    GetInterviewDtoToJSON,
} from './GetInterviewDto';
import type { GetQuestionWithAnswerDto } from './GetQuestionWithAnswerDto';
import {
    GetQuestionWithAnswerDtoFromJSON,
    GetQuestionWithAnswerDtoFromJSONTyped,
    GetQuestionWithAnswerDtoToJSON,
} from './GetQuestionWithAnswerDto';

/**
 * 
 * @export
 * @interface GetInterviewAnalyzingResultDto
 */
export interface GetInterviewAnalyzingResultDto {
    /**
     * 
     * @type {GetInterviewDto}
     * @memberof GetInterviewAnalyzingResultDto
     */
    interview: GetInterviewDto;
    /**
     * Interview questions with answers
     * @type {Array<GetQuestionWithAnswerDto>}
     * @memberof GetInterviewAnalyzingResultDto
     */
    questions: Array<GetQuestionWithAnswerDto>;
    /**
     * Average points
     * @type {number}
     * @memberof GetInterviewAnalyzingResultDto
     */
    averagePoints: number;
}

/**
 * Check if a given object implements the GetInterviewAnalyzingResultDto interface.
 */
export function instanceOfGetInterviewAnalyzingResultDto(value: object): value is GetInterviewAnalyzingResultDto {
    if (!('interview' in value) || value['interview'] === undefined) return false;
    if (!('questions' in value) || value['questions'] === undefined) return false;
    if (!('averagePoints' in value) || value['averagePoints'] === undefined) return false;
    return true;
}

export function GetInterviewAnalyzingResultDtoFromJSON(json: any): GetInterviewAnalyzingResultDto {
    return GetInterviewAnalyzingResultDtoFromJSONTyped(json, false);
}

export function GetInterviewAnalyzingResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInterviewAnalyzingResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'interview': GetInterviewDtoFromJSON(json['interview']),
        'questions': ((json['questions'] as Array<any>).map(GetQuestionWithAnswerDtoFromJSON)),
        'averagePoints': json['averagePoints'],
    };
}

export function GetInterviewAnalyzingResultDtoToJSON(value?: GetInterviewAnalyzingResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'interview': GetInterviewDtoToJSON(value['interview']),
        'questions': ((value['questions'] as Array<any>).map(GetQuestionWithAnswerDtoToJSON)),
        'averagePoints': value['averagePoints'],
    };
}

