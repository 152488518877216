/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetQuestionDto } from './GetQuestionDto';
import {
    GetQuestionDtoFromJSON,
    GetQuestionDtoFromJSONTyped,
    GetQuestionDtoToJSON,
} from './GetQuestionDto';

/**
 * 
 * @export
 * @interface GetInterviewQuestionWrapperDto
 */
export interface GetInterviewQuestionWrapperDto {
    /**
     * 
     * @type {GetQuestionDto}
     * @memberof GetInterviewQuestionWrapperDto
     */
    dto?: GetQuestionDto;
    /**
     * Is template completed
     * @type {boolean}
     * @memberof GetInterviewQuestionWrapperDto
     */
    completed: boolean;
}

/**
 * Check if a given object implements the GetInterviewQuestionWrapperDto interface.
 */
export function instanceOfGetInterviewQuestionWrapperDto(value: object): value is GetInterviewQuestionWrapperDto {
    if (!('completed' in value) || value['completed'] === undefined) return false;
    return true;
}

export function GetInterviewQuestionWrapperDtoFromJSON(json: any): GetInterviewQuestionWrapperDto {
    return GetInterviewQuestionWrapperDtoFromJSONTyped(json, false);
}

export function GetInterviewQuestionWrapperDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInterviewQuestionWrapperDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dto': json['dto'] == null ? undefined : GetQuestionDtoFromJSON(json['dto']),
        'completed': json['completed'],
    };
}

export function GetInterviewQuestionWrapperDtoToJSON(value?: GetInterviewQuestionWrapperDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dto': GetQuestionDtoToJSON(value['dto']),
        'completed': value['completed'],
    };
}

