import React, { type CSSProperties, useCallback, useEffect, useRef, useState } from 'react'
import infoIcon from '../svg/resources/info.svg'
import StandardTooltip from '../util/StandardTooltip'
import { INPUT_MODES, InputModeValues } from '../modes/InputModes'
import { FormControl, MenuItem, Select } from '@mui/material'
import ColoredText, { type ColoredTextItem } from '../text/ColoredText'
import dark_down from '../svg/resources/down_arrow.svg'
import down from '../svg/resources/interview_down.svg'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import { FontModeValues } from '../modes/FontMode'

interface SmallDropdownProps {
  items: Array<string | number>
  label?: string
  error?: boolean
  hintMessage?: ColoredTextItem[]
  sizeMode?: InputModeValues
  selected: string | number | undefined
  setIsSelected: React.Dispatch<React.SetStateAction<any>>
  disabled?: boolean
  alreadySelectedOptions?: Array<string | number | undefined>
  logoMode?: 'light' | 'dark'
  onClick?: () => void
  backgroundColor?: string
  borderRadius?: string
  leftIcon?: string
  fontColor?: string
  onChange?: (val: string | number) => void
  placeholder?: string
  showOptionWhenDisabled?: boolean
  isRequired?: boolean
  leftIconHeight?: string
  leftIconWidth?: string
  eachItemStyles?: CSSProperties
  selectedItemStyles?: CSSProperties
  disabledBgColor?: string
  dontShowError?: boolean
}

function SmallDropdown (props: SmallDropdownProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const dropRef: any = useRef(null)
  const disabled = props.disabled ?? false
  const [open, setOpen] = useState<boolean>(false)
  const onClick = props.onClick ?? (() => {})
  const logoMode = props.logoMode ?? 'dark'

  let width: string
  let height: string
  if (props.sizeMode) {
    if (INPUT_MODES[props.sizeMode]?.mobile) {
      if (isMobile) {
        height = INPUT_MODES[props.sizeMode].mobile.height
        width = INPUT_MODES[props.sizeMode].mobile.width
      } else {
        height = INPUT_MODES[props.sizeMode].browser.height
        width = INPUT_MODES[props.sizeMode].browser.width
      }
    } else {
      height = INPUT_MODES[props.sizeMode].height
      width = INPUT_MODES[props.sizeMode].width
    }
  } else {
    height = INPUT_MODES[InputModeValues.DEFAULT].height
    width = INPUT_MODES[InputModeValues.DEFAULT].width
  }

  const DropDownIcon = useCallback(() => (
    <div
      style={{
        marginLeft: '5px',
        marginRight: '5px'
      }}
      onClick={() => { setOpen(prev => !prev) } }>
      {
        !disabled
          ? <img
              src = {logoMode === 'light' ? down : dark_down}
              alt = 'down'
              width = {20}/>
          : <div></div>
      }

    </div>
  ), [disabled])

  useEffect(() => {
    props.setIsSelected(() => (props.selected !== '' || props.selected !== undefined) ? props.selected : undefined)
  }, [props.items])

  const backgroundColor = props.backgroundColor ?? 'white'
  const eachItemStyles = props.eachItemStyles ?? {}
  const selectedItemStyles = props.selectedItemStyles ?? {}

  return (
    <div
      style={{ pointerEvents: disabled ? 'none' : 'auto', width }}
      className={'d-inline-flex flex-column outline-none justify-content-start'}
      onClick = {onClick}
      >
      <div className={'d-flex flex-row align-items-center'}
        style={{ paddingLeft: '2px', marginBottom: '5px' }}>
        {props.label && <span
          className={'d-flex flex-row'}
          style={{ fontSize: '14px', color: '#000000', visibility: props.label ? 'visible' : 'hidden' }}>
          {props.label || 'any'}
          {props.isRequired && <span style={{ color: 'red', fontSize: '14px', userSelect: 'none' }}>*</span>}
        </span>}
        {
          props.hintMessage &&
          <StandardTooltip
            texts={props.hintMessage}>
            <img
              src={infoIcon}
              alt={'Info'}
              style={{ marginLeft: '3px', paddingBottom: '3.2px' }}/>
          </StandardTooltip>
        }
      </div>
      <span style={{ cursor: 'pointer' }} onClick={() => { setOpen(!open) } }>
        <FormControl
          sx={{
            '& .MuiOutlinedInput-notchedOutline': { borderStyle: 'none' },
            input: { cursor: 'pointer !important' },
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            minWidth: 200,
            flex: 1
          }}
          fullWidth>
          <Select
            value={props.selected || ''}
            ref = {dropRef}
            autoWidth={false}
            style={{ textAlign: 'left' }}
            onChange={(e) => {
              const val = +e.target.value || e.target.value
              if (props.onChange) { props.onChange(val) }
              props.setIsSelected(val)
            }
          }
            renderValue={(value: string | number | undefined) => {
              return (
                <div>
                  {props.leftIcon ? (
                    <div className={'d-flex flex-row align-items-center gap-1'}>
                      <img
                        src={props.leftIcon}
                        alt='icon'
                        style={{ userSelect: 'none', paddingRight: '4px' }}
                        height={props.leftIconHeight ? props.leftIconHeight : '20px'}
                        width={props.leftIconWidth ? props.leftIconWidth : '20px'}
                        draggable={false}
                      />
                      {(!props.selected) &&
                      <span
                        style={{
                          color: props.fontColor ? props.fontColor : 'black',
                          opacity: 0.6
                        }}>
                        {props.placeholder ?? 'Select'}
                      </span>}
                      {props.selected && (
                        <span>{value}</span>
                      )}
                    </div>
                  ) : (
                    <>
                      {(!props.selected) &&
                      <span
                        style={{
                          color: props.fontColor ? props.fontColor : 'black',
                          opacity: 0.6
                        }}>
                        {props.placeholder ?? 'Select'}
                      </span>}
                      {props.selected && (
                      <span>{value}</span>
                      )}
                    </>
                  )}
                </div>
              )
            }}
            open = {open}
            onOpen = {() => { setOpen(!disabled) } }
            onClose={() => { setOpen(false) } }
            displayEmpty
            IconComponent={DropDownIcon}
            sx = {{
              width,
              height,
              bgcolor: !disabled ? backgroundColor : props.disabledBgColor ? props.disabledBgColor : '#f5f5f5',
              boxShadow: props.error ? '0px 0px 4px 0px #FF0001' : props.logoMode !== 'light' ? '0px 0px 4px 0px rgba(0, 0, 0, 0.25)' : '',
              outline: 'none !important',
              border: 'none',
              borderRadius: props.borderRadius ?? '10px',
              cursor: 'pointer',
              marginTop: '-2px',
              padding: '16.5px 0px 16.5px 8px',
              fontFamily: 'Inter, sans-serif',
              color: !disabled ? props.fontColor || 'rgba(0, 0, 0, 1)' : props.showOptionWhenDisabled ? props.fontColor || 'rgba(0, 0, 0, 1)' : '#f5f5f5',
              position: 'relative',
              textAlign: 'left', // Align the text to the left
              '& .MuiSelect-select': {
                paddingRight: '20px !important'
              }
            }}
            multiline
            MenuProps={{
              // disable scroll lock
              disableScrollLock: true,
              // allow the menu to go outside the window
              PaperProps: {
                sx: {
                  marginTop: '2px',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  borderRadius: '10px',
                  maxHeight: 180,
                  backgroundColor
                }
              },
              sx: {
                '&& .Mui-selected': {
                  ...selectedItemStyles
                }
              }
            }}
            maxRows={5}>
            {
              props.items.length > 0
                ? props.items.map((el, idx) => (
                  !props.alreadySelectedOptions?.includes(el) ? (
                    <MenuItem
                      sx={{
                        padding: '12px 10px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        ...eachItemStyles
                      }}
                      key={idx}
                      value={el}>
                      {el}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      hidden={true}
                      sx={{ padding: '12px 10px', zIndex: 1700, ...eachItemStyles }}
                      key={idx}
                      value={el}>
                      {el}
                    </MenuItem>
                  )
                ))
                : null
            }

          </Select>
        </FormControl>
      </span>
      {!props.dontShowError && (
        <div
          style={{ height: '25px' }}>
          <ColoredText
            fontWeight={300}
            fontSize={FontModeValues.EXTRA_SMALL}
            color={'#FF0001'}
            singleText={''} // TODO: add error message if needed
            userSelect={'none'}/>
        </div>
      )}

    </div>
  )
}

export default SmallDropdown
