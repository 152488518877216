/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QuestionSearchResponse } from './QuestionSearchResponse';
import {
    QuestionSearchResponseFromJSON,
    QuestionSearchResponseFromJSONTyped,
    QuestionSearchResponseToJSON,
} from './QuestionSearchResponse';

/**
 * 
 * @export
 * @interface QuestionSearchResponseWrapper
 */
export interface QuestionSearchResponseWrapper {
    /**
     * Search type (e.g. MY, ALL)
     * @type {string}
     * @memberof QuestionSearchResponseWrapper
     */
    searchType: QuestionSearchResponseWrapperSearchTypeEnum;
    /**
     * Questions
     * @type {Array<QuestionSearchResponse>}
     * @memberof QuestionSearchResponseWrapper
     */
    questions: Array<QuestionSearchResponse>;
}


/**
 * @export
 */
export const QuestionSearchResponseWrapperSearchTypeEnum = {
    My: 'MY',
    All: 'ALL'
} as const;
export type QuestionSearchResponseWrapperSearchTypeEnum = typeof QuestionSearchResponseWrapperSearchTypeEnum[keyof typeof QuestionSearchResponseWrapperSearchTypeEnum];


/**
 * Check if a given object implements the QuestionSearchResponseWrapper interface.
 */
export function instanceOfQuestionSearchResponseWrapper(value: object): value is QuestionSearchResponseWrapper {
    if (!('searchType' in value) || value['searchType'] === undefined) return false;
    if (!('questions' in value) || value['questions'] === undefined) return false;
    return true;
}

export function QuestionSearchResponseWrapperFromJSON(json: any): QuestionSearchResponseWrapper {
    return QuestionSearchResponseWrapperFromJSONTyped(json, false);
}

export function QuestionSearchResponseWrapperFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionSearchResponseWrapper {
    if (json == null) {
        return json;
    }
    return {
        
        'searchType': json['searchType'],
        'questions': ((json['questions'] as Array<any>).map(QuestionSearchResponseFromJSON)),
    };
}

export function QuestionSearchResponseWrapperToJSON(value?: QuestionSearchResponseWrapper | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'searchType': value['searchType'],
        'questions': ((value['questions'] as Array<any>).map(QuestionSearchResponseToJSON)),
    };
}

