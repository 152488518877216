import React, { useEffect, useState } from 'react'
import StandardList from './StandardList'
import { type GetInterviewDto, RecordingControllerApi } from '../api/ic'
import ApiManager from '../manager/ApiManager'
import ColoredText from '../components/text/ColoredText'
import Navigation from '../components/navigation/Navigation'
import { NavigationModeValues } from '../components/modes/NavigationMode'

function RecordingManagementList () {
  const [recordings, setRecordings] = useState<GetInterviewDto[]>([])

  const recordingControllerApi = ApiManager.getInstance(RecordingControllerApi)

  useEffect(() => {
    void recordingControllerApi.getAllRecordings().then(res => {
      setRecordings(res)
    })
  }, [])

  const columnNames = [
    'topic',
    'recording',
    'taker',
    'fullDate',
    'recordingManagementActions',
    'recordingState'
  ]

  return (
    <div>
      <Navigation
        navigationMode={NavigationModeValues.FIXED_MAIN_WITH_PLAN_AND_PROFILE}/>
      {recordings.length ? <StandardList
        rows={recordings}
        withArrow={true}
        columnNames={columnNames}
        deleteButton={false}/>
        : <div
            className={'container-md'}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
          <ColoredText singleText={'There are no recordings to manage — this is a great chance to explore new interviews!'}/>
        </div>}
    </div>
  )
}

export default RecordingManagementList
