import React, { useEffect, useLayoutEffect, useState } from 'react'
import Navigation from '../navigation/Navigation'
import { NavigationModeValues } from '../modes/NavigationMode'
import { useParams } from 'react-router-dom'
import ColoredText from '../text/ColoredText'
import SyncOffsetVideos from '../../pages/mpd_player/SyncOffsetVideos'
import ApiManager from '../../manager/ApiManager'
import { type GetInterviewDto, GetInterviewDtoStatusEnum, InterviewControllerApi } from '../../api/ic'
import { formatDate } from '../VerifyModal/utils'
import StarIcon from '@mui/icons-material/Star'

function InterviewRecordingPage () {
  const { interviewID } = useParams()
  const [analyzed, setAnalyzed] = useState(false)
  const [interview, setInterview] = useState<GetInterviewDto>()
  const [avgPoints, setAvgPoints] = useState<number | undefined>(undefined)

  useEffect(() => {
    document.body.style.background = 'rgba(32, 33, 36, 1)'
    return () => { document.body.style.background = 'white' }
  }, [])

  const interviewApi = ApiManager.getInstance(InterviewControllerApi)

  useLayoutEffect(() => {
    if (interviewID) {
      void interviewApi.getInterviewByID({ id: interviewID }).then((res) => {
        setInterview(res)
        setAnalyzed(res.status === GetInterviewDtoStatusEnum.Analyzed)
      })
    }
  }, [interviewID])

  function makeInterviewInfoHeader (interview: GetInterviewDto) {
    return (<div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10
    }}>
      <ColoredText
        color={'white'}
        singleText={`${formatDate(interview.acceptedDate)} / ${interview.name}`}/>
      {avgPoints && (<div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 3
      }}>
        <StarIcon style={{
          color: '#FFD700',
          fontSize: '1.6em'
        }}/>
        <ColoredText
          color={'white'}
          singleText={`${avgPoints.toFixed(1)}/5`}/>
      </div>)}
    </div>)
  }

  return (
    <div>
      <Navigation
        navigationMode={NavigationModeValues.FIXED_WHITE_WITH_INTERVIEW_INFO}
        mobileNavItems={[]}
        height={'100px'}/>
      <div className={'container-md'} style={{ display: 'flex', justifyContent: 'end' }}>
        {interview && makeInterviewInfoHeader(interview)}
      </div>
      {analyzed && interviewID
        ? <SyncOffsetVideos interviewId={interviewID} setAvgPoints={setAvgPoints}/>
        : <div className={'container d-flex justify-content-center align-items-center'} style={{ height: '80vh' }}>
          <ColoredText
            singleText={'Our team is working to make your recording ready, wait a little bit...'}
            color={'white'}/>
        </div>
      }
    </div>
  )
}

export default InterviewRecordingPage
