export const scrollTo = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({ behavior: 'instant' })
  }
}

const months = ['Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec']

export function formatDate (date: Date | undefined) {
  if (!date) {
    return ''
  }
  const month = months[date.getMonth()]
  const day = date.getDate()
  const year = date.getFullYear()

  // Zero-pad hours/minutes to always have two digits (e.g., '02', '09', '14')
  const hour = String(date.getHours()).padStart(2, '0')
  const minute = String(date.getMinutes()).padStart(2, '0')

  return `${month} ${day} ${year} ${hour}:${minute}`
}

export const getRootHost = () => {
  const host = window.location.host

  const parts = host.split('.')
  if (host.endsWith('.com')) {
    if (parts.length === 3) {
      return `${parts[1]}.${parts[2]}`
    }
    return `${host}`
  } else {
    // case: localhost
    if (parts.length === 2) {
      return `${parts[1]}`
    }
    return `${host}`
  }
}

export const isCorporateDomain = () => {
  const host = window.location.host

  const parts = host.split('.')
  if (host.endsWith('.com')) {
    return parts.length === 3 && parts[0] !== 'www'
  } else {
    // case: localhost
    return parts.length === 2
  }
}

export const makeCorporateLink = (domain: string) => {
  const rootHost = getRootHost()
  return `http://${domain}.${rootHost}`
}

export const getPublicIsHost = () => {
  const rootHost = getRootHost()
  return `http://${rootHost}`
}
