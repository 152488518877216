/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCorporateDto
 */
export interface GetCorporateDto {
    /**
     * Corporate ID
     * @type {string}
     * @memberof GetCorporateDto
     */
    id: string;
    /**
     * Corporate name
     * @type {string}
     * @memberof GetCorporateDto
     */
    name: string;
    /**
     * Corporate description
     * @type {string}
     * @memberof GetCorporateDto
     */
    description: string;
    /**
     * Corporate domain
     * @type {string}
     * @memberof GetCorporateDto
     */
    domain: string;
    /**
     * Corporate status
     * @type {boolean}
     * @memberof GetCorporateDto
     */
    active: boolean;
}

/**
 * Check if a given object implements the GetCorporateDto interface.
 */
export function instanceOfGetCorporateDto(value: object): value is GetCorporateDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('domain' in value) || value['domain'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    return true;
}

export function GetCorporateDtoFromJSON(json: any): GetCorporateDto {
    return GetCorporateDtoFromJSONTyped(json, false);
}

export function GetCorporateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCorporateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'domain': json['domain'],
        'active': json['active'],
    };
}

export function GetCorporateDtoToJSON(value?: GetCorporateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'domain': value['domain'],
        'active': value['active'],
    };
}

