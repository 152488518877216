export const enum InputModeValues {
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  DEFAULT = 'DEFAULT',
  MEDIUM = 'MEDIUM',
  BIG = 'BIG',
  DOUBLE_BIG = 'DOUBLE_BIG',
  EXTRA_BIG = 'EXTRA_BIG',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
  TEXT_AREA = 'TEXT_AREA',
  CUSTOM_FONT = 'CUSTOM_FONT',
  LARGER = 'LARGER',
  TINY_BIG = 'TINY_BIG',
  TEXT_AREA_MODAL = 'TEXT_AREA_MODAL',
  TINY_EXTRA_BIG = 'TINY_EXTRA_BIG',
  TAKER_TEXT_AREA = 'TAKER_TEXT_AREA',
  MEDIUM_WIDTH = 'MEDIUM_WIDTH',
  MAX_WIDTH = 'MAX_WIDTH',
  MAX_WIDTH_2 = 'MAX_WIDTH_2',
  AVERAGE_WIDTH = 'AVERAGE_WIDTH',
  TEXT_AREA_MAX_WIDTH = 'TEXT_AREA_MAX_WIDTH',
  SMALL_DROPDOWN = 'SMALL_DROPDOWN',
  AVERAGE_PERCENTAGE = 'AVERAGE_PERCENTAGE'
}

export const INPUT_MODES: Record<InputModeValues, any> = {
  [InputModeValues.EXTRA_SMALL]: {
    width: '132px',
    height: '40px'
  },
  [InputModeValues.SMALL]: {
    width: '140px',
    height: '32px'
  },
  [InputModeValues.DEFAULT]: {
    width: '160px',
    height: '32px'
  },
  [InputModeValues.MEDIUM]: {
    width: '190px',
    height: '40px'
  },
  [InputModeValues.BIG]: {
    width: '252px',
    height: '48px'
  },
  [InputModeValues.DOUBLE_BIG]: {
    width: '504px',
    height: '48px'
  },
  [InputModeValues.EXTRA_BIG]: {
    width: '305px',
    height: '48px'
  },
  [InputModeValues.TINY_BIG]: {
    width: '305px',
    height: '40px'
  },
  [InputModeValues.LARGE]: {
    width: '320px',
    height: '48px'
  },
  [InputModeValues.EXTRA_LARGE]: {
    browser: {
      width: '720px',
      height: '40px'
    },
    mobile: {
      width: '320px',
      height: '40px'
    }
  },
  [InputModeValues.TEXT_AREA]: {
    browser: {
      width: '720px',
      height: '260px'
    },
    mobile: {
      width: '320px',
      height: '310px'
    }
  },
  [InputModeValues.TEXT_AREA_MODAL]: {
    browser: {
      width: '100%',
      height: '260px'
    },
    mobile: {
      width: '100%',
      height: '310px'
    }
  },
  [InputModeValues.TINY_EXTRA_BIG]: {
    browser: {
      width: '100%',
      height: '32px'
    },
    mobile: {
      width: '100%',
      height: '32px'
    }
  },
  [InputModeValues.CUSTOM_FONT]: {
    width: 'auto',
    height: 'auto'
  },
  [InputModeValues.TAKER_TEXT_AREA]: {
    browser: {
      width: '100%',
      height: '175px'
    },
    mobile: {
      width: '305px',
      height: '175px'
    }
  },
  [InputModeValues.LARGER]: {
    browser: {
      width: '550px',
      height: '48px'
    },
    mobile: {
      width: '320px',
      height: '48px'
    }
  },
  [InputModeValues.MEDIUM_WIDTH]: {
    width: '60%',
    height: '40px'
  },
  [InputModeValues.MAX_WIDTH]: {
    width: '100%',
    height: '40px'
  },
  [InputModeValues.MAX_WIDTH_2]: {
    width: '100%',
    height: '40px'
  },
  [InputModeValues.AVERAGE_WIDTH]: {
    browser: {
      width: '75%',
      height: '40px'
    },
    mobile: {
      width: '100%',
      height: '40px'
    }
  },
  [InputModeValues.TEXT_AREA_MAX_WIDTH]: {
    browser: {
      width: '100%',
      height: '260px'
    },
    mobile: {
      width: '320px',
      height: '310px'
    }
  },
  [InputModeValues.SMALL_DROPDOWN]: {
    browser: {
      width: '180px',
      height: '48px'
    },
    mobile: {
      width: '320px',
      height: '48px'
    }
  },
  [InputModeValues.AVERAGE_PERCENTAGE]: {
    browser: {
      width: '100%',
      height: '100px'
    },
    mobile: {
      width: '100%',
      height: '50px'
    }
  }
}
