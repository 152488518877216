import React, { useCallback, useEffect, useState } from 'react'
import ProfileMenu from './ProfileMenu'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import MarginWrapper from '../util/MarginWrapper'
import VerifyModal from '../VerifyModal/VerifyModal'
import toast from 'react-hot-toast'
import { CorporateControllerApi, GetICUserDtoTakerStatusEnum as TakerStatus } from '../../api/ic'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import { GetAccessToken } from '../../features/AccessTokenSlice'
import { useSelector } from 'react-redux'
import ApiManager from '../../manager/ApiManager'
import { isCorporateTaker, isGiver } from '../../shared/role_validation_functions'
import { useNavigate } from 'react-router-dom'

function NavigationProfile () {
  const body = useSelector(GetAccessToken)
  const navigate = useNavigate()
  const MAIN_ROWS = [
    {
      value: 'Home',
      route: NavigationRoute.HOME_PAGE,
      color: 'black'
    },
    {
      value: 'Profile',
      route: NavigationRoute.PROFILE,
      color: 'black'
    },
    // Conditionally spread an array containing the extra item
    ...(!isCorporateTaker(body)
      ? [{
          value: 'Manage Recordings',
          route: NavigationRoute.RECORDINGS_MANAGEMENT,
          color: 'black'
        }]
      : []
    )
  ]
  const [show, setShow] = useState(false)
  const [hasInvitations, setHasInvitations] = useState<boolean>(false)
  const [mainRows, setMainRows] = useState(MAIN_ROWS)
  const takerValidationRequested = body?.takerStatus === TakerStatus.RequestedForValidation
  const takerValidationRejected = body?.takerStatus === TakerStatus.Rejected

  const corporateApi = ApiManager.getInstance(CorporateControllerApi)

  // TODO: make generic functional component for this functionality
  window.onload = function () {
    checkForToast('RequestAgain', 'true', 'Requested for validation successfully')
  }
  const removeQueryParameter = (query: any) => {
    if (history.replaceState) {
      const url = new URL(window.location.href)
      url.searchParams.delete(query)
      const newUrl = url.toString()
      history.replaceState({}, '', newUrl)
    }
  }
  const checkForToast = (query: string, value: string, message: string) => {
    const { search } = window.location
    const isToastNeeded = new URLSearchParams(search).get(query)
    if (isToastNeeded === value) {
      // The page was just reloaded, display the toast:
      toast.success(message)
      removeQueryParameter(query)
    }
  }

  const handleOpenModal = () => { setShow(true) }
  const handleClose = useCallback(() => {
    setShow(false)
  }, [])

  useEffect(() => {
    void corporateApi.hasInvitations()
      .then(has => {
        if (has) {
          setHasInvitations(true)
          setMainRows([
            ...MAIN_ROWS,
            {
              value: 'Corporate Invitations',
              route: NavigationRoute.CORPORATE_INVITATIONS,
              color: 'black'
            }
          ])
        }
      })
      .catch(() => {})
    return () => {
      setHasInvitations(false)
    }
  }, [])
  return (
    <div className={'d-flex d-row justify-content-center align-items-center'}>
      {hasInvitations && (
        <MarginWrapper right={'22px'}>
          <span
            style={{
              textDecoration: 'underline',
              fontSize: '14px',
              whiteSpace: 'nowrap',
              cursor: 'pointer'
            }}
            onClick={() => {
              navigate(NavigationRoute.CORPORATE_INVITATIONS)
            }}>
            Corporate Invitations
          </span>
        </MarginWrapper>
      )}
      {isGiver(body) &&
        <MarginWrapper right={'22px'}>
          <StandardButton
            onClick={handleOpenModal}
            clickable={!takerValidationRequested}
            gradient={takerValidationRequested}
            text={takerValidationRejected ? 'Request Again' : takerValidationRequested ? 'Taker validation pending...' : 'Become a Taker'}
            sizeMode={ButtonModeValues.EPIC_SMALL_AUTO}/>
        </MarginWrapper>}
      <ProfileMenu
        imgURI={'DEFAULT'}
        role={body?.role}
        firstname={body?.firstName ?? 'firstname'}
        lastname={body?.lastName ?? 'lastName'}
        isTakerModeEnabled={body?.takerModeEnabled}
        corporate={body?.corporate}
        takerStatus={body?.takerStatus}
        inverse={true}
        main={mainRows}
        secondary={[
          {
            color: 'black',
            value: 'Settings',
            route: NavigationRoute.SETTING_PAYMENT_CARD
          },
          ...((isCorporateTaker(body))
            ? [
                {
                  color: 'black',
                  value: 'Manage Templates',
                  route: NavigationRoute.MANAGE_TEMPLATES
                }
              ]
            : [])
        ]}/>
      {(show)
        ? <VerifyModal
            setVerified={() => {
            }}
            onClose={handleClose}
            show={show}/>
        : <></>}
    </div>
  )
}

export default NavigationProfile
