import React, { useRef, useState } from 'react'
import camera from '../svg/resources/Camera.svg'
import intersect from '../svg/resources/Intersect.svg'
import DefaultProfilePicture from '../DefaultPictureProfile/DefaultPictureProfile'
import user_select_light from '../svg/resources/user_scan_light.svg'
import ImgCropper from './ImgCropper'
import ColoredText from '../text/ColoredText'
import { COLORED_TEXT_VALUES, SINGLE_TEXT_VALUES } from '../../util/Consts'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import { CropperType } from '../../enumeration/CropperType'
import StandardModal from '../modal/StandardModal'

interface profilePictureProps {
  name: string
  surname: string
  pictureSrc: string
  isChangeable?: boolean
  setIsOpened?: any
  width: string
  height?: string
  inverse?: boolean
  cropperType?: CropperType
}

function ProfilePicture (props: profilePictureProps) {
  const [profileUrl, setProfileUrl] = useState(props.pictureSrc)
  const [isOpen, setIsOpen] = useState(false)
  const [imgAvailable, setImgAvailable] = useState(false)
  const [cropping, setCropping] = useState(false)
  const [triggerInputWithoutModal, setTriggerInputWithoutModal] = useState(false)
  const cropRef = useRef<any>(null)
  const cropperType = props.cropperType ?? CropperType.WITH_MODAL
  const handleOpen = (event: any) => {
    if (props.setIsOpened) {
      props.setIsOpened(event)
    } else {
      return 0
    }
  }

  function handleCropping () {
    if (cropperType === CropperType.WITH_MODAL) {
      setIsOpen(true)
    } else if (cropperType === CropperType.WITHOUT_MODAL) {
      setTriggerInputWithoutModal(true)
    }
  }
  function saveImage () {
    if (cropRef.current) {
      const dataUrl = cropRef.current?.getImage().toDataURL()
      setProfileUrl(dataUrl)
      setCropping(false)
      setImgAvailable(false)
    }
    setIsOpen(false)
  }
  function cancelSaveImage () {
    console.log(`img av: ${imgAvailable}`)
    if (imgAvailable) {
      setCropping(false)
      setImgAvailable(false)
      setProfileUrl(prevState => { return prevState })
    } else {
      setCropping(false)
      setImgAvailable(false)
      setIsOpen(false)
      setProfileUrl(prevState => { return prevState })
    }
  }
  return (
    // TODO create components for each type
    <>
      {cropperType === CropperType.WITH_MODAL &&
        <div>
          <div
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
            <div
              onClick = {(event) => { handleOpen(event) } }>
              {
                  profileUrl === 'DEFAULT'
                    ? <DefaultProfilePicture
                        name = {props.name}
                        surname = {props.surname}
                        size = {parseInt(props.width)}
                        clickable={props.isChangeable}
                        inverse = {props.inverse}/>
                    : <img
                        src={profileUrl}
                        alt='picture'
                        style={{ borderRadius: '50%', cursor: props.setIsOpened ? 'pointer' : 'cursor' }}
                        width={props.width}
                        height={props.height ?? props.width}/>
                }
            </div>
            {
              props.isChangeable &&
                <>
                  <div
                    style={{ position: 'absolute', bottom: '0%', cursor: 'pointer', userSelect: 'none' }}
                    onClick={ handleCropping }>
                    <img
                      src={intersect}
                      alt='intersect'
                      width='110px'
                      draggable={false}/>
                    <img
                      src={camera}
                      alt='camera'
                      draggable={false}
                      style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }} />
                  </div>
                </>
            }
          </div>
          <StandardModal
            isOpen={isOpen}
            closeModal={setIsOpen}
            width={`${(screen.availWidth * 30 / 100)}px`}
            height={`${(screen.availHeight * 65 / 100)}px`}
            title={'Upload Image'}
            closeWhenClickingOutOfModal={true}
            footerComponent={ <>
              <span
                onClick={cancelSaveImage}>
                <ColoredText
                  texts={SINGLE_TEXT_VALUES.CANCEL}
                  userSelect={'none'}/>
              </span>
              <StandardButton
                sizeMode={ButtonModeValues.SMALL}
                text={'Save'}
                onClick={() => { saveImage() }}
                clickable={imgAvailable}/>
            </>}>
            <div className={'d-flex flex-column justify-content-center align-items-center'}>
              <ImgCropper
                setImgAvailable={setImgAvailable}
                cropRef={cropRef}
                setCropping={setCropping}
                cropping={cropping}/>
              {
                  !imgAvailable
                    ? <div
                        className={'d-flex flex-column'}>
                      <div
                        className={'d-flex flex-row mt-4'}
                        style={{ userSelect: 'none' }}>
                        <img
                          src={user_select_light}
                          alt={''}
                          draggable={false}/>
                        <div
                          className={'d-flex flex-column'}>
                          <span
                            style={{ fontSize: '12px', fontWeight: 400, fontFamily: 'Inter,sans-serif' }}>
                            Images
                          </span>
                          <span
                            style={{ fontSize: '12px', fontWeight: 300, fontFamily: 'Inter,sans-serif' }}>
                            PDF,PNG,SVG,512px*512px in app-cropping
                          </span>
                        </div>
                      </div>
                      <ColoredText texts={COLORED_TEXT_VALUES.READ_PRIVACY}/>
                    </div>
                    : null
                }
            </div>
          </StandardModal>
        </div>
      }

      {
        (props.cropperType === CropperType.WITHOUT_MODAL) &&
          <div>
              {!cropping
                ? (
                  <div
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}
                    onClick = {handleOpen}>
                    {profileUrl === 'DEFAULT'
                      ? (
                        <DefaultProfilePicture
                          name={props.name}
                          surname={props.surname}
                          size={parseInt(props.width)}
                          inverse={props.inverse}/>
                        )
                      : (
                        <img
                          src={profileUrl}
                          alt='picture'
                          style={{ borderRadius: '50%', cursor: props.setIsOpened ? 'pointer' : 'default' }}
                          width={props.width}
                          height={props.height ?? props.width}/>
                        )}
                    {props.isChangeable && (
                    <div
                      style={{ position: 'absolute', bottom: '0%', cursor: 'pointer', userSelect: 'none' }}
                      onClick={handleCropping}>
                      <img
                        src={intersect}
                        alt='intersect'
                        draggable={false}
                        width='100px' />
                      <img
                        src={camera}
                        alt='camera'
                        draggable={false}
                        style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}/>
                    </div>
                    )}
                  </div>
                  )
                : null
              }
            <ImgCropper
              setCropping={setCropping}
              cropping={cropping}
              cropRef={cropRef}
              cropperType={CropperType.WITHOUT_MODAL}
              triggerInputWithoutModal={triggerInputWithoutModal}
              setTriggerInputWithoutModal={setTriggerInputWithoutModal}
              width={props.width}
              height={props.height ?? props.width}/>
            { cropping &&
            <div
              className={'d-flex flex-row justify-content-between align-items-center gap-2 mt-1'}>
              <StandardButton
                sizeMode={ButtonModeValues.SMALLEST}
                text={'Cancel'}
                onClick={() => {
                  cancelSaveImage()
                }}
                backgroundColorMode={'dark'}/>
              <StandardButton
                sizeMode={ButtonModeValues.SMALLEST}
                text={'Save'}
                onClick={() => {
                  saveImage()
                }}/>
            </div>
            }
          </div>
      }
    </>
  )
}

export default ProfilePicture
