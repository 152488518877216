import React, { useEffect, useState } from 'react'
import Navigation from '../../navigation/Navigation'
import { NavigationModeValues } from '../../modes/NavigationMode'
import StandardInput from '../../input/StandardInput'
import { StandardInputModeValues } from '../../modes/StandardInputMode'
import StandardButton from '../../buttons/StandardButton'
import { ButtonModeValues } from '../../modes/ButtonMode'
import { FontModeValues } from '../../modes/FontMode'
import ColoredText from '../../text/ColoredText'
import toast from 'react-hot-toast'
import { NavigationRoute } from '../../../enumeration/NavigationRoute'
import ApiManager from '../../../manager/ApiManager'
import { InterviewControllerApi, VerificationRequestDtoTypeEnum } from '../../../api/ic'
import { AuthManager } from '../../../manager/AuthManager'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { GetAccessToken } from '../../../features/AccessTokenSlice'
import { Navigate, useParams } from 'react-router-dom'
import { getPublicIsHost } from '../../VerifyModal/utils'

function InstantInterviewProcess () {
  const { id } = useParams()
  const interviewId = id
  const body = useSelector(GetAccessToken) ?? {}
  const sessionExists = Object.keys(body).length !== 0

  useEffect(() => {
    if (!interviewId) {
      return
    }
    if (body?.role && (
      body?.role === VerificationRequestDtoTypeEnum.RoleInstantGiver ||
      body?.role === VerificationRequestDtoTypeEnum.RoleGiver ||
      body?.role === VerificationRequestDtoTypeEnum.RoleTaker)
    ) {
      console.log('navigating', NavigationRoute.INTERVIEW_JOIN.replace(':id', interviewId))
      navigate(NavigationRoute.INTERVIEW_JOIN.replace(':id', interviewId))
    }
  }, [interviewId])

  const [email, setEmail] = useState<string | undefined>(undefined)

  const interviewApi = ApiManager.getInstance(InterviewControllerApi)

  const navigate = useNavigate()

  const checkInterviewValidityAndGetSession = () => {
    if (!interviewId) {
      return
    }
    if (!email) {
      toast.error('Fill the email field to continue')
      return
    }

    void interviewApi.validateAndGetSession({
      instantInterviewGetSessionRequestDto: {
        interviewId,
        email
      }
    }).then(res => {
      AuthManager.setTokens(res, false)
      console.log('navigating', NavigationRoute.INTERVIEW_JOIN.replace(':id', interviewId))
      navigate(NavigationRoute.INTERVIEW_JOIN.replace(':id', interviewId))
    })
      .catch(() => {})
  }

  if (sessionExists && interviewId) {
    return <Navigate to={NavigationRoute.INTERVIEW_JOIN.replace(':id', interviewId)}/>
  }

  return (
    <div style={{ backgroundColor: '#202124', width: '100%', height: '100%', minHeight: '100vh', overflowX: 'hidden' }}>
      <div>
        <Navigation
          navigationMode={NavigationModeValues.FIXED_WHITE_WITH_INTERVIEW_INFO}
          mobileNavItems={[]}
          height={'40px'}/>
        <div
          className={'d-flex gap-5 flex-column align-items-center justify-content-center'}
          style={{ width: '100%', minHeight: '80vh', overflowX: 'hidden' }}>
          <div>
            <ColoredText
              centered={true}
              color={'white'}
              fontWeight={500}
              fontSize={FontModeValues.EXTRA_LARGE}
              lineHeight={1.2}
              texts={[
                {
                  value: 'Welcome to Interview Center',
                  newLine: true
                },
                {
                  value: 'Type your email to start!',
                  newLine: true
                }
              ]}/>
          </div>
          <div>
            <div className={'d-flex gap-4 flex-row align-items-center justify-content-center'}>
              <StandardInput
                label={'Invitation email'}
                labelColor={'white'}
                backgroundColor={'white'}
                placeHolder={'Enter your email'}
                inputValue={email}
                type={StandardInputModeValues.EMAIL}
                setInputValue={setEmail}/>
              <StandardButton
                text={'Join interview'}
                sizeMode={ButtonModeValues.DEFAULT}
                isLoading={false}
                textFontSize={FontModeValues.LARGE}
                textFontWeight={400}
                onClick={checkInterviewValidityAndGetSession}
              />
            </div>
            <div className={'d-flex flex-row gap-2'}>
              <ColoredText color={'white'} singleText={'To use full functional'}/>
              <ColoredText
                color={'white'}
                onClick={() => {
                  window.location.href = getPublicIsHost() + NavigationRoute.LOG_IN + `?nextUrl=${encodeURIComponent(window.location.href)}`
                }}
                cursor={'pointer'}
                textDecoration={'underline'}
                singleText={'Log in'}/>
              <ColoredText color={'white'} singleText={'or'}/>
              <ColoredText
                color={'white'}
                onClick={() => {
                  window.location.href = getPublicIsHost() + NavigationRoute.SIGN_UP + `?nextUrl=${encodeURIComponent(window.location.href)}`
                }}
                cursor={'pointer'}
                textDecoration={'underline'}
                singleText={'Sign up'}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstantInterviewProcess
