import React, { useLayoutEffect, useState } from 'react'
import Navigation from '../navigation/Navigation'
import { NavigationModeValues } from '../modes/NavigationMode'
import ColoredText from '../text/ColoredText'
import { COLORED_TEXT_VALUES } from '../../util/Consts'
import { FontModeValues } from '../modes/FontMode'
import StandardInput from '../input/StandardInput'
import { InputModeValues } from '../modes/InputModes'
import { StandardInputModeValues } from '../modes/StandardInputMode'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import message from '../svg/resources/dark_message.svg'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import { useNavigate, useParams } from 'react-router-dom'
import ApiManager from '../../manager/ApiManager'
import { InterviewControllerApi } from '../../api/ic'
import toast from 'react-hot-toast'
import { getHeaderTextForInterview } from './interviewComponents/InterviewPageFunctions'
import InterviewLoading from './InterviewLoading'
import { useSelector } from 'react-redux'
import { GetAccessToken } from '../../features/AccessTokenSlice'

function InterviewFeedbackPage () {
  const [feedback, setFeedback] = useState('')
  const [acceptedDate, setAcceptedDate] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [typeName, setTypeName] = useState('')
  const body = useSelector(GetAccessToken) ?? {}

  const navigate = useNavigate()
  const interviewApi = ApiManager.getInstance(InterviewControllerApi)
  const { interviewID } = useParams()
  useLayoutEffect(() => {
    setIsLoading(true)
    if (interviewID) {
      void interviewApi.getInterviewByID({ id: interviewID }).then((res) => {
        setAcceptedDate(res.acceptedDate?.getTime() ?? 0)
        setTypeName(res.name ?? '')
        if (body.userId === res.giver.id) {
          setFeedback(res.giverFeedback ?? '')
        } else {
          setFeedback(res.takerFeedback ?? '')
        }
      }).catch(() => { setIsLoading(false) })
    }
    setIsLoading(false)
  }, [interviewID])

  const publishFeedback = () => {
    void interviewApi.updateFeedback({
      updateFeedbackDto: { interviewId: interviewID, feedback }
    })
      .then(() => {
        toast.success('Feedback submitted successfully')
        navigate(NavigationRoute.HOME_PAGE)
      })
      .catch(() => {})
      .finally(() => {
        navigate(NavigationRoute.HOME_PAGE)
      })
  }

  return (
    <>
      {isLoading && (
        <InterviewLoading/>
      )}
      {!isLoading && (
        <div style={{ background: 'rgba(32, 33, 36, 1)' }}>
          <img
            src= { message}
            alt= 'message'
            style = {{
              position: 'absolute',
              left: 0,
              marginTop: '5%',
              zIndex: 100,
              width: '48%'
            }}/>
          <Navigation
            navigationMode={NavigationModeValues.FIXED_WHITE_WITH_INTERVIEW_INFO}
            interviewText={getHeaderTextForInterview(acceptedDate, typeName)}
            mobileNavItems={[]}
            height = {'100px'}/>
          <div
            style = {{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 101,
              position: 'relative',
              height: 'calc(100vh - 100px)'
            }}>
            <div>
              <ColoredText
                texts={COLORED_TEXT_VALUES.SHARE_YOUR_COSMIC}
                fontSize= {FontModeValues.EXTRA_LARGE}/>
            </div>
            <div style={{ marginTop: '60px' }}>
              <StandardInput
                maxLength={30000}
                inputValue = {feedback}
                setInputValue={setFeedback}
                label = 'Feedback Shuttle'
                placeHolder=''
                type= {StandardInputModeValues.TEXT_AREA}
                sizeMode= {InputModeValues.TEXT_AREA}
                labelColor='#AEC3FF'
                labelFontSize={16}
                textAreaCountColor={'white'}
                backgroundColor='#2E302F'
                textColor='white'/>
            </div>
            <div>
              <StandardButton
                sizeMode= {ButtonModeValues.FEEDBACK_BUTTON}
                style={{
                  background: feedback.length > 0 ? 'white' : 'gray',
                  boxShadow: 'none'
                }}
                clickable={feedback.length > 0}
                onClick={() => {
                  publishFeedback()
                }}
                textColor='black'
                textFontSize='20px'
                textFontWeight={500}
                text = {'Send'}/>
            </div>
            <div style = {{ marginTop: '30px' }}>
              <ColoredText
                texts={COLORED_TEXT_VALUES.EVERY_STAR}
                fontSize= {FontModeValues.AVERAGE}
                centered = {true}
                fontWeight={300}/>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default InterviewFeedbackPage
