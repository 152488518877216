/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetRuntimeInterviewTemplateDto } from './GetRuntimeInterviewTemplateDto';
import {
    GetRuntimeInterviewTemplateDtoFromJSON,
    GetRuntimeInterviewTemplateDtoFromJSONTyped,
    GetRuntimeInterviewTemplateDtoToJSON,
} from './GetRuntimeInterviewTemplateDto';

/**
 * 
 * @export
 * @interface GetRuntimeInterviewQuestionTemplateWrapperDto
 */
export interface GetRuntimeInterviewQuestionTemplateWrapperDto {
    /**
     * 
     * @type {GetRuntimeInterviewTemplateDto}
     * @memberof GetRuntimeInterviewQuestionTemplateWrapperDto
     */
    dto?: GetRuntimeInterviewTemplateDto;
    /**
     * Is template completed
     * @type {boolean}
     * @memberof GetRuntimeInterviewQuestionTemplateWrapperDto
     */
    completed: boolean;
}

/**
 * Check if a given object implements the GetRuntimeInterviewQuestionTemplateWrapperDto interface.
 */
export function instanceOfGetRuntimeInterviewQuestionTemplateWrapperDto(value: object): value is GetRuntimeInterviewQuestionTemplateWrapperDto {
    if (!('completed' in value) || value['completed'] === undefined) return false;
    return true;
}

export function GetRuntimeInterviewQuestionTemplateWrapperDtoFromJSON(json: any): GetRuntimeInterviewQuestionTemplateWrapperDto {
    return GetRuntimeInterviewQuestionTemplateWrapperDtoFromJSONTyped(json, false);
}

export function GetRuntimeInterviewQuestionTemplateWrapperDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRuntimeInterviewQuestionTemplateWrapperDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dto': json['dto'] == null ? undefined : GetRuntimeInterviewTemplateDtoFromJSON(json['dto']),
        'completed': json['completed'],
    };
}

export function GetRuntimeInterviewQuestionTemplateWrapperDtoToJSON(value?: GetRuntimeInterviewQuestionTemplateWrapperDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dto': GetRuntimeInterviewTemplateDtoToJSON(value['dto']),
        'completed': value['completed'],
    };
}

