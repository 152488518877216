import React, { useEffect, useState } from 'react'
import Navigation from '../components/navigation/Navigation'
import { NavigationModeValues } from '../components/modes/NavigationMode'
import InterviewBar from '../giver/InterviewBar'
import { Outlet } from 'react-router'
import { checkForToast } from '../util/Helper'
// import { type GetInterviewQuestionTemplateDto } from '../api/ic'

function HomePage () {
  const [Explore, setExplore] = useState(true)
  const [Upcoming, setUpcoming] = useState(false)
  const [History, setHistory] = useState(false)
  const isUpcomingBlank = false
  const isHistoryBlank = false
  useEffect(() => {
    checkForToast('error_in_interview', 'true', 'Unable to join', true)
  }, [])

  // const a: Partial<GetInterviewQuestionTemplateDto> = {}

  return (
    <>
      <Navigation
        navigationMode={NavigationModeValues.FIXED_MAIN_WITH_PLAN_AND_PROFILE}/>
      <div
        style = {{
          display: 'flex',
          flexDirection: 'column'
          // alignItems: 'center'
        }}>
        {/* TODO: setter props are deleted check for their usage if necessary */}
        <InterviewBar
          setExplore={setExplore}
          setUpcoming={setUpcoming}
          setHistory={setHistory}
          Explore={Explore}
          Upcoming={Upcoming}
          History={History}
          isUpcomingBlank={isUpcomingBlank}
          isHistoryBlank={isHistoryBlank}/>
        <Outlet />
      </div>
    </>
  )
}

export default HomePage
