/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetCorporateDto } from './GetCorporateDto';
import {
    GetCorporateDtoFromJSON,
    GetCorporateDtoFromJSONTyped,
    GetCorporateDtoToJSON,
} from './GetCorporateDto';
import type { GetICUserDto } from './GetICUserDto';
import {
    GetICUserDtoFromJSON,
    GetICUserDtoFromJSONTyped,
    GetICUserDtoToJSON,
} from './GetICUserDto';

/**
 * Corporate Member
 * @export
 * @interface GetCorporateMemberDto
 */
export interface GetCorporateMemberDto {
    /**
     * Membership ID
     * @type {string}
     * @memberof GetCorporateMemberDto
     */
    id: string;
    /**
     * 
     * @type {GetICUserDto}
     * @memberof GetCorporateMemberDto
     */
    member: GetICUserDto;
    /**
     * 
     * @type {GetCorporateDto}
     * @memberof GetCorporateMemberDto
     */
    corporate: GetCorporateDto;
    /**
     * Member Status
     * @type {string}
     * @memberof GetCorporateMemberDto
     */
    status: GetCorporateMemberDtoStatusEnum;
}


/**
 * @export
 */
export const GetCorporateMemberDtoStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type GetCorporateMemberDtoStatusEnum = typeof GetCorporateMemberDtoStatusEnum[keyof typeof GetCorporateMemberDtoStatusEnum];


/**
 * Check if a given object implements the GetCorporateMemberDto interface.
 */
export function instanceOfGetCorporateMemberDto(value: object): value is GetCorporateMemberDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('member' in value) || value['member'] === undefined) return false;
    if (!('corporate' in value) || value['corporate'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function GetCorporateMemberDtoFromJSON(json: any): GetCorporateMemberDto {
    return GetCorporateMemberDtoFromJSONTyped(json, false);
}

export function GetCorporateMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCorporateMemberDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'member': GetICUserDtoFromJSON(json['member']),
        'corporate': GetCorporateDtoFromJSON(json['corporate']),
        'status': json['status'],
    };
}

export function GetCorporateMemberDtoToJSON(value?: GetCorporateMemberDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'member': GetICUserDtoToJSON(value['member']),
        'corporate': GetCorporateDtoToJSON(value['corporate']),
        'status': value['status'],
    };
}

