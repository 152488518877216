import React, { useState } from 'react'
import ColoredText from '../text/ColoredText'
import { COLORED_TEXT_VALUES, SINGLE_TEXT_VALUES } from '../../util/Consts'
import StandardInput from '../input/StandardInput'
import { StandardInputModeValues } from '../modes/StandardInputMode'
import { InputModeValues } from '../modes/InputModes'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import instagram from '../svg/resources/purple_insta.svg'
import whatsapp from '../svg/resources/purple_whatsapp.svg'
import linkedin from '../svg/resources/purple_linkedin.svg'
import mail from '../svg/resources/purple_mail.svg'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import toast from 'react-hot-toast'
import ApiManager from '../../manager/ApiManager'
import { ContactUsControllerApi } from '../../api/ic'

function ContactUsRightPart () {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [triggerValidate, settriggerValidate] = useState(false)
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile

  const contactUsApi = ApiManager.getInstance(ContactUsControllerApi)

  const send = () => {
    if (name && email && message) {
      void contactUsApi.newRequest({
        contactUsDto: { name, email, message }
      }).then((res) => {
        if (res === 'SUCCESS') {
          toast.success('Thank you for reaching out, we\'ll communicate you very soon!')
          setName('')
          setEmail('')
          setMessage('')
        }
      })
    } else {
      toast.error('Fill al required fields')
    }
  }

  return (
    <div
      style = {{
        width: isMobile ? '100%' : '65%',
        padding: isMobile ? '0 20px 0 20px' : '30px 100px 0 100px',
        marginTop: isMobile ? '20px' : '0px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        overflow: 'scroll',
        alignItems: isMobile ? 'center' : ''
      }}>
      <ColoredText texts= {SINGLE_TEXT_VALUES.GET_IN_TOUCH} lineHeight={1}/>
      <ColoredText singleText='24/7 we will answer questions and problems'/>
      <StandardInput
        dontAlignStart
        label='Your name'
        placeHolder='Please enter your name'
        inputValue={name}
        isRequired={true}
        setInputValue={setName}
        triggerValidate={triggerValidate}
        sizeMode = {!isMobile ? InputModeValues.MAX_WIDTH : InputModeValues.EXTRA_LARGE}/>
      <StandardInput
        dontAlignStart
        sizeMode = {!isMobile ? InputModeValues.MAX_WIDTH : InputModeValues.EXTRA_LARGE}
        label='Email'
        isRequired={true}
        placeHolder='Please enter your email'
        inputValue={email}
        setInputValue={setEmail}
        triggerValidate={triggerValidate}
        type={StandardInputModeValues.EMAIL}/>
      <StandardInput
        dontAlignStart
        label='Message'
        placeHolder='Tell us what we can hel you with'
        inputValue={message}
        isRequired={true}
        setInputValue={setMessage}
        triggerValidate={triggerValidate}
        type= {StandardInputModeValues.TEXT_AREA}
        sizeMode= {InputModeValues.TEXT_AREA_MAX_WIDTH}
        maxLength={0}/>
      <StandardButton
        text = 'Send'
        textFontSize='20px'
        onClick={send}
        setTriggerValidate={settriggerValidate}
        sizeMode= {ButtonModeValues.EPIC_LARGE_MAX_WIDTH}/>
      {
        isMobile &&
        <>
          <ColoredText texts={COLORED_TEXT_VALUES.SOCIAL_MEDIA_MOBILE}/>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '7px',
              marginTop: '25px',
              marginBottom: '10px',
              userSelect: 'none'
            }}>
            <span style={{ cursor: 'pointer' }}>
              <img src = {instagram} alt = 'insta'
                onClick={() => { alert('instagram link') }}
                draggable={false}/>
            </span>
            <span style={{ cursor: 'pointer' }}>
              <img src = {whatsapp} alt = 'whatsapp'
                onClick={() => { alert('whatsapp link') }}
                draggable={false}/>
            </span>
            <span style={{ cursor: 'pointer' }}>
              <img src = {linkedin} alt = 'linkedin'
                onClick={() => { alert('linkedin link') }}
                draggable={false}/>
            </span>
            <span style={{ cursor: 'pointer' }}>
              <img src = {mail} alt = 'mail'
                onClick={() => { alert('mail link') }}
                draggable={false}/>
            </span>
          </div>
        </>
      }
    </div>
  )
}

export default ContactUsRightPart
