import React, { useEffect, useState } from 'react'
import AdminHeader from '../../components/admin/AdminHeader'
import StandardList from '../../giver/StandardList'
import { CorporateControllerApi, type GetCorporateInvitationDto, type GetCorporateMemberDto, GetCorporateMemberDtoStatusEnum } from '../../api/ic'
import ApiManager from '../../manager/ApiManager'

function CorporateAdminSeeTakers () {
  const [takerRows, setTakerRows] = useState<any[]>([])
  const corporateApi = ApiManager.getInstance(CorporateControllerApi)

  useEffect(() => {
    void corporateApi.getCorporateMembersAndInvitations()
      .then((res) => {
        const result = [
          ...buildInvitations(res.corporateInvitations), ...buildMembers(res.corporateMembers)
        ]
        setTakerRows(result)
      })
      .catch(() => {})
  }, [])

  const buildInvitations = (invitedMembers: GetCorporateInvitationDto[]) => {
    return invitedMembers.map((invitation) => {
      return {
        id: invitation.id,
        Email: invitation.email,
        status: invitation.status,
        available: false
      }
    })
  }

  const buildMembers = (memberships: GetCorporateMemberDto[]) => {
    return memberships.map((membership) => {
      return {
        id: membership.id,
        Email: membership.member.email,
        taker: membership.member,
        status: membership.status,
        available: membership.status === GetCorporateMemberDtoStatusEnum.Active
      }
    })
  }

  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <AdminHeader title={'All Takers'}/>
      {takerRows.length && <StandardList
        rows={takerRows}
        withArrow={true}
        columnNames={[
          'Email',
          'taker',
          'corporate_invitation_status',
          'available']}
        deleteButton={false}/>}
    </div>
  )
}

export default CorporateAdminSeeTakers
