import React, { useEffect, useState } from 'react'
import InterviewLoading from './components/VideoChat/InterviewLoading'
import { Navigate } from 'react-router-dom'
import { NavigationRoute } from './enumeration/NavigationRoute'
import { GetAccessToken } from './features/AccessTokenSlice'
import { useSelector } from 'react-redux'
import ApiManager from './manager/ApiManager'
import { SessionControllerApi } from './api/ic'
import { TRIED_SEARCH_PARAM } from './PromisedSessionProvider'

export const CALL_BACK_URL_PARAM = 'callbackUrl'
export const PROMISE_TOKEN_PARAM = 'promiseToken'

const PromisedSessionTokenProvider: React.FC = () => {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  const body = useSelector(GetAccessToken) ?? {}
  const sessionExists = Object.keys(body).length !== 0
  const sessionApi = ApiManager.getInstance(SessionControllerApi)
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null)

  // Extract callback URL
  const callBackUrlFromParams = params.get(CALL_BACK_URL_PARAM)
  if (!callBackUrlFromParams) {
    return <Navigate to={NavigationRoute.LOG_IN} />
  }

  const callbackUrl = decodeURIComponent(callBackUrlFromParams)
  const callbackUrlParams = new URL(callbackUrl)
  callbackUrlParams.searchParams.set(TRIED_SEARCH_PARAM, 'true')

  useEffect(() => {
    if (sessionExists) {
      sessionApi.grantSessionPromiseToken()
        .then((res) => {
          callbackUrlParams.searchParams.set(PROMISE_TOKEN_PARAM, res)
          setRedirectUrl(callbackUrlParams.toString())
        })
        .catch(() => {
          setRedirectUrl(callbackUrlParams.toString())
        })
    } else {
      setRedirectUrl(callbackUrlParams.toString())
    }
  }, [sessionExists])

  useEffect(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl
    }
  }, [redirectUrl])

  return <InterviewLoading />
}

export default PromisedSessionTokenProvider
