import React, { useEffect, useState } from 'react'
import StandardInput from '../components/input/StandardInput'
import StandardButton from '../components/buttons/StandardButton'
import MarginWrapper from '../components/util/MarginWrapper'
import AdminHeader from '../components/admin/AdminHeader'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { StandardInputModeValues } from '../components/modes/StandardInputMode'
import ApiManager from '../manager/ApiManager'
import {
  CorporateControllerApi,
  type CorporateCreationDto,
  type CorporateEditDto
} from '../api/ic'
import { makeCorporateLink } from '../components/VerifyModal/utils'

function CreateEditCorporatePage () {
  const [idVal, setIdVal] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [domain, setDomain] = useState('')
  const { id } = useParams()

  const corporateApi = ApiManager.getInstance(CorporateControllerApi)

  useEffect(() => {
    if (id) {
      void corporateApi.getCorporate({ id })
        .then(res => {
          setIdVal(res.id)
          setName(res.name)
          setDescription(res.description)
          setDomain(res.domain)
        }).catch(() => {})
    }
  }, [])

  const createEdit = async () => {
    if (!name || !description || !domain) {
      toast.error('Fill all information')
      return
    }

    const body: CorporateCreationDto = {
      name,
      description,
      domain
    }

    if (id) {
      const editBody = body as CorporateEditDto
      editBody.id = id
      await corporateApi.editCorporate({
        corporateEditDto: editBody
      }).then(async res => {
        toast.success(`Successfully edited corporate ${res.name}`)
      }).catch(() => {})
    } else {
      await corporateApi.createCorporate({
        corporateCreationDto: body
      }).then(async res => {
        toast.success(`Successfully created corporate ${res.name}`)

        const fileContent = `Admin Email: ${res.defaultAdminEmail}\nAdmin Password: ${res.defaultAdminPassword}`
        const blob = new Blob([fileContent], { type: 'text/plain' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'admin_credentials.txt'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch(() => {})
    }
  }

  const link = domain ? `${makeCorporateLink(domain)}/login` : ''
  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <AdminHeader title={`${id ? 'Editing' : 'Adding new '} corporate`}/>

      <MarginWrapper top={'40px'}>
        <StandardInput
          label={'Id'}
          inputValue={idVal}
          setInputValue={setIdVal}
          type={StandardInputModeValues.ONLYREAD}
          placeHolder={'id'}/>
        <StandardInput
          label={'Corporate name'}
          inputValue={name}
          setInputValue={setName}
          placeHolder={'Interview center'}/>
        <StandardInput
          label={'Corporate description'}
          inputValue={description}
          setInputValue={setDescription}
          placeHolder={'Interviewing platform company'}/>
        <StandardInput
          label={'Corporate domain'}
          inputValue={domain}
          type={id ? StandardInputModeValues.ONLYREAD : StandardInputModeValues.TEXT}
          setInputValue={setDomain}
          placeHolder={'ic'}/>
        <MarginWrapper top={'20px'}>
          <a href={link}
            target="_blank"
            rel="noreferrer">
            {link}
          </a>
        </MarginWrapper>
        <MarginWrapper top={'20px'}>
          <StandardButton onClick={createEdit}/>
        </MarginWrapper>
      </MarginWrapper>
    </div>
  )
}

export default CreateEditCorporatePage
