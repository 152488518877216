/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomTemplateCreationRequestDto
 */
export interface CustomTemplateCreationRequestDto {
    /**
     * Template name
     * @type {string}
     * @memberof CustomTemplateCreationRequestDto
     */
    name: string;
    /**
     * Interview date
     * @type {string}
     * @memberof CustomTemplateCreationRequestDto
     */
    interviewType: string;
    /**
     * Interview date
     * @type {Array<string>}
     * @memberof CustomTemplateCreationRequestDto
     */
    levels: Array<CustomTemplateCreationRequestDtoLevelsEnum>;
}


/**
 * @export
 */
export const CustomTemplateCreationRequestDtoLevelsEnum = {
    Beginner: 'BEGINNER',
    Intermediate: 'INTERMEDIATE',
    Pro: 'PRO'
} as const;
export type CustomTemplateCreationRequestDtoLevelsEnum = typeof CustomTemplateCreationRequestDtoLevelsEnum[keyof typeof CustomTemplateCreationRequestDtoLevelsEnum];


/**
 * Check if a given object implements the CustomTemplateCreationRequestDto interface.
 */
export function instanceOfCustomTemplateCreationRequestDto(value: object): value is CustomTemplateCreationRequestDto {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('interviewType' in value) || value['interviewType'] === undefined) return false;
    if (!('levels' in value) || value['levels'] === undefined) return false;
    return true;
}

export function CustomTemplateCreationRequestDtoFromJSON(json: any): CustomTemplateCreationRequestDto {
    return CustomTemplateCreationRequestDtoFromJSONTyped(json, false);
}

export function CustomTemplateCreationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomTemplateCreationRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'interviewType': json['interviewType'],
        'levels': json['levels'],
    };
}

export function CustomTemplateCreationRequestDtoToJSON(value?: CustomTemplateCreationRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'interviewType': value['interviewType'],
        'levels': value['levels'],
    };
}

