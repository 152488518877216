import React, { useCallback, useEffect, useState } from 'react'
import BoxWithBorder from './BoxWithBorder'
import { CustomTemplate } from './CustomTemplate'
import ColoredText from '../../components/text/ColoredText'
import { COLORS } from '../../util/Consts'
import { InlineEditableText } from './InlineEditableText'
import SelectableList from './SelectableList'
import QuestionBank from './QuestionBank'
import { DragDropContext, type DropResult } from '@hello-pangea/dnd'
import StandardInput from '../../components/input/StandardInput'
import { InputModeValues } from '../../components/modes/InputModes'
import Navigation from '../../components/navigation/Navigation'
import { NavigationModeValues } from '../../components/modes/NavigationMode'
import StandardModal from '../../components/modal/StandardModal'
import StandardButton from '../../components/buttons/StandardButton'
import { ButtonModeValues } from '../../components/modes/ButtonMode'
import debounce from 'lodash.debounce'
import { StandardSlideChoice } from '../../components/SlideChoice/StandardSlideChoice'
import {
  type CustomTemplateCreationRequestDtoLevelsEnum,
  type EnumTypeDto,
  EnumValuesControllerApi,
  GetDropdownListTypeEnum,
  GetInterviewDtoLevelEnum as InterviewLevel,
  type GetInterviewTemplateDto,
  type GetTemplateSection,
  InterviewRuntimeTemplateControllerApi,
  type TemplateEditRequestDto,
  type GetQuestionDto,
  InterviewQuestionControllerApi,
  QuestionSearchRequestDtoSearchTypeEnum
} from '../../api/ic'
import EnumValueDropDown from '../../components/dropdown/EnumValueDropDown'
import ApiManager from '../../manager/ApiManager'
import toast from 'react-hot-toast'
import GenericUnderlinedText from '../../components/underlinedText/GenericUnderlinedText'
import StandardConfirmationModal from '../../components/modal/StandardConfirmationModal'
import { CircularProgress } from '@mui/material'

function CustomTemplatePage () {
  const interviewTypeApi = ApiManager.getInstance(EnumValuesControllerApi)
  const questionsApi = ApiManager.getInstance(InterviewQuestionControllerApi)
  const templateApi = ApiManager.getInstance(InterviewRuntimeTemplateControllerApi)

  const [searchText, setSearchText] = React.useState('')
  const [name, setName] = React.useState('')
  const [type, setType] = React.useState<EnumTypeDto | undefined>()
  const [levels, setLevels] = React.useState<string[]>([])
  const [availableTypes, setAvailableTypes] = React.useState<EnumTypeDto[]>([])
  const [isOpen, setIsOpen] = React.useState(false)
  const [questionBank, setQuestionBank] = React.useState<any[]>([])
  const [questionSearchLoading, setQuestionSearchLoading] = useState(false)
  const [templateData, setTemplateData] = useState<GetInterviewTemplateDto[]>([])
  const [currentTemplate, setCurrentTemplate] = useState<GetInterviewTemplateDto | undefined>()
  const [currentTemplateId, setCurrentTemplateId] = useState<string | undefined>()
  const [searchTypeValue, setSearchTypeValue] = useState<QuestionSearchRequestDtoSearchTypeEnum>(
    QuestionSearchRequestDtoSearchTypeEnum.My
  )
  const [warningIds, setWarningIds] = useState<{ sectionIds: string[], questionIds: string[] }>({
    sectionIds: [],
    questionIds: []
  })
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)

  // -----------------------------
  // New state for unsaved changes
  // -----------------------------
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [showDiscardModal, setShowDiscardModal] = useState(false)
  const [nextTemplateId, setNextTemplateId] = useState<string | undefined>(undefined)
  // ---------------------------------------------
  // Warn user if they try to close/refresh the tab
  // ---------------------------------------------
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault()
        e.returnValue = 'You have unsaved changes. Are you sure you want to leave?'
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [hasUnsavedChanges])

  // --------------------------------------------------
  // Function to sort a fetched template (sections, etc.)
  // --------------------------------------------------
  function sortTemplate (template: GetInterviewTemplateDto): GetInterviewTemplateDto {
    return {
      ...template,
      sections: [...(template.sections ?? [])]
        .sort((a, b) => (a.sectionOrder ?? 0) - (b.sectionOrder ?? 0))
        .map(section => ({
          ...section,
          questions: [...(section.questions ?? [])]
            .sort((q1, q2) => (q1.order ?? 0) - (q2.order ?? 0))
        }))
    }
  }

  // ---------------------------------------
  // Effect to load the active template data
  // ---------------------------------------
  useEffect(() => {
    if (currentTemplateId) {
      const foundTemplate = templateData.find((template) => template.id === currentTemplateId)
      if (foundTemplate) {
        setCurrentTemplate(foundTemplate)
        if (!foundTemplate.sections.length) {
          void templateApi.getTemplateById({ templateId: currentTemplateId }).then((res: GetInterviewTemplateDto) => {
            const sortedTemplate = sortTemplate(res)
            setCurrentTemplate(sortedTemplate)
          })
        }
      } else {
        void templateApi.getTemplateById({ templateId: currentTemplateId }).then((res: GetInterviewTemplateDto) => {
          const sortedTemplate = sortTemplate(res)
          setCurrentTemplate(sortedTemplate)
        })
      }
    }
  }, [currentTemplateId])

  // -------------------------------------------------------
  // Debounce-based question search (existing code condensed)
  // -------------------------------------------------------
  const doSearch = useCallback(
    debounce((value: string, searchType: QuestionSearchRequestDtoSearchTypeEnum) => {
      setQuestionSearchLoading(true)
      void questionsApi.search({
        questionSearchRequestDto: {
          searchType,
          interviewType: currentTemplate?.interviewType,
          searchValue: value,
          excludedIds: currentTemplate?.sections
            .map(section => section.questions.map(q => q.id))
            .filter(id => !!id)
            .flat() ?? []
        }
      }).then(res => {
        setQuestionBank(res.questions)
        setQuestionSearchLoading(false)
      }).catch(err => {
        setQuestionSearchLoading(false)
        console.log(err)
        toast.error("Couldn't fetch questions")
      })
    }, 1000),
    [currentTemplate]
  )

  useEffect(() => {
    doSearch(searchText, searchTypeValue)
    return () => {
      doSearch.cancel()
    }
  }, [searchText,
    searchTypeValue,
    doSearch])

  // ----------------------------------
  // Sync currentTemplate with templateData
  // ----------------------------------
  useEffect(() => {
    if (currentTemplate && templateData.length) {
      const foundTemplate = templateData.find((template) => template.id === currentTemplateId)
      if (!foundTemplate) {
        toast.error("Something went wrong. Couldn't find template")
        return
      }

      const warningIds: {
        sectionIds: string[]
        questionIds: string[]
      } = {
        sectionIds: [],
        questionIds: []
      }

      currentTemplate.sections.forEach((section) => {
        // Check for duplicate section labels
        const matchingSections = currentTemplate.sections.filter(s => s.label === section.label)
        if (matchingSections.length > 1) {
          warningIds.sectionIds.push(section.id)
        }

        // Check for duplicate questions within the SAME section
        const questionContentMap: Record<string, string[]> = {}

        section.questions.forEach((question) => {
          if (!questionContentMap[question.content]) {
            questionContentMap[question.content] = []
          }
          questionContentMap[question.content].push(question.id)
        })

        // Add question IDs that appear more than once
        Object.values(questionContentMap).forEach(ids => {
          if (ids.length > 1) {
            warningIds.questionIds.push(...ids)
          }
        })
      })

      const updatedTemplates = templateData.map(template =>
        template.id === currentTemplate.id ? currentTemplate : template
      )
      setTemplateData(updatedTemplates)
      setWarningIds(warningIds)
    }
  }, [currentTemplate])

  // -------------------------------
  // Fetch all Taker templates on load
  // -------------------------------
  useEffect(() => {
    void templateApi.getAllTakerTemplates().then(
      (res: GetInterviewTemplateDto[]) => {
        const sortedTemplates = res.map(template => ({
          ...template,
          sections: template.sections
            .sort((a, b) => a.sectionOrder - b.sectionOrder)
            .map(section => ({
              ...section,
              questions: section.questions.sort((q1, q2) => q1.order - q2.order)
            }))
        }))
        setTemplateData(sortedTemplates)
        if (res.length) {
          setCurrentTemplate(sortedTemplates[0])
          setCurrentTemplateId(sortedTemplates[0].id)
        }
      }
    ).catch(error => {
      console.log(error)
      toast.error("Couldn't load templates")
    })
    return () => {
      setSearchTypeValue(QuestionSearchRequestDtoSearchTypeEnum.All)
    }
  }, [])

  // --------------------------------
  // Fetch interview types if modal open
  // --------------------------------
  useEffect(() => {
    if (isOpen) {
      void interviewTypeApi.getDropdownList({ type: GetDropdownListTypeEnum.AvailableInterviewTypes })
        .then((res: EnumTypeDto[]) => {
          setAvailableTypes(res)
        }).catch(() => {})
    }
  }, [isOpen])

  // ---------------------------------------
  // Reorder helpers (lists, drag & drop, etc.)
  // ---------------------------------------
  const reorderList = <T,>(list: T[], startIndex: number, endIndex: number): T[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const moveItemBetweenLists = <T,>(
    sourceList: T[],
    destinationList: T[],
    sourceIndex: number,
    destinationIndex: number
  ): { source: T[], destination: T[] } => {
    const sourceClone = Array.from(sourceList)
    const destClone = Array.from(destinationList)
    const [removed] = sourceClone.splice(sourceIndex, 1)
    destClone.splice(destinationIndex, 0, removed)

    return {
      source: sourceClone,
      destination: destClone
    }
  }

  function resetSectionOrder (sections: GetTemplateSection[]): GetTemplateSection[] {
    return sections.map((section, index) => ({
      ...section,
      sectionOrder: index + 1
    }))
  }

  function resetQuestionOrder (questions: GetQuestionDto[]): GetQuestionDto[] {
    return questions.map((q, index) => ({
      ...q,
      order: index + 1
    }))
  }

  // ----------------------------
  // DRAG & DROP onDragEnd handler
  // ----------------------------
  const onDragEnd = (result: DropResult) => {
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const { source, destination, draggableId, type } = result

    // If no destination (e.g., dropped outside a droppable), do nothing
    if (!destination) return

    // If the draggable is placed exactly where it started, do nothing
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    // 1. Decline if moving TO question-bank from any other section
    if (destination.droppableId === 'question-bank' && source.droppableId !== 'question-bank') {
      return
    }

    // If moving from Question Bank to a Section
    if (source.droppableId === 'question-bank') {
      const questionToMove = questionBank.find(q => q.id === draggableId)
      if (!questionToMove) return

      setQuestionBank((prev: any) => prev.filter((q: any) => q.id !== draggableId))
      setCurrentTemplate(prevState => {
        if (!prevState) return prevState
        const newSections = prevState.sections.map(section => {
          if (section.id === destination.droppableId) {
            const updatedQuestions = Array.from(section.questions)
            updatedQuestions.splice(destination.index, 0, questionToMove)
            const finalQuestions = resetQuestionOrder(updatedQuestions)
            return {
              ...section,
              questions: finalQuestions
            }
          }
          return section
        })
        return { ...prevState, sections: newSections }
      })
      setHasUnsavedChanges(true) // Mark unsaved
      return
    }

    // If we're reordering entire sections
    if (type === 'SECTION') {
      if (!currentTemplate) return
      const newSections = reorderList(currentTemplate.sections, source.index, destination.index)
      const finalSections = resetSectionOrder(newSections)
      setCurrentTemplate(prevState => {
        if (!prevState) return prevState
        return { ...prevState, sections: finalSections }
      })
      setHasUnsavedChanges(true) // Mark unsaved
      return
    }

    // Otherwise, we’re moving/reordering questions within or across sections
    if (!currentTemplate) return
    const sourceSectionIndex = currentTemplate.sections.findIndex(
      (section) => section.id === source.droppableId
    )
    const destinationSectionIndex = currentTemplate.sections.findIndex(
      (section) => section.id === destination.droppableId
    )

    const sourceSection = currentTemplate.sections[sourceSectionIndex]
    const destinationSection = currentTemplate.sections[destinationSectionIndex]

    // If dropping within the same section
    if (sourceSection.id === destinationSection.id) {
      const newQuestions = reorderList(
        sourceSection.questions,
        source.index,
        destination.index
      )
      const finalQuestions = resetQuestionOrder(newQuestions)
      const updatedSections = Array.from(currentTemplate.sections)
      updatedSections[sourceSectionIndex] = {
        ...sourceSection,
        questions: finalQuestions
      }
      setCurrentTemplate(prevState => {
        if (!prevState) return prevState
        return { ...prevState, sections: updatedSections }
      })
      setHasUnsavedChanges(true) // Mark unsaved
    } else {
      // Move question from source section to destination section
      const { source: newSourceQuestions, destination: newDestQuestions } =
        moveItemBetweenLists(
          sourceSection.questions,
          destinationSection.questions,
          source.index,
          destination.index
        )
      const finalSourceQuestions = resetQuestionOrder(newSourceQuestions)
      const finalDestQuestions = resetQuestionOrder(newDestQuestions)

      const updatedSections = Array.from(currentTemplate.sections)
      updatedSections[sourceSectionIndex] = {
        ...sourceSection,
        questions: finalSourceQuestions
      }
      updatedSections[destinationSectionIndex] = {
        ...destinationSection,
        questions: finalDestQuestions
      }
      setCurrentTemplate(prevState => {
        if (!prevState) return prevState
        return { ...prevState, sections: updatedSections }
      })
      setHasUnsavedChanges(true) // Mark unsaved
    }
  }

  // ------------------------
  // Deleting entire template
  // ------------------------
  const deleteTemplate = () => {
    if (!currentTemplateId) {
      return
    }
    void templateApi.deleteTemplate({
      id: currentTemplateId
    }).then(() => {
      toast.success('Template Deleted successfully')
      setTemplateData(prev => prev.filter(t => t.id !== currentTemplateId))
      setCurrentTemplate(undefined)
      setCurrentTemplateId(undefined)
    }).catch(() => {})
  }

  // --------------------------------
  // Handle switching templates safely
  // --------------------------------
  const handleTemplateSelect = (id: string | undefined) => {
    if (!id) return
    // if there are unsaved changes, show a discard-changes modal
    if (hasUnsavedChanges) {
      setNextTemplateId(id)
      setShowDiscardModal(true)
    } else {
      // No unsaved changes -> just switch
      setCurrentTemplateId(id)
    }
  }

  // --------------------------
  // Rendering the main component
  // --------------------------
  return (
    <div style={{ height: '100vh' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Navigation
          navigationMode={NavigationModeValues.FIXED_MAIN_WITH_PLAN_AND_PROFILE} />
        <StandardModal
          isOpen={isOpen}
          closeWhenClickingOutOfModal={false}
          title={'New Template'}
          titleColor={'black'}
          width={`${(screen.availWidth * 30 / 100)}px`}
          height={`${(screen.availHeight * 50 / 100)}px`}
          footerComponent={
            <>
              <div></div>
              <div className={'d-flex flex-row gap-4 align-items-center'}>
                <ColoredText
                  singleText={'Cancel'}
                  cursor={'pointer'}
                  onClick={() => {
                    setIsOpen(false)
                    setLevels([])
                    setName('')
                    setType(undefined)
                  } }
                />
                <StandardButton
                  clickable={(name !== '' && type !== undefined && levels.length !== 0)}
                  text={'Create'}
                  sizeMode={ButtonModeValues.SMALL}
                  onClick={() => {
                    void templateApi.generateTemplate({
                      customTemplateCreationRequestDto: {
                        name,
                        interviewType: type?.key ?? '',
                        levels: levels as CustomTemplateCreationRequestDtoLevelsEnum[]
                      }
                    }).then((res: GetInterviewTemplateDto) => {
                      setTemplateData(prev => [res, ...prev])
                      setCurrentTemplate(res)
                      setCurrentTemplateId(res.id)
                      setIsOpen(false)
                      setLevels([])
                      setName('')
                      setType(undefined)
                    }).catch((err: Error) => {
                      console.log(err)
                      toast.error("Couldn't create template")
                    })
                  }}
                />
              </div>
            </>
          }
        >
          <div className={'d-inline-flex flex-column gap-1'}>
            <StandardInput
              dontAlignStart
              label={'Name'}
              isRequired={true}
              placeHolder={'Template Name'}
              inputValue={name}
              sizeMode={InputModeValues.MAX_WIDTH}
              setInputValue={setName}
            />
            <EnumValueDropDown
              isRequired={true}
              placeholder={'Select'}
              items={availableTypes ?? []}
              selectedValue={type}
              setSelectedValue={setType}
              label={'Interview Type'}
              sizeMode={InputModeValues.MAX_WIDTH}
            />
            <StandardSlideChoice
              isRequired={true}
              label="Level(s)"
              singleChoice={false}
              setSelection={setLevels}
              values={[
                {
                  value: InterviewLevel.Beginner,
                  isChosen: levels.includes(InterviewLevel.Beginner)
                },
                {
                  value: InterviewLevel.Intermediate,
                  isChosen: levels.includes(InterviewLevel.Intermediate)
                },
                {
                  value: InterviewLevel.Pro,
                  isChosen: levels.includes(InterviewLevel.Pro)
                }
              ]}
            />
          </div>
        </StandardModal>

        <div
          className={'d-flex flex-row justify-content-center container-fluid w-100'}
          style={{ overflowY: 'auto', height: 'calc(100vh - 150px)' }}
        >
          {/* Left Panel: Template list */}
          <BoxWithBorder
            width={'16%'}
            header={
              <div className={'d-flex flex-row gap-3 mt-1 flex-wrap'}>
                <ColoredText
                  singleText={'Templates'}
                  fontWeight={200}
                  fontSize={'24px'}
                />
                <ColoredText
                  singleText={'Create +'}
                  color={COLORS.MAIN_COLOR}
                  onClick={() => {
                    // If user has unsaved changes, you could check here as well
                    setIsOpen(true)
                  }}
                  cursor={'pointer'}
                  whiteSpace={'nowrap'}
                />
              </div>
            }
            content={
              <SelectableList
                data={templateData}
                selectedIdOutside={currentTemplateId}
                onSelect={handleTemplateSelect} // Hook for unsaved-changes check
              />
            }
          />

          {/* Middle Panel: The actual template details */}
          <BoxWithBorder
            width={'55%'}
            content={
              <>
                {
                  currentTemplate
                    ? (
                      <CustomTemplate
                        sections={currentTemplate?.sections ?? []}
                        setTemplate={setCurrentTemplate}
                        warningIds={warningIds}
                        onChange={() => { setHasUnsavedChanges(true) }}
                        // You can pass setHasUnsavedChanges here if needed in the child
                      />
                      )
                    : (
                      <div className={'d-flex justify-content-center flex-row gap-3 align-items-center vh-100'}>
                        <ColoredText
                          singleText={'Create a new template'}
                          color={COLORS.MAIN_COLOR}
                          onClick={() => {
                            setIsOpen(true)
                          }}
                          lineHeight={1}
                          cursor={'pointer'}
                          whiteSpace={'nowrap'}
                        />
                      </div>
                      )
                }
              </>
            }
            showFooter={currentTemplateId !== undefined}
            footer={
              <div className={'d-flex flex-row justify-content-end align-items-center gap-4'}>
                <ColoredText
                  singleText={'Cancel'}
                  cursor={'pointer'}
                  fontWeight={500}
                  fontSize={'14px'}
                  onClick={() => {
                    if (currentTemplateId) {
                      void templateApi.getTemplateById({ templateId: currentTemplateId })
                        .then(res => {
                          const sortedTemplate = sortTemplate(res)
                          setCurrentTemplate(sortedTemplate)
                          setHasUnsavedChanges(false) // revert changes -> no longer dirty
                          toast.success('Template Canceled successfully')
                        }).catch((err: Error) => {
                          console.log(err)
                          toast.error("Couldn't cancel your changes")
                        })
                    }
                  }}
                />
                <StandardButton
                  text={'Save'}
                  sizeMode={ButtonModeValues.SMALL}
                  clickable={!(warningIds.questionIds.length > 0 || warningIds.sectionIds.length > 0)}
                  onClick={() => {
                    void templateApi.editTemplate({ templateEditRequestDto: currentTemplate as TemplateEditRequestDto })
                      .then(res => {
                        setCurrentTemplate(res)
                        setHasUnsavedChanges(false) // saved changes -> no longer dirty
                        toast.success('Template Edited successfully')
                      }).catch((err: Error) => {
                        console.log(err)
                        toast.error("Couldn't save your changes")
                      })
                  }}
                />
              </div>
            }
            growContent={true}
            showHeader={currentTemplateId !== undefined}
            header={
              <InlineEditableText
                placeholder={'Template Name'}
                fontSize={'24px'}
                fontWeight={200}
                value={currentTemplate?.name ?? ''}
                onChange={(newValue) => {
                  const cleanedValue = newValue.trim()
                  if (cleanedValue !== '') {
                    // Mark unsaved as soon as we confirm a valid change
                    setHasUnsavedChanges(true)
                    setCurrentTemplate(prevState => {
                      if (!prevState) return prevState
                      return { ...prevState, name: newValue }
                    })
                  }
                }}
                canDelete={true}
                onDelete={() => { setIsDeleting(true) }}
              />
            }
          />

          {/* Right Panel: Question Bank */}
          <BoxWithBorder
            width={'25%'}
            header={
              <div className={'d-flex flex-row justify-content-start mt-1 w-100'}>
                <ColoredText
                  singleText={'Questions'}
                  fontWeight={200}
                  fontSize={'24px'}
                />
              </div>
            }
            notScrollableContent={
              currentTemplate
                ? (
                  <div className={'d-flex flex-column justify-content-center align-items-start gap-3'}>
                    <GenericUnderlinedText
                      setSectionValue={setSearchTypeValue}
                      initialSection={searchTypeValue}
                      firstWordValue={QuestionSearchRequestDtoSearchTypeEnum.All}
                      secondWordValue={QuestionSearchRequestDtoSearchTypeEnum.My}
                      firstWord={'All'}
                      secondWord={'My'}
                    />
                    <div
                      style={{ marginLeft: '1px', width: '98%' }}
                      className={'d-flex flex-row align-items-center gap-2'}
                    >
                      <StandardInput
                        label={'Search in existing for exact interview type'}
                        dontAlignStart
                        sizeMode={InputModeValues.MAX_WIDTH}
                        placeHolder={'Search'}
                        inputValue={searchText}
                        setInputValue={setSearchText}
                      />
                    </div>
                  </div>
                  )
                : <div className={'d-flex justify-content-center align-items-center vh-100'}>Select a Template</div>
            }
            content={
              <>
                {questionSearchLoading && (
                  <div className={'pt-5 d-flex flex-row align-items-center justify-content-center w-100 h-100'}>
                    <CircularProgress sx={{ color: COLORS.MAIN_COLOR }} size={60} />
                  </div>
                )}
                { currentTemplate && (
                  <div className={'d-flex flex-column justify-content-center align-items-center flex-grow-1'}>
                    <QuestionBank questions={questionBank} questionSearchLoading={questionSearchLoading} />
                  </div>
                )}
              </>
            }
          />
        </div>
      </DragDropContext>

      {/* Delete Template Confirmation Modal */}
      <StandardConfirmationModal
        confirmationMessage={`Are you sure you want delete the '${currentTemplate?.name}' template?`}
        isOpen={isDeleting}
        confirmText={'Delete'}
        setIsOpen={setIsDeleting}
        onConfirm={deleteTemplate}
      />

      {/* Discard Changes Confirmation Modal (when switching templates) */}
      <StandardConfirmationModal
        isOpen={showDiscardModal}
        setIsOpen={setShowDiscardModal}
        confirmationMessage="You have unsaved changes. They won’t be lost as long as you stay on this page,
         but you must save them if you want to keep them permanently.
         Are you sure you want to switch templates now?"
        onConfirm={() => {
          // user confirms discarding -> switch to the next template
          setHasUnsavedChanges(false)
          if (nextTemplateId) {
            setCurrentTemplateId(nextTemplateId)
          }
          setNextTemplateId(undefined)
          setShowDiscardModal(false)
        }}
        onCancel={() => {
          // user cancels -> remain on current template
          setNextTemplateId(undefined)
          setShowDiscardModal(false)
        }}
      />
    </div>
  )
}

export default CustomTemplatePage
