import React from 'react'
import StandardModal from './StandardModal'
import ColoredText from '../text/ColoredText'
import { FontModeValues } from '../modes/FontMode'
import StandardButton from '../buttons/StandardButton'
import MarginWrapper from '../util/MarginWrapper'
import { ButtonModeValues } from '../modes/ButtonMode'

interface StandardConfirmationModalInterface {
  confirmationMessage?: string
  isOpen: boolean
  setIsOpen: any
  onConfirm: any
  onCancel?: () => void
  confirmText?: string
}

function StandardConfirmationModal (props: StandardConfirmationModalInterface) {
  const confirmationMessage = props.confirmationMessage ?? 'Are you sure?'

  return (
    <div>
      <StandardModal
        title={'Confirmation'}
        isOpen={props.isOpen}
        needDividers={false}
        footerComponent={
          <div
            className={'d-flex flex-row justify-content-between align-items-center'}
            style={{ width: '100%' }}>
            <ColoredText
              cursor={'pointer'}
              fontSize={FontModeValues.DEFAULT}
              singleText={'Cancel'}
              onClick={() => {
                if (props.onCancel) props.onCancel()
                props.setIsOpen(false)
              }}
              userSelect={'none'}/>
            <StandardButton
              sizeMode={ButtonModeValues.SMALL}
              onClick={() => {
                props.onConfirm()
                props.setIsOpen(false)
              }}
              text={props.confirmText ?? 'Confirm'}/>
          </div>
        }>
        <MarginWrapper top={'10px'} bottom={'10px'}>
          <ColoredText singleText={confirmationMessage} centered={true}/>
        </MarginWrapper>
      </StandardModal>
    </div>
  )
}

export default StandardConfirmationModal
