/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstantInterviewCreationDto
 */
export interface InstantInterviewCreationDto {
    /**
     * Level
     * @type {string}
     * @memberof InstantInterviewCreationDto
     */
    level: InstantInterviewCreationDtoLevelEnum;
    /**
     * Template
     * @type {string}
     * @memberof InstantInterviewCreationDto
     */
    templateId: string;
    /**
     * Email
     * @type {string}
     * @memberof InstantInterviewCreationDto
     */
    email: string;
    /**
     * sendEmail
     * @type {boolean}
     * @memberof InstantInterviewCreationDto
     */
    shouldSendInvitationEmail: boolean;
}


/**
 * @export
 */
export const InstantInterviewCreationDtoLevelEnum = {
    Beginner: 'BEGINNER',
    Intermediate: 'INTERMEDIATE',
    Pro: 'PRO'
} as const;
export type InstantInterviewCreationDtoLevelEnum = typeof InstantInterviewCreationDtoLevelEnum[keyof typeof InstantInterviewCreationDtoLevelEnum];


/**
 * Check if a given object implements the InstantInterviewCreationDto interface.
 */
export function instanceOfInstantInterviewCreationDto(value: object): value is InstantInterviewCreationDto {
    if (!('level' in value) || value['level'] === undefined) return false;
    if (!('templateId' in value) || value['templateId'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('shouldSendInvitationEmail' in value) || value['shouldSendInvitationEmail'] === undefined) return false;
    return true;
}

export function InstantInterviewCreationDtoFromJSON(json: any): InstantInterviewCreationDto {
    return InstantInterviewCreationDtoFromJSONTyped(json, false);
}

export function InstantInterviewCreationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstantInterviewCreationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'level': json['level'],
        'templateId': json['templateId'],
        'email': json['email'],
        'shouldSendInvitationEmail': json['shouldSendInvitationEmail'],
    };
}

export function InstantInterviewCreationDtoToJSON(value?: InstantInterviewCreationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'level': value['level'],
        'templateId': value['templateId'],
        'email': value['email'],
        'shouldSendInvitationEmail': value['shouldSendInvitationEmail'],
    };
}

