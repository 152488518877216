import Navigation from '../../navigation/Navigation'
import { NavigationModeValues } from '../../modes/NavigationMode'
import React, { createContext, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { getHeaderTextForInterview } from './InterviewPageFunctions'
// import InterviewTimeAlerts from '../interviewTimeAlerts'
import {
  type GetRuntimeInterviewTemplateDto,
  InterviewRuntimeTemplateControllerApi, VerificationRequestDtoTypeEnum
} from '../../../api/ic'
import { useSelector } from 'react-redux'
// import { GetDeviceTypeInformation } from '../../../features/DeviceTypeInformationSlice'
import AgoraRTC, {
  type IAgoraRTCClient,
  type IAgoraRTCRemoteUser,
  type ICameraVideoTrack, type ILocalAudioTrack,
  type IMicrophoneAudioTrack, type IRemoteAudioTrack,
  LocalVideoTrack, RemoteUser, useClientEvent,
  useJoin,
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  useLocalCameraTrack, useLocalMicrophoneTrack, usePublish, useRemoteUsers, type ILocalVideoTrack
} from 'agora-rtc-react'
import VideoMicComponent from './VideoMicComponent'
import { CircularProgress, Grid, Popover } from '@mui/material'
import SmallDropdown from '../../dropdown/SmallDropdown'
import interview_mic from '../../svg/resources/interview_mic.svg'
import interview_speaker from '../../svg/resources/interview_speaker.svg'
import { type InterviewProcessDTO } from '../../../features/InterviewProcessSlice'
import interview_cam from '../../svg/resources/interview_cam.svg'
// import { type InterviewJoinCreds } from '../InterviewProcess/InterviewProcess'
import StandardButton from '../../buttons/StandardButton'
import { NavigationRoute } from '../../../enumeration/NavigationRoute'
import { ButtonModeValues } from '../../modes/ButtonMode'
import { useNavigate } from 'react-router-dom'
import AgoraRTM, { createChannel, createClient, type RtmTextMessage, type RtmChannel, type RtmClient } from 'agora-rtm-react'
import InterviewQuestionsTemplate from './InterviewQuestionsTemplate'
import toast from 'react-hot-toast'
import ApiManager from '../../../manager/ApiManager'
import { receiveMessage, sendMessage } from './MessageHelperFunctions'
import { GetAccessToken } from '../../../features/AccessTokenSlice'
import Chat from './Chat'
import chat_message from '../../svg/resources/chat_message.svg'
import start_screen_share from '../../svg/resources/start_screen_share.svg'
import stop_screen_share from '../../svg/resources/stop_screen_share.svg'
import leave_interview from '../../svg/resources/leave_interview.svg'
import html2canvas from 'html2canvas'
import ProfilePicture from '../../profilePicture/ProfilePicture'
// Define the shape of the Agora context
interface AgoraContextType {
  localCameraTrack: ICameraVideoTrack | null
  localMicrophoneTrack: IMicrophoneAudioTrack | null
  children: React.ReactNode
}

// Create the Agora context
const AgoraContext = createContext<AgoraContextType | null>(null)

export const AgoraProvider: React.FC<AgoraContextType> = ({ children, localCameraTrack, localMicrophoneTrack }) => (
  <AgoraContext.Provider value={{ localCameraTrack, localMicrophoneTrack, children }}>
    {children}
  </AgoraContext.Provider>
)

interface InterviewPageProps {
  interviewId: string
  acceptedDate: number
  typeName: string
  interviewPresetSettings: InterviewProcessDTO
  joinCreds: any // InterviewJoinCreds
  agoraEngine: IAgoraRTCClient
  interviewJwt: any
}

const popoverStyle = {
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
  backgroundColor: '#3C4043',
  borderRadius: '10px'
}

function InterviewPage (props: InterviewPageProps) {
  // template state
  // rtm events start
  const fetchTemplate = useCallback(async () => {
    await interviewQuestionApi.getRuntimeTemplate({
      interviewId: props.interviewId
    }).then(res => {
      setTemplate(res.dto)
      setInterviewCompleted(res.completed)
    }).catch(() => {
      // TODO make in a way to not toast the error case
    })
  }, [])

  useEffect(() => {
    void fetchTemplate()
  }, [])

  const fireGenerateEvent = () => {
    if (sessionRef.current) {
      void sendMessage(sessionRef.current, { type: 'templateGenerated', message: {} })
    }
  }

  // Leave popup components
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'leave-interview' : undefined

  // rtm events end
  const [sharingLoading, setSharingLoading] = useState(false)
  const [interviewCompleted, setInterviewCompleted] = useState<boolean>(false)
  const interviewQuestionApi = ApiManager.getInstance(InterviewRuntimeTemplateControllerApi)
  const [template, setTemplate] = useState<GetRuntimeInterviewTemplateDto | undefined>(undefined)
  const [generateButtonLoading, setGenerateButtonLoading] = useState(false)
  const [rtmChannel, setRtmClient] = useState<any>(null)
  const [isChatOpen, setIsChatOpen] = useState(false)
  function handleGenerateTemplate () {
    setGenerateButtonLoading(true)
    void interviewQuestionApi.generateRuntimeTemplate({
      interviewId: props.interviewId
    }).then(res => {
      setTemplate(res)
      fireGenerateEvent()
    }).catch(() => {
      toast.error('Error while generating a template')
    }).finally(() => {
      setGenerateButtonLoading(false)
    })
  }
  // rtm start
  const clientRef = useRef<RtmClient | null>(null)
  const sessionRef = useRef<RtmChannel | null>(null)

  useEffect(() => {
    if (!rtmChannel) return

    const handleMessage = (event: RtmTextMessage, senderId: string) => {
      const message = receiveMessage(event.text)
      if (message?.type === 'ChatMessage') {
        setMessages((prev: any) => [...prev, { text: message.message.text, sender: message.message.sender || senderId }])
      }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    rtmChannel.on('ChannelMessage', handleMessage)

    return () => {
      rtmChannel.off('ChannelMessage', handleMessage)
    }
  }, [rtmChannel])
  useLayoutEffect(() => {
    const initializeRTMClient = async () => {
      try {
        const useClient = createClient(props.joinCreds.appId, { logFilter: AgoraRTM.LOG_FILTER_OFF })
        const useChannel = createChannel(props.joinCreds.channelName)
        const client = useClient()
        const session = useChannel(client)
        setRtmClient(session)
        clientRef.current = client
        sessionRef.current = session
        // Log in the client
        await client.login({ uid: props.joinCreds.uid.toString(), token: props.joinCreds.rtm })
        // Join the channel
        await session.join()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        session.on('ChannelMessage', (event: RtmTextMessage) => {
          const message = receiveMessage(event.text)
          if (message?.type === 'templateGenerated') {
            void fetchTemplate()
          }
        }
        )
      } catch (error) {
        console.error('An error occurred during initialization:', error)
      }
    }
    void initializeRTMClient()
    return () => {
      void logout()
    }
  }, [])
  const logout = async () => {
    const client = clientRef.current
    const session = sessionRef.current
    if (session && client) {
      await session.leave()
      session.removeAllListeners()
    }
  }
  // rtm end

  const createMockAudioTrack = (): MediaStreamTrack => {
    const audioContext = new AudioContext()

    // Create a gain node with zero gain (no volume)
    const gainNode = audioContext.createGain()
    gainNode.gain.setValueAtTime(0, audioContext.currentTime) // Silence

    // Create an oscillator (required as input, but it won't be audible)
    const oscillator = audioContext.createOscillator()
    oscillator.type = 'sine' // Type doesn't matter as volume is zero
    oscillator.frequency.setValueAtTime(440, audioContext.currentTime) // Frequency doesn't matter
    oscillator.connect(gainNode)

    // Connect gain node to a media stream destination
    const destination = audioContext.createMediaStreamDestination()
    gainNode.connect(destination)

    // Start the oscillator
    oscillator.start()

    // Return the audio track
    return destination.stream.getAudioTracks()[0]
  }
  const hiddenRef = useRef<HTMLDivElement>(null)
  const createCanvasTrack = async () => {
    if (!hiddenRef.current) return null

    // 1) Create a "main" canvas that we'll stream from
    const mainCanvas = document.createElement('canvas')
    mainCanvas.width = 1280
    mainCanvas.height = 720
    const context = mainCanvas.getContext('2d')

    // 2) Start capturing from the mainCanvas at 1 FPS
    const stream = mainCanvas.captureStream(1)
    const track = AgoraRTC.createCustomVideoTrack({
      mediaStreamTrack: stream.getVideoTracks()[0]
    })

    async function draw () {
      if (!hiddenRef.current) return

      // a) Snapshot the hidden element.
      const snapshotCanvas = await html2canvas(hiddenRef.current, {
        backgroundColor: '#2E302F',
        logging: false
      })
      if (!context) return
      // b) Clear and draw that snapshot onto mainCanvas.
      context.clearRect(0, 0, mainCanvas.width, mainCanvas.height)

      // (Optional) Flip horizontally, like a mirrored webcam:
      context.save()
      context.scale(-1, 1)
      context.translate(-mainCanvas.width, 0)

      // draw the snapshot
      context.drawImage(snapshotCanvas, 0, 0, mainCanvas.width, mainCanvas.height)

      context.restore()

      // c) Loop again on next animation frame.
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      requestAnimationFrame(draw)
    }
    // 4) Start the animation loop.
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    requestAnimationFrame(draw)
    return track
  }
  // INTERVIEW DATA
  // const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const body = useSelector(GetAccessToken) ?? {}
  const publisherFullName = (body.userId === props.interviewJwt?.takerId) ? props.interviewJwt?.takerFullname : props.interviewJwt?.giverFullname
  const subscriberFullName = (body.userId === props.interviewJwt?.takerId) ? props.interviewJwt?.giverFullname : props.interviewJwt?.takerFullname
  // COMPONENT STATE

  // camera, speaker, mic lists and states
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([])
  const [mics, setMics] = useState<MediaDeviceInfo[]>([])
  const [speakers, setSpeakers] = useState<MediaDeviceInfo[]>([])
  const [selectedMic, setSelectedMic] = useState<string | undefined>(undefined)
  const [selectedSpeaker, setSelectedSpeaker] = useState<string | undefined>(undefined)
  const [selectedCamera, setSelectedCamera] = useState<string | undefined>(undefined)
  const [cameraDeviceId, setCameraDeviceId] = useState<string | undefined>(props.interviewPresetSettings.selectedCamera ?? undefined)
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const [micDeviceId, setMicDeviceId] = useState<string | undefined>(props.interviewPresetSettings.selectedMicrophone ?? 'default')
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const [speakerDeviceId, setSpeakerDeviceId] = useState<string | undefined>(props.interviewPresetSettings.selectedAudio ?? undefined)
  const [currentMicTrack, setCurrentMicTrack] = useState<ILocalAudioTrack | null>(
    null
  )

  const [canToggleMicrophone, setCanToggleMicrophone] = useState<boolean>(true)
  const [canToggleCamera, setCanToggleCamera] = useState<boolean>(true)

  // enabled/disabled states
  const [localCameraEnabled, setLocalCameraEnabled] = useState(true)
  const [localMicEnabled, setLocalMicEnabled] = useState(true)
  const [localScreenSharingEnabled, setLocalScreenSharingEnabled] = useState(false)
  const [remoteScreenSharingEnabled, setRemoteScreenSharingEnabled] = useState(false)
  const [canShareScreen, setCanShareScreen] = useState<boolean>(true)
  const [screenTrack, setScreenTrack] = useState<any | null>(null)
  const screenSharingClientRef = useRef<IAgoraRTCClient | null>(null)
  const [messages, setMessages] = useState<any[]>([])
  const videoPlayerContainConfig: { fit: 'contain' | 'fill' | 'cover' | undefined } = useMemo(() => ({ fit: 'contain' }), [])
  const videoPlayerCoverConfig: { fit: 'contain' | 'fill' | 'cover' | undefined } = useMemo(() => ({ fit: 'cover' }), [])

  // Preset Devices
  useEffect(() => {
    void AgoraRTC.getDevices(false).then((devices: MediaDeviceInfo[]) => {
      const initialCamera = devices.filter((device: MediaDeviceInfo) => device.kind === 'videoinput').filter((initialDevice: MediaDeviceInfo) => initialDevice.deviceId === props.interviewPresetSettings.selectedCamera)
      const initialMicro = devices.filter(device => device.kind === 'audioinput').filter((initialDevice: MediaDeviceInfo) => initialDevice.deviceId === props.interviewPresetSettings.selectedMicrophone)
      const initialSpeaker = devices.filter(device => device.kind === 'audiooutput').filter((initialDevice: MediaDeviceInfo) => initialDevice.deviceId === props.interviewPresetSettings.selectedAudio)
      if (initialCamera.length) {
        setSelectedCamera(initialCamera[0].label)
      }
      if (initialMicro.length) {
        setSelectedMic(initialMicro[0].label)
      }
      if (initialSpeaker.length) {
        setSelectedSpeaker(initialSpeaker[0].label)
      }
    })
  }, [])

  // SESSION STATE
  const [initiallyToggledDevices, setInitiallyToggledDevices] = useState<boolean>(false)
  const { isLoading: isCameraLoading, localCameraTrack } = useLocalCameraTrack(true, { cameraId: props.interviewPresetSettings.selectedCamera ?? undefined }
  )
  const { isLoading: isMicrophoneLoading, localMicrophoneTrack } = useLocalMicrophoneTrack(true, { microphoneId: props.interviewPresetSettings.selectedMicrophone ?? undefined }
  )
  usePublish([localCameraTrack, localMicrophoneTrack], undefined, props.agoraEngine)
  const unmountUserMedia = () => {
    try {
      // Get all media streams and stop all tracks
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then((stream) => {
          // Just requesting and then immediately stopping ensures permissions are released
          const tracks = stream.getTracks()
          tracks.forEach((track) => {
            track.stop()
          })
        })
        .catch((err) => {
          console.log('No new media to clean up:', err)
        })

      // Also try to directly get all active media on the page and stop it
      const allTracks: any[] = []

      // Collect all tracks from all media elements on the page
      document.querySelectorAll('video, audio').forEach((element) => {
        const mediaElement = element as HTMLMediaElement
        if (mediaElement.srcObject instanceof MediaStream) {
          const tracks = mediaElement.srcObject.getTracks()
          tracks.forEach((track) => allTracks.push(track))
          mediaElement.srcObject = null // Clear the source
        }
      })

      // Stop all collected tracks
      allTracks.forEach((track) => {
        if (track?.readyState === 'live' || track?.readyState === 'ended') {
          track.stop()
        }
      })
    } catch (error) {
      console.error('Error releasing media devices:', error)
    }
  }
  useEffect(() => {
    if (!isMicrophoneLoading && !isCameraLoading) {
      // Wrap your async logic in an IIFE
      void (async () => {
        // 1. If microphone is supposed to be off, but we have a localMicrophoneTrack
        if (!props.interviewPresetSettings.enabledMicrophone && localMicrophoneTrack) {
          await props.agoraEngine.unpublish(localMicrophoneTrack)
          localMicrophoneTrack.stop()
          localMicrophoneTrack.close()

          // Create the mock (silent) track
          const customAudioTrack = AgoraRTC.createCustomAudioTrack({
            mediaStreamTrack: createMockAudioTrack()
          })

          // Publish the mock audio track
          await props.agoraEngine.publish(customAudioTrack)
          setCurrentMicTrack(customAudioTrack)
          setLocalMicEnabled(false)
          // eslint-disable-next-line @typescript-eslint/brace-style
        }
        // 2. If microphone is supposed to be on and we have a localMicrophoneTrack
        else if (props.interviewPresetSettings.enabledMicrophone && localMicrophoneTrack) {
          await props.agoraEngine.unpublish(localMicrophoneTrack)
          localMicrophoneTrack.stop()
          localMicrophoneTrack.close()

          // Create a new real microphone track
          const newMicrophoneTrack = await AgoraRTC.createMicrophoneAudioTrack({
            microphoneId: micDeviceId
          })
          if (newMicrophoneTrack) {
            await props.agoraEngine.publish(newMicrophoneTrack)
            setCurrentMicTrack(newMicrophoneTrack)
            setLocalMicEnabled(true)
          }
        }

        // 3. If video is supposed to be off, replace camera track with canvas track
        if (!props.interviewPresetSettings.enabledVideo && localCameraTrack) {
          const currentSettings = localCameraTrack.getMediaStreamTrack().getSettings()
          if (currentSettings.deviceId) {
            setCameraDeviceId(currentSettings.deviceId)
          }

          // Create a canvas-based video track
          void createCanvasTrack().then(canvasTrack => {
            if (!canvasTrack) return
            void localCameraTrack.replaceTrack(canvasTrack.getMediaStreamTrack(), true).then(
              res => {
                setInitiallyToggledDevices(true)
                console.log(res)
              }
            )
            setLocalCameraEnabled(false)
          })
          // Replace the camera track with your canvas track
        }
        if (props.interviewPresetSettings.enabledVideo && localCameraTrack) {
          setInitiallyToggledDevices(true)
        }
      })()
    }
  }, [
    // Dependencies that, when changed, will re-run this effect:
    isCameraLoading, isMicrophoneLoading
  ])
  const remoteUsers = useRemoteUsers()
  const filterRemoteUsers = (remoteUsers: IAgoraRTCRemoteUser[]): IAgoraRTCRemoteUser[] => {
    let includedUIDs: number[] = []
    if (props.agoraEngine.uid === 2) {
      includedUIDs = [1]
    } else if (props.agoraEngine.uid === 1) {
      includedUIDs = [2]
    }
    return remoteUsers.filter((user: IAgoraRTCRemoteUser) => includedUIDs.includes(Number(user.uid)))
  }
  const filterLocalScreenShareUser = (remoteUsers: IAgoraRTCRemoteUser[]): IAgoraRTCRemoteUser[] => {
    let includedUIDs: number[] = []
    if (!localScreenSharingEnabled) {
      return []
    }
    if (props.agoraEngine.uid === 2) {
      includedUIDs = [20]
    } else if (props.agoraEngine.uid === 1) {
      includedUIDs = [10]
    }
    return remoteUsers.filter((user: IAgoraRTCRemoteUser) => includedUIDs.includes(Number(user.uid)))
  }
  const filterRemoteScreenShareUser = (remoteUsers: IAgoraRTCRemoteUser[]): IAgoraRTCRemoteUser[] => {
    let includedUIDs: number[] = []
    if (!remoteScreenSharingEnabled) {
      return []
    }
    if (props.agoraEngine.uid === 2) {
      includedUIDs = [10]
    } else if (props.agoraEngine.uid === 1) {
      includedUIDs = [20]
    }
    return remoteUsers.filter((user: IAgoraRTCRemoteUser) => includedUIDs.includes(Number(user.uid)))
  }
  const navigate = useNavigate()
  try {
    useJoin({
      appid: props.joinCreds.appId,
      channel: props.joinCreds.channelName,
      token: props.joinCreds.rtc,
      uid: props.joinCreds.uid
    })
  } catch (error) {
    console.log(error)
  }
  const stopScreenCall = async () => {
    const stopScreenShare = async () => {
      setCanShareScreen(false)

      if (screenTrack && screenSharingClientRef.current) {
        if (Array.isArray(screenTrack)) {
          for (const t of screenTrack) {
            await screenSharingClientRef.current.unpublish(t)
            t.stop()
            t.close()
          }
        } else {
          await screenSharingClientRef.current.unpublish(screenTrack)
          screenTrack.stop()
          screenTrack.close()
        }
      }

      if (screenSharingClientRef.current) {
        await screenSharingClientRef.current.leave()
        screenSharingClientRef.current = null
      }
      setScreenTrack(null)
      setCanShareScreen(true)
    }

    await stopScreenShare()
  }

  const startScreenCall = async () => {
    try {
      // **Step 2: Stop any existing screen share before starting a new one**
      await stopScreenCall()
      setSharingLoading(true)
      // **Step 1: Initialize and join client if not already connected**
      if (!screenSharingClientRef.current) {
        screenSharingClientRef.current = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })
        if (!screenSharingClientRef.current) {
          toast.error('Could not start screen sharing')
          return
        }
        await screenSharingClientRef.current.join(
          props.joinCreds.appId,
          props.joinCreds.channelName,
          props.joinCreds.screenSharingAgent,
          props.joinCreds.uid * 10
        )
      }
      setSharingLoading(false)
      // **Step 3: Create and publish screen share track**
      const trackOrTracks = await AgoraRTC.createScreenVideoTrack(
        {
          encoderConfig: '720p_2',
          optimizationMode: 'detail',
          selfBrowserSurface: 'exclude'
        },
        'auto'
      )

      let videoTrack: ILocalVideoTrack | null
      let audioTrack: ILocalAudioTrack | null = null

      if (Array.isArray(trackOrTracks)) {
        [videoTrack, audioTrack] = trackOrTracks
      } else {
        videoTrack = trackOrTracks
      }

      if (videoTrack) {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        videoTrack.on('track-ended', async () => {
          await stopScreenCall() // Unpublish & leave session
        })

        await screenSharingClientRef.current.publish(videoTrack)
      }

      if (audioTrack) await screenSharingClientRef.current.publish(audioTrack)

      setScreenTrack(trackOrTracks)
    } catch (error) {
      console.error('Screen sharing failed:', error)
    }
  }
  useEffect(() => {
    async function fetchDevices () {
      const devices = await AgoraRTC.getDevices(false)
      const upToDateCams = devices.filter((device: MediaDeviceInfo) => device.kind === 'videoinput')
      const upToDateMicros = devices.filter(device => device.kind === 'audioinput')
      const upToDateSpeakers = devices.filter(device => device.kind === 'audiooutput')
      setCameras(upToDateCams)
      setMics(upToDateMicros)
      setSpeakers(upToDateSpeakers)
    }
    void fetchDevices()
    navigator.mediaDevices.ondevicechange = fetchDevices

    // Cleanup listener on component unmount
    return () => {
      navigator.mediaDevices.ondevicechange = null
    }
  }, [])

  //   // Function to change the camera device
  const handleCameraChange = async (deviceId: string) => {
    if (localCameraTrack) {
      await localCameraTrack.setDevice(deviceId)
      setCameraDeviceId(deviceId)
    }
  }

  // Function to change the microphone device
  const handleMicChange = async (deviceId: string) => {
    if (currentMicTrack) {
      try {
        await props.agoraEngine.unpublish(currentMicTrack)
        currentMicTrack.stop()
        currentMicTrack.close()
        const newMicrophoneTrack = await AgoraRTC.createMicrophoneAudioTrack({
          microphoneId: deviceId
        })
        await props.agoraEngine.publish(newMicrophoneTrack)
        setCurrentMicTrack(newMicrophoneTrack)
        setMicDeviceId(deviceId)
      } catch (error) {
        console.error('Error changing microphone device:', error)
      }
    }
  }

  // Function to enable/disable the camera and replace it with canvas
  const toggleCamera = async () => {
    if (localCameraTrack) {
      setCanToggleCamera(false)
      if (localCameraEnabled) {
        // Store the current camera device ID before disabling
        const currentSettings = localCameraTrack.getMediaStreamTrack().getSettings()
        if (currentSettings.deviceId) {
          setCameraDeviceId(currentSettings.deviceId)
        }
        // Create and replace with a canvas track
        void createCanvasTrack().then(async canvasTrack => {
          if (!canvasTrack) return
          await localCameraTrack.replaceTrack(canvasTrack.getMediaStreamTrack(), false).then(() => {
            setCanToggleCamera(true)
            setLocalCameraEnabled(false) // Set camera state as disabled
          }).catch(() => { setCanToggleCamera(true) })
        })
      } else {
        // Re-enable the original camera
        if (cameraDeviceId) {
          await localCameraTrack.setDevice(cameraDeviceId).then(() => {
            setCanToggleCamera(true)
            setLocalCameraEnabled(true) // Set camera state as enabled
          }).catch(() => { setCanToggleCamera(true) }) // Reapply the original camera device
        }
      }
    }
  }

  // Function to change the speaker device (audio output)
  const handleSpeakerChange = async (deviceId: string) => {
    setSpeakerDeviceId(deviceId)
    remoteUsers.forEach((remoteUser) => {
      const audioTrack = remoteUser.audioTrack as IRemoteAudioTrack
      if (audioTrack) {
        void audioTrack.setPlaybackDevice(deviceId)
      }
    })
  }
  // device selection and enable/disable
  const toggleMicrophone = async () => {
    try {
      setCanToggleMicrophone(false)
      if (localMicEnabled) {
        // Switch to mock track
        if (currentMicTrack) {
          const currentSettings = currentMicTrack.getMediaStreamTrack().getSettings()
          if (currentSettings.deviceId) {
            setMicDeviceId(currentSettings.deviceId)
          }
          // Unpublish the real microphone track
          await props.agoraEngine.unpublish(currentMicTrack)
          currentMicTrack.stop()
          currentMicTrack.close()

          // Publish the mock audio track
          const mockTrack = AgoraRTC.createCustomAudioTrack({
            mediaStreamTrack: createMockAudioTrack()
          })
          await props.agoraEngine.publish(mockTrack).then(() => {
            setCanToggleMicrophone(true)
            setLocalMicEnabled(false)
          }).catch(() => {
            setCanToggleMicrophone(true)
          })

          // Update state
          setCurrentMicTrack(mockTrack)
        } else {
          setCanToggleMicrophone(true)
        }
      } else {
        // Switch back to the real microphone
        if (currentMicTrack) {
          await props.agoraEngine.unpublish(currentMicTrack)
          currentMicTrack.stop()
          currentMicTrack.close()
        } else {
          setCanToggleMicrophone(true)
        }
        if (micDeviceId) {
          const newMicrophoneTrack = await AgoraRTC.createMicrophoneAudioTrack({
            microphoneId: micDeviceId
          })

          await props.agoraEngine.publish(newMicrophoneTrack).then(() => {
            setCanToggleMicrophone(true)
            setLocalMicEnabled(true)
          }).catch(() => { setCanToggleMicrophone(true) })

          setCurrentMicTrack(newMicrophoneTrack)
        } else {
          setCanToggleMicrophone(true)
        }
      }
    } catch (error) {
      console.log('error while toggling Microphone', error)
      setCanToggleMicrophone(true)
    }
  }
  // MICROPHONE
  const [microphoneListAnchor, setMicrophoneListAnchor] = React.useState<HTMLButtonElement | null>(null)

  const handleMicrophoneListClick = (event: any) => {
    setMicrophoneListAnchor(event.currentTarget)
  }
  const handleMicrophoneListClose = () => {
    setMicrophoneListAnchor(null)
  }
  const microphoneListOpen = Boolean(microphoneListAnchor)
  const microphoneId = microphoneListOpen ? 'open-microphone-list' : undefined

  // VIDEO
  const [videoListAnchor, setVideoListAnchor] = React.useState<HTMLButtonElement | null>(null)

  const handleVideoListClick = (event: any) => {
    setVideoListAnchor(event.currentTarget)
  }
  const handleVideoListClose = () => {
    setVideoListAnchor(null)
  }
  const videoListOpen = Boolean(videoListAnchor)
  const videoId = videoListOpen ? 'open-video-list' : undefined

  // Listen for user events
  useClientEvent(props.agoraEngine, 'user-published', (user, mediaType) => {
    if (mediaType === 'video') {
      if (user.uid === 10 && props.agoraEngine.uid === 2) {
        setRemoteScreenSharingEnabled(true)
      }
      if (user.uid === 20 && props.agoraEngine.uid === 1) {
        setRemoteScreenSharingEnabled(true)
      }
      if (user.uid === 20 && props.agoraEngine.uid === 2) {
        setLocalScreenSharingEnabled(true)
      }
      if (user.uid === 10 && props.agoraEngine.uid === 1) {
        setLocalScreenSharingEnabled(true)
      }
      void props.agoraEngine.subscribe(user, 'video')
    }
  })

  useClientEvent(props.agoraEngine, 'user-unpublished', (user, mediaType) => {
    if (mediaType === 'video') {
      if (user.uid === 10 && props.agoraEngine.uid === 2) {
        setRemoteScreenSharingEnabled(false)
      }
      if (user.uid === 20 && props.agoraEngine.uid === 1) {
        setRemoteScreenSharingEnabled(false)
      }
      if (user.uid === 20 && props.agoraEngine.uid === 2) {
        setLocalScreenSharingEnabled(false)
      }
      if (user.uid === 10 && props.agoraEngine.uid === 1) {
        setLocalScreenSharingEnabled(false)
      }
      void props.agoraEngine.unsubscribe(user, 'video')
    }
  })

  // 11.03.25
  const onlyUsersAndTemplate = () => {
    return (
      <div
        className={'d-flex flex-row justify-content-evenly align-items-center gap-4'}
        style={{
          height: '100%', // Parent container height (excluding navbar & bottom)
          overflow: 'hidden' // Prevent scrollbars from appearing
        }}
      >
        <div style={{ position: 'relative', width: '60%', flexGrow: 1 }}>
          {!interviewCompleted && template ? (
            <div style={{ width: '80%' }}>
              <InterviewQuestionsTemplate
                subscriber={
                    filterRemoteUsers(remoteUsers).length
                      ? filterRemoteUsers(remoteUsers)[0]
                      : null
                  }
                client={clientRef.current}
                session={sessionRef.current}
                template={template}
                setTemplate={setTemplate}
                interviewCompleted={interviewCompleted}
                setInterviewCompleted={setInterviewCompleted}
                type={body?.role}
                />
            </div>
          ) : ((body?.role === VerificationRequestDtoTypeEnum.RoleTaker || body?.role === VerificationRequestDtoTypeEnum.RoleCorporateTaker) && !interviewCompleted) ? (
            <div className={'d-flex flex-row justify-content-center'}>
              <StandardButton
                text={'Start Interview'}
                backgroundColor={'white'}
                isLoading={generateButtonLoading}
                clickable={filterRemoteUsers(remoteUsers).length > 0}
                onClick={handleGenerateTemplate}
                textColor={'black'}
                sizeMode={ButtonModeValues.LARGE}/>
            </div>
          ) : null}

          {interviewCompleted && (
          <span className={'d-flex flex-row justify-content-center text-white text-center'}>
            The interview is complete. It will be reviewed shortly. Thank you!
          </span>
          )}
        </div>
        <div className={'d-inline-flex flex-column gap-2'} style={{ width: '40%' }}>
          <div
            className="d-inline-flex flex-column position-relative"
            style={{
              width: '90%', // 👈 Ensure the container takes full width
              aspectRatio: '16/9 auto',
              height: 'auto',
              backgroundColor: '#2E302F',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              resize: 'both'
            }}>
            {!isCameraLoading && !isMicrophoneLoading && initiallyToggledDevices && (
              <LocalVideoTrack
                style={{
                  width: '100%', // 👈 Take full width
                  height: '100%', // 👈 Take full height
                  objectFit: 'cover',
                  transform: localCameraEnabled ? '' : 'scaleX(-1)',
                  backgroundColor: 'transparent',
                  borderRadius: '100px'
                }}
                videoPlayerConfig={videoPlayerCoverConfig}
                play={true}
                track={localCameraTrack}
              />
            )}
            <div
              style={{
                position: 'absolute',
                bottom: '8%',
                left: '4%',
                userSelect: 'none',
                zIndex: 1000,
                color: 'white',
                fontWeight: 'bold',
                fontSize: '14px'
              }}
            >
              {publisherFullName}
            </div>
          </div>
          {!!filterRemoteUsers(remoteUsers).length && (
            <div
              className="d-inline-flex flex-column position-relative"
              style={{
                width: '90%', // 👈 Ensure the container takes full width
                aspectRatio: '16/9 auto',
                backgroundColor: '#2E302F',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                resize: 'both'
              }}>

              <RemoteUser
                videoPlayerConfig={videoPlayerCoverConfig}
                user={filterRemoteUsers(remoteUsers)[0]}
                playVideo={true}
                playAudio={true}
                key={filterRemoteUsers(remoteUsers)[0].uid}
                style={{
                  // transform: 'scaleX(-1)',
                  width: '100%',
                  height: '100%',
                  borderRadius: '10px'
                }}/>
              <div
                style={{
                  position: 'absolute',
                  bottom: '8%',
                  left: '4%',
                  zIndex: 1000,
                  userSelect: 'none',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '14px'
                }}>
                {subscriberFullName}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const usersAndTemplateAndOneSharing = () => {
    return (
      <div
        className={'d-flex flex-row justify-content-evenly align-items-center gap-4'}
        style={{
          height: '100%', // Parent container height (excluding navbar & bottom)
          overflow: 'hidden' // Prevent scrollbars from appearing
        }}
      >
        <div style={{ position: 'relative', width: '70%', aspectRatio: '16/9' }}>
          {localScreenSharingEnabled &&
            filterLocalScreenShareUser(remoteUsers).map((user) => (
              <RemoteUser
                key={user.uid}
                user={user}
                playVideo
                playAudio
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  borderRadius: '10px'
                }}
                videoPlayerConfig={videoPlayerContainConfig}
              />
            ))
          }
          {remoteScreenSharingEnabled &&
            filterRemoteScreenShareUser(remoteUsers).map((user) => (
              <RemoteUser
                key={user.uid}
                user={user}
                playVideo
                playAudio
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  borderRadius: '10px'
                }}
                videoPlayerConfig={videoPlayerContainConfig}
              />
            ))
          }
        </div>
        <div className={'d-flex flex-column justify-content-center align-items-center gap-2'} style={{ width: '30%' }}>
          {!interviewCompleted && template ? (
            <InterviewQuestionsTemplate
              subscriber={
                filterRemoteUsers(remoteUsers).length
                  ? filterRemoteUsers(remoteUsers)[0]
                  : null
              }
              client={clientRef.current}
              session={sessionRef.current}
              template={template}
              setTemplate={setTemplate}
              interviewCompleted={interviewCompleted}
              setInterviewCompleted={setInterviewCompleted}
              type={body?.role}
            />
          ) : ((body?.role === VerificationRequestDtoTypeEnum.RoleTaker || body?.role === VerificationRequestDtoTypeEnum.RoleCorporateTaker) && !interviewCompleted) ? (
            <div className={'d-flex flex-row justify-content-center'}>
              <StandardButton
                text={'Start Interview'}
                backgroundColor={'white'}
                isLoading={generateButtonLoading}
                clickable={filterRemoteUsers(remoteUsers).length > 0}
                onClick={handleGenerateTemplate}
                textColor={'black'}
                sizeMode={ButtonModeValues.LARGE}/>
            </div>
          ) : null}

          {interviewCompleted && (
            <span className={'text-white'}>
              The interview is complete. It will be reviewed shortly. Thank you!
            </span>
          )}
          <div className={'d-flex flex-row gap-2'} style={{ width: '100%' }}>
            <div
              className="d-inline-flex flex-column position-relative"
              style={{
                width: '80%', // 👈 Ensure the container takes full width
                aspectRatio: '16/9',
                backgroundColor: '#2E302F',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              {!isCameraLoading && !isMicrophoneLoading && initiallyToggledDevices && (
                <LocalVideoTrack
                  style={{
                    width: '100%', // 👈 Take full width
                    height: '100%', // 👈 Take full height
                    transform: localCameraEnabled ? '' : 'scaleX(-1)',
                    objectFit: 'cover',
                    borderRadius: '10px'
                  }}
                  videoPlayerConfig={videoPlayerCoverConfig}
                  play={true}
                  track={localCameraTrack}
                />
              )}
              <div
                style={{
                  position: 'absolute',
                  bottom: '8%',
                  left: '4%',
                  userSelect: 'none',
                  zIndex: 1000,
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '14px'
                }}
              >
                {publisherFullName}
              </div>
            </div>
            {!!filterRemoteUsers(remoteUsers).length && (
              <div
                className="d-inline-flex flex-column position-relative"
                style={{
                  width: '80%', // 👈 Ensure the container takes full width
                  aspectRatio: '16/9',
                  backgroundColor: '#2E302F',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <RemoteUser
                  videoPlayerConfig={videoPlayerCoverConfig}
                  user={filterRemoteUsers(remoteUsers)[0]}
                  playVideo={true}
                  playAudio={true}
                  key={filterRemoteUsers(remoteUsers)[0].uid}
                  style={{
                    // transform: 'scaleX(-1)',
                    width: '100%',
                    height: '100%',
                    borderRadius: '10px'
                  }}/>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '8%',
                    left: '4%',
                    userSelect: 'none',
                    zIndex: 1000,
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '14px'
                  }}>
                  {subscriberFullName}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  const usersAndTemplateAndBothSharing = () => {
    return (
      <div
        className={'d-flex flex-row justify-content-evenly align-items-center gap-4'}
        style={{
          height: '100%', // Parent container height (excluding navbar & bottom)
          overflow: 'hidden' // Prevent scrollbars from appearing
        }}
      >
        <div style={{ position: 'relative', width: '70%', aspectRatio: '16/9' }}>
          {remoteScreenSharingEnabled &&
            filterRemoteScreenShareUser(remoteUsers).map((user) => (
              <RemoteUser
                key={user.uid}
                user={user}
                playVideo
                playAudio
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  borderRadius: '10px'
                }}
                videoPlayerConfig={videoPlayerContainConfig}
              />
            ))
          }
        </div>
        <div className={'d-flex flex-column justify-content-center align-items-center gap-2'} style={{ width: '30%' }}>
          {!interviewCompleted && template ? (
            <InterviewQuestionsTemplate
              subscriber={
                filterRemoteUsers(remoteUsers).length
                  ? filterRemoteUsers(remoteUsers)[0]
                  : null
              }
              client={clientRef.current}
              session={sessionRef.current}
              template={template}
              setTemplate={setTemplate}
              interviewCompleted={interviewCompleted}
              setInterviewCompleted={setInterviewCompleted}
              type={body?.role}
            />
          ) : ((body?.role === VerificationRequestDtoTypeEnum.RoleTaker || body?.role === VerificationRequestDtoTypeEnum.RoleCorporateTaker) && !interviewCompleted) ? (
            <div className={'d-flex flex-row justify-content-center'}>
              <StandardButton
                text={'Start Interview'}
                backgroundColor={'white'}
                isLoading={generateButtonLoading}
                clickable={filterRemoteUsers(remoteUsers).length > 0}
                onClick={handleGenerateTemplate}
                textColor={'black'}
                sizeMode={ButtonModeValues.LARGE}/>
            </div>
          ) : null}

          {interviewCompleted && (
            <span className={'text-white'}>
              The interview is complete. It will be reviewed shortly. Thank you!
            </span>
          )}
          <div className={'d-flex flex-row gap-2'} style={{ width: '100%' }}>
            <div
              className="d-inline-flex flex-column position-relative"
              style={{
                width: '80%', // 👈 Ensure the container takes full width
                aspectRatio: '16/9',
                backgroundColor: '#2E302F',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              {!isCameraLoading && !isMicrophoneLoading && initiallyToggledDevices && (
                <LocalVideoTrack
                  style={{
                    width: '100%', // 👈 Take full width
                    height: '100%', // 👈 Take full height
                    transform: localCameraEnabled ? '' : 'scaleX(-1)',
                    objectFit: 'cover',
                    borderRadius: '10px !important'
                  }}
                  videoPlayerConfig={videoPlayerCoverConfig}
                  play={true}
                  track={localCameraTrack}
                />
              )}
              <div
                style={{
                  position: 'absolute',
                  bottom: '8%',
                  left: '4%',
                  userSelect: 'none',
                  zIndex: 1000,
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '14px'
                }}
              >
                {publisherFullName}
              </div>
            </div>
            {!!filterRemoteUsers(remoteUsers).length && (
              <div
                className="d-inline-flex flex-column position-relative"
                style={{
                  width: '80%', // 👈 Ensure the container takes full width
                  aspectRatio: '16/9',
                  backgroundColor: '#2E302F',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <RemoteUser
                  videoPlayerConfig={videoPlayerCoverConfig}
                  user={filterRemoteUsers(remoteUsers)[0]}
                  playVideo={true}
                  playAudio={true}
                  key={filterRemoteUsers(remoteUsers)[0].uid}
                  style={{
                    // transform: 'scaleX(-1)',
                    width: '100%',
                    height: '100%',
                    borderRadius: '10px'
                  }}/>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '8%',
                    left: '4%',
                    zIndex: 1000,
                    userSelect: 'none',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '14px'
                  }}>
                  {subscriberFullName}
                </div>
              </div>
            )}
          </div>
          <div
            className="d-inline-flex flex-column position-relative"
            style={{
              width: '80%', // 👈 Ensure the container takes full width
              aspectRatio: '16/9',
              backgroundColor: '#2E302F',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            {localScreenSharingEnabled &&
              filterLocalScreenShareUser(remoteUsers).map((user) => (
                <RemoteUser
                  key={user.uid}
                  user={user}
                  playVideo
                  playAudio
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    borderRadius: '10px'
                  }}
                  videoPlayerConfig={videoPlayerContainConfig}
                />
              ))
            }
            <div
              style={{
                position: 'absolute',
                bottom: '8%',
                left: '4%',
                zIndex: 1000,
                color: 'white',
                userSelect: 'none',
                fontWeight: 'bold',
                fontSize: '14px'
              }}>
              {publisherFullName}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Grid
      container
      direction="column"
      sx={{
        width: '100vw'
      }}
    >
      {/* Wrap everything in AgoraProvider */}
      <AgoraProvider localCameraTrack={localCameraTrack} localMicrophoneTrack={localMicrophoneTrack}>
        {/* 1) Header (Navigation) */}
        <Grid item>
          <Navigation
            navigationMode={NavigationModeValues.FIXED_WHITE_WITH_INTERVIEW_INFO}
            interviewText={getHeaderTextForInterview(props.acceptedDate, props.typeName)}
            copyInterviewID={props.interviewId}
            mobileNavItems={[]}
            height="60px"
            logoOnclick={() => {
              try {
                // 1) Unpublish the local tracks from the main client
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                void props.agoraEngine.unpublish([localCameraTrack, localMicrophoneTrack])

                // 2) Stop and close them so the browser sees them as released
                localCameraTrack?.stop()
                localCameraTrack?.close()
                localMicrophoneTrack?.stop()
                localMicrophoneTrack?.close()

                // 3) If you used a screen-sharing client, unpublish + leave that too
                if (screenTrack) {
                  void screenSharingClientRef?.current?.unpublish(screenTrack)

                  // If it’s an array of tracks, stop/close each
                  if (Array.isArray(screenTrack)) {
                    screenTrack.forEach(t => {
                      t.stop()
                      t.close()
                    })
                  } else {
                    screenTrack.stop()
                    screenTrack.close()
                  }
                }

                // 4) Leave the screen-sharing client (if any)
                void screenSharingClientRef?.current?.leave()

                // 5) Finally, leave the main client
                void props.agoraEngine.leave()

                // 6) Optionally clear out references in your state
                unmountUserMedia() // or set localCameraTrack = null, etc.
              } catch (e) {
                console.error('Error stopping tracks/leaving Agora:', e)
              }
            }}
          />
        </Grid>
        {/* 2) Main Content – centered horizontally via maxWidth */}
        <Grid
          item
          container
          direction="column"
          alignItems="start"
          justifyContent="flex-start"
          sx={{ flex: 1, ml: 4 }}>
          <Grid item sx={{ width: '100%', mx: 'auto', mt: 1, pb: 3 }}>
            {/* <div className="w-100 d-flex flex-row justify-content-center position-relative" style={{ zIndex: 1000 }}> */}
            {/*   <InterviewTimeAlerts */}
            {/*     timeStamp={props.acceptedDate ?? 0} */}
            {/*     alerts={[ */}
            {/*       { */}
            {/*         time: 30, */}
            {/*         warningText: '30 minutes that interview has started', */}
            {/*         action: () => {} */}
            {/*       }, */}
            {/*       { */}
            {/*         time: 50, */}
            {/*         warningText: '10 minutes remaining', */}
            {/*         action: () => {} */}
            {/*       } */}
            {/*     ]} */}
            {/*   /> */}
            {/* </div> */}
          </Grid>
          <style>
            {`
              div[id^='agora-video-player-track-cam-'] {
                background-color: transparent !important;
                border-radius: 10px;
                overflow: hidden;
              }
              video[id^='video_track-cam-'] {
                background-color: transparent !important;
                border-radius: 10px;
              }
            `}
          </style>
          <div style={{ width: '100%', maxWidth: isChatOpen ? '75vw' : '96vw', transition: 'all 0.3s ease-in-out', height: 'calc(100vh - 150px)' }} className={'mt-1 d-flex flex-row align-items-center'}>
            <div
              style={{
                width: '100%',
                transition: 'width 0.3s ease-in-out',
                height: '100%'
              }}>
              {(!localScreenSharingEnabled && !remoteScreenSharingEnabled) && onlyUsersAndTemplate()}
              {((localScreenSharingEnabled && !remoteScreenSharingEnabled) || (!localScreenSharingEnabled && remoteScreenSharingEnabled)) && usersAndTemplateAndOneSharing()}
              {((localScreenSharingEnabled && remoteScreenSharingEnabled)) && usersAndTemplateAndBothSharing()}
            </div>
          </div>
          {isChatOpen && (
          <Chat
            session={rtmChannel}
            isOpen={isChatOpen}
            onClose={() => { setIsChatOpen(false) }}
            senderName={publisherFullName}
            messages={messages}
            setMessages={setMessages}
              />
          )}
          <div
            className="position-fixed start-0 end-0 d-inline-flex justify-content-center"
            style={{
              bottom: '30px',
              height: '60px' // Adjust button height
            }}
          >
            <div className="d-flex flex-row gap-3 pt-4 justify-content-center align-items-center">
              {/* Microphone */}
              <div className="d-flex flex-row align-items-start">
                <VideoMicComponent
                  disabled={!canToggleMicrophone}
                  byDefaultEnabled={props.interviewPresetSettings.enabledMicrophone}
                  mode="mic"
                  click={toggleMicrophone}
                  arrowClick={handleMicrophoneListClick}
                />
                <div>
                  <Popover
                    id={microphoneId}
                    disableRestoreFocus
                    open={microphoneListOpen}
                    anchorEl={microphoneListAnchor}
                    slotProps={{
                      paper: {
                        sx: { ...popoverStyle }
                      }
                    }}
                    onClose={handleMicrophoneListClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                  >
                    <div className="d-flex flex-row p-2 mx-2 gap-2">
                      <SmallDropdown
                        onChange={(val: string | number) => {
                          void AgoraRTC.getMicrophones(true).then((mics: MediaDeviceInfo[]) => {
                            const foundMic = mics.find((mic) => mic.label === val)
                            if (foundMic) {
                              void handleMicChange(foundMic.deviceId)
                            }
                          })
                        }}
                        dontShowError
                        leftIcon={interview_mic}
                        leftIconHeight="25px"
                        leftIconWidth="25px"
                        fontColor="white"
                        logoMode="light"
                        backgroundColor="#565A5D"
                        borderRadius="20px"
                        items={mics.map((micro) => micro.label)}
                        selected={selectedMic}
                        setIsSelected={setSelectedMic}
                        eachItemStyles={{ color: 'white' }}
                      />
                      <SmallDropdown
                        dontShowError
                        onChange={(val: string | number) => {
                          const foundSpeaker = speakers.find((spk) => spk.label === val)
                          if (foundSpeaker) {
                            void handleSpeakerChange(foundSpeaker.deviceId)
                          }
                        }}
                        leftIcon={interview_speaker}
                        leftIconHeight="25px"
                        leftIconWidth="25px"
                        fontColor="white"
                        logoMode="light"
                        backgroundColor="#565A5D"
                        borderRadius="20px"
                        items={speakers.map((speaker) => speaker.label)}
                        selected={selectedSpeaker}
                        setIsSelected={setSelectedSpeaker}
                        eachItemStyles={{ color: 'white' }}
                      />
                    </div>
                  </Popover>
                </div>
              </div>

              {/* Video */}
              <div className="d-flex flex-row align-items-start">
                <VideoMicComponent
                  disabled={!canToggleCamera}
                  byDefaultEnabled={props.interviewPresetSettings.enabledVideo}
                  mode="video"
                  click={async () => {
                    await toggleCamera()
                  }}
                  arrowClick={handleVideoListClick}
                />
                <Popover
                  id={videoId}
                  disableRestoreFocus
                  open={videoListOpen}
                  anchorEl={videoListAnchor}
                  slotProps={{
                    paper: {
                      sx: { ...popoverStyle }
                    }
                  }}
                  onClose={handleVideoListClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <div className="d-flex flex-row p-2">
                    <SmallDropdown
                      dontShowError
                      onChange={(val: string | number) => {
                        void AgoraRTC.getCameras(true).then((cams: MediaDeviceInfo[]) => {
                          const foundCam = cams.find((cam) => cam.label === val)
                          if (foundCam) {
                            void handleCameraChange(foundCam.deviceId)
                          }
                        })
                      }}
                      items={cameras.map((video) => video.label)}
                      leftIcon={interview_cam}
                      leftIconHeight="25px"
                      leftIconWidth="25px"
                      fontColor="white"
                      logoMode="light"
                      backgroundColor="#565A5D"
                      borderRadius="20px"
                      selected={selectedCamera}
                      setIsSelected={setSelectedCamera}
                      eachItemStyles={{ color: 'white' }}
                    />
                  </div>
                </Popover>
              </div>
              <div
                onClick={() => {
                  if (canShareScreen) {
                    if (screenTrack) {
                      void stopScreenCall()
                    } else {
                      void startScreenCall()
                    }
                  }
                }}
                className={'d-flex justify-content-center align-items-center'}
                style={{
                  backgroundColor: '#494E51',
                  position: 'relative',
                  borderRadius: '20px',
                  width: '40px',
                  height: '40px',
                  cursor: 'pointer',
                  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
                }}>
                {sharingLoading && (<CircularProgress sx={{ color: 'white' }} size={20} />)}
                {!sharingLoading && (
                  <img
                    src={screenTrack ? stop_screen_share : start_screen_share}
                    alt={screenTrack ? stop_screen_share : start_screen_share}
                    height={20}
                    width={20}
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                  />
                )}

              </div>
              <div
                onClick={() => {
                  setIsChatOpen(prevState => !prevState)
                }}
                className={'d-flex justify-content-center align-items-center'}
                style={{
                  backgroundColor: '#494E51',
                  borderRadius: '20px',
                  width: '40px',
                  height: '40px',
                  cursor: 'pointer',
                  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
                }}>
                <img
                  src={chat_message}
                  alt={chat_message}
                  height={20}
                  width={20}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                />
              </div>
              <div
                onClick={handlePopoverOpen}
                className={'d-flex justify-content-center align-items-center'}
                style={{
                  backgroundColor: 'red',
                  borderRadius: '20px',
                  width: '40px',
                  height: '40px',
                  cursor: 'pointer',
                  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
                }}>
                <img
                  src={leave_interview}
                  alt={leave_interview}
                  height={20}
                  width={20}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                />
              </div>
              <Popover
                id={id}
                disableRestoreFocus
                open={open}
                onClose={handlePopoverClose}
                anchorEl={anchorEl}
                slotProps={{
                  paper: {
                    sx: { ...popoverStyle, padding: 1, mb: 1 }
                  }
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}>
                <div className={'d-flex flex-column gap-2 align-items-center justify-content-evenly'}>
                  <div className={'text-white m-1 mt-1'} style={{ fontSize: '14px' }}>Are you sure about leaving the interview</div>
                  <div
                    onClick={() => {
                      try {
                        // 1) Unpublish the local tracks from the main client
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        void props.agoraEngine.unpublish([localCameraTrack, localMicrophoneTrack])

                        // 2) Stop and close them so the browser sees them as released
                        localCameraTrack?.stop()
                        localCameraTrack?.close()
                        localMicrophoneTrack?.stop()
                        localMicrophoneTrack?.close()

                        // 3) If you used a screen-sharing client, unpublish + leave that too
                        if (screenTrack) {
                          void screenSharingClientRef?.current?.unpublish(screenTrack)

                          // If it’s an array of tracks, stop/close each
                          if (Array.isArray(screenTrack)) {
                            screenTrack.forEach(t => {
                              t.stop()
                              t.close()
                            })
                          } else {
                            screenTrack.stop()
                            screenTrack.close()
                          }
                        }

                        // 4) Leave the screen-sharing client (if any)
                        void screenSharingClientRef?.current?.leave()

                        // 5) Finally, leave the main client
                        void props.agoraEngine.leave()

                        // 6) Optionally clear out references in your state
                        unmountUserMedia() // or set localCameraTrack = null, etc.
                      } catch (e) {
                        console.error('Error stopping tracks/leaving Agora:', e)
                      }

                      navigate(
                        NavigationRoute.INTERVIEW_FEEDBACK.replace(
                          '/:interviewID/',
                          `/${props.interviewId}/`
                        ),
                        { replace: true }
                      )
                    }}
                    className={'d-flex justify-content-center align-items-center m-1 mb-1 p-1 text-white'}
                    style={{
                      backgroundColor: 'red',
                      userSelect: 'none',
                      cursor: 'pointer',
                      border: '1px solid red',
                      borderRadius: '5px',
                      width: '90%'
                    }}>
                    Leave Interview
                  </div>
                </div>
              </Popover>
            </div>
          </div>
        </Grid>
        <div
          className={'d-flex flex-row justify-content-center align-items-center'}
          ref={hiddenRef}
          style={{
            position: 'absolute',
            left: -9999,
            width: '1280px',
            height: '720px',
            transform: 'scaleX(-1)'
          }}>
          <ProfilePicture
            width={'260px'}
            name={publisherFullName.split(' ').map((part: string) => part.trim())[0]}
            surname={publisherFullName.split(' ').map((part: string) => part.trim())[1]}
            pictureSrc={'DEFAULT'}
            isChangeable={false}
          />
        </div>
      </AgoraProvider>
    </Grid>
  )
}

export default InterviewPage
