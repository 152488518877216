import React, { useEffect, useState } from 'react'
import TakerUpcomingList from './TakerUpcomingList'
import ColoredText from '../text/ColoredText'
import ApiManager from '../../manager/ApiManager'
import { type GetInterviewDto, TakerControllerApi } from '../../api/ic'
import CircularLoading from '../CircularLoading'

function TakerUpcomingCards () {
  const [updateTimes, setUpdateTimes] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [upcomingList, setUpcomingList] = useState<GetInterviewDto[]>([])

  const takerApi = ApiManager.getInstance(TakerControllerApi)

  useEffect(() => {
    setIsLoading(true)
    void takerApi.getTakerInterviewsInfo()
      .then(res => {
        const upcomingResponse = res.upcomingInterviews
        if (upcomingResponse?.length) {
          setUpcomingList(upcomingResponse)
        } else {
          setUpcomingList([])
        }
        setIsLoading(false)
      }).catch(() => {
        setIsLoading(false)
      })
    setUpdateTimes(false)
  }, [updateTimes])
  return (
    <div className={'d-flex flex-column'} style={{ width: '100%' }}>
      <div
        className={'d-flex flex-row flex-wrap align-items-center justify-content-evenly'}
        style={{
          gap: '20px',
          padding: '0px 0px 20px 0px'
        }}>
        {!isLoading &&
          (
            <>
              {upcomingList.length
                ? <TakerUpcomingList upcomingInterviews={upcomingList} setUpdateTimes={setUpdateTimes}/>
                : <div className={'d-flex justify-content-center align-items-center mt-5'}>
                  <ColoredText singleText={'No Upcoming Interviews. Please check Take New One section'}/>
                </div>
              }
            </>
          )}
        {isLoading && (
          <div style={{ width: '100vw', height: '100vh' }}>
            <CircularLoading />
          </div>
        )}
      </div>
    </div>
  )
}

export default TakerUpcomingCards
