import React, { useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import StarIcon from '@mui/icons-material/Star'
import QuestionAnswerActionsBar from '../../components/QuestionAnswerActionsBar'
import type { GetQuestionWithAnswerDto } from '../../api/ic'

interface FlipListProps {
  items: GetQuestionWithAnswerDto[]
  activeId?: string
  onChange?: (id: string) => void
  timeLine: any
  setScrubTime: any
  giverRef: any
  takerRef: any
  setActiveQuestionId: any
  takerOffset: any
  giverOffset: any
}

const FlipList: React.FC<FlipListProps> = ({ items, activeId, onChange, timeLine, setScrubTime, giverRef, takerRef, setActiveQuestionId, giverOffset, takerOffset }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [realQuestionIndex, setRealQuestionIndex] = useState(0)
  const [hasBeenScrolled, setHasBeenScrolled] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const itemHeight = 100
  const visibleItems = 5
  const paddingCount = Math.floor(visibleItems / 2)

  // Center the active item on scroll
  const handleScroll = () => {
    if (containerRef.current) {
      const scrollTop = containerRef.current.scrollTop
      const newIndex = Math.round(scrollTop / itemHeight)
      if (newIndex !== realQuestionIndex) {
        setHasBeenScrolled(true)
        setIsModalVisible(true)
      } else {
        setHasBeenScrolled(false)
        setIsModalVisible(false)
      }
      if (newIndex !== currentIndex) {
        setCurrentIndex(newIndex)
        onChange?.(items[newIndex]?.id)
      }
    }
  }

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
      return () => {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  // Center the active item when activeId changes
  useEffect(() => {
    if (activeId) {
      if (hasBeenScrolled) {
        const index = items.findIndex((item) => item.id === activeId)
        if (index !== -1) {
          setRealQuestionIndex(index)
        }
        if (index === currentIndex) {
          setIsModalVisible(false)
          setHasBeenScrolled(false)
        }
        return
      }

      const index = items.findIndex((item) => item.id === activeId)

      if (index !== -1) {
        setCurrentIndex(index)
        setRealQuestionIndex(index)
        // Calculate the scroll position
        const scrollPosition = (index + -2 + paddingCount) * itemHeight

        // Calculate the total scrollable height
        const totalHeight = (items.length + paddingCount * 2) * itemHeight

        // Calculate the maximum scrollable position
        const maxScrollTop = totalHeight - itemHeight * visibleItems

        // Ensure scroll position stays within the valid range
        const clampedScrollPosition = Math.max(
          0,
          Math.min(scrollPosition, maxScrollTop)
        )

        // Smoothly scroll to the calculated position
        containerRef.current?.scrollTo({
          top: clampedScrollPosition,
          behavior: 'smooth'
        })
      }
    }
  }, [activeId,
    items,
    paddingCount,
    itemHeight,
    visibleItems])

  // Get items with padding to keep the active one in the center
  const getItemsWithPadding = () => {
    const paddedItems = [
      ...Array(paddingCount).fill({ id: -1, content: '' }),
      ...items,
      ...Array(paddingCount).fill({ id: -2, content: '' })
    ]

    return paddedItems.map((item, index) => {
      const isActive = index === currentIndex + paddingCount
      return (
        <>
          <motion.div
            key={index}
            style={{
              cursor: 'pointer',
              minHeight: `${itemHeight}px`,
              display: 'flex',
              flexDirection: 'column', // Arrange text lines vertically
              alignItems: isActive ? '' : 'center',
              justifyContent: 'center',
              fontSize: isActive ? '1em' : '0.7em',
              opacity: isActive ? 1 : 0.5,
              transition: 'all 0.3s ease',
              scrollSnapAlign: 'center',
              textAlign: 'center',
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              width: '90%', // Constrain width to force wrapping
              overflow: 'hidden', // Hide overflow to prevent horizontal scrolling
              padding: '0px 5px', // Padding for better text layout
              boxSizing: 'border-box', // Consistent box sizing
              lineHeight: '1.2em' // Adjust line height for better wrapping
            }}
        >
            <div className={'d-flex flex-row gap-2 justify-content-between'}>
              {isActive && (
              <div className={'d-flex flex-column gap-2'}>
                <div className={'text-nowrap'} style={{ color: 'white' }}>{item.questionType}</div>
                {item.points && (
                  <div className={'d-flex flex-row align-items-center gap-2'}>
                    <StarIcon style={{
                      color: '#FFD700',
                      fontSize: '2em'
                    }}/>
                    <div className={'text-nowrap'} style={{ color: 'white' }}>
                      {item.points}
                      /5
                    </div>
                  </div>
                )}
              </div>
              )}
              <div className={'d-flex flex-column gap-1 justify-content-start'} style={{ color: 'white' }}>
                <div
                  onClick={() => {
                    const index = items.findIndex((q) => q.id === item.id)
                    if (index !== -1) {
                      setCurrentIndex(index)
                      // Calculate the scroll position
                      const scrollPosition = (index + -2 + paddingCount) * itemHeight

                      // Calculate the total scrollable height
                      const totalHeight = (items.length + paddingCount * 2) * itemHeight

                      // Calculate the maximum scrollable position
                      const maxScrollTop = totalHeight - itemHeight * visibleItems

                      // Ensure scroll position stays within the valid range
                      const clampedScrollPosition = Math.max(
                        0,
                        Math.min(scrollPosition, maxScrollTop)
                      )

                      // Smoothly scroll to the calculated position
                      containerRef.current?.scrollTo({
                        top: clampedScrollPosition,
                        behavior: 'smooth'
                      })
                    }
                  }}
                >
                  {item.content}
                </div>
                {isActive && (
                  <QuestionAnswerActionsBar
                    visible={isModalVisible}
                    position={'left'}
                    navigationBackOnClick={() => {
                      // fixme: there is a little state bug that shows modal until we change and come back to the question
                      const newQuestionId = item.id
                      const newQuestionIndex = items.findIndex((item) => item.id === newQuestionId)
                      const newQuestion = timeLine.filter((item: any) => item.id === newQuestionId)
                      if (newQuestion.length) {
                        setScrubTime(newQuestion[0].startTime + 0.2)
                        if (giverRef.current) {
                          giverRef.current.currentTime = giverOffset + newQuestion[0].startTime
                        }
                        if (takerRef.current) {
                          takerRef.current.currentTime = takerOffset + newQuestion[0].startTime
                        }
                        const activeQuestion = timeLine.find(
                          (q: any) => newQuestion[0].startTime >= q.startTime && newQuestion[0].startTime < q.endTime
                        )
                        // Set active question ID
                        if (activeQuestion) {
                          setActiveQuestionId(activeQuestion.id)
                        } else {
                          // Edge case: If time is beyond the last question, set the last question as active
                          setActiveQuestionId(timeLine[timeLine.length - 1].id)
                        }
                        setRealQuestionIndex(newQuestionIndex)
                        setCurrentIndex(newQuestionIndex)
                        setIsModalVisible(false)
                        setHasBeenScrolled(false)
                      }
                    }}
                    navigationToCurrentOnClick={() => {
                      setCurrentIndex(realQuestionIndex)
                      // Calculate the scroll position
                      const scrollPosition = (realQuestionIndex + -2 + paddingCount) * itemHeight

                      // Calculate the total scrollable height
                      const totalHeight = (items.length + paddingCount * 2) * itemHeight

                      // Calculate the maximum scrollable position
                      const maxScrollTop = totalHeight - itemHeight * visibleItems

                      // Ensure scroll position stays within the valid range
                      const clampedScrollPosition = Math.max(
                        0,
                        Math.min(scrollPosition, maxScrollTop)
                      )

                      // Smoothly scroll to the calculated position
                      containerRef.current?.scrollTo({
                        top: clampedScrollPosition,
                        behavior: 'smooth'
                      })
                      setIsModalVisible(false)
                      setHasBeenScrolled(false)
                    }}/>
                )}
              </div>
              <div></div>
            </div>
          </motion.div>
        </>
      )
    })
  }

  return (
    <div
      ref={containerRef}
      style={{
        overflowY: 'scroll',
        height: `${itemHeight * visibleItems}px`,
        maxWidth: '600px', // Set width to constrain text inside
        textAlign: 'center',
        scrollSnapType: 'y mandatory',
        boxSizing: 'border-box',
        scrollbarWidth: 'none', // For Firefox
        msOverflowStyle: 'none' // For IE and Edge
      }}
    >
      <div
        style={{
          /* Hide scrollbar in WebKit browsers (Chrome, Safari) */
          overflow: 'hidden'
        }}
        >
        {getItemsWithPadding()}
      </div>
    </div>
  )
}

export default FlipList
