import React, { useEffect, useState } from 'react'
import ApiManager from '../../manager/ApiManager'
import { type TakerAvailableInterviewDatesDto, TakerControllerApi } from '../../api/ic'
import { useSelector } from 'react-redux'
import { GetAccessToken } from '../../features/AccessTokenSlice'
import NotCorporateTakerInterviewCards from './NotCorporateTakerInterviewCards'
import CorporateTakerInterviewCreation from './CorporateTakerInterviewCreation'

function TakerInterviewCards () {
  const [cards, setCards] = useState<TakerAvailableInterviewDatesDto[]>([])
  const [updateTimes, setUpdateTimes] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const body = useSelector(GetAccessToken) ?? {}
  const isCorporate = !!body?.corporate ?? false
  const takerApi = ApiManager.getInstance(TakerControllerApi)

  useEffect(() => {
    if (isCorporate) {
      return
    }
    setIsLoading(true)
    void takerApi.getTakerInterviewsInfo()
      .then(res => {
        if (res.availableInterviewDates) {
          const interviewDates = [...res.availableInterviewDates]
          interviewDates.map(obj => {
            return {
              type: obj.type,
              dates: obj.dates // TODO check this check
            }
          }
          )
          setCards(res.availableInterviewDates)
          setIsLoading(false)
        }
      }).catch(() => {
        setIsLoading(false)
      })

    setUpdateTimes(false)
  }, [updateTimes])
  return (
    <>
      {
      isCorporate
        ? <CorporateTakerInterviewCreation/>
        : <NotCorporateTakerInterviewCards
            isLoading={isLoading}
            setUpdateTimes={setUpdateTimes}
            cards={cards}/>
    }
    </>
  )
}

export default TakerInterviewCards
