/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetInterviewBalanceCardTagDto
 */
export interface GetInterviewBalanceCardTagDto {
    /**
     * Tag ID
     * @type {string}
     * @memberof GetInterviewBalanceCardTagDto
     */
    id: string;
    /**
     * Tag text
     * @type {string}
     * @memberof GetInterviewBalanceCardTagDto
     */
    text: string;
    /**
     * Tag type
     * @type {string}
     * @memberof GetInterviewBalanceCardTagDto
     */
    type: GetInterviewBalanceCardTagDtoTypeEnum;
}


/**
 * @export
 */
export const GetInterviewBalanceCardTagDtoTypeEnum = {
    Balance: 'BALANCE',
    BalanceCard: 'BALANCE_CARD'
} as const;
export type GetInterviewBalanceCardTagDtoTypeEnum = typeof GetInterviewBalanceCardTagDtoTypeEnum[keyof typeof GetInterviewBalanceCardTagDtoTypeEnum];


/**
 * Check if a given object implements the GetInterviewBalanceCardTagDto interface.
 */
export function instanceOfGetInterviewBalanceCardTagDto(value: object): value is GetInterviewBalanceCardTagDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function GetInterviewBalanceCardTagDtoFromJSON(json: any): GetInterviewBalanceCardTagDto {
    return GetInterviewBalanceCardTagDtoFromJSONTyped(json, false);
}

export function GetInterviewBalanceCardTagDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInterviewBalanceCardTagDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'text': json['text'],
        'type': json['type'],
    };
}

export function GetInterviewBalanceCardTagDtoToJSON(value?: GetInterviewBalanceCardTagDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'text': value['text'],
        'type': value['type'],
    };
}

