'use client'

import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import VideoComponent from './Video'
import microphone from '../svg/resources/microphone.svg'
import videoCamera from '../svg/resources/videoCamera.svg'
import ColoredText from '../text/ColoredText'
import { FontModeValues } from '../modes/FontMode'
import SmallDropdown from '../dropdown/SmallDropdown'
import { InputModeValues } from '../modes/InputModes'
import toast from 'react-hot-toast'
import interview_mic from '../svg/resources/interview_mic.svg'
import interview_cam from '../svg/resources/interview_cam.svg'
import interview_speaker from '../svg/resources/interview_speaker.svg'
import blocked_warn from '../svg/resources/blocked_video_mic_warn.svg'
import cameraOff from '../svg/resources/cameraOff.svg'
import microphoneOff from '../svg/resources/microphoneOff.svg'
import type { InterviewProcessDTO } from '../../features/InterviewProcessSlice'
import MicVideoBlockedModal from './MicVideoBlockedModal'
import AgoraRTC from 'agora-rtc-react'

interface videoProps {
  setInterviewPresetDTO: React.Dispatch<React.SetStateAction<InterviewProcessDTO>>
  width?: string
  fullName?: string
  height?: string
  hasVideoPermission?: boolean
  hasAudioPermission?: boolean
}

function SmallVideo (props: videoProps) {
  const width = props.width ?? '600px'
  const height = props.height ?? '600px'
  const fullName = props.fullName ?? 'John Smith'
  const [cameraList, setCameraList] = useState<Array<{ label: string, deviceId: string }>>([])
  const [selectedCamera, setSelectedCamera] = useState<string | undefined>(undefined)
  const [microphoneList, setMicrophoneList] = useState<Array<{ label: string, deviceId: string }>>([])
  const [selectedMicrophone, setSelectedMicrophone] = useState<string | undefined>(undefined)
  const [speakerList, setSpeakerList] = useState<Array<{ label: string, deviceId: string }>>([])
  const [selectedSpeaker, setSelectedSpeaker] = useState<string | undefined>(undefined)
  const [muteButton, setMuteButton] = useState(true)
  const [audioLocalStream, setAudioLocalStream] = useState<MediaStream | null>(null)
  const [videoLocalStream, setVideoLocalStream] = useState<MediaStream | null>(null)
  const [videoFlag, setVideoFlag] = useState(true)
  const [anim, setAnim] = useState(false)

  // Track active media streams for reliable cleanup
  const activeStreamsRef = useRef<{
    audio: MediaStreamTrack[]
    video: MediaStreamTrack[]
  }>({
    audio: [],
    video: []
  })

  // video, mic permissions
  const [isBlockedModalOpen, setIsBlockedModalOpen] = useState(false)
  const [blockedItem, setBlockedItem] = useState<'camera' | 'mic'>('camera')

  useLayoutEffect(() => {
    void updateMediaStream()
  }, [selectedCamera,
    selectedMicrophone,
    selectedSpeaker,
    videoFlag,
    muteButton])

  // Function to safely stop all media tracks
  const stopAllMediaTracks = () => {
    // Stop all audio tracks
    if (audioLocalStream) {
      audioLocalStream.getTracks().forEach((track) => {
        track.stop()
      })
    }

    // Stop all video tracks
    if (videoLocalStream) {
      videoLocalStream.getTracks().forEach((track) => {
        track.stop()
      })
    }

    // Stop any tracks in our ref
    activeStreamsRef.current.audio.forEach((track) => {
      if (track.readyState === 'live') {
        track.stop()
      }
    })

    activeStreamsRef.current.video.forEach((track) => {
      if (track.readyState === 'live') {
        track.stop()
      }
    })

    // Clear our tracking arrays
    activeStreamsRef.current.audio = []
    activeStreamsRef.current.video = []

    // Reset state
    setVideoLocalStream(null)
    setAudioLocalStream(null)
  }

  const updateMediaStream = async () => {
    const camera = cameraList.find((obj) => obj.label === selectedCamera)
    const cameraDeviceId = camera ? camera.deviceId : null
    const microphone = microphoneList.find((obj) => obj.label === selectedMicrophone)
    const microphoneDeviceId = microphone ? microphone.deviceId : null
    const speaker = speakerList.find((obj) => obj.label === selectedSpeaker)
    const speakerDeviceId = speaker ? speaker.deviceId : null
    props.setInterviewPresetDTO((prevState) => {
      return {
        ...prevState,
        selectedCamera: cameraDeviceId,
        selectedMicrophone: microphoneDeviceId,
        selectedAudio: speakerDeviceId,
        enabledVideo: videoFlag,
        enabledMicrophone: muteButton
      }
    })

    try {
      // Stop previous audio tracks before creating new ones
      if (audioLocalStream) {
        audioLocalStream.getAudioTracks().forEach((track) => {
          track.stop()
          // Remove from active tracks
          activeStreamsRef.current.audio = activeStreamsRef.current.audio.filter((t) => t.id !== track.id)
        })
      }

      const micConstraints = {
        audio: { deviceId: microphoneDeviceId ? { exact: microphoneDeviceId } : undefined }
      }
      const stream = await navigator.mediaDevices.getUserMedia(micConstraints)

      // Track the new audio tracks
      stream.getAudioTracks().forEach((track) => {
        activeStreamsRef.current.audio.push(track)
      })

      setAudioLocalStream(stream)
    } catch (err: any) {
      console.error('Error accessing media devices:', err.name)
    }

    try {
      // Stop previous video tracks before creating new ones
      if (videoLocalStream) {
        videoLocalStream.getVideoTracks().forEach((track) => {
          track.stop()
          // Remove from active tracks
          activeStreamsRef.current.video = activeStreamsRef.current.video.filter((t) => t.id !== track.id)
        })
      }

      if (!videoFlag) {
        return
      }

      const cameraConstraints = {
        video: { deviceId: cameraDeviceId ? { exact: cameraDeviceId } : undefined }
      }
      const stream = await navigator.mediaDevices.getUserMedia(cameraConstraints)

      // Track the new video tracks
      stream.getVideoTracks().forEach((track) => {
        activeStreamsRef.current.video.push(track)
      })

      setVideoLocalStream(stream)
    } catch (err: any) {
      console.error('Error accessing media devices:', err.name)
    }
  }

  async function makeVideo (cameraList: MediaDeviceInfo[]) {
    setCameraList((prev) => {
      if (
        cameraList.length !== prev.length ||
        !cameraList.every((cam) => prev.some((pCam) => pCam.deviceId === cam.deviceId))
      ) {
        return cameraList
      }
      return prev
    })
    if (!selectedCamera && cameraList.length) {
      setSelectedCamera(cameraList[0].label)
    }
  }

  async function makeAudio (microphoneList: MediaDeviceInfo[]) {
    void navigator.mediaDevices.enumerateDevices().then((devices) => {
      const audioOutputDevices = devices.filter((device) => device.kind === 'audiooutput')
      if (!selectedSpeaker && audioOutputDevices.length) {
        setSelectedSpeaker(audioOutputDevices[0].label)
      }
      setSpeakerList(audioOutputDevices)
    })
    setMicrophoneList((prev) => {
      if (
        microphoneList.length !== prev.length ||
        !microphoneList.every((mic) => prev.some((pMic) => pMic.deviceId === mic.deviceId))
      ) {
        return microphoneList
      }
      return prev
    })
    if (!selectedMicrophone && microphoneList.length) {
      setSelectedMicrophone(microphoneList[0].label)
    }
    setSpeakerList((prev) => {
      if (
        speakerList.length !== prev.length ||
        !speakerList.every((spk) => prev.some((pSpk) => pSpk.deviceId === spk.deviceId))
      ) {
        return speakerList
      }
      return prev
    })
  }

  async function getPermissions () {
    try {
      await AgoraRTC.getDevices().then((devices: MediaDeviceInfo[]) => {
        if (!devices) {
          return
        }
        const microphoneList = devices.filter(
          (
            device
          ): device is MediaDeviceInfo & {
            kind: 'audioinput'
          } => device.kind === 'audioinput'
        )

        const cameraList = devices.filter(
          (
            device
          ): device is MediaDeviceInfo & {
            kind: 'videoinput'
          } => device.kind === 'videoinput'
        )
        void makeVideo(cameraList)
        void makeAudio(microphoneList)
      })

      // Get audio stream
      const audioConstraints = { audio: true }
      const audioStream = await navigator.mediaDevices.getUserMedia(audioConstraints)

      // Track audio tracks
      audioStream.getAudioTracks().forEach((track) => {
        activeStreamsRef.current.audio.push(track)
      })

      setAudioLocalStream(audioStream)

      // Get video stream
      const videoConstraints = { video: true }
      const videoStream = await navigator.mediaDevices.getUserMedia(videoConstraints)

      // Track video tracks
      videoStream.getVideoTracks().forEach((track) => {
        activeStreamsRef.current.video.push(track)
      })

      setVideoLocalStream(videoStream)

      setVideoFlag(true)
      setMuteButton(true)
      setAnim(true)
    } catch (error) {
      setVideoFlag(false)
      setMuteButton(false)
      console.error('Error accessing media devices:', error)
    }
  }

  // Setup page visibility change detection
  useEffect(() => {
    // Handle page visibility change
    // const handleVisibilityChange = () => {
    //   if (document.visibilityState === 'hidden') {
    //     // User is navigating away, stop all media
    //     stopAllMediaTracks()
    //   } else if (document.visibilityState === 'visible') {
    //     // User returned, reinitialize if needed
    //     void getPermissions()
    //   }
    // }

    // Handle beforeunload event
    const handleBeforeUnload = () => {
      stopAllMediaTracks()
    }

    // Add event listeners
    // document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      // Remove event listeners
      // document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('beforeunload', handleBeforeUnload)

      // Clean up all media tracks
      stopAllMediaTracks()
    }
  }, [])

  useEffect(() => {
    void getPermissions()

    // Handle device change
    const handleDeviceChange = () => {
      void getPermissions()
    }

    navigator.mediaDevices.ondevicechange = handleDeviceChange

    return () => {
      // Clean up device change listener
      navigator.mediaDevices.ondevicechange = null

      // Clean up all media tracks
      stopAllMediaTracks()
    }
  }, [])

  function toggleCamera () {
    if (!props.hasVideoPermission) {
      setIsBlockedModalOpen(true)
      setBlockedItem('camera')
      return
    }
    if (!videoLocalStream) {
      return // Modal opening
    }

    if (videoFlag) {
      setVideoFlag(false)
      toast.error(
        'Off-camera? Your interview becomes a private exploration. Turn on your camera! Illuminate your journey. '
      )

      // Stop video tracks and update tracking
      videoLocalStream.getVideoTracks().forEach((track: MediaStreamTrack) => {
        track.stop()
        // Remove from active tracks
        activeStreamsRef.current.video = activeStreamsRef.current.video.filter((t) => t.id !== track.id)
      })
    } else {
      setVideoFlag(true)
      void updateMediaStream()
    }
  }

  function toggleMicrophone () {
    if (!props.hasAudioPermission) {
      setIsBlockedModalOpen(true)
      setBlockedItem('mic')
      return
    }
    if (!audioLocalStream) {
      return // Modal opening
    }
    if (muteButton) {
      setMuteButton(false)

      // Stop audio tracks and update tracking
      audioLocalStream.getAudioTracks().forEach((track: MediaStreamTrack) => {
        track.stop()
        // Remove from active tracks
        activeStreamsRef.current.audio = activeStreamsRef.current.audio.filter((t) => t.id !== track.id)
      })
    } else {
      setMuteButton(true)
      void updateMediaStream() // Recreate the audio stream
    }
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}
      className="position-relative"
    >
      <div className="d-flex flex-column position-relative">
        <VideoComponent
          width={width}
          height={height}
          autoPlay
          muted
          style={{
            borderRadius: '10px',
            transform: 'scaleX(-1)'
          }}
          srcObject={videoLocalStream}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: '20px',
            zIndex: 2,
            left: '20px'
          }}
        >
          <ColoredText singleText={fullName} color="white"
            fontWeight={700} fontSize={FontModeValues.DEFAULT} />
        </div>
        <div
          style={{
            position: 'absolute',
            borderRadius: '10px',
            width: '100%',
            height: '100%',
            background: '#2E302F',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            zIndex: 0,
            visibility: videoFlag ? 'hidden' : 'visible'
          }}
        >
          <ColoredText singleText={anim ? 'Camera is off' : ''} color="white"
            fontSize={FontModeValues.LOGIN} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            gap: '5px'
          }}
        >
          <div
            onClick={toggleMicrophone}
            style={{
              width: '40px',
              height: '40px',
              background: '#3C4043',
              opacity: props.hasAudioPermission ? '100%' : '50%',
              display: 'flex',
              padding: '5px 0 5px 0',
              borderRadius: '20px',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
            }}
          >
            <img src={muteButton ? microphone : microphoneOff} alt="micro" />
          </div>
          {!props.hasAudioPermission && (
            <div style={{ position: 'absolute', top: -10, right: 37 }}>
              <img src={blocked_warn || '/placeholder.svg'} alt={'blocked'} />
            </div>
          )}
          <div
            onClick={toggleCamera}
            style={{
              width: '40px',
              height: '40px',
              background: '#3C4043',
              opacity: props.hasVideoPermission ? '100%' : '50%',
              display: 'flex',
              padding: '5px 0 5px 0',
              borderRadius: '20px',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
            }}
          >
            <img src={videoFlag ? videoCamera : cameraOff} alt="video" />
          </div>
          {!props.hasVideoPermission && (
            <div style={{ position: 'absolute', top: -10, right: -8 }}>
              <img src={blocked_warn || '/placeholder.svg'} alt={'blocked'} />
            </div>
          )}
        </div>
      </div>
      <div
        className={'d-flex flex-row justify-content-evenly gap-1 mx-4 flex-wrap'}
        style={{
          marginTop: '15px',
          width: '100%'
        }}
      >
        <SmallDropdown
          disabledBgColor={'#3C4043'}
          disabled={Boolean(!props.hasAudioPermission)}
          sizeMode={InputModeValues.MEDIUM}
          backgroundColor={'#3C4043'}
          selected={selectedMicrophone}
          leftIconHeight={'25px'}
          leftIconWidth={'25px'}
          logoMode="light"
          setIsSelected={setSelectedMicrophone}
          leftIcon={interview_mic}
          fontColor={!props.hasAudioPermission ? 'gray' : 'white'}
          items={microphoneList.map((device) => {
            return device.label
          })}
          eachItemStyles={{
            color: 'white'
          }}
        />

        <SmallDropdown
          disabledBgColor={'#3C4043'}
          disabled={Boolean(!props.hasVideoPermission)}
          sizeMode={InputModeValues.MEDIUM}
          backgroundColor={'#3C4043'}
          selected={selectedCamera}
          logoMode="light"
          fontColor={!props.hasVideoPermission ? 'gray' : 'white'}
          setIsSelected={setSelectedCamera}
          leftIcon={interview_cam}
          items={cameraList.map((device) => {
            return device.label
          })}
          eachItemStyles={{
            color: 'white'
          }}
        />

        <SmallDropdown
          disabledBgColor={'#3C4043'}
          disabled={Boolean(!props.hasAudioPermission)}
          sizeMode={InputModeValues.MEDIUM}
          backgroundColor={'#3C4043'}
          leftIconHeight={'25px'}
          leftIconWidth={'25px'}
          fontColor={!props.hasAudioPermission ? 'gray' : 'white'}
          selected={selectedSpeaker}
          leftIcon={interview_speaker}
          logoMode="light"
          setIsSelected={setSelectedSpeaker}
          items={speakerList.map((device) => {
            return device.label
          })}
          eachItemStyles={{
            color: 'white'
          }}
        />
      </div>
      <MicVideoBlockedModal mode={blockedItem} isOpen={isBlockedModalOpen}
        setIsOpen={setIsBlockedModalOpen} />
    </div>
  )
}

export default SmallVideo
