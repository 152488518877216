import React from 'react'
import { type GetInterviewDto } from '../api/ic'

interface RecordingManagementActionsProps {
  interview: GetInterviewDto
}

const RecordingManagementState = (props: RecordingManagementActionsProps) => {
  const { interview } = props

  return (
    <div>
      {interview.recordingPaymentCancelled ? 'Expires Soon' : ''}
    </div>
  )
}

export default RecordingManagementState
