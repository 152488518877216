/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnumTypeDto } from './EnumTypeDto';
import {
    EnumTypeDtoFromJSON,
    EnumTypeDtoFromJSONTyped,
    EnumTypeDtoToJSON,
} from './EnumTypeDto';

/**
 * 
 * @export
 * @interface InterviewTypesLevelsDto
 */
export interface InterviewTypesLevelsDto {
    /**
     * Interview types
     * @type {Array<EnumTypeDto>}
     * @memberof InterviewTypesLevelsDto
     */
    interviewTypes: Array<EnumTypeDto>;
    /**
     * Interview levels
     * @type {Array<EnumTypeDto>}
     * @memberof InterviewTypesLevelsDto
     */
    levels: Array<EnumTypeDto>;
}

/**
 * Check if a given object implements the InterviewTypesLevelsDto interface.
 */
export function instanceOfInterviewTypesLevelsDto(value: object): value is InterviewTypesLevelsDto {
    if (!('interviewTypes' in value) || value['interviewTypes'] === undefined) return false;
    if (!('levels' in value) || value['levels'] === undefined) return false;
    return true;
}

export function InterviewTypesLevelsDtoFromJSON(json: any): InterviewTypesLevelsDto {
    return InterviewTypesLevelsDtoFromJSONTyped(json, false);
}

export function InterviewTypesLevelsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewTypesLevelsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'interviewTypes': ((json['interviewTypes'] as Array<any>).map(EnumTypeDtoFromJSON)),
        'levels': ((json['levels'] as Array<any>).map(EnumTypeDtoFromJSON)),
    };
}

export function InterviewTypesLevelsDtoToJSON(value?: InterviewTypesLevelsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'interviewTypes': ((value['interviewTypes'] as Array<any>).map(EnumTypeDtoToJSON)),
        'levels': ((value['levels'] as Array<any>).map(EnumTypeDtoToJSON)),
    };
}

