import React, { useEffect, useState } from 'react'

interface Item {
  id: string
  name: string
}

interface SelectableListProps {
  data: Item[]
  onSelect?: (value: any) => void
  selectedIdOutside?: string | undefined
}

const SelectableList: React.FC<SelectableListProps> = ({ data, onSelect, selectedIdOutside }) => {
  const [selectedId, setSelectedId] = useState<string | null>(null)
  useEffect(() => {
    if (selectedIdOutside) {
      setSelectedId(selectedIdOutside)
    }
  }, [selectedIdOutside])
  const handleSelect = (id: string) => {
    if (onSelect) {
      onSelect(id)
    }
    setSelectedId(id)
  }

  return (
    <div className="d-flex flex-column gap-2">
      {data.map((item) => (
        <div
          key={item.id}
          onClick={() => { handleSelect(item.id) }}
          style={{
            padding: '4px',
            borderRadius: '6px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            background: selectedId === item.id ? 'rgba(217, 217, 217, 0.3)' : 'transparent'
          }}
        >
          {item.name}
        </div>
      ))}
    </div>
  )
}

export default SelectableList
