import React, { useCallback, useEffect, useState } from 'react'
import VerifyFooter from './VerifyFooter'
import VerifyHeader from './VerifyHeader'
import VerifyModalRouter from './VerifyModalRouter'
import VerificationForm from './VerificationForm'
import Congrats from './Congrats'
import StandardModal from '../modal/StandardModal'
import { useDispatch, useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import { setInitialState } from '../../features/TakerInfoSlice'
import CircularLoading from '../CircularLoading'
import ApiManager from '../../manager/ApiManager'
import { type EnumTypeDto, TakerControllerApi } from '../../api/ic'

interface VerifyAccountProps {
  onClose: () => void
  show: boolean
  setVerified: React.Dispatch<React.SetStateAction<boolean>>
}

export interface VerificationModalEnums {
  genders: EnumTypeDto[]
  levels: EnumTypeDto[]
  types: EnumTypeDto[]
  urls: EnumTypeDto[]
  languages: EnumTypeDto[]
  countries: EnumTypeDto[]
}

function VerifyModal (props: VerifyAccountProps) {
  const [complete, setComplete] = useState(false)
  const [busyTimeStamps, setBusyTimeStamps] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [enumsValues, setEnumValues] = useState<VerificationModalEnums>(
    {
      genders: [],
      levels: [],
      types: [],
      urls: [],
      languages: [],
      countries: []
    }
  )
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(true)

  const dispatch = useDispatch()
  const takerApi = ApiManager.getInstance(TakerControllerApi)

  const handleComplete = () => {
    setIsVerifyModalOpen(false)
    setComplete(true)
  }

  const handleClose = useCallback(() => {
    setComplete(false)
    props.onClose()
    props.setVerified(true)
  }, [])

  useEffect(() => {
    setLoading(true)
    void takerApi.getTakerValidationAllInfo().then(res => {
      dispatch(setInitialState(res))
      setBusyTimeStamps(res.busyTimes)
      setEnumValues({
        genders: res.genders,
        urls: res.urlsTypes,
        levels: res.interviewLevels,
        types: res.interviewTypes,
        languages: res.languages,
        countries: res.countries
      })
      setLoading(false)
    }).catch(() => {})
  }, [])

  return (
    <div>

      <>
        <StandardModal isOpen={isVerifyModalOpen} closeModal={setIsVerifyModalOpen}
          width={isMobile ? '98%' : `${(screen.availWidth * 80 / 100)}px`}
          height={isMobile ? '98%' : `${(screen.availHeight * 70 / 100)}px`}
          sx={{ zIndex: 13, overflowY: 'hidden' }}
          footerHeight={'80px'}
          bodyPadding={'none'}
          overflow={'hidden'}
          headerComponent={<VerifyHeader/>}
          footerComponent={loading ? <></> : <VerifyFooter onClose={props.onClose} handleComplete={handleComplete}/>}>
          {loading && <CircularLoading/>}
          {!loading && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: !isMobile ? '450px' : '98%'
              }}>
              {!isMobile && <VerifyModalRouter/>}
              <VerificationForm enumsValues={enumsValues} busyTimeStamps={busyTimeStamps}/>
            </div>
          )}
        </StandardModal>
        <Congrats complete={complete} onClose={handleClose}/>
      </>

    </div>
  )
}

export default VerifyModal
