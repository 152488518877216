import React, { useEffect, useState } from 'react'
import StandardList from './StandardList'
import { type GetInterviewDto, InterviewControllerApi } from '../api/ic'
import ApiManager from '../manager/ApiManager'
import ColoredText from '../components/text/ColoredText'
import CircularLoading from '../components/CircularLoading'

function HistoryList () {
  const [history, setHistory] = useState<GetInterviewDto[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const interviewApi = ApiManager.getInstance(InterviewControllerApi)

  useEffect(() => {
    setLoading(true)
    void interviewApi.getHistory()
      .then(res => {
        setHistory(res)
        setLoading(false)
      })
      .catch(() => { setLoading(false) })
  }, [])

  return (
    <>
      {loading && (
        <CircularLoading/>
      )}
      {!loading && (
        <>
          {history.length
            ? <StandardList
                rows={history}
                withArrow={true}
                columnNames={[
                  // 'rate', // TODO enable when the rating will be available
                  'topic',
                  'level',
                  'recording',
                  'giver',
                  'taker',
                  'language',
                  'fullDate',
                  'status'
                ]}
                deleteButton={false}/>
            : <div
                style={{
                  display: 'flex',
                  width: '100vw',
                  marginTop: '50px',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
              <ColoredText singleText={'Your history is empty at the moment — this is a great chance to explore new interviews!'}/>
            </div>
        }
        </>
      )}

    </>
  )
}

export default HistoryList
