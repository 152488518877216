import { ReduxActionType } from '../enumeration/ReduxActionType'
import { AuthManager } from '../manager/AuthManager'

export const GetAccessToken = (state: any) => {
  if (!AuthManager.getAccessToken()) {
    return null
  }
  return state.accessToken
}

export const accessTokenReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case ReduxActionType.UPDATE_ACCESS_TOKEN_VALUE:
      return action.payload
    default:
      return state
  }
}

export const updateAccessToken = (value: any) => {
  return {
    type: ReduxActionType.UPDATE_ACCESS_TOKEN_VALUE,
    payload: value
  }
}
