/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EnumTypeDto,
  InterviewTypesLevelsDto,
} from '../models/index';
import {
    EnumTypeDtoFromJSON,
    EnumTypeDtoToJSON,
    InterviewTypesLevelsDtoFromJSON,
    InterviewTypesLevelsDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface BulkGetDropdownListRequest {
    types: Array<BulkGetDropdownListTypesEnum>;
}

export interface GetDropdownListRequest {
    type: GetDropdownListTypeEnum;
}

/**
 * 
 */
export class EnumValuesControllerApi extends runtime.BaseAPI {

    /**
     * Gets dropdown list based on the type
     * Get dropdown list
     */
    async bulkGetDropdownListRaw(requestParameters: BulkGetDropdownListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<EnumTypeDto>; }>> {
        if (requestParameters['types'] == null) {
            throw new runtime.RequiredError(
                'types',
                'Required parameter "types" was null or undefined when calling bulkGetDropdownList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['types'] != null) {
            queryParameters['types'] = requestParameters['types'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/enum-values/bulk`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Gets dropdown list based on the type
     * Get dropdown list
     */
    async bulkGetDropdownList(requestParameters: BulkGetDropdownListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<EnumTypeDto>; }> {
        const response = await this.bulkGetDropdownListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets dropdown list based on the type
     * Get dropdown list
     */
    async getDropdownListRaw(requestParameters: GetDropdownListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EnumTypeDto>>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getDropdownList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/enum-values`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EnumTypeDtoFromJSON));
    }

    /**
     * Gets dropdown list based on the type
     * Get dropdown list
     */
    async getDropdownList(requestParameters: GetDropdownListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EnumTypeDto>> {
        const response = await this.getDropdownListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets dropdown list interview types and levels
     * Get dropdown list interview types and levels
     */
    async getDropdownListInterviewTypesLevelsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InterviewTypesLevelsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/enum-values/interview-types-levels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewTypesLevelsDtoFromJSON(jsonValue));
    }

    /**
     * Gets dropdown list interview types and levels
     * Get dropdown list interview types and levels
     */
    async getDropdownListInterviewTypesLevels(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InterviewTypesLevelsDto> {
        const response = await this.getDropdownListInterviewTypesLevelsRaw(initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const BulkGetDropdownListTypesEnum = {
    Urls: 'URLS',
    Languages: 'LANGUAGES',
    AvailableInterviewTypes: 'AVAILABLE_INTERVIEW_TYPES',
    Genders: 'GENDERS',
    InterviewLevels: 'INTERVIEW_LEVELS',
    Countries: 'COUNTRIES'
} as const;
export type BulkGetDropdownListTypesEnum = typeof BulkGetDropdownListTypesEnum[keyof typeof BulkGetDropdownListTypesEnum];
/**
 * @export
 */
export const GetDropdownListTypeEnum = {
    Urls: 'URLS',
    Languages: 'LANGUAGES',
    AvailableInterviewTypes: 'AVAILABLE_INTERVIEW_TYPES',
    Genders: 'GENDERS',
    InterviewLevels: 'INTERVIEW_LEVELS',
    Countries: 'COUNTRIES'
} as const;
export type GetDropdownListTypeEnum = typeof GetDropdownListTypeEnum[keyof typeof GetDropdownListTypeEnum];
