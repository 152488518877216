export const enum ButtonModeValues {
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  DEFAULT = 'DEFAULT',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
  CUSTOM_FONT = 'CUSTOM_FONT',
  EPIC_LARGE = 'EPIC_LARGE',
  CARD = 'CARD',
  EPIC_SMALL = 'EPIC_SMALL',
  EPIC_SMALL_AUTO = 'EPIC_SMALL_AUTO',
  SMALLEST = 'SMALLEST',
  REVIEW_BUTTON = 'REVIEW_BUTTON',
  FEEDBACK_BUTTON = 'FEEDBACK_BUTTON',
  EPIC_LARGE_MAX_WIDTH = 'EPIC_LARGE_MAX_WIDTH',
  TINY_SMALL = 'TINY_SMALL',
  MAX_WIDTH = 'MAX_WIDTH',
  LONG_SMALLEST = 'LONG_SMALLEST',
  MEDIUM_WIDTH = 'MEDIUM_WIDTH',
  SELECT_SIZE = 'SELECT_SIZE'
}

export const BUTTON_MODES: Record<ButtonModeValues, any> = {
  [ButtonModeValues.EXTRA_SMALL]: {
    width: '100px',
    height: '40px'
  },
  [ButtonModeValues.EPIC_SMALL]: {
    width: '65px',
    height: '20px'
  },
  [ButtonModeValues.TINY_SMALL]: {
    width: '127px',
    height: '30px'
  },
  [ButtonModeValues.EPIC_SMALL_AUTO]: {
    width: 'auto',
    height: '26px'
  },
  [ButtonModeValues.SMALL]: {
    browser: {
      width: '127px',
      height: '40px'
    },
    mobile: {
      width: '100px',
      height: '40px'
    }
  },
  [ButtonModeValues.DEFAULT]: {
    width: '155px',
    height: '48px'
  },
  [ButtonModeValues.LARGE]: {
    width: '220px',
    height: '48px'
  },
  [ButtonModeValues.EXTRA_LARGE]: {
    width: '320px',
    height: '48px'
  },
  [ButtonModeValues.MAX_WIDTH]: {
    width: '100%',
    height: '30px'
  },
  [ButtonModeValues.MEDIUM_WIDTH]: {
    width: '50%',
    height: '40px'
  },
  [ButtonModeValues.EPIC_LARGE]: {
    browser: {
      width: '720px',
      height: '48px'
    },
    mobile: {
      width: '320px',
      height: '48px'
    }
  },
  [ButtonModeValues.REVIEW_BUTTON]: {
    width: '240px',
    height: '42px'
  },
  [ButtonModeValues.SELECT_SIZE]: {
    width: '190px',
    height: '40px'
  },
  [ButtonModeValues.CUSTOM_FONT]: {
    width: 'auto',
    height: 'auto'
  },
  [ButtonModeValues.CARD]: {
    width: '220px',
    height: '24px'
  },
  [ButtonModeValues.SMALLEST]: {
    width: '64px',
    height: '28px'
  },
  [ButtonModeValues.LONG_SMALLEST]: {
    width: '156px',
    height: '28px'
  },
  [ButtonModeValues.FEEDBACK_BUTTON]: {
    browser: {
      width: '720px',
      height: '42px'
    },
    mobile: {
      width: '320px',
      height: '32px'
    }
  },
  [ButtonModeValues.EPIC_LARGE_MAX_WIDTH]: {
    browser: {
      width: '100%',
      height: '48px'
    },
    mobile: {
      width: '320px',
      height: '48px'
    }
  }

}
