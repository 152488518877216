import React from 'react'
import ColoredText from '../text/ColoredText'
import { type GetCorporateDto } from '../../api/ic'

interface CorporateBadgeInterface {
  corporate: GetCorporateDto
}

function CorporateBadge (props: CorporateBadgeInterface) {
  if (!props.corporate) {
    return <></>
  }

  return (
    <div
      style={{
        borderRadius: '5px',
        background: 'linear-gradient(90deg, #880CE8 0%, #B616B4 100%)',
        padding: '2px 15px 2px 15px'
      }}>
      <ColoredText
        singleText={`Corporate - ${props.corporate.name}`}
        fontWeight={500}
        color={'white'}
        userSelect={'none'}/>
    </div>
  )
}

export default CorporateBadge
