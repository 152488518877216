import React, { useEffect, useState } from 'react'
import arrow from '../svg/resources/right_corner_upcoming_arrow.svg'
import interviewer_face from '../svg/resources/interviewer_face.svg'
import { COLORS } from '../../util/Consts'
import SVGHandler from '../svg/SVGHandler'
import InterviewTypeImg from '../img/InterviewTypeImg'
import ColoredText from '../text/ColoredText'
import { capitalizeFirstLetter, extractDateAndTime, formatFullName, isXMinutesBefore } from '../../util/Helper'
import { useNavigate } from 'react-router-dom'
import { Popover } from '@mui/material'
import ApiManager from '../../manager/ApiManager'
import { type GetLatestUpcomingInterviewDto, GetLatestUpcomingInterviewDtoStatusEnum, InterviewControllerApi } from '../../api/ic'
import { useSelector } from 'react-redux'
import { GetAccessToken } from '../../features/AccessTokenSlice'
import { isTaker } from '../../shared/role_validation_functions'

function AbsoluteUpcomingCard () {
  const [isOpen, setIsOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [obj, setObj] = useState<GetLatestUpcomingInterviewDto>()
  const navigate = useNavigate()
  const body = useSelector(GetAccessToken) ?? {}
  const isUserTaker = isTaker(body)
  const [clickable, setClickable] = useState(false)
  const [anchorFullName, setAnchorFullName] = React.useState<HTMLElement | null>(null)

  const openFullName = Boolean(anchorFullName)
  const interviewApi = ApiManager.getInstance(InterviewControllerApi)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFullName(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorFullName(null)
  }
  useEffect(() => {
    void interviewApi.getLatestUpcoming()
      .then(res => {
        if (res) {
          setObj(res)
          setShow(true)
          setClickable(isXMinutesBefore(res.acceptedDate.toUTCString(), 27)) // TODO: change to 5)
        }
      }).catch(() => {
      })
  }, [])

  const toggleContent = () => {
    setIsOpen(!isOpen)
  }

  const contentStyle = {
    transform: `translateX(${isOpen ? '-20px' : '360px'})`,
    transition: 'transform 0.5s ease-in-out',
    width: '450px' // Adjust width based on isOpen
  }

  return (
    <>
      {show && <div
        className="d-flex flex-row"
        style={{
          ...contentStyle,
          overflow: 'hidden',
          height: '150px',
          position: 'fixed',
          right: '0',
          zIndex: '10',
          bottom: '10%'
        }}>
        <div
          className={'d-flex flex-row'}
          onClick={toggleContent}
          style={{
            width: '100px',
            position: 'relative',
            marginRight: '-10px',
            cursor: 'pointer'
          }}>
          <span
            style={{
              background: COLORS.MAIN_COLOR,
              width: '45%',
              zIndex: 5,
              boxShadow: '4px 0px 4px 0px rgba(0, 0, 0, 0.25)',
              borderRadius: '10px',
              marginLeft: '2px'
            }}
            className={'d-flex justify-content-center align-items-center'}>
            <div style={{
              transform: `rotate(${isOpen ? 0 : 180}deg)`,
              transition: 'transform 0.5s ease-in-out'
            }}>
              <SVGHandler static={true} svg={arrow}/>
            </div>
          </span>
          <div style={{
            zIndex: -1,
            writingMode: 'vertical-rl',
            textOrientation: 'mixed',
            whiteSpace: 'nowrap',
            background: COLORS.MAIN_COLOR_DARK,
            color: 'white',
            transform: 'scaleY(-1)',
            width: '55%',
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '40px',
            // boxShadow: '2px 0px 4px 0px #880CE8',
            overflow: 'visible',
            borderRadius: '4px 2px 2px 4px'
          }}
            className={'d-flex justify-content-center align-items-center'}>
            <span style={{ transform: 'scaleX(-1)' }}>
              {obj?.status === GetLatestUpcomingInterviewDtoStatusEnum.Started ? 'Ongoing' : 'Upcoming'}
            </span>
          </div>
        </div>
        {obj && <div
          className={'d-flex flex-column justify-content-between'}
          style={{ width: '350px', padding: '5px', background: 'white', borderRadius: '10px' }}>
          <div style={{ height: '75%' }} className={'d-flex flex-row'}>
            <div className={'d-flex flex-column'} style={{ width: '50%' }}>
              <div style={{ height: '49%' }} className={'d-flex flex-row align-items-center pb-2'}>
                <div>
                  <InterviewTypeImg mini={true} obj={{ type: obj.type }}/>
                </div>
                <div style={{ width: '50%' }}>
                  <ColoredText singleText={'Date'} color={COLORS.MAIN_COLOR}/>
                  {extractDateAndTime(obj.acceptedDate.toUTCString()).date}
                </div>
              </div>
              <div style={{
                height: '0.5px',
                width: '80%',
                border: '1px solid #D9D9D9',
                borderRadius: '1px',
                marginLeft: '10px'
              }}>
              </div>
              <div style={{ height: '49%' }}>
                <div style={{ height: '50%' }} className={'d-flex flex-row align-items-center pt-4'}>
                  <div style={{
                    paddingLeft: '10px',
                    paddingRight: '10px'
                  }}>
                    <img
                      src={interviewer_face}
                      alt={''}
                      width={'30px'}
                      height={'30px'}/>
                  </div>
                  <div style={{ width: '50%' }}>
                    <ColoredText singleText={isUserTaker ? 'Giver' : 'Taker'} color={COLORS.MAIN_COLOR}/>
                    <span
                      aria-owns={openFullName ? 'show-full-name' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      {formatFullName(isUserTaker ? (obj.giver.firstName ?? '') + ' ' + obj.giver.lastName : (obj.taker.firstName ?? '') + ' ' + obj.taker.lastName)}
                    </span>
                    <Popover
                      id="show-full-name"
                      sx={{
                        pointerEvents: 'none'
                      }}
                      slotProps={{
                        paper: {
                          sx: {
                            boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)'
                          }
                        }
                      }}
                      open={openFullName}
                      anchorEl={anchorFullName}
                      disableScrollLock={true}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus>
                      <span
                        className={'d-flex px-2 py-1 justify-content-center'}>
                        {isUserTaker ? (obj.giver.firstName ?? '') + ' ' + obj.giver.lastName : (obj.taker.firstName ?? '') + ' ' + obj.taker.lastName}
                      </span>
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
            <div style={{
              transform: 'rotate(90)deg',
              height: '88%',
              marginTop: '4px',
              marginBottom: '2px',
              marginRight: '8px',
              width: '1px',
              border: '1px solid #D9D9D9',
              borderRadius: '1px'
            }}>
            </div>
            <div className={'d-flex flex-column'} style={{ width: '50%' }}>
              <div style={{ height: '50%' }} className={'d-flex flex-row'}>
                <div style={{ width: '50%' }}>
                  <ColoredText singleText={'Time'} color={COLORS.MAIN_COLOR}/>
                  {extractDateAndTime(obj.acceptedDate.toUTCString()).time}
                </div>
                <div style={{ width: '50%' }}>
                  <ColoredText singleText={'Language'} color={COLORS.MAIN_COLOR}/>
                  <div>{capitalizeFirstLetter(obj.acceptedLanguage.toLowerCase() ?? '')}</div>
                </div>
              </div>
              <div style={{ height: '50%' }}>
                <ColoredText singleText={'Level'} color={COLORS.MAIN_COLOR}/>
                <div>{capitalizeFirstLetter(obj.level.toLowerCase() ?? '')}</div>
              </div>
            </div>
          </div>
          <div style={{ height: '25%', cursor: clickable ? 'pointer' : 'not-allowed' }}>
            <div onClick={() => {
              if (clickable) navigate(`/interview/${obj.id}/join`, { replace: true })
            }}
              style={{
                border: `1px solid ${clickable ? COLORS.MAIN_COLOR : 'gray'}`,
                borderRadius: '5px'
              }}
              className={'d-flex justify-content-center align-items-center'}>
              <ColoredText
                singleText={'Join'}
                color={clickable ? COLORS.MAIN_COLOR : 'gray'}
                userSelect={'none'}/>
            </div>
          </div>
        </div>}
      </div>}
    </>
  )
}

export default AbsoluteUpcomingCard
