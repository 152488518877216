/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PromiseTokenValidationRequestDto
 */
export interface PromiseTokenValidationRequestDto {
    /**
     * Promise token
     * @type {string}
     * @memberof PromiseTokenValidationRequestDto
     */
    token: string;
}

/**
 * Check if a given object implements the PromiseTokenValidationRequestDto interface.
 */
export function instanceOfPromiseTokenValidationRequestDto(value: object): value is PromiseTokenValidationRequestDto {
    if (!('token' in value) || value['token'] === undefined) return false;
    return true;
}

export function PromiseTokenValidationRequestDtoFromJSON(json: any): PromiseTokenValidationRequestDto {
    return PromiseTokenValidationRequestDtoFromJSONTyped(json, false);
}

export function PromiseTokenValidationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromiseTokenValidationRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
    };
}

export function PromiseTokenValidationRequestDtoToJSON(value?: PromiseTokenValidationRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
    };
}

