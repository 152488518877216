import React, { useEffect } from 'react'
import { type IAgoraRTCClient, useJoin } from 'agora-rtc-react'
import type { InterviewJoinCreds } from './InterviewProcess'

interface AgoraAgentProps {
  client: IAgoraRTCClient
  agentCreds: InterviewJoinCreds
  setIsConnected: (isConnected: boolean) => void
}
function AgoraAgent (props: AgoraAgentProps) {
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const { data, isLoading, isConnected, error } = useJoin({
    appid: props.agentCreds.appId,
    channel: props.agentCreds.channelName,
    token: props.agentCreds.rtc,
    uid: props.agentCreds.uid
  }, true, props.client)
  useEffect(() => {
    if (error) {
      console.error('Join failed:', error)
      return
    }
    if (isConnected) {
      props.setIsConnected(true)
    }
  }, [isConnected, error])

  return (
    <></>
  )
}

export default AgoraAgent
