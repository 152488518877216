/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Questions
 * @export
 * @interface GetQuestionDto
 */
export interface GetQuestionDto {
    /**
     * Question
     * @type {string}
     * @memberof GetQuestionDto
     */
    content: string;
    /**
     * Hint
     * @type {string}
     * @memberof GetQuestionDto
     */
    hint: string;
    /**
     * Interview type
     * @type {string}
     * @memberof GetQuestionDto
     */
    interviewType: string;
    /**
     * Levels
     * @type {Array<string>}
     * @memberof GetQuestionDto
     */
    levels: Array<GetQuestionDtoLevelsEnum>;
    /**
     * Type (default: 'system')
     * @type {string}
     * @memberof GetQuestionDto
     */
    type: string;
    /**
     * Status
     * @type {string}
     * @memberof GetQuestionDto
     */
    status: GetQuestionDtoStatusEnum;
    /**
     * Priority
     * @type {number}
     * @memberof GetQuestionDto
     */
    priority: number;
    /**
     * Interview id
     * @type {string}
     * @memberof GetQuestionDto
     */
    id: string;
    /**
     * Requires review
     * @type {boolean}
     * @memberof GetQuestionDto
     */
    requiresReview: boolean;
    /**
     * Order
     * @type {number}
     * @memberof GetQuestionDto
     */
    order: number;
}


/**
 * @export
 */
export const GetQuestionDtoLevelsEnum = {
    Beginner: 'BEGINNER',
    Intermediate: 'INTERMEDIATE',
    Pro: 'PRO'
} as const;
export type GetQuestionDtoLevelsEnum = typeof GetQuestionDtoLevelsEnum[keyof typeof GetQuestionDtoLevelsEnum];

/**
 * @export
 */
export const GetQuestionDtoStatusEnum = {
    Active: 'ACTIVE',
    DisActive: 'DIS_ACTIVE'
} as const;
export type GetQuestionDtoStatusEnum = typeof GetQuestionDtoStatusEnum[keyof typeof GetQuestionDtoStatusEnum];


/**
 * Check if a given object implements the GetQuestionDto interface.
 */
export function instanceOfGetQuestionDto(value: object): value is GetQuestionDto {
    if (!('content' in value) || value['content'] === undefined) return false;
    if (!('hint' in value) || value['hint'] === undefined) return false;
    if (!('interviewType' in value) || value['interviewType'] === undefined) return false;
    if (!('levels' in value) || value['levels'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('priority' in value) || value['priority'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('requiresReview' in value) || value['requiresReview'] === undefined) return false;
    if (!('order' in value) || value['order'] === undefined) return false;
    return true;
}

export function GetQuestionDtoFromJSON(json: any): GetQuestionDto {
    return GetQuestionDtoFromJSONTyped(json, false);
}

export function GetQuestionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetQuestionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'content': json['content'],
        'hint': json['hint'],
        'interviewType': json['interviewType'],
        'levels': json['levels'],
        'type': json['type'],
        'status': json['status'],
        'priority': json['priority'],
        'id': json['id'],
        'requiresReview': json['requiresReview'],
        'order': json['order'],
    };
}

export function GetQuestionDtoToJSON(value?: GetQuestionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'content': value['content'],
        'hint': value['hint'],
        'interviewType': value['interviewType'],
        'levels': value['levels'],
        'type': value['type'],
        'status': value['status'],
        'priority': value['priority'],
        'id': value['id'],
        'requiresReview': value['requiresReview'],
        'order': value['order'],
    };
}

