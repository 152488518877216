/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCreatedCorporateDto
 */
export interface GetCreatedCorporateDto {
    /**
     * Corporate ID
     * @type {string}
     * @memberof GetCreatedCorporateDto
     */
    id: string;
    /**
     * Corporate name
     * @type {string}
     * @memberof GetCreatedCorporateDto
     */
    name: string;
    /**
     * Corporate description
     * @type {string}
     * @memberof GetCreatedCorporateDto
     */
    description: string;
    /**
     * Corporate domain
     * @type {string}
     * @memberof GetCreatedCorporateDto
     */
    domain: string;
    /**
     * Corporate status
     * @type {boolean}
     * @memberof GetCreatedCorporateDto
     */
    active: boolean;
    /**
     * Default Admin Email
     * @type {string}
     * @memberof GetCreatedCorporateDto
     */
    defaultAdminEmail: string;
    /**
     * Default Admin Password
     * @type {string}
     * @memberof GetCreatedCorporateDto
     */
    defaultAdminPassword: string;
}

/**
 * Check if a given object implements the GetCreatedCorporateDto interface.
 */
export function instanceOfGetCreatedCorporateDto(value: object): value is GetCreatedCorporateDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('domain' in value) || value['domain'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('defaultAdminEmail' in value) || value['defaultAdminEmail'] === undefined) return false;
    if (!('defaultAdminPassword' in value) || value['defaultAdminPassword'] === undefined) return false;
    return true;
}

export function GetCreatedCorporateDtoFromJSON(json: any): GetCreatedCorporateDto {
    return GetCreatedCorporateDtoFromJSONTyped(json, false);
}

export function GetCreatedCorporateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCreatedCorporateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'domain': json['domain'],
        'active': json['active'],
        'defaultAdminEmail': json['defaultAdminEmail'],
        'defaultAdminPassword': json['defaultAdminPassword'],
    };
}

export function GetCreatedCorporateDtoToJSON(value?: GetCreatedCorporateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'domain': value['domain'],
        'active': value['active'],
        'defaultAdminEmail': value['defaultAdminEmail'],
        'defaultAdminPassword': value['defaultAdminPassword'],
    };
}

