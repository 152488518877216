/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionSearchRequestDto
 */
export interface QuestionSearchRequestDto {
    /**
     * 
     * @type {string}
     * @memberof QuestionSearchRequestDto
     */
    searchType?: QuestionSearchRequestDtoSearchTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionSearchRequestDto
     */
    searchValue?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionSearchRequestDto
     */
    interviewType?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionSearchRequestDto
     */
    excludedIds?: Array<string>;
}


/**
 * @export
 */
export const QuestionSearchRequestDtoSearchTypeEnum = {
    My: 'MY',
    All: 'ALL'
} as const;
export type QuestionSearchRequestDtoSearchTypeEnum = typeof QuestionSearchRequestDtoSearchTypeEnum[keyof typeof QuestionSearchRequestDtoSearchTypeEnum];


/**
 * Check if a given object implements the QuestionSearchRequestDto interface.
 */
export function instanceOfQuestionSearchRequestDto(value: object): value is QuestionSearchRequestDto {
    return true;
}

export function QuestionSearchRequestDtoFromJSON(json: any): QuestionSearchRequestDto {
    return QuestionSearchRequestDtoFromJSONTyped(json, false);
}

export function QuestionSearchRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionSearchRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'searchType': json['searchType'] == null ? undefined : json['searchType'],
        'searchValue': json['searchValue'] == null ? undefined : json['searchValue'],
        'interviewType': json['interviewType'] == null ? undefined : json['interviewType'],
        'excludedIds': json['excludedIds'] == null ? undefined : json['excludedIds'],
    };
}

export function QuestionSearchRequestDtoToJSON(value?: QuestionSearchRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'searchType': value['searchType'],
        'searchValue': value['searchValue'],
        'interviewType': value['interviewType'],
        'excludedIds': value['excludedIds'],
    };
}

