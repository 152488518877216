/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomTemplateCreationRequestDto,
  GetInterviewQuestionWrapperDto,
  GetInterviewTemplateDto,
  GetRuntimeInterviewQuestionTemplateWrapperDto,
  GetRuntimeInterviewTemplateDto,
  InterviewQuestionAnswerRequestDto,
  TemplateEditRequestDto,
} from '../models/index';
import {
    CustomTemplateCreationRequestDtoFromJSON,
    CustomTemplateCreationRequestDtoToJSON,
    GetInterviewQuestionWrapperDtoFromJSON,
    GetInterviewQuestionWrapperDtoToJSON,
    GetInterviewTemplateDtoFromJSON,
    GetInterviewTemplateDtoToJSON,
    GetRuntimeInterviewQuestionTemplateWrapperDtoFromJSON,
    GetRuntimeInterviewQuestionTemplateWrapperDtoToJSON,
    GetRuntimeInterviewTemplateDtoFromJSON,
    GetRuntimeInterviewTemplateDtoToJSON,
    InterviewQuestionAnswerRequestDtoFromJSON,
    InterviewQuestionAnswerRequestDtoToJSON,
    TemplateEditRequestDtoFromJSON,
    TemplateEditRequestDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface DeleteTemplateRequest {
    id: string;
}

export interface DontAskForNextRequest {
    interviewId: string;
}

export interface EditTemplateRequest {
    templateEditRequestDto: TemplateEditRequestDto;
}

export interface GenerateRuntimeTemplateRequest {
    interviewId: string;
}

export interface GenerateTemplateRequest {
    customTemplateCreationRequestDto: CustomTemplateCreationRequestDto;
}

export interface GetAllTakerTemplatesRequest {
    level?: GetAllTakerTemplatesLevelEnum;
}

export interface GetRuntimeTemplateRequest {
    interviewId: string;
}

export interface GetTemplateByIdRequest {
    templateId: string;
}

export interface NextRequest {
    interviewId: string;
    interviewQuestionAnswerRequestDto: InterviewQuestionAnswerRequestDto;
}

/**
 * 
 */
export class InterviewRuntimeTemplateControllerApi extends runtime.BaseAPI {

    /**
     */
    async deleteTemplateRaw(requestParameters: DeleteTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteTemplate(requestParameters: DeleteTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Make do not ask again for next question
     * Make do not ask again
     */
    async dontAskForNextRaw(requestParameters: DontAskForNextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['interviewId'] == null) {
            throw new runtime.RequiredError(
                'interviewId',
                'Required parameter "interviewId" was null or undefined when calling dontAskForNext().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/runtime/template/{interviewId}/dontAskForNext`.replace(`{${"interviewId"}}`, encodeURIComponent(String(requestParameters['interviewId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Make do not ask again for next question
     * Make do not ask again
     */
    async dontAskForNext(requestParameters: DontAskForNextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.dontAskForNextRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async editTemplateRaw(requestParameters: EditTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewTemplateDto>> {
        if (requestParameters['templateEditRequestDto'] == null) {
            throw new runtime.RequiredError(
                'templateEditRequestDto',
                'Required parameter "templateEditRequestDto" was null or undefined when calling editTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/template`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateEditRequestDtoToJSON(requestParameters['templateEditRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewTemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async editTemplate(requestParameters: EditTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewTemplateDto> {
        const response = await this.editTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate template for interview questions
     * Generate template
     */
    async generateRuntimeTemplateRaw(requestParameters: GenerateRuntimeTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRuntimeInterviewTemplateDto>> {
        if (requestParameters['interviewId'] == null) {
            throw new runtime.RequiredError(
                'interviewId',
                'Required parameter "interviewId" was null or undefined when calling generateRuntimeTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/runtime/template/{interviewId}/generate`.replace(`{${"interviewId"}}`, encodeURIComponent(String(requestParameters['interviewId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRuntimeInterviewTemplateDtoFromJSON(jsonValue));
    }

    /**
     * Generate template for interview questions
     * Generate template
     */
    async generateRuntimeTemplate(requestParameters: GenerateRuntimeTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRuntimeInterviewTemplateDto> {
        const response = await this.generateRuntimeTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateTemplateRaw(requestParameters: GenerateTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewTemplateDto>> {
        if (requestParameters['customTemplateCreationRequestDto'] == null) {
            throw new runtime.RequiredError(
                'customTemplateCreationRequestDto',
                'Required parameter "customTemplateCreationRequestDto" was null or undefined when calling generateTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/template/custom`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomTemplateCreationRequestDtoToJSON(requestParameters['customTemplateCreationRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewTemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async generateTemplate(requestParameters: GenerateTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewTemplateDto> {
        const response = await this.generateTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllTakerTemplatesRaw(requestParameters: GetAllTakerTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetInterviewTemplateDto>>> {
        const queryParameters: any = {};

        if (requestParameters['level'] != null) {
            queryParameters['level'] = requestParameters['level'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/template/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInterviewTemplateDtoFromJSON));
    }

    /**
     */
    async getAllTakerTemplates(requestParameters: GetAllTakerTemplatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetInterviewTemplateDto>> {
        const response = await this.getAllTakerTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get generated template for interview questions
     * Get generated template
     */
    async getRuntimeTemplateRaw(requestParameters: GetRuntimeTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRuntimeInterviewQuestionTemplateWrapperDto>> {
        if (requestParameters['interviewId'] == null) {
            throw new runtime.RequiredError(
                'interviewId',
                'Required parameter "interviewId" was null or undefined when calling getRuntimeTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/runtime/template/{interviewId}`.replace(`{${"interviewId"}}`, encodeURIComponent(String(requestParameters['interviewId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRuntimeInterviewQuestionTemplateWrapperDtoFromJSON(jsonValue));
    }

    /**
     * Get generated template for interview questions
     * Get generated template
     */
    async getRuntimeTemplate(requestParameters: GetRuntimeTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRuntimeInterviewQuestionTemplateWrapperDto> {
        const response = await this.getRuntimeTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTemplateByIdRaw(requestParameters: GetTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewTemplateDto>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling getTemplateById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/template/{templateId}`.replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters['templateId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewTemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async getTemplateById(requestParameters: GetTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewTemplateDto> {
        const response = await this.getTemplateByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Created answer entity, updates current question id on the template
     * Next question handler
     */
    async nextRaw(requestParameters: NextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewQuestionWrapperDto>> {
        if (requestParameters['interviewId'] == null) {
            throw new runtime.RequiredError(
                'interviewId',
                'Required parameter "interviewId" was null or undefined when calling next().'
            );
        }

        if (requestParameters['interviewQuestionAnswerRequestDto'] == null) {
            throw new runtime.RequiredError(
                'interviewQuestionAnswerRequestDto',
                'Required parameter "interviewQuestionAnswerRequestDto" was null or undefined when calling next().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/runtime/template/{interviewId}/next`.replace(`{${"interviewId"}}`, encodeURIComponent(String(requestParameters['interviewId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewQuestionAnswerRequestDtoToJSON(requestParameters['interviewQuestionAnswerRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewQuestionWrapperDtoFromJSON(jsonValue));
    }

    /**
     * Created answer entity, updates current question id on the template
     * Next question handler
     */
    async next(requestParameters: NextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewQuestionWrapperDto> {
        const response = await this.nextRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetAllTakerTemplatesLevelEnum = {
    Beginner: 'BEGINNER',
    Intermediate: 'INTERMEDIATE',
    Pro: 'PRO'
} as const;
export type GetAllTakerTemplatesLevelEnum = typeof GetAllTakerTemplatesLevelEnum[keyof typeof GetAllTakerTemplatesLevelEnum];
