import { JWTManager } from './JWTManager'
import Cookies from 'js-cookie'
import {
  AppControllerApi,
  AuthenticationControllerApi,
  type BaseAPI,
  type ICExceptionDto,
  ICExceptionDtoReasonEnum,
  type RefreshableAuthenticationResponseDto, VerificationRequestDtoTypeEnum
} from '../api/ic'
import ApiManager from './ApiManager'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import { updateAccessToken } from '../features/AccessTokenSlice'
import { store } from '../store/store'
import { getPublicIsHost } from '../components/VerifyModal/utils'

const ACCESS_TOKEN = 'access_token'
const REFRESH_TOKEN = 'refresh_token'

export class AuthManager {
  private constructor () {
  }

  public static setTokens (tokens: RefreshableAuthenticationResponseDto, needRefresh: boolean = true) {
    AuthManager.setAccessToken(tokens.token, needRefresh)
    AuthManager.setRefreshToken(tokens.refreshToken)
  }

  public static setAccessToken (token: string | undefined, needRefresh: boolean = true) {
    if (!token) {
      AuthManager.deleteAccessToken()
      if (needRefresh) {
        window.location.reload()
      }
      throw Error('Session token should not be null')
    }
    const payload: any = JWTManager.extractPayload(token)
    payload.role = payload.role ?? VerificationRequestDtoTypeEnum.Anonymous
    const exp = new Date(payload.expiresOn)

    Cookies.set(ACCESS_TOKEN, token, {
      expires: exp,
      path: '/',
      secure: window.location.protocol === 'https:',
      sameSite: 'Lax'
    })
    store.dispatch(updateAccessToken(payload))
  }

  public static setRefreshToken (token: string | undefined) {
    localStorage.removeItem(REFRESH_TOKEN)
    if (token) {
      localStorage.setItem(REFRESH_TOKEN, token)
    }
  }

  public static getRefreshToken (): string | null {
    const token = localStorage.getItem(REFRESH_TOKEN)
    if (token) {
      const payload: any = JWTManager.extractPayload(token)
      const expDate = new Date(payload.expiresOn)
      if (new Date() > expDate) {
        localStorage.removeItem(REFRESH_TOKEN)
      }
    }

    return localStorage.getItem(REFRESH_TOKEN)
  }

  public static getAccessToken (): string | undefined {
    const cookies = document.cookie.split('; ')
    let token
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=')
      if (name === ACCESS_TOKEN) {
        token = value
        break
      }
    }
    return token
  }

  public static getAccessTokenBody () {
    const sessionToken = AuthManager.getAccessToken()
    if (!sessionToken) {
      return undefined
    }
    return JWTManager.extractPayload(sessionToken) ?? {}
  }

  public static deleteTokens () {
    AuthManager.deleteAccessToken()
    AuthManager.deleteRefreshToken()
    store.dispatch(updateAccessToken({}))
  }

  public static deleteAccessToken () {
    const cookies = document.cookie.split('; ')
    for (let i = 0; i < cookies.length; i++) {
      const cookieName = cookies[i].split('=')[0]

      document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=' + window.location.hostname
      document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
    }
  }

  public static deleteRefreshToken () {
    localStorage.removeItem(REFRESH_TOKEN)
  }

  public static getUserRole () {
    const sessionToken = AuthManager.getAccessToken()
    if (!sessionToken) {
      return null
    }
    const tokenBody = JWTManager.extractPayload(sessionToken)
    return tokenBody?.role
  }

  public static async validateAuthRefresh (response: Response, baseApi: BaseAPI) {
    if (baseApi instanceof AuthenticationControllerApi || baseApi instanceof AppControllerApi) {
      return
    }
    if (!response) {
      return
    }
    await AuthManager.validateUserStatusChange(response, baseApi)
    // TODO handle role change
  }

  private static async validateUserStatusChange (response: Response, baseApi: BaseAPI) {
    if (baseApi instanceof AuthenticationControllerApi || baseApi instanceof AppControllerApi) {
      return
    }
    if (!response) {
      return
    }
    const customHeader = response.headers.get('X-ic-user-payload-changed')
    if (customHeader) {
      await AuthManager.refreshToken().catch(() => {})
    }
  }

  private static async validateCorporationAccess (response: Response, baseApi: BaseAPI) {
    console.log('lav txa', response, baseApi)
  }

  static async refreshTokenIfNeeded (baseApi?: BaseAPI) {
    if (baseApi && (baseApi instanceof AuthenticationControllerApi || baseApi instanceof AppControllerApi)) {
      return
    }
    const refreshToken = AuthManager.getRefreshToken()
    const accessToken = AuthManager.getAccessToken()
    if (refreshToken && !accessToken) {
      const authApi = ApiManager.getInstance(AuthenticationControllerApi)
      const res = await authApi
        .refreshToken({ authorization: refreshToken })
        .catch(e => {
          console.log('Could not obtain new token', e.message)
          throw e
        })

      AuthManager.setTokens(res)
      return res
    }

    return undefined
  }

  static async refreshToken () {
    const refreshToken = AuthManager.getRefreshToken()
    if (refreshToken) {
      const authApi = ApiManager.getInstance(AuthenticationControllerApi)
      const res = await authApi
        .refreshToken({ authorization: refreshToken })
        .catch(e => {
          console.log('Could not obtain new token', e.message)
          throw e
        })

      AuthManager.setTokens(res)
      return res
    }

    return undefined
  }

  static handleInvalidAuthResponse (icException: ICExceptionDto) {
    const corporateMember = icException.reason === ICExceptionDtoReasonEnum.UserIsNotMemberOfCorporateException
    if (icException.reason === ICExceptionDtoReasonEnum.SessionExpiredException ||
      icException.reason === ICExceptionDtoReasonEnum.InvalidSessionTokenException ||
      icException.reason === ICExceptionDtoReasonEnum.NoSessionIdProvidedInPayloadException ||
      corporateMember
    ) {
      AuthManager.deleteTokens()
      location.href = corporateMember ? (getPublicIsHost() + NavigationRoute.LOG_IN) : NavigationRoute.LOG_IN
      return true
    }
    return false
  }
}
