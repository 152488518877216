import React, { useEffect } from 'react'
import { Droppable, Draggable } from '@hello-pangea/dnd'
import drag_and_drop_dots from '../../components/svg/resources/drag_and_drop_dots.svg'
import { InlineEditableText } from './InlineEditableText'
import type { GetInterviewTemplateDto, GetQuestionDto, GetTemplateSection } from '../../api/ic'
import { scrollTo } from '../../components/VerifyModal/utils'
import uuid from 'react-uuid'

interface DndProps {
  sections: GetTemplateSection[]
  setTemplate: React.Dispatch<React.SetStateAction<GetInterviewTemplateDto | undefined>>
  warningIds: { sectionIds: string[], questionIds: string[] }
  onChange?: () => void
}
export const CustomTemplate: React.FC<DndProps> = ({ sections = [], setTemplate, warningIds, onChange }) => {
  useEffect(() => {
    if (sections.length) {
      scrollTo(sections[0].id)
    }
  }, [])
  // Example data (IDs must be unique strings).
  const addQuestion = (sectionId: string) => {
    setTemplate((prev: GetInterviewTemplateDto | undefined) => {
      if (!prev) return prev

      const updatedSections = prev.sections.map((s: GetTemplateSection) => {
        if (s.id === sectionId) {
          // Append a new question object with some unique ID
          const newQuestion: Partial<GetQuestionDto> = {
            content: '',
            id: uuid(),
            order: s.questions.length + 1
          }
          return { ...s, questions: [...s.questions, newQuestion as GetQuestionDto] }
        }
        return s
      })

      return { ...prev, sections: updatedSections }
    })
  }

  // Handler to add a new section
  const addSection = () => {
    setTemplate((prev: GetInterviewTemplateDto | undefined) => {
      if (!prev) return prev
      const newSection: GetTemplateSection = {
        id: uuid(),
        label: '',
        questions: [],
        sectionOrder: prev.sections.length + 1
      }
      const newSections = [...prev.sections, newSection]
      return { ...prev, sections: newSections }
    })
  }

  const removeSection = (sectionId: string) => {
    setTemplate((prev: GetInterviewTemplateDto | undefined) => {
      if (!prev) return prev
      const newSections = prev.sections.filter((s) => s.id !== sectionId)
      return { ...prev, sections: newSections }
    })
  }

  // Removes a question from a given section
  const removeQuestion = (sectionId: string, questionId: string) => {
    setTemplate((prev: GetInterviewTemplateDto | undefined) => {
      if (!prev) return prev

      const updatedSections = prev.sections
        .map((section) => {
          if (section.id !== sectionId) return section
          const updatedQuestions = section.questions.filter((q) => q.id !== questionId)
          // If no questions left, return null to filter out later
          return updatedQuestions.length > 0
            ? { ...section, questions: updatedQuestions }
            : null
        })
        .filter((s): s is typeof prev.sections[number] => s !== null) // Filter out removed sections

      return {
        ...prev,
        sections: updatedSections
      }
    })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
      <div className={'container-md'} id={'top'}>
        <Droppable droppableId="all-sections" direction="vertical"
          type="SECTION">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {sections.map((section, sectionIndex) => (
                <Draggable
                  draggableId={section.id}
                  index={sectionIndex}
                  key={section.id}
                >
                  {(sectionProvided) => (
                    <div
                      ref={sectionProvided.innerRef}
                      {...sectionProvided.draggableProps}
                      style={{
                        border: warningIds.sectionIds.includes(section.id) ? '1px solid red' : '1px solid rgba(0, 0, 0, 0.16)',
                        marginBottom: 30,
                        borderRadius: 10,
                        ...sectionProvided.draggableProps.style,
                        backgroundColor: 'white'
                      }}>
                      <div {...sectionProvided.dragHandleProps}
                        className={'d-flex flex-row gap-1 align-items-center justify-content-start pt-1 pb-1'}
                        style={{ background: 'rgba(0, 0, 0, 0.04)', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                        <img src={drag_and_drop_dots} alt={''}
                          style={{ userSelect: 'none' }}
                          width={'24px'} height={'24px'}/>
                        <div
                          {...sectionProvided.dragHandleProps}>
                          <InlineEditableText
                            isInitiallyEditable={true}
                            placeholder={'Section Name...'}
                            isBold
                            multiline={true}
                            value={section.label}
                            onChange={(newValue) => {
                              if (section.label !== newValue) {
                                if (onChange) onChange()
                              }
                              const cleanedValue = newValue.trim()
                              // Save edited section title
                              setTemplate((prev: GetInterviewTemplateDto | undefined) => {
                                if (!prev) return prev
                                if (cleanedValue === '') {
                                  if (section.questions.length) {
                                    return prev
                                  }
                                  const newSections = prev.sections.filter((s) =>
                                    s.id !== section.id
                                  )
                                  return { ...prev, sections: newSections }
                                } else {
                                  const newSections = prev.sections.map((s) =>
                                    s.id === section.id ? { ...s, label: newValue } : s
                                  )
                                  return { ...prev, sections: newSections }
                                }
                              }
                              )
                            }}
                            canDelete={true}
                            onDelete={() => { removeSection(section.id) } }
                            />
                        </div>
                      </div>

                      {/** Now let’s create a Droppable for questions **/}
                      <Droppable droppableId={section.id} type="QUESTION">
                        {(providedQuestions) => (
                          <div
                            ref={providedQuestions.innerRef}
                            {...providedQuestions.droppableProps}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              minHeight: 50,
                              margin: 10,
                              backgroundColor: 'white'
                            }}>
                            {section.questions.map((q, qIndex) => (
                              <Draggable
                                key={q.id}
                                draggableId={q.id}
                                index={qIndex}>
                                {(qProvided) => (
                                  <div
                                    ref={qProvided.innerRef}
                                    {...qProvided.draggableProps}
                                    {...qProvided.dragHandleProps}
                                    style={{
                                      flex: '0 0 auto', // do not stretch
                                      userSelect: 'none',
                                      padding: '4px 4px 4px 2px',
                                      border: warningIds.questionIds.includes(q.id) ? '1px solid red' : '1px solid rgba(0, 0, 0, 0.16)',
                                      borderRadius: 10,
                                      backgroundColor: 'white',
                                      ...qProvided.draggableProps.style
                                    }}
                                    className={'d-inline-flex flex-row gap-1 align-items-center justify-content-start ms-3 my-1'}>
                                    <img src={drag_and_drop_dots} alt={''}
                                      style={{ userSelect: 'none' }}
                                      width={'24px'} height={'24px'}/>
                                    <InlineEditableText
                                      placeholder={'New question here...'}
                                      isInitiallyEditable={true}
                                      multiline
                                      value={q.content}
                                      onChange={(newValue: string) => {
                                        if (q.content !== newValue) {
                                          if (onChange) onChange()
                                        }
                                        const cleanedValue = newValue.trim()
                                        // Update the question text
                                        setTemplate((prev: GetInterviewTemplateDto | undefined) => {
                                          if (!prev) return prev
                                          if (cleanedValue === '') {
                                            const newSections = prev.sections.map((s) => {
                                              if (s.id !== section.id) return s
                                              return {
                                                ...s,
                                                questions: s.questions.filter((question: GetQuestionDto) =>
                                                  question.id !== q.id
                                                )
                                              }
                                            })
                                            return { ...prev, sections: newSections }
                                          } else {
                                            const newSections = prev.sections.map((s) => {
                                              if (s.id !== section.id) return s
                                              return {
                                                ...s,
                                                questions: s.questions.map((question: GetQuestionDto) =>
                                                  question.id === q.id
                                                    ? { ...question, content: newValue }
                                                    : question
                                                )
                                              }
                                            })
                                            return { ...prev, sections: newSections }
                                          }
                                        }
                                        )
                                      }}
                                      canDelete={true}
                                      onDelete={() => { removeQuestion(section.id, q.id) } }
                                      />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {providedQuestions.placeholder}
                            <div
                              style={{ fontWeight: 'bold', cursor: 'pointer' }}
                              onClick={() => { addQuestion(section.id) }}
                              className={'ms-3 my-2'}
                              >
                              Add Question +
                            </div>
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <div onClick={addSection} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                Add Section +
              </div>
            </div>
          )}
        </Droppable>
      </div>
    </div>
  )
}
