import React, { useEffect } from 'react'
import { getPublicIsHost, isCorporateDomain } from './components/VerifyModal/utils'

interface PublicOnlyRouteProps {
  children: React.ReactNode
}

const PublicICOnly: React.FC<PublicOnlyRouteProps> = ({ children }) => {
  const isCorporate = isCorporateDomain()

  useEffect(() => {
    if (isCorporate) {
      const { pathname, search } = window.location
      window.location.href = `${getPublicIsHost()}${pathname}${search}`
    }
  }, [])

  if (isCorporate) {
    return null
  }

  return <>{children}</>
}

export default PublicICOnly
