/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GoogleAuthRequest
 */
export interface GoogleAuthRequest {
    /**
     * 
     * @type {string}
     * @memberof GoogleAuthRequest
     */
    token?: string;
}

/**
 * Check if a given object implements the GoogleAuthRequest interface.
 */
export function instanceOfGoogleAuthRequest(value: object): value is GoogleAuthRequest {
    return true;
}

export function GoogleAuthRequestFromJSON(json: any): GoogleAuthRequest {
    return GoogleAuthRequestFromJSONTyped(json, false);
}

export function GoogleAuthRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleAuthRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'] == null ? undefined : json['token'],
    };
}

export function GoogleAuthRequestToJSON(value?: GoogleAuthRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
    };
}

