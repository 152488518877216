/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LoginSessionDto,
  PromiseTokenValidationRequestDto,
  RefreshableAuthenticationResponseDto,
} from '../models/index';
import {
    LoginSessionDtoFromJSON,
    LoginSessionDtoToJSON,
    PromiseTokenValidationRequestDtoFromJSON,
    PromiseTokenValidationRequestDtoToJSON,
    RefreshableAuthenticationResponseDtoFromJSON,
    RefreshableAuthenticationResponseDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface GetPromisedSessionRequest {
    promiseTokenValidationRequestDto: PromiseTokenValidationRequestDto;
}

export interface TerminateRequest {
    uuid: string;
}

/**
 * 
 */
export class SessionControllerApi extends runtime.BaseAPI {

    /**
     * Get all sessions
     * Get all sessions
     */
    async getAllSessionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LoginSessionDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/session/allLogins`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LoginSessionDtoFromJSON));
    }

    /**
     * Get all sessions
     * Get all sessions
     */
    async getAllSessions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LoginSessionDto>> {
        const response = await this.getAllSessionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPromisedSessionRaw(requestParameters: GetPromisedSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefreshableAuthenticationResponseDto>> {
        if (requestParameters['promiseTokenValidationRequestDto'] == null) {
            throw new runtime.RequiredError(
                'promiseTokenValidationRequestDto',
                'Required parameter "promiseTokenValidationRequestDto" was null or undefined when calling getPromisedSession().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/session/getPromisedSession`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PromiseTokenValidationRequestDtoToJSON(requestParameters['promiseTokenValidationRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshableAuthenticationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPromisedSession(requestParameters: GetPromisedSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefreshableAuthenticationResponseDto> {
        const response = await this.getPromisedSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async grantSessionPromiseTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/session/grantSessionPromiseToken`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async grantSessionPromiseToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.grantSessionPromiseTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Terminate session
     * Terminate session
     */
    async terminateRaw(requestParameters: TerminateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginSessionDto>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling terminate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['uuid'] != null) {
            queryParameters['uuid'] = requestParameters['uuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/session/terminate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginSessionDtoFromJSON(jsonValue));
    }

    /**
     * Terminate session
     * Terminate session
     */
    async terminate(requestParameters: TerminateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginSessionDto> {
        const response = await this.terminateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
