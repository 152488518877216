/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetQuestionDto } from './GetQuestionDto';
import {
    GetQuestionDtoFromJSON,
    GetQuestionDtoFromJSONTyped,
    GetQuestionDtoToJSON,
} from './GetQuestionDto';

/**
 * Sections
 * @export
 * @interface GetTemplateSection
 */
export interface GetTemplateSection {
    /**
     * Section id
     * @type {string}
     * @memberof GetTemplateSection
     */
    id: string;
    /**
     * Section label
     * @type {string}
     * @memberof GetTemplateSection
     */
    label: string;
    /**
     * Section order in the template
     * @type {number}
     * @memberof GetTemplateSection
     */
    sectionOrder: number;
    /**
     * Questions
     * @type {Array<GetQuestionDto>}
     * @memberof GetTemplateSection
     */
    questions: Array<GetQuestionDto>;
}

/**
 * Check if a given object implements the GetTemplateSection interface.
 */
export function instanceOfGetTemplateSection(value: object): value is GetTemplateSection {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('sectionOrder' in value) || value['sectionOrder'] === undefined) return false;
    if (!('questions' in value) || value['questions'] === undefined) return false;
    return true;
}

export function GetTemplateSectionFromJSON(json: any): GetTemplateSection {
    return GetTemplateSectionFromJSONTyped(json, false);
}

export function GetTemplateSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTemplateSection {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'sectionOrder': json['sectionOrder'],
        'questions': ((json['questions'] as Array<any>).map(GetQuestionDtoFromJSON)),
    };
}

export function GetTemplateSectionToJSON(value?: GetTemplateSection | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'label': value['label'],
        'sectionOrder': value['sectionOrder'],
        'questions': ((value['questions'] as Array<any>).map(GetQuestionDtoToJSON)),
    };
}

