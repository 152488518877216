/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserResult } from './UserResult';
import {
    UserResultFromJSON,
    UserResultFromJSONTyped,
    UserResultToJSON,
} from './UserResult';

/**
 * Analyzing result, containing data for both giver and taker
 * @export
 * @interface InterviewAnalyzingResult
 */
export interface InterviewAnalyzingResult {
    /**
     * 
     * @type {UserResult}
     * @memberof InterviewAnalyzingResult
     */
    giver?: UserResult;
    /**
     * 
     * @type {UserResult}
     * @memberof InterviewAnalyzingResult
     */
    taker?: UserResult;
}

/**
 * Check if a given object implements the InterviewAnalyzingResult interface.
 */
export function instanceOfInterviewAnalyzingResult(value: object): value is InterviewAnalyzingResult {
    return true;
}

export function InterviewAnalyzingResultFromJSON(json: any): InterviewAnalyzingResult {
    return InterviewAnalyzingResultFromJSONTyped(json, false);
}

export function InterviewAnalyzingResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewAnalyzingResult {
    if (json == null) {
        return json;
    }
    return {
        
        'giver': json['giver'] == null ? undefined : UserResultFromJSON(json['giver']),
        'taker': json['taker'] == null ? undefined : UserResultFromJSON(json['taker']),
    };
}

export function InterviewAnalyzingResultToJSON(value?: InterviewAnalyzingResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'giver': UserResultToJSON(value['giver']),
        'taker': UserResultToJSON(value['taker']),
    };
}

