import React, { useEffect, useState } from 'react'
import { decryptJWT } from './InterviewProcessFunctions'
import ApiManager from '../../../manager/ApiManager'
import { InterviewControllerApi } from '../../../api/ic'
import InterviewLoading from '../InterviewLoading'
import { type InterviewProcessDTO } from '../../../features/InterviewProcessSlice'
import InterviewJoinPage from '../interviewJoinPage/InterviewJoinPage'
import InterviewPage from '../interviewComponents/InterviewPage'
import AgoraRTC, { AgoraRTCProvider, useRTCClient } from 'agora-rtc-react'
import { NavigationRoute } from '../../../enumeration/NavigationRoute'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import AgoraAgent from './AgoraAgent'
import { useSelector } from 'react-redux'
import { GetAccessToken } from '../../../features/AccessTokenSlice'

export interface InterviewJoinCreds {
  appId: string
  channelName: string
  rtc: string
  uid: number
  rtm: string
}

function InterviewProcess () {
  // rtm start
  const [joinCreds, setJoinCreds] = useState<any>({ appId: '', channelName: '', rtc: '', uid: 0, rtm: '' })
  const [agentCreds, setAgentCreds] = useState<InterviewJoinCreds | null>(null)

  // rtm end
  const agoraEngine = useRTCClient(AgoraRTC.createClient({ codec: 'vp8', mode: 'rtc' }))
  const agoraAgentEngine = useRTCClient(AgoraRTC.createClient({ codec: 'vp8', mode: 'rtc' }))
  const body = useSelector(GetAccessToken) ?? {}
  const [interviewPresetDTO, setInterviewPresetDTO] = useState<InterviewProcessDTO>({ interviewFinished: false, selectedCamera: null, selectedMicrophone: null, selectedAudio: null, enabledMicrophone: true, enabledVideo: true })
  const [loading, setLoading] = useState(true)
  const [isJoined, setIsJoined] = useState(false)
  const [agentConnected, setIsAgentConnected] = useState(false)
  const [signedToken, setSignedToken] = useState<string | null>(null)
  const [decryptedToken, setDecryptedToken] = useState<any>(null)
  const { id } = useParams()
  const interviewId = id
  const navigate = useNavigate()

  useEffect(() => {
    if (!interviewId) {
      return
    }
    const interviewApi = ApiManager.getInstance(InterviewControllerApi)
    interviewApi.getSignedInterviewById({ id: interviewId }).then((res) => {
      if (!res.value) {
        navigate({
          pathname: NavigationRoute.HOME_PAGE,
          search: createSearchParams({
            error_in_interview: 'true'
          }).toString()
        })
        return
      }
      setSignedToken(res.value)
      setDecryptedToken(decryptJWT(res.value))
      setLoading(false)
    })
      .catch(() => {
        setLoading(false)
        navigate({
          pathname: NavigationRoute.HOME_PAGE,
          search: createSearchParams({
            error_in_interview: 'true'
          }).toString()
        })
      })
    return () => {
      setLoading(true)
      setIsJoined(false)
      setSignedToken(null)
      setDecryptedToken(null)
    }
  }, [interviewId])

  return (
    <div style={{
      backgroundColor: '#202124',
      width: '100%',
      height: '100%',
      minHeight: '100vh',
      overflowX: 'hidden'
    }}>
      {loading && !decryptedToken && (
        <InterviewLoading/>
      )}
      {!loading && (
        <div>
          {signedToken !== null && !isJoined && decryptedToken && (
            <InterviewJoinPage
              setInterviewPresetDTO={setInterviewPresetDTO}
              acceptedDate={decryptedToken.acceptedDate}
              interviewStatus={decryptedToken.status}
              typeName={decryptedToken.name}
              isPublic={decryptedToken.isPublic}
              fullName={body.userId === decryptedToken?.takerId ? decryptedToken?.takerFullname : decryptedToken?.giverFullname}
              setIsJoined={setIsJoined}
              signedToken={signedToken}
              interviewId={decryptedToken.interviewId}
              setJoinCreds={setJoinCreds}
              setAgentCreds={setAgentCreds}
            />
          )}
          {isJoined && (
            <>
              {agentCreds && (
                <AgoraRTCProvider client={agoraAgentEngine}>
                  <AgoraAgent
                    client={agoraAgentEngine}
                    agentCreds={agentCreds}
                    setIsConnected={setIsAgentConnected}/>
                </AgoraRTCProvider>
              )}
              {(!agentCreds || (agentCreds && agentConnected)) && ( // agentConnected doesnt change its state i guess
                <AgoraRTCProvider client={agoraEngine}>
                  {interviewId && <InterviewPage
                    agoraEngine={agoraEngine}
                    interviewId={interviewId}
                    interviewJwt={decryptedToken}
                    acceptedDate={decryptedToken.acceptedDate}
                    typeName={decryptedToken.name}
                    interviewPresetSettings={interviewPresetDTO}
                    joinCreds={joinCreds}
                  />}
                </AgoraRTCProvider>
              )}
            </>
          )}
        </div>
      )
      }
    </div>
  )
}

export default InterviewProcess
