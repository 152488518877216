/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstantInterviewGetSessionRequestDto
 */
export interface InstantInterviewGetSessionRequestDto {
    /**
     * Interview Id
     * @type {string}
     * @memberof InstantInterviewGetSessionRequestDto
     */
    interviewId: string;
    /**
     * Email
     * @type {string}
     * @memberof InstantInterviewGetSessionRequestDto
     */
    email: string;
}

/**
 * Check if a given object implements the InstantInterviewGetSessionRequestDto interface.
 */
export function instanceOfInstantInterviewGetSessionRequestDto(value: object): value is InstantInterviewGetSessionRequestDto {
    if (!('interviewId' in value) || value['interviewId'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function InstantInterviewGetSessionRequestDtoFromJSON(json: any): InstantInterviewGetSessionRequestDto {
    return InstantInterviewGetSessionRequestDtoFromJSONTyped(json, false);
}

export function InstantInterviewGetSessionRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstantInterviewGetSessionRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'interviewId': json['interviewId'],
        'email': json['email'],
    };
}

export function InstantInterviewGetSessionRequestDtoToJSON(value?: InstantInterviewGetSessionRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'interviewId': value['interviewId'],
        'email': value['email'],
    };
}

