import React from 'react'
// import BoxWithBorder from './custom_template/BoxWithBorder'
// import SyncOffsetVideos from './mpd_player/SyncOffsetVideos'
// import { CustomTemplate } from './custom_template/CustomTemplate'
import CustomTemplatePage from './custom_template/CustomTemplatePage'

const TestPage: React.FC = () => {
  return (
    <div>
      {/* <SyncOffsetVideos setAvgPoints={() => {}} interviewId={'378e1926-3b89-4adb-ab80-d9d426c54f10'}/> */}
      {/* <CustomTemplate/> */}
      <CustomTemplatePage/>
    </div>
  )
}

export default TestPage
