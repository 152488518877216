import React, { useEffect, useState } from 'react'
import StandardList from '../giver/StandardList'
import AdminHeader from '../components/admin/AdminHeader'
import StandardButton from '../components/buttons/StandardButton'
import { WidthModeValues } from '../components/modes/WidthMode'
import MarginWrapper from '../components/util/MarginWrapper'
import { useNavigate } from 'react-router-dom'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import { CorporateControllerApi, type GetCorporateDto } from '../api/ic'
import ApiManager from '../manager/ApiManager'

function AllCorporatesListPage () {
  const [currentId, setCurrentId] = useState<undefined | string>(undefined)
  const [rows, setRows] = useState<GetCorporateDto[]>([])
  const navigate = useNavigate()
  const columns = [
    'checkbox',
    'id',
    'name',
    'description',
    'domain',
    'adminEmail',
    'active'
  ]

  const corporateApi = ApiManager.getInstance(CorporateControllerApi)

  useEffect(() => {
    void corporateApi.getAllCorporates()
      .then(res => {
        setRows(res)
      }).catch(() => {
      })
  }, [])

  const handleSelect = (id: string) => {
    setCurrentId(id)
  }

  const edit = () => {
    if (!currentId) {
      console.log('currentId is null')
      return
    }
    navigate(NavigationRoute.ADMIN_EDIT_CORPORATE.replace('/:id', `/${String(currentId)}`))
  }

  const changeActiveStatus = () => {
    if (!currentId) {
      console.log('currentId is null')
      return
    }
    void corporateApi.changeActiveStatus({ id: currentId })
      .then(() => {
        window.location.reload()
      }).catch(() => {
      })
  }

  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <AdminHeader title={'All corporates'}/>
      <StandardList
        rows={rows}
        withArrow={true}
        handleCheckbox={handleSelect}
        SetIsUpcomingBlank={() => {
        }}
        columnNames={columns}
        deleteButton={false}/>
      <MarginWrapper top={'20px'}>
        <div className={'d-flex flex-row'}>
          {rows.length
            ? <MarginWrapper left={'20px'}>
              <div className={'d-flex flex-row'}>
                <StandardButton
                  clickable={!!currentId}
                  text={'Edit'}
                  onClick={edit}
                  widthMode={WidthModeValues.EXTRA_SMALL}/>
                <MarginWrapper left={'20px'}>
                  <StandardButton
                    clickable={!!currentId}
                    text={'Change active status'}
                    onClick={changeActiveStatus}
                    widthMode={WidthModeValues.EXTRA_SMALL}/>
                </MarginWrapper>
              </div>
            </MarginWrapper>
            : 'No Corportaes'}
        </div>
      </MarginWrapper>
    </div>
  )
}

export default AllCorporatesListPage
