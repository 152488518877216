import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  type FormEvent,
  type KeyboardEvent,
  type FocusEvent,
  type CSSProperties
} from 'react'

interface EditableSpanProps {
  value: string
  onChange: (newValue: string) => void
  placeholder?: string
  onBlur?: (e: FocusEvent<HTMLSpanElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLSpanElement>) => void
  wrapperStyle?: CSSProperties
}

export const EditableSpan: React.FC<EditableSpanProps> = ({
  value,
  onChange,
  placeholder,
  onBlur,
  onKeyDown,
  wrapperStyle
}) => {
  const spanRef = useRef<HTMLSpanElement>(null)
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const [isFocused, setIsFocused] = useState(false)
  // Sync the internal textContent of the span with the `value` prop
  useEffect(() => {
    if (spanRef.current && spanRef.current.textContent !== value) {
      spanRef.current.textContent = value
    }
  }, [value])

  // Function to place the caret at the end of the content
  const placeCaretAtEnd = (element: HTMLElement) => {
    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(element)
    range.collapse(false)
    selection?.removeAllRanges()
    selection?.addRange(range)
  }

  // Auto focus on span when rendered and place the caret at the end
  useEffect(() => {
    if (spanRef.current) {
      if (value.trim() === '') {
        spanRef.current.focus()
      }
      placeCaretAtEnd(spanRef.current)
    }
  }, [])

  const handleInput = useCallback(
    (e: FormEvent<HTMLSpanElement>) => {
      const newValue = e.currentTarget.textContent ?? ''
      onChange(newValue)
    },
    [onChange]
  )

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLSpanElement>) => {
      setIsFocused(false)
      onBlur?.(e)
    },
    [onBlur]
  )
  const handleKeyDownInternal = useCallback(
    (e: KeyboardEvent<HTMLSpanElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault()
        spanRef.current?.blur()
        return
      }
      onKeyDown?.(e)
    },
    [onKeyDown]
  )
  const containerStyle: CSSProperties = {
    position: 'relative',
    display: 'inline-block',
    ...wrapperStyle
  }

  const editableStyle: CSSProperties = {
    outline: 'none',
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    marginRight: '10px',
    zIndex: 1
  }

  const placeholderStyle: CSSProperties = {
    color: '#aaa',
    pointerEvents: 'none',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    width: '90%',
    marginLeft: '-10px',
    marginRight: '10px',
    zIndex: 0
  }

  const showPlaceholder = !value || value === ''

  return (
    <span style={containerStyle} className={'d-flex flex-row align-items-center'}>
      <span
        ref={spanRef}
        style={editableStyle}
        contentEditable
        onInput={handleInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDownInternal}
      />
      {showPlaceholder && placeholder && (
        <span style={placeholderStyle}>{placeholder}</span>
      )}
    </span>
  )
}
