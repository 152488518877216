import React, { useEffect, useLayoutEffect, useState } from 'react'
import { checkMicPermission, checkVideoPermission } from '../../../util/VideoMicUtils'
import Navigation from '../../navigation/Navigation'
import { NavigationModeValues } from '../../modes/NavigationMode'
import SmallVideo from '../SmallVideo'
import ColoredText from '../../text/ColoredText'
import { COLORED_TEXT_VALUES } from '../../../util/Consts'
import { FontModeValues } from '../../modes/FontMode'
import StandardTooltip from '../../util/StandardTooltip'
import info from '../../svg/resources/info_interview.svg'
import StandardButton from '../../buttons/StandardButton'
import { ButtonModeValues } from '../../modes/ButtonMode'
import Timer from '../../Timer/Timer'
import MicVideoBlockedModal from '../MicVideoBlockedModal'
import { getHeaderTextForInterview } from '../interviewComponents/InterviewPageFunctions'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../../features/DeviceTypeInformationSlice'
import { getTimeDifference, isXMinutesAfter, isXMinutesBefore } from '../../../util/Helper'
import { NavigationRoute } from '../../../enumeration/NavigationRoute'
import { createSearchParams, useNavigate } from 'react-router-dom'
import type { InterviewProcessDTO } from '../../../features/InterviewProcessSlice'
import { type InterviewJoiningResponseDto, InterviewVideoControllerApi } from '../../../api/video'
import toast from 'react-hot-toast'
import ApiManager from '../../../manager/ApiManager'
import { type InterviewJoinCreds } from '../InterviewProcess/InterviewProcess'
import { GetInterviewDtoStatusEnum } from '../../../api/ic'

interface InterviewJoinPageProps {
  acceptedDate: number
  interviewStatus: GetInterviewDtoStatusEnum
  typeName: string
  fullName: string
  isPublic: boolean
  setInterviewPresetDTO: React.Dispatch<React.SetStateAction<InterviewProcessDTO>>
  setIsJoined: React.Dispatch<React.SetStateAction<boolean>>
  signedToken: string
  interviewId: string
  setJoinCreds: React.Dispatch<React.SetStateAction<InterviewJoinCreds>>
  setAgentCreds: any
}

function InterviewJoinPage (props: InterviewJoinPageProps) {
  // Controller
  const interviewVideoApi = ApiManager.getInstance(InterviewVideoControllerApi)
  // UI states
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const navigate = useNavigate()
  // Timer State
  const [timerValue, setTimerValue] = useState<null | number>(null)
  // Video, audio permissions
  const [hasVideoPermission, setHasVideoPermission] = useState<boolean>(false)
  const [hasAudioPermission, setHasAudioPermission] = useState<boolean>(false)
  const [permissionPassed, setPermissionPassed] = useState<boolean>(false)
  const [blockedItem, setBlockedItem] = useState<'camera' | 'mic'>('camera')
  const [isBlockedModalOpen, setIsBlockedModalOpen] = useState<boolean>(false)

  const getAgoraJoinDetails = (token: string): any | undefined => {
    try {
      // Validate input is a string and not empty
      if (token.trim() === '') {
        console.error('Invalid token: Token must be a non-empty string.')
        return undefined
      }

      // Decode the base64 token
      const decoded = atob(token)
      if (!decoded) {
        console.error('Decoding failed: Token could not be decoded.')
        return undefined
      }

      // Validate that the decoded string is a valid JSON
      try {
        const parsed = JSON.parse(decoded)
        if (typeof parsed !== 'object' || parsed === null) {
          console.error('Parsing failed: Decoded content is not a valid JSON object.')
          return undefined
        }
        return parsed // Return the JSON object if all validations pass
      } catch (jsonError) {
        console.error('Parsing failed: Invalid JSON format.', jsonError)
        return undefined
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error)
      return undefined
    }
  }

  // Loading State
  const [joinButtonLoading, setJoinButtonLoading] = useState<boolean>(false)
  useLayoutEffect(() => {
    // set Timer Value based on interview accepted Date
    const acceptedDateInDateObject = new Date(props.acceptedDate)
    const atLeastTenMinutesBeforeInterview = isXMinutesBefore(acceptedDateInDateObject.toUTCString(), 60)
    const thresholdMinutesPassedAfterInterview = isXMinutesAfter(acceptedDateInDateObject.toUTCString(), 60)
    if (props.interviewStatus !== GetInterviewDtoStatusEnum.Instant && (!atLeastTenMinutesBeforeInterview || thresholdMinutesPassedAfterInterview)) {
      navigate({
        pathname: NavigationRoute.HOME_PAGE,
        search: createSearchParams({
          error_in_interview: 'true'
        }).toString()
      })
      return
    }
    const timeDifference = getTimeDifference(acceptedDateInDateObject.toUTCString())
    setTimerValue(timeDifference > 0 ? timeDifference : null)
  }, [])

  useEffect(() => {
    // Check Permissions
    void checkVideoPermission().then(setHasVideoPermission)
    void checkMicPermission().then(setHasAudioPermission)
    setPermissionPassed(true)
  }, [])

  const callToSS = async () => {
    if (!hasAudioPermission) {
      setBlockedItem('mic')
      setIsBlockedModalOpen(true)
      return
    }
    if (props.isPublic && !hasVideoPermission) {
      setBlockedItem('camera')
      setIsBlockedModalOpen(true)
      return
    }
    setJoinButtonLoading(true)
    await interviewVideoApi.interviewVideoControllerJoin({
      interviewJoiningRequestDto: { bearerToken: props.signedToken }
    }).then((res: InterviewJoiningResponseDto) => {
      props.setJoinCreds(getAgoraJoinDetails(res.token))
      if (res.agentToken) {
        const agentToken = getAgoraJoinDetails(res.agentToken)
        props.setAgentCreds(agentToken)
      }
      props.setIsJoined(true)
      setJoinButtonLoading(false)
    }).catch(() => {
      toast.error('Unable to join, please try again later')
    }).finally(() => {
      setJoinButtonLoading(false)
    })
  }

  return (
    <div style={{ backgroundColor: '#202124', width: '100%', height: '100%', minHeight: '100vh', overflowX: 'hidden' }}>
      <div>
        <Navigation
          navigationMode={NavigationModeValues.FIXED_WHITE_WITH_INTERVIEW_INFO}
          interviewText={getHeaderTextForInterview(props.acceptedDate, props.typeName)}
          copyInterviewID={props.interviewId}
          mobileNavItems={[]}
          height = {'40px'}/>
        <div
          style = {{
            width: '100%',
            minHeight: 'calc(100vh - 40px)',
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            gap: '50px',
            marginTop: isMobile ? '50px' : '0px'
          }}>
          {permissionPassed && (
            <SmallVideo
              width = {!isMobile ? '600px' : '450px'}
              height={!isMobile ? '350px' : '300px'}
              fullName={props.fullName} // pass here states about permissions
              setInterviewPresetDTO={props.setInterviewPresetDTO}
              hasVideoPermission={hasVideoPermission}
              hasAudioPermission={hasAudioPermission}
            />
          )}

          <div
            style = {{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
            <ColoredText
              texts={COLORED_TEXT_VALUES.READY_TO_JOIN}
              centered = {true}/>
            <div
              style = {{
                gap: '30px',
                paddingTop: '50px'
              }}
              className='d-flex flex-column align-items-center'>
              <div
                style={{
                  maxWidth: '200px',
                  width: '200px',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
                className= {'d-flex flex-row'}>
                <ColoredText
                  singleText='Swift and Silent Feedback'
                  color = {'rgba(174, 195, 255, 1)'}
                  fontSize= {FontModeValues.SMALL}/>
                <StandardTooltip
                  texts = {COLORED_TEXT_VALUES.WELCOME_TEXT}>
                  <img alt = {'info'} src = {info}/>
                </StandardTooltip>
              </div>
              <div
                style={{
                  maxWidth: '200px',
                  width: '200px',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
                className= {'d-flex flex-row'}>
                <ColoredText
                  singleText='Time is of the Essence '
                  color = {'rgba(174, 195, 255, 1)'}
                  fontSize= {FontModeValues.SMALL}/>
                <StandardTooltip
                  texts = {COLORED_TEXT_VALUES.WELCOME_TEXT}>
                  <img alt = {'info'} src = {info}/>
                </StandardTooltip>
              </div>
              <div
                style={{
                  maxWidth: '200px',
                  width: '200px',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
                className= {'d-flex flex-row'}>
                <ColoredText
                  singleText='Here are the key points '
                  color = {'rgba(174, 195, 255, 1)'}
                  fontSize= {FontModeValues.SMALL}/>
                <StandardTooltip
                  texts = {COLORED_TEXT_VALUES.WELCOME_TEXT}>
                  <img alt = {'info'} src = {info}/>
                </StandardTooltip>
              </div>
              <div
                style = {{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  alignItems: 'center',
                  paddingTop: '20px'
                }}>
                <StandardButton
                  text={'Join interview'}
                  sizeMode= {ButtonModeValues.DEFAULT}
                  textColor='black'
                  isLoading={joinButtonLoading}
                  textFontSize= {FontModeValues.LARGE}
                  textFontWeight={500}
                  style = {{
                    background: 'white'
                  }}
                  onClick={callToSS}
                  />
                {timerValue && (
                <Timer
                  mode = 'Decrease'
                  startValue = {timerValue}
                  setValue={setTimerValue}
                  color = 'white'
                  fontSize= {FontModeValues.SMALL}/>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MicVideoBlockedModal
        mode={blockedItem}
        isOpen={isBlockedModalOpen}
        setIsOpen={setIsBlockedModalOpen}/>
    </div>
  )
}

export default InterviewJoinPage
