/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetCorporateDto } from './GetCorporateDto';
import {
    GetCorporateDtoFromJSON,
    GetCorporateDtoFromJSONTyped,
    GetCorporateDtoToJSON,
} from './GetCorporateDto';

/**
 * 
 * @export
 * @interface GetCorporateInvitationDto
 */
export interface GetCorporateInvitationDto {
    /**
     * Corporate invitation ID
     * @type {string}
     * @memberof GetCorporateInvitationDto
     */
    id: string;
    /**
     * Corporate invitation email
     * @type {string}
     * @memberof GetCorporateInvitationDto
     */
    email: string;
    /**
     * 
     * @type {GetCorporateDto}
     * @memberof GetCorporateInvitationDto
     */
    corporate: GetCorporateDto;
    /**
     * Corporate invitation status
     * @type {string}
     * @memberof GetCorporateInvitationDto
     */
    status: GetCorporateInvitationDtoStatusEnum;
}


/**
 * @export
 */
export const GetCorporateInvitationDtoStatusEnum = {
    Pending: 'PENDING',
    Accepted: 'ACCEPTED',
    Declined: 'DECLINED',
    Canceled: 'CANCELED'
} as const;
export type GetCorporateInvitationDtoStatusEnum = typeof GetCorporateInvitationDtoStatusEnum[keyof typeof GetCorporateInvitationDtoStatusEnum];


/**
 * Check if a given object implements the GetCorporateInvitationDto interface.
 */
export function instanceOfGetCorporateInvitationDto(value: object): value is GetCorporateInvitationDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('corporate' in value) || value['corporate'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function GetCorporateInvitationDtoFromJSON(json: any): GetCorporateInvitationDto {
    return GetCorporateInvitationDtoFromJSONTyped(json, false);
}

export function GetCorporateInvitationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCorporateInvitationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'corporate': GetCorporateDtoFromJSON(json['corporate']),
        'status': json['status'],
    };
}

export function GetCorporateInvitationDtoToJSON(value?: GetCorporateInvitationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email': value['email'],
        'corporate': GetCorporateDtoToJSON(value['corporate']),
        'status': value['status'],
    };
}

