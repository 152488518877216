import React, { type ReactElement } from 'react'
import ColoredText from '../../components/text/ColoredText'

interface SettingConfigurationInterface {
  title: string
  children: ReactElement[] | ReactElement
  enabled?: boolean
}

function SettingActionManager (props: SettingConfigurationInterface) {
  const enabled = props.enabled ?? true

  if (!enabled) {
    return <></>
  }

  return (
    <div className={'d-flex flex-column gap-1'}>
      <div>
        <ColoredText
          fontSize={'16px'}
          color={'#00000080'}
          singleText={props.title}/>
      </div>
      <div className={'d-flex flex-column gap-1'}>
        {props.children}
      </div>
    </div>
  )
}

export default SettingActionManager
