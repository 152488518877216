import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import { standardInputReducer } from '../features/standartInputSlice'
import { pageValueReducer } from '../features/PageValueSlice'
import { timerValueReducer } from '../features/ResendTimerSlice'
import { signupUserReducer } from '../features/SignupUserSlice'
import { loginUserReducer } from '../features/LoginUserSlice'
import { giverFlipperValueReducer } from '../features/GiverCalendarFlipperSlice'
import { giverInterviewReducer } from '../features/GiverInterviewSlice'
import { takerInfoReducer } from '../features/TakerInfoSlice'
import storage from 'redux-persist/lib/storage'
import { takerValidationNextStepReducer } from '../features/TakerValidationNextStepSlice'
import { deviceTypeInformationReducer } from '../features/DeviceTypeInformationSlice'
import { interviewJWTReducer } from '../features/InterviewJWTSlice'
import { interviewProcessReducer } from '../features/InterviewProcessSlice'
import { accessTokenReducer } from '../features/AccessTokenSlice'

const rootReducer = combineReducers({
  standardInput: standardInputReducer,
  pageValue: pageValueReducer,
  timerValue: timerValueReducer,
  signupUser: signupUserReducer,
  loginUser: loginUserReducer,
  isGiverCalendarFlipped: giverFlipperValueReducer,
  giverInterviewObject: giverInterviewReducer,
  takerInfo: takerInfoReducer,
  isTakerValidationSubmitted: takerValidationNextStepReducer,
  deviceInformation: deviceTypeInformationReducer,
  interviewJWT: interviewJWTReducer,
  interviewProcess: interviewProcessReducer,
  accessToken: accessTokenReducer
  // Add other reducers here if you have more parts of the state
})

const store = configureStore({
  reducer: persistReducer(
    {
      key: 'root',
      storage,
      blacklist: [
        'timerValue',
        'passwordValue',
        'password_confirmValue',
        'pageValue',
        'isGiverCalendarFlipped',
        'giverInterviewObject',
        'giverSelectedTimesByDates',
        'takerSelectedTimesByDates',
        'takerInfo',
        'isTakerValidationSubmitted',
        'deviceInformation',
        'interviewJWT',
        'interviewJWTReducer'
      ]
    },
    rootReducer
  ),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
})
const persistor = persistStore(store)

export { store, persistor }

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
