import React, { type ReactElement } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import { SessionControllerApi, VerificationRequestDtoTypeEnum as AuthRole } from '../api/ic'
import { useSelector } from 'react-redux'
import { GetAccessToken } from '../features/AccessTokenSlice'
import { AuthManager } from '../manager/AuthManager'
import ApiManager from '../manager/ApiManager'
import InterviewLoading from '../components/VideoChat/InterviewLoading'

interface AuthProps {
  allowedRoles: string[]
  exclude?: string[]
  additionalComponent?: ReactElement
}

const Auth: React.FC<AuthProps> = ({ allowedRoles, exclude, additionalComponent }) => {
  const pathname = window.location.pathname
  const body = useSelector(GetAccessToken) ?? {}
  const userRole = body?.role ?? AuthRole.Anonymous
  const sessionApi = ApiManager.getInstance(SessionControllerApi)

  if (allowedRoles.includes('*') || allowedRoles.includes(userRole)) {
    if (exclude && !exclude.some((p) => pathname.startsWith(p))) {
      return (
        <>
          <Outlet />
          {additionalComponent}
        </>
      )
    } else {
      return <Outlet />
    }
  }
  if (userRole === AuthRole.Anonymous) {
    return (
      <Navigate to={NavigationRoute.LOG_IN}/>
    )
  }

  if (userRole === AuthRole.RoleCorporateGiver || userRole === AuthRole.RoleInstantGiver) {
    void sessionApi.terminate({ uuid: body?.sessionId })
      .then(() => {
        AuthManager.deleteTokens()
        return <Outlet />
      })
      .catch(() => {
        AuthManager.deleteTokens()
        return <Navigate to={NavigationRoute.LOG_IN}/>
      })
    return <InterviewLoading/>
  }

  return (
    userRole === AuthRole.Admin
      ? <Navigate to={NavigationRoute.ADMIN}/>
      : userRole === AuthRole.RoleCorporateAdmin
        ? <Navigate to={NavigationRoute.CORPORATE_ADMIN}/>
        : <Navigate to={NavigationRoute.HOME_PAGE}/>
  )
}

export default Auth
