/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Interview questions with answers
 * @export
 * @interface GetQuestionWithAnswerDto
 */
export interface GetQuestionWithAnswerDto {
    /**
     * Question id
     * @type {string}
     * @memberof GetQuestionWithAnswerDto
     */
    id: string;
    /**
     * Question content
     * @type {string}
     * @memberof GetQuestionWithAnswerDto
     */
    content: string;
    /**
     * Question order
     * @type {number}
     * @memberof GetQuestionWithAnswerDto
     */
    questionOrder: number;
    /**
     * Question type
     * @type {string}
     * @memberof GetQuestionWithAnswerDto
     */
    questionType: string;
    /**
     * When the question was answered
     * @type {Date}
     * @memberof GetQuestionWithAnswerDto
     */
    answerTime: Date;
    /**
     * When the question was answered
     * @type {number}
     * @memberof GetQuestionWithAnswerDto
     */
    points: number;
}

/**
 * Check if a given object implements the GetQuestionWithAnswerDto interface.
 */
export function instanceOfGetQuestionWithAnswerDto(value: object): value is GetQuestionWithAnswerDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    if (!('questionOrder' in value) || value['questionOrder'] === undefined) return false;
    if (!('questionType' in value) || value['questionType'] === undefined) return false;
    if (!('answerTime' in value) || value['answerTime'] === undefined) return false;
    if (!('points' in value) || value['points'] === undefined) return false;
    return true;
}

export function GetQuestionWithAnswerDtoFromJSON(json: any): GetQuestionWithAnswerDto {
    return GetQuestionWithAnswerDtoFromJSONTyped(json, false);
}

export function GetQuestionWithAnswerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetQuestionWithAnswerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'content': json['content'],
        'questionOrder': json['questionOrder'],
        'questionType': json['questionType'],
        'answerTime': (new Date(json['answerTime'])),
        'points': json['points'],
    };
}

export function GetQuestionWithAnswerDtoToJSON(value?: GetQuestionWithAnswerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'content': value['content'],
        'questionOrder': value['questionOrder'],
        'questionType': value['questionType'],
        'answerTime': ((value['answerTime']).toISOString()),
        'points': value['points'],
    };
}

