import React, { useState } from 'react'
import StandardInput from '../input/StandardInput'
import StandardButton from '../buttons/StandardButton'
import MarginWrapper from '../util/MarginWrapper'
import { InputModeValues } from '../modes/InputModes'
import { ButtonModeValues } from '../modes/ButtonMode'
import { useNavigate } from 'react-router-dom'
import { StandardInputModeValues } from '../modes/StandardInputMode'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import { AuthManager } from '../../manager/AuthManager'
import { useDispatch, useSelector } from 'react-redux'
import { getLoginUser } from '../../features/LoginUserSlice'
import ApiManager from '../../manager/ApiManager'
import { ForgotPasswordControllerApi } from '../../api/ic'
import { convertJavaRegexToJS } from '../VerifyModal/VerificationFormComponents/helper'
import { updatePageValue } from '../../features/PageValueSlice'
import { LoginPage } from '../../enumeration/LoginPage'

function ResetPassword () {
  const [triggerValidate, setTriggerValidate] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isValidPassword1, setIsValidPassword1] = useState(false)
  const [isValidPassword2, setIsValidPassword2] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loginUser = useSelector(getLoginUser)
  const forgotPasswordApi = ApiManager.getInstance(ForgotPasswordControllerApi)

  const recoverPassword = async () => {
    if (password !== confirmPassword) {
      return
    }
    setLoading(true)
    await forgotPasswordApi.recover({
      passwordRecoverRequestDto: { email: loginUser.email, password }
    }).then(res => {
      AuthManager.setAccessToken(res.token)
      navigate(NavigationRoute.HOME_PAGE)
      dispatch(updatePageValue(LoginPage.MAIN_PAGE))
    }).catch(() => {})
    setLoading(false)
  }

  return (
    <>
      <div
        style={{ marginTop: '38px' }}
        className={'d-flex flex-column justify-content-center align-items-center'}>
        <StandardInput
          sizeMode={InputModeValues.LARGE}
          label={'New Password'}
          placeHolder={'Abc123$'}
          inputValue={password}
          backgroundColor={'rgba(246, 246, 246, 1)'}
          type = {StandardInputModeValues.PASSWORD}
          regex={convertJavaRegexToJS('^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?]).{8,}$')}
          showPassword = {true}
          setInputValue={setPassword}
          setTriggerValidate={setTriggerValidate}
          triggerValidate={triggerValidate}
          setIsValid={setIsValidPassword1}/>
        <MarginWrapper
          top='10px'>
          <StandardInput
            sizeMode={InputModeValues.LARGE}
            label={'Confirm Password'}
            placeHolder={'Abc123$'}
            inputValue={confirmPassword}
            backgroundColor={'rgba(246, 246, 246, 1)'}
            type = {StandardInputModeValues.PASSWORD}
            regex={convertJavaRegexToJS('^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?]).{8,}$')}
            showPassword = {true}
            setInputValue={setConfirmPassword}
            setTriggerValidate={setTriggerValidate}
            triggerValidate={triggerValidate}
            setIsValid={setIsValidPassword2}/>
        </MarginWrapper>
        <MarginWrapper
          top={'10px'}>
          <StandardButton
            sizeMode={ButtonModeValues.EXTRA_LARGE}
            text={'Confirm'}
            textFontWeight={700}
            setTriggerValidate={setTriggerValidate}
            onClick={recoverPassword}
            isLoading={loading}
            clickable={password === confirmPassword}
            isValid={isValidPassword1 && isValidPassword2}/>
        </MarginWrapper>
      </div>
    </>
  )
}

export default ResetPassword
