import toast from 'react-hot-toast'
import { isEqual } from 'lodash'
import { type TimeBlock } from '../timeRangeSelector/TakerVerificationTimeRangeSelector'
import { type EnumTypeDto } from '../api/ic'

const STRONG_PASS_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#:$%^_?+=.&*])(?=.{12,})/
const MEDIUM_PASS_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#:$%^_?+=.&*])(?=.{9,})/

const removeQueryParameter = (query: any) => {
  if (history.replaceState) {
    const url = new URL(window.location.href)
    url.searchParams.delete(query)
    const newUrl = url.toString()
    history.replaceState({}, '', newUrl)
  }
}
export const checkForToast = (query: string, value: string, message: string, isError: boolean = false) => {
  const { search } = window.location
  const isToastNeeded = new URLSearchParams(search).get(query)
  if (isToastNeeded === value) {
    // The page was just reloaded, display the toast:
    if (isError) {
      toast.error(message)
    } else {
      toast.success(message)
    }
    removeQueryParameter(query)
  }
}

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// TODO have this method for date also
export const isXMinutesBefore = (isoString: string | undefined | Date, x: number) => {
  if (!isoString) {
    return false
  }
  if (typeof isoString !== 'string' && typeof isoString !== 'undefined') {
    const currentDate = new Date()
    return isoString.getTime() - currentDate.getTime() < x * 60000
  } else {
    const dateFromIso = isoOrTimestampToDate(isoString)
    const currentDate = new Date()
    return dateFromIso.getTime() - currentDate.getTime() < x * 60000
  }
}

export const isXMinutesAfter = (isoString: string | undefined | Date, x: number) => {
  if (!isoString) {
    return false
  }
  if (typeof isoString !== 'string' && typeof isoString !== 'undefined') {
    const currentDate = new Date()
    return currentDate.getTime() - isoString.getTime() > x * 60000
  } else {
    const dateFromIso = isoOrTimestampToDate(isoString)
    const currentDate = new Date()
    return currentDate.getTime() - dateFromIso.getTime() > x * 60000
  }
}

export const getTimeDifference = (isoString: string | undefined) => {
  if (!isoString) {
    return 0
  }
  const dateFromIso = isoOrTimestampToDate(isoString)
  const currentDate = new Date()
  return Math.trunc((dateFromIso.getTime() - currentDate.getTime()) / 1000)
}

export const extractDateAndTime = (isoOrTimestamp: string | undefined) => {
  if (!isoOrTimestamp) {
    return { date: 'Unknown', time: 'Unknown' }
  }
  let yearDate
  if (isoOrTimestamp) {
    yearDate = new Date(isoOrTimestamp)
  }
  const yyyymmdd = yearDate ? `${(yearDate.getDate() >= 10) ? yearDate.getDate() : '0' + yearDate.getDate()}.${(yearDate.getMonth() > 9) ? yearDate.getMonth() + 1 : '0' + (yearDate.getMonth() + 1)}.${yearDate.getFullYear()}` : 'Unknown'
  const hhmm = yearDate ? `${(yearDate.getHours() >= 10) ? yearDate.getHours() : '0' + yearDate.getHours()}:${(yearDate.getMinutes() >= 10) ? yearDate.getMinutes() : '0' + yearDate.getMinutes().toString()}` : 'Unknown'
  return { date: yyyymmdd, time: hhmm }
}

export const isPasswordStrong = (password: string) => {
  return STRONG_PASS_REGEX.test(password)
}

export const isPasswordMedium = (password: string) => {
  return MEDIUM_PASS_REGEX.test(password)
}

export const generateStrongPassword = (length = 12) => {
  const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz'
  const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const digits = '0123456789'
  const specialChars = '!@#:$%^_?+=.&*'

  const allChars = lowerCaseChars + upperCaseChars + digits + specialChars

  let password = ''

  password += lowerCaseChars[Math.floor(Math.random() * lowerCaseChars.length)]
  password += upperCaseChars[Math.floor(Math.random() * upperCaseChars.length)]
  password += digits[Math.floor(Math.random() * digits.length)]
  password += specialChars[Math.floor(Math.random() * specialChars.length)]

  for (let i = 0; i < length - 4; i++) {
    password += allChars[Math.floor(Math.random() * allChars.length)]
  }

  password = password.split('').sort(() => Math.random() - 0.5).join('')

  return password
}

export const formatFullName = (fullName: string | undefined) => {
  if (!fullName) {
    return 'Unknown'
  }
  const words = fullName.trim().split(' ')

  if (words.length === 1) {
    if (words[0].length <= 6) {
      return words[0]
    }
    // Only one word, could be either name or surname
    if (words[0].length <= 10 && words[0].length >= 6) {
      // If the word length is less than or equal to 6, consider it as name
      return words[0].substring(0, 6) + '...'
    } else {
      // Otherwise, consider it as surname
      return words[0].substring(0, 1) + '.' + '  ' + words[0].substring(words[0].length - 4) + '...'
    }
  } else if (words.length >= 2) {
    // Full name
    const firstName = words[0]
    const lastName = words.slice(1).join(' ')
    if (lastName.length < 6) {
      return firstName.substring(0, 1) + '.' + '  ' + lastName
    }
    return firstName.substring(0, 1) + '.' + '  ' + lastName.substring(0, 4) + '...'
  } else {
    return 'Unknown'
  }
}

export function areListsWithObjectsEquivalent (list1: object[], list2: object[]) {
  if (list1.length !== list2.length) {
    return false
  }
  for (let i = 0; i < list1.length; i++) {
    const obj1 = list1[i]
    const obj2 = list2[i]

    if (!isEqual(obj1, obj2)) {
      return false
    }
  }
  return true
}

export function groupDatesFromIsoStrings (isoStrings: string[]): Array<{ date: Date, times: Date[] }> {
  const datesFromIso = isoStrings.map((isoString: any) => {
    let date
    if (typeof isoString === 'string') {
      date = isoString
    } else if (typeof isoString === 'number') {
      if (isoString.toString().length === 9 && isoString.toString().includes('.')) {
        date = isoString * 1000000
      }
      if (isoString.toString().length === 10) {
        date = isoString * 1000
      } else if (isoString.toString().length === 13) {
        date = isoString
      }
    }
    if (date) {
      return new Date(date)
    } else {
      console.log(`not valid date provided: ${isoString}`)
      throw new Error(`not valid date provided: ${isoString}`)
    }
  }
  )
  const dateMap = new Map()
  datesFromIso.forEach((date: Date) => {
    const dateKeyTimestamp = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime()
    if (dateMap.has(dateKeyTimestamp)) {
      dateMap.get(dateKeyTimestamp).times.push(date)
    } else {
      dateMap.set(dateKeyTimestamp, { date: new Date(dateKeyTimestamp), times: [date] })
    }
  })
  return Array.from(dateMap.values())
}

export function datesToIsoStrings (dates: Date[]): string[] {
  return dates.map(date => date.toISOString())
}

export function isoOrTimestampsToDates (dates: string[] | number[] | undefined): Date[] {
  if (!dates) {
    return []
  }
  return dates.map((isoString: any) => {
    let date
    if (typeof isoString === 'string') {
      date = isoString
    } else if (typeof isoString === 'number') {
      if (isoString.toString().length === 9 && isoString.toString().includes('.')) {
        date = isoString * 1000000
      }
      if (isoString.toString().length === 10) {
        date = isoString * 1000
      } else if (isoString.toString().length === 13) {
        date = isoString
      }
    }
    if (date) {
      return new Date(date)
    } else {
      console.log(`not valid date provided: ${isoString}`)
      // throw new Error(`not valid date provided: ${isoString}`)
      return new Date('') // TODO: change after fix
    }
  }
  )
}

export function isoOrTimestampToDate (date: string | number | undefined): Date {
  if (!date) {
    return new Date()
  }
  let result
  if (typeof date === 'string') {
    result = date
  } else {
    if (date.toString().length === 9 && date.toString().includes('.')) {
      result = date * 1000000
    }
    if (date.toString().length === 10) {
      result = date * 1000
    } else if (date.toString().length === 13) {
      result = date
    }
  }
  if (result) {
    return new Date(result)
  } else {
    console.log(`not valid date provided: ${date}`)
    // throw new Error(`not valid date provided: ${isoString}`)
    return new Date('') // TODO: change after fix
  }
}

export const findObjectFromKey = (values: EnumTypeDto[], obj: { key?: string }): EnumTypeDto | undefined => {
  const foundObj = values.filter(enumObj => enumObj.key === obj?.key)
  if (foundObj?.length) {
    return foundObj[0]
  }
}

export const findObjectFromKeyWithoutUndefinedValue = (values: EnumTypeDto[], obj: { key?: string }): EnumTypeDto => {
  const foundObj = values.filter(enumObj => enumObj.key === obj?.key)
  if (foundObj?.length) {
    return foundObj[0]
  }
  return { name: '', key: '' }
}

export function getEnumValueByName<T> (enumType: T, enumValue: string | undefined): T | undefined {
  if (!enumValue) {
    return undefined
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const enumValues = Object.values(enumType)
  if (enumValues.includes(enumValue)) {
    return enumValue as T
  }

  return undefined
}

export const formatDateForCalendar = (date: Date | undefined) => {
  if (!date) {
    return undefined
  }
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const formatIsoForCalendar = (isoString: string | undefined) => {
  if (!isoString) {
    return undefined
  }
  const year = new Date(isoString).getFullYear()
  const month = String(new Date(isoString).getMonth() + 1).padStart(2, '0')
  const day = String(new Date(isoString).getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const formatDateToCalendar = (date: Date | undefined) => {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  if (!date) {
    return undefined
  }
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const filterTimeBlocksByInitialTime = (initialTime: number | undefined, date: Date, timeBlocks: TimeBlock[]): TimeBlock[] => {
  const filteredBlocks: TimeBlock[] = [...timeBlocks]
  const leftList: TimeBlock[] = []
  const rightList: TimeBlock[] = []
  const dateForSorting = new Date(date)
  dateForSorting.setHours(initialTime ?? 9, 0, 0, 0)
  filteredBlocks.forEach((timeBlock: TimeBlock) => {
    if (timeBlock.startTime.getTime() >= dateForSorting.getTime()) {
      rightList.push(timeBlock)
    } else {
      leftList.push(timeBlock)
    }
  })
  rightList.sort((a: TimeBlock, b: TimeBlock) => a.startTime.getTime() - b.startTime.getTime())
  return [...rightList, ...leftList]
}
// export function groupDatesFromDates (dates: Date[]): Array<{ day: Date, times: Date[] }> {
//   const dateMap = new Map()
//   dates.forEach((date: Date) => {
//     const dateKeyTimestamp = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime()
//     if (dateMap.has(dateKeyTimestamp)) {
//       dateMap.get(dateKeyTimestamp).times.push(date)
//     } else {
//       dateMap.set(dateKeyTimestamp, { date: new Date(dateKeyTimestamp), times: [date] })
//     }
//   })
//   return Array.from(dateMap.values())
// }
export const checkDatesEqualityFromIso = (date1: string | undefined, date2: string | undefined): boolean => {
  if (date1 === undefined && date2 === undefined) {
    return true
  } else if (date1 === undefined && date2) {
    return false
  } else if (date1 && date2 === undefined) {
    return false
  } else if (typeof date2 === 'string' && typeof date1 === 'string') {
    const firstDate = new Date(date1)
    const secondDate = new Date(date2)
    return firstDate.getTime() === secondDate.getTime()
  } else {
    return false
  }
}

export const enumTypeToEnum = <T extends object> (value: EnumTypeDto | undefined, type: T): T | undefined => {
  if (!value) {
    console.log('DEV error: could not map enum type dto to enum as the value is not given')
    return undefined
  }
  const enumValue: T = Object.values(type).find(e => e === value.key)
  if (!enumValue) {
    console.log('DEV error: could not map enum type dto to enum')
    return undefined
  }
  return enumValue
}

export const enumTypesToEnumValues = <T extends object> (value: EnumTypeDto[] | undefined, type: T): T[] | undefined => {
  if (!value?.length) {
    console.log('DEV error: could not map enum type dto to enum as the list is not given')
    return undefined
  }
  const result = []
  for (const enumTypeDto of value) {
    const mappedVal = enumTypeToEnum(enumTypeDto, type)
    if (!mappedVal) {
      console.log('DEV error: could not map enum type dto to enum for lists')
      return
    }
    result.push(mappedVal)
  }
  return result
}

export const maskCardNumber = (cardNumber: string) => {
  const start = cardNumber.slice(0, 4)
  const end = cardNumber.slice(-4)
  const masked = cardNumber.slice(4, -4).replace(/\d/g, '*')

  return `${start}${masked}${end}`
}
