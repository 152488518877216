import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { NavigationRoute } from './enumeration/NavigationRoute'
import AdminPage from './pages/AdminPage'
import CreateEditInterviewTypePage from './pages/CreateEditInterviewTypePage'
import AllInterviewTypesPage from './pages/AllInterviewTypesPage'
import TakerDetailsPage from './pages/TakerDetailsPage'
import AllTakersListPage from './pages/AllTakersListPage'
import Auth from './util/Auth'
import AdPage from './pages/AdPage'
import WelcomePage from './pages/WelcomePage'
import SignUpPage from './pages/SignUpPage'
import LoginPage from './pages/LoginPage'
import ContactUsPage from './pages/ContactUsPage'
import InterviewReviewPage from './components/VideoChat/InterviewReviewPage'
import InterviewFeedbackPage from './components/VideoChat/InterviewFeedbackPage'
// import VideoChatPage from './components/VideoChat/VideoChatPage'
import HomePage from './pages/HomePage'
import InterviewCards from './giver/InterviewCards'
import HistoryList from './giver/HistoryList'
import TakerInterviewCards from './components/TakerProfil/TakerInterviewCards'
import { ProfilePage } from './pages/ProfilePage'
import NotFoundPage from './pages/NotFoundPage'
import AboutUsPage from './pages/AboutUsPage'
import AbsoluteUpcomingCard from './components/absoluteUpcomingCard/AbsoluteUpcomingCard'
import SettingsPage from './pages/settings/SettingsPage'
import TestPage from './pages/TestPage'
import CreateEditInterviewBalanceCardPage from './pages/CreateEditInterviewBalanceCardPage'
import CreateEditInterviewBalanceCardTagPage from './pages/CreateEditInterviewBalanceCardTagPage'
import AllBalanceCardTagsListPage from './pages/AllBalanceCardTagsListPage'
import AllBalanceCardListPage from './pages/AllBalanceCardListPage'
import { VerificationRequestDtoTypeEnum as AuthRole } from './api/ic'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import InterviewQuestionInsertionPage from './pages/InterviewQuestionInsertionPage'
import InterviewRecordingPage from './components/VideoChat/InterviewRecordingPage'
import InterviewProcess from './components/VideoChat/InterviewProcess/InterviewProcess'
import RecordingManagementList from './giver/RecordingManagementList'
import CreateEditCorporatePage from './pages/CreateEditCorporatePage'
import AllCorporatesListPage from './pages/AllCorporatesListPage'
import TakerUpcomingCards from './components/TakerProfil/TakerUpcomingCards'
import CorporateAdminPage from './pages/corporate_admin/CorporateAdminPage'
import CorporateAdminCreateTaker from './pages/corporate_admin/CorporateAdminCreateTaker'
import CorporateAdminSeeTakers from './pages/corporate_admin/CorporateAdminSeeTakers'
import PublicICOnly from './PublicICOnly'
import CustomTemplatePage from './pages/custom_template/CustomTemplatePage'
import CorporateInvitationsPage from './components/VideoChat/CorporateInvitations'
import InstantInterviewProcess from './components/VideoChat/InterviewProcess/InstantInterviewProcess'
import { useSelector } from 'react-redux'
import { GetAccessToken } from './features/AccessTokenSlice'
import PromisedSessionProvider from './PromisedSessionProvider'
import PromisedSessionTokenProvider from './PromisedSessionTokenProvider'
import AgoraRTC from 'agora-rtc-react'
import useRefreshTokenOnRouteChange from './useRefreshTokenOnRouteChange'

function App () {
  const isAd = localStorage.getItem('isAd') !== 'true' // TODO temp logics
  const body = useSelector(GetAccessToken) ?? {}
  AgoraRTC.setLogLevel(4)

  useRefreshTokenOnRouteChange()

  return (
    <div>
      <Routes>
        <Route path={NavigationRoute.TRY_PROVIDE_SESSION_PROMISE_TOKEN} element={
          <PublicICOnly>
            <PromisedSessionTokenProvider/>
          </PublicICOnly>
        }/>
        <Route path={NavigationRoute.TEST_PAGE} element={<TestPage/>}/>
        <Route path={NavigationRoute.PRIVACY_POLICY} element={<PrivacyPolicyPage/>}/>
        <Route element={
          <Auth allowedRoles={[AuthRole.RoleCorporateAdmin]}/>}>
          <Route path={NavigationRoute.CORPORATE_ADMIN} element={<CorporateAdminPage/>}/>

          <Route path={NavigationRoute.CORPORATE_ADMIN_CREATE_TAKER} element={<CorporateAdminCreateTaker/>}/>
          <Route path={NavigationRoute.CORPORATE_ADMIN_SEE_TAKERS} element={<CorporateAdminSeeTakers/>}/>
        </Route>
        <Route element={
          <Auth allowedRoles={[AuthRole.Admin]}/>}>
          <Route path={NavigationRoute.ADMIN} element={<AdminPage/>}/>

          <Route path={NavigationRoute.ADMIN_ADD_INTERVIEW_TYPE} element={<CreateEditInterviewTypePage/>}/>
          <Route path={NavigationRoute.ADMIN_EDIT_INTERVIEW_TYPE} element={<CreateEditInterviewTypePage/>}/>

          <Route path={NavigationRoute.ADMIN_ADD_CORPORATE} element={<CreateEditCorporatePage/>}/>
          <Route path={NavigationRoute.ADMIN_EDIT_CORPORATE} element={<CreateEditCorporatePage/>}/>
          <Route path={NavigationRoute.ADMIN_SEE_ALL_CORPORATES} element={<AllCorporatesListPage/>}/>

          <Route path={NavigationRoute.ADMIN_SEE_ALL_INTERVIEWS} element={<AllInterviewTypesPage/>}/>

          <Route path={NavigationRoute.ADMIN_TAKER_DETAILS} element={<TakerDetailsPage/>}/>
          <Route path={NavigationRoute.ADMIN_SEE_ALL_TAKER} element={<AllTakersListPage mode={'all'}/>}/>
          <Route path={NavigationRoute.ADMIN_SEE_ALL_REQUESTED_TAKER} element={<AllTakersListPage mode={'requested'}/>}/>
          <Route path={NavigationRoute.ADMIN_SEE_ALL_REJECTED_TAKER} element={<AllTakersListPage mode={'rejected'}/>}/>
          <Route path={NavigationRoute.ADMIN_INTERVIEW_QUESTION_INSERTION} element={<InterviewQuestionInsertionPage/>}/>

          <Route path={NavigationRoute.ADMIN_ADD_INTERVIEW_BALANCE_CARD_TAG} element={<CreateEditInterviewBalanceCardTagPage/>}/>
          <Route path={NavigationRoute.ADMIN_EDIT_INTERVIEW_BALANCE_CARD_TAG} element={<CreateEditInterviewBalanceCardTagPage/>}/>
          <Route path={NavigationRoute.ADMIN_SEE_ALL_INTERVIEW_BALANCE_CARD_TAGS} element={<AllBalanceCardTagsListPage/>}/>

          <Route path={NavigationRoute.ADMIN_ADD_INTERVIEW_BALANCE_CARD} element={<CreateEditInterviewBalanceCardPage/>}/>
          <Route path={NavigationRoute.ADMIN_EDIT_INTERVIEW_BALANCE_CARD} element={<CreateEditInterviewBalanceCardPage/>}/>
          <Route path={NavigationRoute.ADMIN_SEE_ALL_INTERVIEW_BALANCE_CARDS} element={<AllBalanceCardListPage/>}/>
        </Route>

        <Route element={
          <Auth allowedRoles={[
            AuthRole.RoleGiver,
            AuthRole.RoleCorporateGiver,
            AuthRole.RoleTaker,
            AuthRole.RoleCorporateTaker,
            AuthRole.Admin]}/>}>
          <Route path={NavigationRoute.SETTINGS} element={<SettingsPage/>}/>
        </Route>

        <Route element={
          <Auth allowedRoles={[
            AuthRole.Anonymous,
            AuthRole.RolePreSignup,
            AuthRole.RoleForgotPassword
          ]}/>}>
          <Route path={NavigationRoute.DEFAULT} element={isAd ? <AdPage/> : <WelcomePage/>}/>
          <Route path={NavigationRoute.CONTACT_US} element={<ContactUsPage/>}/>
        </Route>

        <Route
          element={
            <Auth allowedRoles={[
              AuthRole.Anonymous,
              AuthRole.RolePreSignup,
              AuthRole.RoleForgotPassword,
              AuthRole.RoleInstantGiver
            ]}/>
          }>
          <Route
            path={NavigationRoute.LOG_IN}
            element={
              [AuthRole.RoleInstantGiver].includes(body?.role) ? (
                <PublicICOnly>
                  <LoginPage/>
                </PublicICOnly>
              ) : (
              // <PromisedSessionProvider corporateOnly={true}>
                <LoginPage/>
              // </PromisedSessionProvider>
              )
            }
          />
        </Route>

        <Route element={
          <Auth allowedRoles={[
            AuthRole.Anonymous,
            AuthRole.RolePreSignup,
            AuthRole.RoleForgotPassword,
            AuthRole.RoleInstantGiver
          ]}/>}>
          <Route path={NavigationRoute.SIGN_UP} element={
            <PublicICOnly>
              <SignUpPage/>
            </PublicICOnly>
          }/>
        </Route>

        <Route element={
          <Auth allowedRoles={['*']}/>}>
          <Route path={NavigationRoute.INSTANT_INTERVIEW_JOIN} element={
            <PromisedSessionProvider>
              <InstantInterviewProcess/>
            </PromisedSessionProvider>
          }/>
        </Route>

        <Route element=
          {<Auth
            allowedRoles={[
              AuthRole.RoleGiver,
              AuthRole.RoleCorporateGiver,
              AuthRole.RoleTaker,
              AuthRole.RoleCorporateTaker,
              AuthRole.RoleInstantGiver
            ]}
            additionalComponent={<AbsoluteUpcomingCard/>}
            exclude={[NavigationRoute.TAKE_INTERVIEW]}/>}>
          <Route path={NavigationRoute.INTERVIEW_JOIN} element={<InterviewProcess/>}/>
          <Route path={NavigationRoute.INTERVIEW_REVIEW} element={<InterviewReviewPage/>}/>
          <Route path={NavigationRoute.INTERVIEW_FEEDBACK} element={<InterviewFeedbackPage/>}/>
        </Route>

        <Route element=
          {<Auth
            allowedRoles={[
              AuthRole.RoleGiver,
              AuthRole.RoleTaker,
              AuthRole.RoleCorporateTaker]}
            additionalComponent={<AbsoluteUpcomingCard/>}
            exclude={[NavigationRoute.TAKE_INTERVIEW]}/>}>
          <Route path={NavigationRoute.INTERVIEW_RECORDING} element={<InterviewRecordingPage/>}/>
          <Route path={NavigationRoute.CORPORATE_INVITATIONS} element={<CorporateInvitationsPage/>}/>

          <Route path={NavigationRoute.HOME_PAGE} element={<HomePage/>}>
            <Route index element={<InterviewCards/>}/>
            <Route path={NavigationRoute.INTERVIEW_HISTORY.replace('/', '')} element={<HistoryList/>}/>
          </Route>
          <Route path={NavigationRoute.RECORDINGS_MANAGEMENT} element={<RecordingManagementList/>}/>
          <Route path={NavigationRoute.PROFILE} element={<ProfilePage/>}/>
        </Route>

        <Route element=
          {<Auth
            allowedRoles={[AuthRole.RoleTaker, AuthRole.RoleCorporateTaker]}
            additionalComponent={<AbsoluteUpcomingCard/>}
            exclude={[NavigationRoute.TAKE_INTERVIEW]}/>}>
          <Route path={NavigationRoute.MANAGE_TEMPLATES} element={<CustomTemplatePage/>}/>
          <Route path={NavigationRoute.HOME_PAGE} element={<HomePage/>}>
            <Route path={NavigationRoute.TAKE_INTERVIEW.replace('/', '')} element={<TakerInterviewCards/>}/>
            <Route path={NavigationRoute.UPCOMINGS.replace('/', '')} element={<TakerUpcomingCards/>}/>
          </Route>
        </Route>

        <Route path="*" element={<NotFoundPage/>}/>
        <Route path={NavigationRoute.ABOUT} element={<AboutUsPage/>}/>
      </Routes>

    </div>
  )
}

export default App
