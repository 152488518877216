import React, { useState } from 'react'
import AdminHeader from '../../components/admin/AdminHeader'
import MarginWrapper from '../../components/util/MarginWrapper'
import StandardInput from '../../components/input/StandardInput'
import { StandardInputModeValues } from '../../components/modes/StandardInputMode'
import StandardButton from '../../components/buttons/StandardButton'
import ApiManager from '../../manager/ApiManager'
import { CorporateControllerApi } from '../../api/ic'
import toast from 'react-hot-toast'

function CorporateAdminCreateTaker () {
  const corporateApi = ApiManager.getInstance(CorporateControllerApi)

  const inviteTakerToCorporate = () => {
    void corporateApi.inviteTakerForCorporate({
      memberInvitationDto: {
        email: invitationMail
      }
    }).then(() => {
      toast.success('Taker invited to corporate successfully!')
      setInvitationMail('')
    }).catch(() => {})
  }
  const [invitationMail, setInvitationMail] = useState('')
  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <AdminHeader title={'Add Corporate Taker'}/>

      <MarginWrapper top={'40px'}>
        <StandardInput
          label={'Email'}
          inputValue={invitationMail}
          setInputValue={setInvitationMail}
          type={StandardInputModeValues.EMAIL}
          placeHolder={'Enter Invitation Email Address'}/>
        <MarginWrapper top={'20px'}>
          <StandardButton onClick={inviteTakerToCorporate}/>
        </MarginWrapper>
      </MarginWrapper>
    </div>
  )
}

export default CorporateAdminCreateTaker
