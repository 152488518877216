import React, { useEffect, useState } from 'react'
import Navigation from '../navigation/Navigation'
import { NavigationModeValues } from '../modes/NavigationMode'
import ApiManager from '../../manager/ApiManager'
import { CorporateControllerApi, type GetCorporateInvitationDto } from '../../api/ic'
import StandardList from '../../giver/StandardList'
import ColoredText from '../text/ColoredText'

function CorporateInvitationsPage () {
  const corporateApi = ApiManager.getInstance(CorporateControllerApi)
  const [rows, setRows] = useState<GetCorporateInvitationDto[]>([])

  useEffect(() => {
    void corporateApi.getUserInvitations()
      .then(res => {
        setRows(res)
      })
      .catch(() => {})
  }, [])

  return (
    <div>
      <Navigation
        navigationMode={NavigationModeValues.FIXED_MAIN_WITH_PLAN_AND_PROFILE}
        mobileNavItems={[]}
        height={'100px'}/>

      {rows.length ? <StandardList
        rows={rows}
        withArrow={true}
        columnNames={[
          'corporate.name',
          'corporate.description',
          'corporate.link',
          'corporateInvitationActions'
        ]}
        deleteButton={false}/>
        : <div
            style={{
              paddingLeft: '50px',
              paddingRight: '50px',
              marginTop: '50px',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
          <ColoredText singleText={'No invitations'}/>
        </div>
      }
    </div>
  )
}

export default CorporateInvitationsPage
