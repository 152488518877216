export const enum NavigationRoute {
  ADMIN = '/admin',
  ADMIN_ADD_INTERVIEW_TYPE = '/admin/newInterviewType',
  ADMIN_EDIT_INTERVIEW_TYPE = '/admin/newInterviewType/:id',
  ADMIN_SEE_ALL_INTERVIEWS = '/admin/allInterviews',
  ADMIN_SEE_ALL_TAKER = '/admin/takers/all',
  ADMIN_SEE_ALL_REQUESTED_TAKER = '/admin/takers/requested',
  ADMIN_SEE_ALL_REJECTED_TAKER = '/admin/takers/rejected',
  ADMIN_TAKER_DETAILS = '/admin/takers/:id',
  ADMIN_ADD_INTERVIEW_BALANCE_CARD_TAG = '/admin/balanceCard/tag',
  ADMIN_EDIT_INTERVIEW_BALANCE_CARD_TAG = '/admin/balanceCard/tag/:id',
  ADMIN_SEE_ALL_INTERVIEW_BALANCE_CARD_TAGS = '/admin/balanceCard/tag/all',
  ADMIN_ADD_INTERVIEW_BALANCE_CARD = '/admin/balanceCard',
  ADMIN_EDIT_INTERVIEW_BALANCE_CARD = '/admin/balanceCard/:id',
  ADMIN_SEE_ALL_INTERVIEW_BALANCE_CARDS = '/admin/balanceCard/all',
  ADMIN_INTERVIEW_QUESTION_INSERTION = '/admin/interview/question/insert',
  ADMIN_ADD_CORPORATE = '/admin/corporate/create',
  ADMIN_EDIT_CORPORATE = '/admin/corporate/edit/:id',
  ADMIN_SEE_ALL_CORPORATES = '/admin/corporate/all',
  DEFAULT = '/',
  ABOUT = '/about',
  CONTACT_US = '/contactus',
  SIGN_UP = '/signup',
  LOG_IN = '/login',
  TRY_PROVIDE_SESSION_PROMISE_TOKEN = '/tryProvideSessionPromiseToken',
  HOME_PAGE = '/homepage',
  INTERVIEW_HISTORY = '/history',
  TAKE_INTERVIEW = '/interview',
  UPCOMINGS = '/upcomings',
  PLAN = '/plan',
  PROFILE = '/profile',
  INTERVIEW_CONNECTING = '/interview/:interviewID/connecting',
  INTERVIEW_JOIN = '/interview/:id/join',
  INSTANT_INTERVIEW_JOIN = '/instant/interview/:id/join',
  INTERVIEW_REVIEW = '/interview/:id/review',
  INTERVIEW_FEEDBACK = '/interview/:interviewID/feedback',
  INTERVIEW_RECORDING = '/interview/:interviewID/recording',
  RECORDINGS_MANAGEMENT = '/recordings',
  INTERVIEW = '/interview/:interviewID',
  TEST_PAGE = '/testpage',
  SETTINGS = '/settings/*',
  SETTING_PAYMENT_CARD = '/settings/payment/card',
  SETTING_PAYMENT_HISTORY = '/settings/payment/history',
  SETTING_DEVICE_MANAGE = '/settings/device/manage',
  PRIVACY_POLICY = '/privacy&policy',
  // Corporate Admin URLs
  CORPORATE_ADMIN = '/corporate',
  CORPORATE_ADMIN_CREATE_TAKER = '/corporate/takers/create',
  CORPORATE_ADMIN_SEE_TAKERS = '/corporate/takers/all',
  CORPORATE_INVITATIONS = '/corporate/invitations',

  // Corporate Taker pages
  MANAGE_TEMPLATES = '/interview/templates',
}
