import React from 'react'
import { Draggable, Droppable } from '@hello-pangea/dnd'
import drag_and_drop_dots from '../../components/svg/resources/drag_and_drop_dots.svg'
import ColoredText from '../../components/text/ColoredText'

interface QuestionBankProps {
  questions: any[]
  questionSearchLoading: boolean
}

const QuestionBank: React.FC<QuestionBankProps> = ({ questions = [], questionSearchLoading }) => {
  return (
    <div style={{ overflowY: 'scroll' }}>
      <Droppable
        droppableId="question-bank"
        type="QUESTION"
        isDropDisabled={true}>
        {(provided) => {
          // If loading, we can either display nothing or show a spinner etc.
          if (questionSearchLoading) {
            return (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="d-flex flex-column gap-4"
              >
                {/* For example: "Loading..." */}
                <div>Loading...</div>
                {provided.placeholder}
              </div>
            )
          }

          // Otherwise, render the Draggable items
          return (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="d-flex flex-column gap-4"
            >
              {questions.map((q, index) => (
                <Draggable key={q.id} draggableId={q.id}
                  index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.16)',
                        borderRadius: 10,
                        backgroundColor: 'white',
                        userSelect: 'none',
                        padding: '4px 4px',
                        ...provided.draggableProps.style
                      }}
                      className="d-inline-flex flex-row align-items-center"
                    >
                      <img
                        src={drag_and_drop_dots}
                        alt=""
                        style={{ userSelect: 'none' }}
                        width="24px"
                        height="24px"
                      />
                      <div>{q.content}</div>
                    </div>
                  )}
                </Draggable>
              ))}
              {!questions.length && <ColoredText singleText={'No questions found'}/>}
              {provided.placeholder}
            </div>
          )
        }}
      </Droppable>
    </div>
  )
}

export default QuestionBank
