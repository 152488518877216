/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InterviewQuestionInsertionResponse,
  QuestionSearchRequestDto,
  QuestionSearchResponseWrapper,
} from '../models/index';
import {
    InterviewQuestionInsertionResponseFromJSON,
    InterviewQuestionInsertionResponseToJSON,
    QuestionSearchRequestDtoFromJSON,
    QuestionSearchRequestDtoToJSON,
    QuestionSearchResponseWrapperFromJSON,
    QuestionSearchResponseWrapperToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface InsertRequest {
    excelFile: Blob;
}

export interface SearchRequest {
    questionSearchRequestDto: QuestionSearchRequestDto;
}

/**
 * 
 */
export class InterviewQuestionControllerApi extends runtime.BaseAPI {

    /**
     * Inserts questions from the excel file
     * Insert questions
     */
    async insertRaw(requestParameters: InsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InterviewQuestionInsertionResponse>> {
        if (requestParameters['excelFile'] == null) {
            throw new runtime.RequiredError(
                'excelFile',
                'Required parameter "excelFile" was null or undefined when calling insert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['excelFile'] != null) {
            formParams.append('excelFile', requestParameters['excelFile'] as any);
        }

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/question/insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewQuestionInsertionResponseFromJSON(jsonValue));
    }

    /**
     * Inserts questions from the excel file
     * Insert questions
     */
    async insert(requestParameters: InsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InterviewQuestionInsertionResponse> {
        const response = await this.insertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchRaw(requestParameters: SearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionSearchResponseWrapper>> {
        if (requestParameters['questionSearchRequestDto'] == null) {
            throw new runtime.RequiredError(
                'questionSearchRequestDto',
                'Required parameter "questionSearchRequestDto" was null or undefined when calling search().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/question/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionSearchRequestDtoToJSON(requestParameters['questionSearchRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionSearchResponseWrapperFromJSON(jsonValue));
    }

    /**
     */
    async search(requestParameters: SearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionSearchResponseWrapper> {
        const response = await this.searchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
