/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetCorporateMemberDto } from './GetCorporateMemberDto';
import {
    GetCorporateMemberDtoFromJSON,
    GetCorporateMemberDtoFromJSONTyped,
    GetCorporateMemberDtoToJSON,
} from './GetCorporateMemberDto';
import type { GetCorporateInvitationDto } from './GetCorporateInvitationDto';
import {
    GetCorporateInvitationDtoFromJSON,
    GetCorporateInvitationDtoFromJSONTyped,
    GetCorporateInvitationDtoToJSON,
} from './GetCorporateInvitationDto';

/**
 * Corporate Members and Invitations
 * @export
 * @interface GetCorporateMembersAndInvitations
 */
export interface GetCorporateMembersAndInvitations {
    /**
     * Corporate Members
     * @type {Array<GetCorporateMemberDto>}
     * @memberof GetCorporateMembersAndInvitations
     */
    corporateMembers: Array<GetCorporateMemberDto>;
    /**
     * Corporate Invitations
     * @type {Array<GetCorporateInvitationDto>}
     * @memberof GetCorporateMembersAndInvitations
     */
    corporateInvitations: Array<GetCorporateInvitationDto>;
}

/**
 * Check if a given object implements the GetCorporateMembersAndInvitations interface.
 */
export function instanceOfGetCorporateMembersAndInvitations(value: object): value is GetCorporateMembersAndInvitations {
    if (!('corporateMembers' in value) || value['corporateMembers'] === undefined) return false;
    if (!('corporateInvitations' in value) || value['corporateInvitations'] === undefined) return false;
    return true;
}

export function GetCorporateMembersAndInvitationsFromJSON(json: any): GetCorporateMembersAndInvitations {
    return GetCorporateMembersAndInvitationsFromJSONTyped(json, false);
}

export function GetCorporateMembersAndInvitationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCorporateMembersAndInvitations {
    if (json == null) {
        return json;
    }
    return {
        
        'corporateMembers': ((json['corporateMembers'] as Array<any>).map(GetCorporateMemberDtoFromJSON)),
        'corporateInvitations': ((json['corporateInvitations'] as Array<any>).map(GetCorporateInvitationDtoFromJSON)),
    };
}

export function GetCorporateMembersAndInvitationsToJSON(value?: GetCorporateMembersAndInvitations | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'corporateMembers': ((value['corporateMembers'] as Array<any>).map(GetCorporateMemberDtoToJSON)),
        'corporateInvitations': ((value['corporateInvitations'] as Array<any>).map(GetCorporateInvitationDtoToJSON)),
    };
}

