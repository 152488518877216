import React, { useEffect, useRef, useState } from 'react'
import { sendMessage } from './MessageHelperFunctions'
import { type RtmChannel } from 'agora-rtm-react'
import send_message from '../../svg/resources/send_message.svg'
import { COLORS } from '../../../util/Consts'
interface ChatProps {
  session: RtmChannel | null
  isOpen: boolean
  onClose: () => void
  senderName: string
  messages: any[]
  setMessages: any
}

const Chat: React.FC<ChatProps> = ({ session, isOpen, onClose, senderName, messages, setMessages }) => {
  const [input, setInput] = useState('')

  const messagesEndRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  // Send a chat message
  const handleSend = async () => {
    if (!session) return

    if (!input.trim()) return

    setMessages((prev: any) => [...prev, { sender: 'Me', text: input }])

    await sendMessage(session, { type: 'ChatMessage', message: { text: input, sender: senderName } })
    setInput('')
  }

  return (
    <div
      className={`position-fixed end-0 bg-white shadow-lg transition ${
        isOpen ? 'd-block' : 'd-none'
      } p-3 border d-flex flex-column`}
      style={{
        top: '100px', // Start below the navbar
        bottom: '80px', // Extend to bottom
        height: 'calc(100vh - 180px)', // Use available height
        width: '20vw', // 20% of viewport width
        maxWidth: '300px', // Prevents excessive width
        borderBottomLeftRadius: '10px',
        borderTopLeftRadius: '10px'
        // Removed overflow: 'hidden'
      }}
    >
      <div className={'d-flex flex-column justify-content-lg-start'}>
        <div className={'d-flex flex-row gap-2 justify-content-between'}>
          <h3 className="fs-5 fw-bold mb-2">Chat</h3>
          <button className="btn-close" onClick={onClose}></button>
        </div>
        <div className={'alert alert-warning'} style={{ fontSize: '12px' }}>
          This chat is not saved. All messages will be deleted upon refreshing the page or leaving the interview.
        </div>
      </div>

      {/* Scrollable message list */}
      <div
        style={{
          overflowY: 'auto',
          flex: '1 1 auto'
        }}
      >
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`p-2 my-1 rounded ${
              msg.sender === 'Me' ? 'text-white' : 'text-black'
            }`}
            style={{
              wordWrap: 'break-word', // or overflowWrap: 'anywhere'
              whiteSpace: 'pre-wrap', // ensures newlines are respected
              backgroundColor: msg.sender === 'Me' ? COLORS.MAIN_COLOR : 'lightgrey',
              opacity: 0.7
            }}
          >
            <strong style={{ fontSize: '14px' }}>
              {msg.sender}
              :
            </strong>
            {' '}
            <span style={{ fontSize: '12px' }}>
              {msg.text}
            </span>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="d-flex mt-2 flex-row align-items-between gap-1 align-items-center">
        <textarea
          className="form-control"
          value={input}
          style={{ resize: 'none', height: '80px', userSelect: 'none' }}
          onChange={(e) => { setInput(e.target.value) }}
          placeholder="Type a message..."
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault()
              void handleSend()
            }
          }}
         />
        <img
          src={send_message}
          alt={send_message}
          height={30}
          width={30}
          style={{ cursor: 'pointer', userSelect: 'none' }}
          className={'ms-2'}
          onClick={() => { void handleSend() }}/>
      </div>
    </div>
  )
}

export default Chat
