/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CorporateCreationDto
 */
export interface CorporateCreationDto {
    /**
     * Corporate name
     * @type {string}
     * @memberof CorporateCreationDto
     */
    name: string;
    /**
     * Corporate description
     * @type {string}
     * @memberof CorporateCreationDto
     */
    description: string;
    /**
     * Corporate domain
     * @type {string}
     * @memberof CorporateCreationDto
     */
    domain: string;
}

/**
 * Check if a given object implements the CorporateCreationDto interface.
 */
export function instanceOfCorporateCreationDto(value: object): value is CorporateCreationDto {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('domain' in value) || value['domain'] === undefined) return false;
    return true;
}

export function CorporateCreationDtoFromJSON(json: any): CorporateCreationDto {
    return CorporateCreationDtoFromJSONTyped(json, false);
}

export function CorporateCreationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorporateCreationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'domain': json['domain'],
    };
}

export function CorporateCreationDtoToJSON(value?: CorporateCreationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'domain': value['domain'],
    };
}

