/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AcceptRequestDto,
  GetInterviewAnalyzingResultDto,
  GetInterviewDto,
  GetLatestUpcomingInterviewDto,
  InstantInterviewCreationDto,
  InstantInterviewGetSessionRequestDto,
  InterviewCancellationDto,
  InterviewCreationDto,
  InterviewsWithBusinessDto,
  RefreshableAuthenticationResponseDto,
  StringWrapperResponseDto,
  UpdateFeedbackDto,
} from '../models/index';
import {
    AcceptRequestDtoFromJSON,
    AcceptRequestDtoToJSON,
    GetInterviewAnalyzingResultDtoFromJSON,
    GetInterviewAnalyzingResultDtoToJSON,
    GetInterviewDtoFromJSON,
    GetInterviewDtoToJSON,
    GetLatestUpcomingInterviewDtoFromJSON,
    GetLatestUpcomingInterviewDtoToJSON,
    InstantInterviewCreationDtoFromJSON,
    InstantInterviewCreationDtoToJSON,
    InstantInterviewGetSessionRequestDtoFromJSON,
    InstantInterviewGetSessionRequestDtoToJSON,
    InterviewCancellationDtoFromJSON,
    InterviewCancellationDtoToJSON,
    InterviewCreationDtoFromJSON,
    InterviewCreationDtoToJSON,
    InterviewsWithBusinessDtoFromJSON,
    InterviewsWithBusinessDtoToJSON,
    RefreshableAuthenticationResponseDtoFromJSON,
    RefreshableAuthenticationResponseDtoToJSON,
    StringWrapperResponseDtoFromJSON,
    StringWrapperResponseDtoToJSON,
    UpdateFeedbackDtoFromJSON,
    UpdateFeedbackDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface AcceptInterviewRequest {
    acceptRequestDto: AcceptRequestDto;
}

export interface CancelInterviewRequest {
    interviewCancellationDto: InterviewCancellationDto;
}

export interface CreateInstantInterviewRequest {
    instantInterviewCreationDto: InstantInterviewCreationDto;
}

export interface CreateInterviewRequest {
    interviewCreationDto: InterviewCreationDto;
}

export interface GetInterviewAnalyzingResultRequest {
    interviewId: string;
}

export interface GetInterviewByIDRequest {
    id: string;
}

export interface GetInterviewsRequest {
    type: string;
}

export interface GetSignedInterviewByIdRequest {
    id: string;
}

export interface UpdateFeedbackRequest {
    updateFeedbackDto: UpdateFeedbackDto;
}

export interface ValidateAndGetSessionRequest {
    instantInterviewGetSessionRequestDto: InstantInterviewGetSessionRequestDto;
}

/**
 * 
 */
export class InterviewControllerApi extends runtime.BaseAPI {

    /**
     * Accepts an interview
     * Accept interview
     */
    async acceptInterviewRaw(requestParameters: AcceptInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewDto>> {
        if (requestParameters['acceptRequestDto'] == null) {
            throw new runtime.RequiredError(
                'acceptRequestDto',
                'Required parameter "acceptRequestDto" was null or undefined when calling acceptInterview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/accept`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcceptRequestDtoToJSON(requestParameters['acceptRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewDtoFromJSON(jsonValue));
    }

    /**
     * Accepts an interview
     * Accept interview
     */
    async acceptInterview(requestParameters: AcceptInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewDto> {
        const response = await this.acceptInterviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async analyzeInterviewRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/temp/analyze`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async analyzeInterview(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.analyzeInterviewRaw(initOverrides);
        return await response.value();
    }

    /**
     * Cancels an interview
     * Cancel interview
     */
    async cancelInterviewRaw(requestParameters: CancelInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewDto>> {
        if (requestParameters['interviewCancellationDto'] == null) {
            throw new runtime.RequiredError(
                'interviewCancellationDto',
                'Required parameter "interviewCancellationDto" was null or undefined when calling cancelInterview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/cancel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewCancellationDtoToJSON(requestParameters['interviewCancellationDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewDtoFromJSON(jsonValue));
    }

    /**
     * Cancels an interview
     * Cancel interview
     */
    async cancelInterview(requestParameters: CancelInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewDto> {
        const response = await this.cancelInterviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async connectedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/connected`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async connected(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.connectedRaw(initOverrides);
        return await response.value();
    }

    /**
     * Creates an interview
     * Create interview
     */
    async createInstantInterviewRaw(requestParameters: CreateInstantInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewDto>> {
        if (requestParameters['instantInterviewCreationDto'] == null) {
            throw new runtime.RequiredError(
                'instantInterviewCreationDto',
                'Required parameter "instantInterviewCreationDto" was null or undefined when calling createInstantInterview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/instant/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstantInterviewCreationDtoToJSON(requestParameters['instantInterviewCreationDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewDtoFromJSON(jsonValue));
    }

    /**
     * Creates an interview
     * Create interview
     */
    async createInstantInterview(requestParameters: CreateInstantInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewDto> {
        const response = await this.createInstantInterviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates an interview
     * Create interview
     */
    async createInterviewRaw(requestParameters: CreateInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewDto>> {
        if (requestParameters['interviewCreationDto'] == null) {
            throw new runtime.RequiredError(
                'interviewCreationDto',
                'Required parameter "interviewCreationDto" was null or undefined when calling createInterview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewCreationDtoToJSON(requestParameters['interviewCreationDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewDtoFromJSON(jsonValue));
    }

    /**
     * Creates an interview
     * Create interview
     */
    async createInterview(requestParameters: CreateInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewDto> {
        const response = await this.createInterviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getHistoryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetInterviewDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInterviewDtoFromJSON));
    }

    /**
     */
    async getHistory(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetInterviewDto>> {
        const response = await this.getHistoryRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getInterviewAnalyzingResultRaw(requestParameters: GetInterviewAnalyzingResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewAnalyzingResultDto>> {
        if (requestParameters['interviewId'] == null) {
            throw new runtime.RequiredError(
                'interviewId',
                'Required parameter "interviewId" was null or undefined when calling getInterviewAnalyzingResult().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/analyzingResult/{interviewId}`.replace(`{${"interviewId"}}`, encodeURIComponent(String(requestParameters['interviewId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewAnalyzingResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async getInterviewAnalyzingResult(requestParameters: GetInterviewAnalyzingResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewAnalyzingResultDto> {
        const response = await this.getInterviewAnalyzingResultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the interview by ID
     * Get interview by ID
     */
    async getInterviewByIDRaw(requestParameters: GetInterviewByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getInterviewByID().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/getByID`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewDtoFromJSON(jsonValue));
    }

    /**
     * Gets the interview by ID
     * Get interview by ID
     */
    async getInterviewByID(requestParameters: GetInterviewByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewDto> {
        const response = await this.getInterviewByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getInterviewSignPublicKeyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringWrapperResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/getInterviewSignPublicKey`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => StringWrapperResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getInterviewSignPublicKey(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringWrapperResponseDto> {
        const response = await this.getInterviewSignPublicKeyRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the interviews with business
     * Get interviews with business
     */
    async getInterviewsRaw(requestParameters: GetInterviewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InterviewsWithBusinessDto>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getInterviews().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/getInterviews`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewsWithBusinessDtoFromJSON(jsonValue));
    }

    /**
     * Gets the interviews with business
     * Get interviews with business
     */
    async getInterviews(requestParameters: GetInterviewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InterviewsWithBusinessDto> {
        const response = await this.getInterviewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLatestUpcomingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLatestUpcomingInterviewDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/getLatestUpcoming`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLatestUpcomingInterviewDtoFromJSON(jsonValue));
    }

    /**
     */
    async getLatestUpcoming(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLatestUpcomingInterviewDto> {
        const response = await this.getLatestUpcomingRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSignedInterviewByIdRaw(requestParameters: GetSignedInterviewByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringWrapperResponseDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getSignedInterviewById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/getSignedInterviewById`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => StringWrapperResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSignedInterviewById(requestParameters: GetSignedInterviewByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringWrapperResponseDto> {
        const response = await this.getSignedInterviewByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateFeedbackRaw(requestParameters: UpdateFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewDto>> {
        if (requestParameters['updateFeedbackDto'] == null) {
            throw new runtime.RequiredError(
                'updateFeedbackDto',
                'Required parameter "updateFeedbackDto" was null or undefined when calling updateFeedback().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/update/feedBack`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFeedbackDtoToJSON(requestParameters['updateFeedbackDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateFeedback(requestParameters: UpdateFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewDto> {
        const response = await this.updateFeedbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a session for instant giver user if they have access to the interview
     * Validate And Get Session
     */
    async validateAndGetSessionRaw(requestParameters: ValidateAndGetSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefreshableAuthenticationResponseDto>> {
        if (requestParameters['instantInterviewGetSessionRequestDto'] == null) {
            throw new runtime.RequiredError(
                'instantInterviewGetSessionRequestDto',
                'Required parameter "instantInterviewGetSessionRequestDto" was null or undefined when calling validateAndGetSession().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/instant/validateAndGetSession`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstantInterviewGetSessionRequestDtoToJSON(requestParameters['instantInterviewGetSessionRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshableAuthenticationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Creates a session for instant giver user if they have access to the interview
     * Validate And Get Session
     */
    async validateAndGetSession(requestParameters: ValidateAndGetSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefreshableAuthenticationResponseDto> {
        const response = await this.validateAndGetSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
