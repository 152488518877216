import React from 'react'
import { Skeleton } from '@mui/material'
import type { TakerAvailableInterviewDatesDto } from '../../api/ic'
import TakerInterviewCard from './TakerInterviewCard'

interface NotCorporateTakerInterviewCardsProps {
  isLoading: boolean
  setUpdateTimes: React.Dispatch<React.SetStateAction<boolean>>
  cards: TakerAvailableInterviewDatesDto[]
}

function NotCorporateTakerInterviewCards (props: NotCorporateTakerInterviewCardsProps) {
  return (
    <div className={'d-flex flex-column'} style={{ width: '100%' }}>
      <div
        className={'d-flex flex-row flex-wrap align-items-center justify-content-evenly mt-2'}
        style={{
          gap: '20px',
          padding: '30px 0px 20px 0px'
        }}>
        {props.isLoading && (
          Array.from({ length: 2 }).map((_, index) => (
            <Skeleton
              key={index}
              animation="wave"
              variant="rectangular"
              height={400}
              width={350}
              sx={{ borderRadius: '10px' }}/>
          ))
        )}
        {!props.isLoading &&
          (
            <>
              {(
                props.cards.map((el: TakerAvailableInterviewDatesDto, idx: number) => (
                  <TakerInterviewCard
                    key={idx}
                    setUpdateTimes={props.setUpdateTimes}
                    obj={el}/>
                ))
              )}
            </>
          )}
      </div>
    </div>
  )
}

export default NotCorporateTakerInterviewCards
