import React, { useEffect, useLayoutEffect, useState } from 'react'
import SmallDropdown from './SmallDropdown'
import { InputModeValues } from '../modes/InputModes'
import { type EnumTypeDto } from '../../api/ic'

interface EnumValueDropDownProps {
  items: EnumTypeDto[]
  selectedValue: EnumTypeDto | undefined
  setSelectedValue: React.Dispatch<React.SetStateAction<EnumTypeDto | undefined>>
  label: string
  onChange?: () => void
  error?: boolean
  sizeMode?: InputModeValues
  placeholder?: string
  disabled?: boolean
  showOptionWhenDisabled?: boolean
  alreadySelectedOptions?: Array<string | number | undefined>
  isRequired?: boolean
  dontShowError?: boolean
}
function EnumValueDropDown (props: EnumValueDropDownProps) {
  const [selectedValue, setSelectedValue] = useState(props.selectedValue?.name)
  const items: string[] = []
  props.items.forEach(e => {
    if (e.name) {
      items.push(e.name)
    }
  })
  useEffect(() => {
    const obj = props.items.filter(obj => obj.name === selectedValue)
    if (obj.length) {
      props.setSelectedValue(obj[0])
    }
  }, [selectedValue])

  useLayoutEffect(() => {
    setSelectedValue(props.selectedValue?.name)
  }, [props.selectedValue])

  return (
    <SmallDropdown
      alreadySelectedOptions={props.alreadySelectedOptions}
      items={items}
      onChange={props.onChange}
      error={props.error}
      label={props.label}
      borderRadius={'5px'}
      isRequired={props.isRequired}
      showOptionWhenDisabled={props.showOptionWhenDisabled}
      selected={selectedValue}
      setIsSelected={setSelectedValue}
      sizeMode={props.sizeMode ?? InputModeValues.DEFAULT}
      disabled={props.disabled}
      dontShowError={props.dontShowError}/>
  )
}

export default EnumValueDropDown
