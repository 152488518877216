import React from 'react'
import { CorporateControllerApi, type GetCorporateInvitationDto } from '../api/ic'
import StandardButton from '../components/buttons/StandardButton'
import { ButtonModeValues } from '../components/modes/ButtonMode'
import ColoredText from '../components/text/ColoredText'
import ApiManager from '../manager/ApiManager'
import toast from 'react-hot-toast'

interface InvitationInterface {
  invitation: GetCorporateInvitationDto
}

const CorporateInvitationActions = (props: InvitationInterface) => {
  const { invitation } = props
  const [decisionMade, setDecisionMade] = React.useState(false)

  const corporateApi = ApiManager.getInstance(CorporateControllerApi)

  const accept = async () => {
    void corporateApi.acceptCorporateInvitation({ invitationId: invitation.id })
      .then(() => {
        setDecisionMade(true)
        toast.success('Successfully accepted, you can now login')
      })
      .catch(() => {})
  }

  const decline = async () => {
    void corporateApi.declineCorporateInvitation({ invitationId: invitation.id })
      .then(() => {
        setDecisionMade(true)
        toast.success('Successfully declined')
      })
      .catch(() => {})
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
      {!decisionMade && <>
        <StandardButton
          text={'Accept'}
          onClick={accept}
          sizeMode={ButtonModeValues.SMALL}/>
        <ColoredText
          singleText={'Decline'}
          cursor={'pointer'}
          onClick={decline}/>
      </>}
    </div>
  )
}

export default CorporateInvitationActions
