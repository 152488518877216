import React from 'react'
import ContactUsLeftPart from './ContactUsLeftPart'
import ContactUsRightPart from './ContactUsRightPart'
import Navigation from '../navigation/Navigation'
import { NavigationModeValues } from '../modes/NavigationMode'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import { NAVIGATION_ITEMS } from '../../util/Consts'

function ContactUs () {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  return (
    <>
      {
        isMobile && <Navigation
          navigationMode={NavigationModeValues.FIXED_MAIN}
          mobileNavItems={[NAVIGATION_ITEMS.ABOUT]}
          height={isMobile ? '50px' : 'auto'}/>
      }
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          maxHeight: '100%',
          overflow: 'hidden',
          width: '100%',
          flexGrow: 1
        }}>
        {(!isMobile) && <ContactUsLeftPart />}
        <ContactUsRightPart />
      </div>
    </>
  )
}

export default ContactUs
