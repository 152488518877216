/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetInterviewDto,
} from '../models/index';
import {
    GetInterviewDtoFromJSON,
    GetInterviewDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface CancelPaymentRequest {
    interviewId: string;
}

export interface RestorePaymentRequest {
    interviewId: string;
}

/**
 * 
 */
export class RecordingControllerApi extends runtime.BaseAPI {

    /**
     * Cancel payment
     * Cancel payment
     */
    async cancelPaymentRaw(requestParameters: CancelPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewDto>> {
        if (requestParameters['interviewId'] == null) {
            throw new runtime.RequiredError(
                'interviewId',
                'Required parameter "interviewId" was null or undefined when calling cancelPayment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/recording/cancel/{interviewId}`.replace(`{${"interviewId"}}`, encodeURIComponent(String(requestParameters['interviewId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewDtoFromJSON(jsonValue));
    }

    /**
     * Cancel payment
     * Cancel payment
     */
    async cancelPayment(requestParameters: CancelPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewDto> {
        const response = await this.cancelPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all recordings
     * Get all recordings
     */
    async getAllRecordingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetInterviewDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/recording/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInterviewDtoFromJSON));
    }

    /**
     * Get all recordings
     * Get all recordings
     */
    async getAllRecordings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetInterviewDto>> {
        const response = await this.getAllRecordingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Restore payment
     * Restore payment
     */
    async restorePaymentRaw(requestParameters: RestorePaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewDto>> {
        if (requestParameters['interviewId'] == null) {
            throw new runtime.RequiredError(
                'interviewId',
                'Required parameter "interviewId" was null or undefined when calling restorePayment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/recording/restore/{interviewId}`.replace(`{${"interviewId"}}`, encodeURIComponent(String(requestParameters['interviewId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewDtoFromJSON(jsonValue));
    }

    /**
     * Restore payment
     * Restore payment
     */
    async restorePayment(requestParameters: RestorePaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewDto> {
        const response = await this.restorePaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
