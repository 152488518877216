import React from 'react'
import Logo from '../svg/Logo'
import { LogoModeValues } from '../modes/LogoModes'
import MarginWrapper from '../util/MarginWrapper'
import ColoredText from '../text/ColoredText'
import { FontModeValues } from '../modes/FontMode'

function InterviewLoading () {
  return (
    <div
      style={{ height: '100vh', width: '100vw' }}
      className='d-flex flex-column justify-content-center align-items-center'>
      <Logo
        type = {LogoModeValues.DEFAULT_WHITE}
        clickable={false}/>
      <MarginWrapper top={'20px'}>
        <ColoredText
          userSelect={'none'}
          singleText='Connecting...'
          fontSize= {FontModeValues.EXTRA_LARGE}
          color = '#AEC3FF'/>
      </MarginWrapper>
    </div>
  )
}

export default InterviewLoading
