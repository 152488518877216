import React from 'react'
import signOutIcon from '../svg/resources/sign_out-Icon.svg'
import { AuthManager } from '../../manager/AuthManager'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import ApiManager from '../../manager/ApiManager'
import { SessionControllerApi } from '../../api/ic'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GetAccessToken } from '../../features/AccessTokenSlice'

function TerminateSession () {
  const navigate = useNavigate()
  const body = useSelector(GetAccessToken)

  const sessionApi = ApiManager.getInstance(SessionControllerApi)
  const terminateSessionCall = () => {
    console.log(body)
    void sessionApi.terminate({ uuid: body?.sessionId })
      .then(() => {
        AuthManager.deleteTokens()
        navigate(NavigationRoute.LOG_IN)
      }).catch(() => {})
  }

  return (
    <div className={'d-inline-flex flex-row w-100 justify-content-end align-items-center gap-2 p-4'}>
      <img
        style={{ cursor: 'pointer' }}
        height={24}
        width={24}
        alt={'Sign out'}
        src={signOutIcon}
        onClick={terminateSessionCall}
      />
      <div
        onClick={terminateSessionCall}
        style={{ cursor: 'pointer' }}>
        Sign Out
      </div>
    </div>
  )
}

export default TerminateSession
