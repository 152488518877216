/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserResult
 */
export interface UserResult {
    /**
     * 
     * @type {string}
     * @memberof UserResult
     */
    url?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserResult
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserResult
     */
    endTime?: Date;
}

/**
 * Check if a given object implements the UserResult interface.
 */
export function instanceOfUserResult(value: object): value is UserResult {
    return true;
}

export function UserResultFromJSON(json: any): UserResult {
    return UserResultFromJSONTyped(json, false);
}

export function UserResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResult {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'] == null ? undefined : json['url'],
        'startTime': json['startTime'] == null ? undefined : (new Date(json['startTime'])),
        'endTime': json['endTime'] == null ? undefined : (new Date(json['endTime'])),
    };
}

export function UserResultToJSON(value?: UserResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'url': value['url'],
        'startTime': value['startTime'] == null ? undefined : ((value['startTime']).toISOString()),
        'endTime': value['endTime'] == null ? undefined : ((value['endTime']).toISOString()),
    };
}

