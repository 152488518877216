import { GetICUserDtoTakerStatusEnum, VerificationRequestDtoTypeEnum } from '../api/ic'

export const isCorporateTaker = (body: any): boolean => {
  return body?.role === VerificationRequestDtoTypeEnum.RoleCorporateTaker
}

export const isGiver = (body: any): boolean => {
  return body?.role === VerificationRequestDtoTypeEnum.RoleGiver
}

export const isPublicTaker = (body: any): boolean => {
  return (
    body?.role === VerificationRequestDtoTypeEnum.RoleTaker &&
    body?.takerStatus === GetICUserDtoTakerStatusEnum.Verified &&
    !body?.corporate
  ) ||
    (
      body?.role === VerificationRequestDtoTypeEnum.RoleGiver &&
      body?.takerStatus === GetICUserDtoTakerStatusEnum.Verified &&
      !body?.corporate
    )
}

export const isTaker = (body: any): boolean => {
  return isCorporateTaker(body) || isPublicTaker(body)
}
