import React from 'react'
import { useSelector } from 'react-redux'
import { GetAccessToken } from './features/AccessTokenSlice'
import { getPublicIsHost, isCorporateDomain } from './components/VerifyModal/utils'
import { NavigationRoute } from './enumeration/NavigationRoute'
import InterviewLoading from './components/VideoChat/InterviewLoading'
import { CALL_BACK_URL_PARAM, PROMISE_TOKEN_PARAM } from './PromisedSessionTokenProvider'
import ApiManager from './manager/ApiManager'
import { SessionControllerApi } from './api/ic'
import { AuthManager } from './manager/AuthManager'

interface PublicOnlyRouteProps {
  children: React.ReactNode
  corporateOnly?: boolean
}

export const TRIED_SEARCH_PARAM = 'tried'

const PromisedSessionProvider: React.FC<PublicOnlyRouteProps> = ({ children, corporateOnly }) => {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  const body = useSelector(GetAccessToken) ?? {}
  const sessionExists = Object.keys(body).length !== 0

  if (corporateOnly && !isCorporateDomain()) {
    return <>{children}</>
  }

  // If 'tried' param exists, render children
  if (params.has(TRIED_SEARCH_PARAM)) {
    const token = params.get(PROMISE_TOKEN_PARAM)
    const sessionApi = ApiManager.getInstance(SessionControllerApi)

    if (token) {
      void sessionApi.getPromisedSession({
        promiseTokenValidationRequestDto: {
          token
        }
      }).then(res => {
        AuthManager.setTokens(res)
      }).catch(() => {})
    }

    params.delete(TRIED_SEARCH_PARAM)
    url.search = params.toString()
    window.history.replaceState({}, '', url.toString())
    return <>{children}</>
  } else {
    // If session exists, render children
    if (sessionExists) {
      return <>{children}</>
    }

    // Redirect to Public IC to try bringing session
    const tokenProviderUrl = new URL(NavigationRoute.TRY_PROVIDE_SESSION_PROMISE_TOKEN, getPublicIsHost())
    const tokenParams = new URLSearchParams()

    tokenParams.set(CALL_BACK_URL_PARAM, encodeURIComponent(window.location.href))

    tokenProviderUrl.search = tokenParams.toString()
    window.location.href = tokenProviderUrl.toString()
  }
  return <InterviewLoading/>
}

export default PromisedSessionProvider
