export const enum ReduxActionType {
  UPDATE_OR_GET_PAGE_VALUE = 'UPDATE_OR_GET_PAGE_VALUE',
  UPDATE_OR_REMEMBER_INPUT_VALUE = 'UPDATE_OR_REMEMBER_INPUT_VALUE',
  GET_TIMER_VALUE = 'GET_TIMER_VALUE',
  UPDATE_OR_REMEMBER_NAME_VALUE = 'UPDATE_OR_REMEMBER_NAME_VALUE',
  UPDATE_OR_REMEMBER_SURNAME_VALUE = 'UPDATE_OR_REMEMBER_SURNAME_VALUE',
  UPDATE_OR_REMEMBER_PASSWORD_VALUE = 'UPDATE_OR_REMEMBER_PASSWORD_VALUE',
  UPDATE_OR_REMEMBER_PASSWORD_CONFIRM_VALUE = 'UPDATE_OR_REMEMBER_PASSWORD_CONFIRM_VALUE',
  ADD_SIGNUP_USER_EMAIL = 'ADD_SIGNUP_USER_EMAIL',
  ADD_SIGNUP_USER_FIRST_NAME = 'ADD_SIGNUP_USER_FIRST_NAME',
  ADD_SIGNUP_USER_LAST_NAME = 'ADD_SIGNUP_USER_LAST_NAME',
  ADD_SIGNUP_USER_PASSWORD = 'ADD_SIGNUP_USER_PASSWORD',
  CLEAR_SIGNUP_USER = 'CLEAR_SIGNUP_USER',
  ADD_LOGIN_USER_EMAIL = 'ADD_LOGIN_USER_EMAIL',
  CLEAR_LOGIN_USER = 'CLEAR_LOGIN_USER',
  // GIVER INTERVIEW OBJECT STATES
  UPDATE_GIVER_FLIPPER_VALUE = 'UPDATE_GIVER_FLIPPER_VALUE',
  UPDATE_INTERVIEW_LEVEL = 'UPDATE_INTERVIEW_LEVEL',
  UPDATE_INTERVIEW_LANGUAGES = 'UPDATE_INTERVIEW_LANGUAGES',
  GET_INTERVIEW_LANGUAGES = 'GET_INTERVIEW_LANGUAGES',
  ADD_OR_UPDATE_INTERVIEW_TYPE = 'ADD_OR_UPDATE_INTERVIEW_TYPE',
  ADD_OR_UPDATE_INTERVIEW_PRIVACY = 'ADD_OR_UPDATE_INTERVIEW_PRIVACY',
  ADD_DATE_TO_INTERVIEW = 'ADD_DATE_TO_INTERVIEW',
  ADD_DATE_TO_TAKE_INTERVIEW = 'ADD_DATE_TO_TAKER_INTERVIEW',
  DELETE_INTERVIEW_DATE = 'DELETE_INTERVIEW_DATE',

  CLEAR_INTERVIEW_DATA = 'CLEAR_INTERVIEW_DATA',
  // TEMP GIVER INTERVIEW DATES
  ADD_INTERVIEW_DATE = 'ADD_INTERVIEW_DATE',
  DELETE_INTERVIEW_TEMP_DATES = 'DELETE_INTERVIEW_TEMP_DATES',
  SET_GIVER_INTERVIEW_TIMES = 'SET_GIVER_INTERVIEW_TIMES',
  SET_GIVER_INTERVIEW_TIMES_FROM_ISO_STRINGS = 'SET_GIVER_INTERVIEW_TIMES_FROM_ISO_STRINGS',
  // TEMP TAKER INTERVIEW DATES
  DELETE_TAKER_INTERVIEW_TEMP_DATES = 'DELETE_TAKER_INTERVIEW_TEMP_DATES',
  SET_TAKER_INTERVIEW_TIMES = 'SET_TAKER_INTERVIEW_TIMES',
  DELETE_TAKER_INTERVIEW_DATE = 'DELETE_TAKER_INTERVIEW_DATE',
  ADD_TAKER_INTERVIEW_DATE = 'ADD_TAKER_INTERVIEW_DATE',

  // TAKER VALIDATION EVENTS
  UPDATE_USER_PERSONAL_INFO = 'UPDATE_USER_PERSONAL_INFO',
  CLEAR_TAKER_VALIDATION_DATA = 'CLEAR_TAKER_VALIDATION_DATA',
  ADD_TAKER_VALIDATION_EDUCATION = 'ADD_TAKER_VALIDATION_EDUCATION',
  UPDATE_TAKER_VALIDATION_EDUCATIONS = 'UPDATE_TAKER_VALIDATION_EDUCATIONS',
  SET_TAKER_VALIDATION_INITIAL_STATE = 'SET_TAKER_VALIDATION_INITIAL_STATE',
  ADD_TAKER_VALIDATION_EXPERIENCE = 'ADD_TAKER_VALIDATION_EXPERIENCE',
  UPDATE_TAKER_VALIDATION_EXPERIENCES = 'UPDATE_TAKER_VALIDATION_EXPERIENCES',
  ADD_TAKER_VALIDATION_URL = 'ADD_TAKER_VALIDATION_URL',
  ADD_TAKER_VALIDATION_DATES = 'ADD_TAKER_VALIDATION_DATES',
  UPDATE_TAKER_VALIDATION_URLS = 'UPDATE_TAKER_VALIDATION_URLS',
  ADD_TAKER_VALIDATION_INTERVIEW_TYPE = 'ADD_TAKER_VALIDATION_INTERVIEW_TYPE',
  REMOVE_TAKER_VALIDATION_INTERVIEW_TYPE = 'REMOVE_TAKER_VALIDATION_INTERVIEW_TYPE',
  TAKER_VALIDATION_REQUEST_FOR_NEXT_STEP = 'TAKER_VALIDATION_REQUEST_FOR_NEXT_STEP',
  TAKER_VALIDATION_OPEN_NEXT_PAGE = 'TAKER_VALIDATION_OPEN_NEXT_PAGE',
  TAKER_VALIDATION_OPEN_PREVIOUS_PAGE = 'TAKER_VALIDATION_OPEN_PREVIOUS_PAGE',
  TAKER_VALIDATION_CLEAR_PAGE_VALUE = 'TAKER_VALIDATION_CLEAR_PAGE_VALUE',
  TAKER_VALIDATION_REQUEST_FOR_ADD = 'TAKER_VALIDATION_REQUEST_FOR_ADD',
  TAKER_VALIDATION_REQUEST_FOR_ADD_ACCEPTED = 'TAKER_VALIDATION_REQUEST_FOR_ADD_ACCEPTED',
  TAKER_VALIDATION_REQUEST_FOR_BACK = 'TAKER_VALIDATION_REQUEST_FOR_BACK',
  TAKER_VALIDATION_CAN_GO = 'TAKER_VALIDATION_CAN_GO',
  TAKER_VALIDATION_CAN_GO_BACK = 'TAKER_VALIDATION_CAN_GO_BACK',
  UPDATE_TAKER_VALIDATION_INTERVIEWS = 'UPDATE_TAKER_VALIDATION_INTERVIEWS',

  // Screen Information
  UPDATE_SCREEN_ORIENTATION = 'UPDATE_SCREEN_ORIENTATION',
  UPDATE_SCREEN_INNER_HEIGHT = 'UPDATE_SCREEN_INNER_HEIGHT',
  UPDATE_SCREEN_INNER_WIDTH = 'UPDATE_SCREEN_INNER_WIDTH',
  UPDATE_SCREEN_TYPE = 'UPDATE_SCREEN_TYPE',

  // Interview JWT
  UPDATE_INTERVIEW_JWT = 'UPDATE_INTERVIEW_JWT',
  UPDATE_CONNECTION_ID = 'UPDATE_CONNECTION_ID',
  UPDATE_INTERVIEW_SESSION = 'UPDATE_INTERVIEW_SESSION',
  UPDATE_INTERVIEW_TOKEN = 'UPDATE_INTERVIEW_TOKEN',
  UPDATE_INTERVIEW_APP_ID = 'UPDATE_INTERVIEW_APP_ID',
  DELETE_INTERVIEW_TOKEN_AND_SESSION_AND_ADD_ID = 'DELETE_INTERVIEW_TOKEN_AND_SESSION_AND_ADD_ID',

  // Interview Process
  UPDATE_INTERVIEW_FINISHED_STATE = 'UPDATE_INTERVIEW_FINISHED_STATE',
  UPDATE_INTERVIEW_PROCESS_DEVICES = 'UPDATE_INTERVIEW_PROCESS_DEVICES',

  // Access Token
  UPDATE_ACCESS_TOKEN_VALUE = 'UPDATE_ACCESS_TOKEN_VALUE'

}
