import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthManager } from './manager/AuthManager'

function useRefreshTokenOnRouteChange () {
  const location = useLocation()

  useEffect(() => {
    void AuthManager.refreshTokenIfNeeded()
  }, [location.pathname])
}

export default useRefreshTokenOnRouteChange
