/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetTemplateSection } from './GetTemplateSection';
import {
    GetTemplateSectionFromJSON,
    GetTemplateSectionFromJSONTyped,
    GetTemplateSectionToJSON,
} from './GetTemplateSection';

/**
 * 
 * @export
 * @interface GetInterviewTemplateDto
 */
export interface GetInterviewTemplateDto {
    /**
     * Interview question template id
     * @type {string}
     * @memberof GetInterviewTemplateDto
     */
    id: string;
    /**
     * Template name
     * @type {string}
     * @memberof GetInterviewTemplateDto
     */
    name: string;
    /**
     * Interview type
     * @type {string}
     * @memberof GetInterviewTemplateDto
     */
    interviewType: string;
    /**
     * Created by
     * @type {string}
     * @memberof GetInterviewTemplateDto
     */
    createdById: string;
    /**
     * Created for corporate
     * @type {string}
     * @memberof GetInterviewTemplateDto
     */
    corporateId: string;
    /**
     * 
     * @type {Array<GetTemplateSection>}
     * @memberof GetInterviewTemplateDto
     */
    sections: Array<GetTemplateSection>;
}

/**
 * Check if a given object implements the GetInterviewTemplateDto interface.
 */
export function instanceOfGetInterviewTemplateDto(value: object): value is GetInterviewTemplateDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('interviewType' in value) || value['interviewType'] === undefined) return false;
    if (!('createdById' in value) || value['createdById'] === undefined) return false;
    if (!('corporateId' in value) || value['corporateId'] === undefined) return false;
    if (!('sections' in value) || value['sections'] === undefined) return false;
    return true;
}

export function GetInterviewTemplateDtoFromJSON(json: any): GetInterviewTemplateDto {
    return GetInterviewTemplateDtoFromJSONTyped(json, false);
}

export function GetInterviewTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInterviewTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'interviewType': json['interviewType'],
        'createdById': json['createdById'],
        'corporateId': json['corporateId'],
        'sections': ((json['sections'] as Array<any>).map(GetTemplateSectionFromJSON)),
    };
}

export function GetInterviewTemplateDtoToJSON(value?: GetInterviewTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'interviewType': value['interviewType'],
        'createdById': value['createdById'],
        'corporateId': value['corporateId'],
        'sections': ((value['sections'] as Array<any>).map(GetTemplateSectionToJSON)),
    };
}

