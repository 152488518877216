import React from 'react'
import StandardButton from '../../components/buttons/StandardButton'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import MarginWrapper from '../../components/util/MarginWrapper'
import ColoredText from '../../components/text/ColoredText'
import { WidthModeValues } from '../../components/modes/WidthMode'
import AdminSection from '../../components/admin/AdminSection'
import { useNavigate } from 'react-router-dom'
import TerminateSession from '../../components/termination/TerminateSession'

function CorporateAdminPage () {
  const navigate = useNavigate()

  const handleTakerCreationClick = () => {
    navigate(NavigationRoute.CORPORATE_ADMIN_CREATE_TAKER)
  }
  const handleSeeTakersClick = () => {
    navigate(NavigationRoute.CORPORATE_ADMIN_SEE_TAKERS)
  }
  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <TerminateSession/>
      <MarginWrapper top={'40px'}>
        <ColoredText singleText={'Corporate Admin Page'} fontSize={'25px'}/>
      </MarginWrapper>

      <MarginWrapper top={'20px'}>
        <AdminSection>
          <ColoredText singleText={'Corporate Taker Actions'}/>
          <MarginWrapper top={'10px'}>
            <StandardButton
              widthMode={WidthModeValues.SMALL}
              onClick={handleTakerCreationClick}
              text={'Add new taker'}/>
          </MarginWrapper>
          <MarginWrapper top={'10px'}>
            <StandardButton
              widthMode={WidthModeValues.SMALL}
              onClick={handleSeeTakersClick}
              text={'See all takers'}/>
          </MarginWrapper>
        </AdminSection>
      </MarginWrapper>
    </div>
  )
}

export default CorporateAdminPage
