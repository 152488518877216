import React, { useEffect, useState } from 'react'
import ColoredText from '../text/ColoredText'
import { FontModeValues } from '../modes/FontMode'
import EnumValueDropDown from '../dropdown/EnumValueDropDown'
import { InputModeValues } from '../modes/InputModes'
import StandardInput from '../input/StandardInput'
import { StandardInputModeValues } from '../modes/StandardInputMode'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import interviewersSVG from '../svg/resources/interviewers.svg'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import { useNavigate } from 'react-router-dom'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import {
  type EnumTypeDto,
  EnumValuesControllerApi,
  GetDropdownListTypeEnum,
  type GetInterviewTemplateDto,
  InterviewControllerApi,
  InterviewRuntimeTemplateControllerApi
} from '../../api/ic'
import ApiManager from '../../manager/ApiManager'
import toast from 'react-hot-toast'
import { COLORS } from '../../util/Consts'

function CorporateTakerInterviewCreation () {
  const navigate = useNavigate()
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile

  const [email, setEmail] = useState<string>('')
  const [levelItems, setLevelItems] = useState<EnumTypeDto[]>([])
  const [selectedLevel, setSelectedLevel] = useState<any>(null)
  const [shouldSendInvitationEmail, setShouldSendInvitationEmail] = useState<boolean>(true)

  const [templates, setTemplates] = useState<any[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<any>()
  const [isCreateInterviewLoading, setIsCreateInterviewLoading] = useState<boolean>(false)

  // API instances
  const enumValuesApi = ApiManager.getInstance(EnumValuesControllerApi)
  const templateAPi = ApiManager.getInstance(InterviewRuntimeTemplateControllerApi)
  const interviewApi = ApiManager.getInstance(InterviewControllerApi)

  // Fetch available levels on mount
  useEffect(() => {
    void enumValuesApi.getDropdownList({
      type: GetDropdownListTypeEnum.InterviewLevels
    }).then((res) => {
      setLevelItems(res)
    }).catch(() => {})
  }, [])

  // Fetch templates when a level is selected
  useEffect(() => {
    if (!selectedLevel) {
      // If no level selected, clear templates
      setTemplates([])
      setSelectedTemplate(undefined)
      return
    }

    // Fetch templates for the selected level
    void templateAPi.getAllTakerTemplates({ level: selectedLevel.key })
      .then((res: GetInterviewTemplateDto[]) => {
        setTemplates(res)

        // If the previously selected template is not in the new list, reset it
        const stillExists = res.some(t => t.id === selectedTemplate?.id)
        if (!stillExists) {
          setSelectedTemplate(undefined)
        }
      })
      .catch(() => {})
  }, [selectedLevel])

  // Create interview handler
  const createInstantInterview = async () => {
    setIsCreateInterviewLoading(true)
    if (!selectedLevel || !selectedTemplate || !email) {
      toast.error('Please fill all required fields')
      setIsCreateInterviewLoading(false)
      return
    }
    void interviewApi.createInstantInterview({
      instantInterviewCreationDto: {
        level: selectedLevel.key,
        templateId: selectedTemplate.id,
        email,
        shouldSendInvitationEmail
      }
    })
      .then(() => {
        toast.success('Instant interview created successfully')
        navigate(NavigationRoute.HOME_PAGE + NavigationRoute.UPCOMINGS)
      })
      .catch(() => {})
      .finally(() => {
        setIsCreateInterviewLoading(false)
      })
  }

  return (
    <div className={'container-md d-flex justify-content-start align-items-center mt-5'}>
      <div>
        <div className={'d-flex flex-column justify-content-start container-md mt-4 gap-3 ms-3'}>
          <ColoredText
            singleText={'Create an instant interview'}
            fontSize={FontModeValues.AVERAGE}
            fontWeight={600}
          />
        </div>

        <div className={'d-flex flex-row justify-content-between w-100'}>
          <div>
            <div className={'d-flex flex-column justify-content-start gap-3 mt-4 ms-5'} style={{ width: '100%' }}>
              <div className={'d-inline-flex flex-row align-items-start gap-4 mt-3'}>
                {/* Level Dropdown */}
                <EnumValueDropDown
                  placeholder={'Select'}
                  items={levelItems}
                  selectedValue={selectedLevel}
                  setSelectedValue={setSelectedLevel}
                  label={'Level'}
                  isRequired={true}
                  sizeMode={InputModeValues.MAX_WIDTH}
                />

                {/* Template Dropdown */}
                <div style={{ width: '100%' }} className={'d-flex flex-column align-items-end'}>
                  <EnumValueDropDown
                    placeholder={'Select'}
                    items={templates}
                    selectedValue={selectedTemplate}
                    setSelectedValue={setSelectedTemplate}
                    label={'Template'}
                    isRequired={true}
                    sizeMode={InputModeValues.MAX_WIDTH}
                    disabled={!selectedLevel || templates.length === 0}
                  />
                  <span
                    onClick={() => {
                      navigate(NavigationRoute.MANAGE_TEMPLATES)
                    }}
                    style={{
                      textDecoration: 'underline',
                      fontSize: '14px',
                      cursor: 'pointer',
                      whiteSpace: 'nowrap'
                    }}>
                    Create/manage templates
                  </span>
                </div>
              </div>

              <div className={'d-flex flex-row align-items-center justify-content-between gap-5'}>
                {/* Email Input */}
                <StandardInput
                  label={'Invitation Email'}
                  isRequired={true}
                  dontAlignStart
                  inputValue={email}
                  setInputValue={setEmail}
                  sizeMode={InputModeValues.MAX_WIDTH}
                  type={StandardInputModeValues.EMAIL}
                  placeHolder={'Invitation Email'}
                />

                <div className={'d-flex flex-row gap-1 align-items-center'}>
                  <input
                    style={{ accentColor: COLORS.MAIN_COLOR }}
                    type={'checkbox'}
                    checked={shouldSendInvitationEmail}
                    onChange={() => {
                      setShouldSendInvitationEmail(prevState => !prevState)
                    }}/>
                  <ColoredText whiteSpace={'nowrap'} singleText={'Send Email'}/>
                </div>
              </div>

              {/* Create Interview Button */}
              <StandardButton
                sizeMode={ButtonModeValues.SELECT_SIZE}
                text={'Create'}
                isLoading={isCreateInterviewLoading}
                onClick={createInstantInterview}
              />
            </div>
          </div>

          {!isMobile && (
            <img
              src={interviewersSVG}
              alt=''
              width='340px'
              height='340px'
              style={{ marginLeft: '400px', marginTop: '25px' }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CorporateTakerInterviewCreation
