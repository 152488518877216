import React, { useEffect, useState } from 'react'
import { DataGrid, type GridRowSelectionModel, type GridRowSpacingParams } from '@mui/x-data-grid'
import { COLORS } from '../util/Consts'
import { Checkbox, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import little_star from '../components/svg/resources/little_star.svg'
import recording_icon from '../components/svg/resources/recording_icon.svg'
import recording_icon_black from '../components/svg/resources/recording_icon_black.svg'
import redDot from '../components/svg/resources/red_dot.svg'
import greenDot from '../components/svg/resources/green_dot.svg'
import SVGHandler from '../components/svg/SVGHandler'
import InterviewTypeImg from '../components/img/InterviewTypeImg'
import { capitalizeFirstLetter, extractDateAndTime, isXMinutesBefore } from '../util/Helper'
import StandardModal from '../components/modal/StandardModal'
import ColoredText from '../components/text/ColoredText'
import StandardButton from '../components/buttons/StandardButton'
import { ButtonModeValues } from '../components/modes/ButtonMode'
import toast from 'react-hot-toast'
import { FontModeValues } from '../components/modes/FontMode'
import InterviewCancellationRadio from './InterviewCancellationRadio'
import ProfilePicture from '../components/profilePicture/ProfilePicture'
import { useNavigate } from 'react-router-dom'
import ApiManager from '../manager/ApiManager'
import { type GetCorporateInvitationDto, type GetInterviewDto, GetInterviewDtoStatusEnum, InterviewControllerApi } from '../api/ic'
import InterviewStatus from './InterviewStatus'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import RecordingManagementActions from './RecordingManagementActions'
import RecordingManagementState from './RecordingManagementState'
import { makeCorporateLink } from '../components/VerifyModal/utils'
import CorporateInvitationActions from './CorporateInvitationActions'
import CopyInterviewLink from '../components/VideoChat/interviewComponents/CopyInterviewLink'

interface UpcomingInterviewsProps {
  SetIsUpcomingBlank?: any
  withArrow?: boolean
  rows: any
  eventSetter?: React.Dispatch<React.SetStateAction<any>>
  columnNames: string[]
  deleteButton: boolean
  handleCheckbox?: any
}

function StandardList (props: UpcomingInterviewsProps) {
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([])
  const [rows, setRows] = useState(props.rows)

  const withArrow = props.withArrow ?? true
  const interviewApi = ApiManager.getInstance(InterviewControllerApi)

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5
    }
  }, [])

  useEffect(() => {
    setRows(props.rows)
  }, [props.rows])

  const generateColumns = (columnNames: any, props: UpcomingInterviewsProps) => {
    return columnNames.map((columnName: any) => {
      if (columnName === 'checkbox') {
        return {
          field: 'checkbox',
          headerName: '',
          width: 50,
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          renderCell: (params: any) => {
            const isChecked = rowSelectionModel.includes(params.row.id)

            const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.checked) {
                setRowSelectionModel([params.row.id])
                if (props.handleCheckbox) {
                  props.handleCheckbox(params.row.id)
                }
              } else {
                setRowSelectionModel([])
              }
            }

            return (
              <Checkbox
                disableRipple
                checked={isChecked}
                onChange={handleCheckboxChange}
                sx={{
                  '& .PrivateSwitchBase-input & .PrivateSwitchBase-input:hover & .PrivateSwitchBase-input:focus': {
                    border: 'none',
                    outline: 'none'
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: '1.6rem',
                    fill: isChecked ? COLORS.MAIN_COLOR : 'gray'
                  },
                  '&:hover': {
                    backgroundColor: 'transparent !important'
                  }
                }}/>
            )
          }
        }
      } else if (columnName === 'id') {
        return {
          field: 'id',
          headerName: 'id',
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          width: 50
        }
      } else if (columnName === 'topic') {
        return {
          field: 'topic',
          headerName: 'Topic',
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          minWidth: 130,
          renderCell: (params: any) => (
            <div
              style={{ display: 'flex', alignItems: 'center' }}>
              <InterviewTypeImg mini={true} obj={ { type: params.row.type } }/>
              {capitalizeFirstLetter(params.row.name ? params.row.name.toLowerCase() : '')}
            </div>
          )
        }
      } else if (columnName === 'interview_type_icon') {
        return {
          field: 'icon',
          headerName: 'icon',
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          width: 200,
          renderCell: (params: any) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <InterviewTypeImg mini={true} obj={params.row}/>
            </div>
          )
        }
      } else if (columnName === 'interviewer') {
        return {
          field: 'interviewer',
          headerName: 'Interviewer',
          width: 200,
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          renderCell: (params: any) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={params.row.interviewer.image}
                alt="Interviewer"
                style={{ marginRight: '10px' }}/>
              {params.row.interviewer.name}
            </div>
          )
        }
      } else if (columnName === 'level') {
        return {
          field: 'level',
          headerName: 'Level',
          sortable: false,
          minWidth: 150,
          disableColumnMenu: true,
          renderCell: (params: any) => {
            const level = params.row.level
            return (
              <span
                style={{ fontSize: '16px' }}>
                {capitalizeFirstLetter(level.toLowerCase())}
              </span>
            )
          }
        }
      } else if (columnName === 'status') {
        return {
          field: 'status',
          headerName: 'Status',
          sortable: false,
          minWidth: 150,
          disableColumnMenu: true,
          renderCell: (params: any) => {
            const status = params.row.status
            return (
              <span
                style={{ fontSize: '16px' }}>
                {capitalizeFirstLetter(status.toLowerCase())}
              </span>
            )
          }
        }
      } else if (columnName === 'giver') {
        return {
          field: 'giver',
          headerName: 'Giver',
          minWidth: 190,
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          renderCell: (params: any) => {
            const giver = params.row.giver.firstName + ' ' + params.row.giver.lastName
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <ProfilePicture
                  name={params.row.giver.firstName ?? ''}
                  surname={params.row.giver.lastName ?? ''}
                  inverse={true}
                  pictureSrc={'DEFAULT'}
                  width={'20px'}/>
                {giver}
              </div>
            )
          }
        }
      } else if (columnName === 'taker') {
        return {
          field: 'taker',
          headerName: 'Taker',
          minWidth: 190,
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          renderCell: (params: any) => {
            const taker = params.row.taker
            const takerName = taker ? taker.firstName + ' ' + taker.lastName : '-'
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                {taker && <ProfilePicture
                  name={taker.firstName ?? ''}
                  surname={taker.lastName ?? ''}
                  inverse={true}
                  pictureSrc={'DEFAULT'}
                  width={'20px'}/>}
                {takerName}
              </div>
            )
          }
        }
      } else if (columnName === 'fullDate') {
        return {
          field: 'fullDate',
          headerName: 'Date',
          width: 200,
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          renderCell: (params: any) => {
            const timestamp = params.row.acceptedDate
            const { date, time } = extractDateAndTime(timestamp)
            const dateStr = date + '  ' + time
            return (
              <span
                style={{ fontSize: '16px' }}>
                {timestamp ? dateStr : '-'}
              </span>
            )
          }
        }
      } else if (columnName === 'instantInterviewLink') {
        return {
          field: 'instantInterviewLink',
          headerName: 'Link',
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          width: 80,
          renderCell: (params: any) => <CopyInterviewLink interviewID={(params.row as GetInterviewDto).id}/>
        }
      } else if (columnName === 'interviewActions') {
        return {
          field: 'interviewActions',
          headerName: 'Actions',
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          width: 250,
          renderCell: (params: any) => {
            const navigate = useNavigate()
            const [cancellationReason, SetCancellationReason] = React.useState<string>('')
            const [otherMessage, setOtherMessage] = useState<string>('')
            const [isOpen, setIsOpen] = useState<boolean>(false)
            return (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                <div style={{
                  opacity: params.row.status === GetInterviewDtoStatusEnum.Started ? 0 : 100
                }}>
                  <ColoredText
                    singleText={'Cancel'}
                    cursor={'pointer'}
                    onClick={() => {
                      setIsOpen(true)
                    }}/>
                </div>
                <StandardButton
                  text={'Join Waiting Room'}
                  sizeMode={ButtonModeValues.LONG_SMALLEST}
                  clickable={isXMinutesBefore(params.row.acceptedDate, 60)} // TODO: change to 5
                  onClick={() => { navigate(`/interview/${params.row.id}/join`, { replace: true }) }}/>
                <StandardModal isOpen={isOpen} closeWhenClickingOutOfModal={true}
                  title={'Interview Cancellation'}
                  width={`${(screen.availWidth * 38 / 100)}px`}
                  height={`${(screen.availHeight * 48 / 100)}px`}
                  footerComponent={
                    <>
                      <ColoredText singleText={'Discard'} cursor={'pointer'}
                        onClick={ () => { setIsOpen(false) } } />
                      <StandardButton
                        clickable={(cancellationReason !== '' && cancellationReason !== 'Other') || (cancellationReason === 'Other' && otherMessage !== '')}
                        text={'Cancel'}
                        sizeMode={ButtonModeValues.SMALL}
                        onClick={ () => {
                          const reason = cancellationReason === 'Other' ? otherMessage : cancellationReason
                          void interviewApi.cancelInterview({
                            interviewCancellationDto: { interviewId: params.row.id, reason }
                          }).then(() => {
                            toast.success('Successfully cancelled interview')
                            setIsOpen(false)
                          }).catch(() => {})
                          if (props.eventSetter) {
                            props.eventSetter(true)
                          }
                        }} />
                    </>}>
                  <>
                    <div style={{ marginBottom: '80px' }} className={'d-flex flex-column gap-1'}>
                      <ColoredText singleText={'Reason'} fontSize={FontModeValues.LARGE}/>
                      <div className={'mt-1'}>
                        <InterviewCancellationRadio
                          otherMessage={otherMessage}
                          setOtherMessage={setOtherMessage}
                          selectedOption={cancellationReason}
                          setSelection={SetCancellationReason}
                          options={[
                            'Schedule Conflict',
                            'Health Issues',
                            'Not interested anymore',
                            'Technical Difficulties',
                            'Problem with taker or giver',
                            'Other']
                          }/>
                      </div>
                    </div>
                  </>
                </StandardModal>
              </div>
            )
          }
        }
      } else if (columnName === 'recordingManagementActions') {
        return {
          field: 'recordingManagementActions',
          headerName: 'Payment',
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          width: 250,
          renderCell: (params: any) => <RecordingManagementActions interview={params.row as GetInterviewDto}/>
        }
      } else if (columnName === 'recordingState') {
        return {
          field: 'recordingState',
          headerName: 'State',
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          width: 250,
          renderCell: (params: any) => <RecordingManagementState interview={params.row as GetInterviewDto}/>
        }
      } else if (columnName === 'language') {
        return {
          field: 'language',
          headerName: 'Language',
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          width: 160,
          renderCell: (params: any) => {
            const acceptedLanguage = params.row.acceptedLanguage
            const languageOptions = params.row.languages // Assuming 'languages' is an array in your row data
            const [selectedValue, setSelectedValue] = useState(params.row.languages[0])

            const handleLanguageChange = (event: any) => {
              setSelectedValue(event.target.value)
            }

            if (languageOptions.length === 1 || acceptedLanguage) {
              return <span style={{ fontSize: '16px' }}>
                {capitalizeFirstLetter(acceptedLanguage ? acceptedLanguage.toLowerCase() : languageOptions[0].toLowerCase())}
              </span>
            } else {
              return (
                <FormControl sx={{ '& .MuiOutlinedInput-notchedOutline': { borderStyle: 'none' } }}>
                  <InputLabel id={`${params.row.id}`}></InputLabel>
                  <Select
                    SelectDisplayProps={{ style: { paddingLeft: 0 } }}
                    labelId={`language-label-${params.row.id}`}
                    value={selectedValue}
                    onChange={handleLanguageChange}>
                    {languageOptions.map((option: any) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{ '& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: 0 } }}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            }
          }
        }
      } else if (columnName === 'date') {
        return {
          field: 'date',
          headerName: 'Date',
          width: 160,
          disableColumnMenu: true
        }
      } else if (columnName === 'rate') {
        return {
          field: 'rate',
          headerName: <img
            src={little_star}
            alt='stars'/>,
          width: 80,
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          headerAlign: 'center',
          align: 'center',
          renderCell: (params: any) => {
            return (
              <span style={{ color: COLORS.MAIN_COLOR }}>
                {params.row.rate}
              </span>
            )
          }
        }
      } else if (columnName === 'recording') {
        return {
          field: 'recording',
          headerName: 'Recording',
          width: 120,
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          renderCell: (params: any) => {
            const shouldShowRecordingLink = params.row.status === GetInterviewDtoStatusEnum.Finished || params.row.status === GetInterviewDtoStatusEnum.Analyzed
            return (
              <div
                className={'d-flex flex-row align-items-center justify-content-center gap-1'}
                style={{
                  cursor: shouldShowRecordingLink ? 'pointer' : 'cursor'
                }}
                onClick={() => {
                  if (shouldShowRecordingLink) {
                    window.open(NavigationRoute.INTERVIEW_RECORDING.replace(':interviewID', params.row.id), '_blank')
                  }
                }}>
                <img src={shouldShowRecordingLink ? recording_icon : recording_icon_black} alt={'Rec'}/>
                <ColoredText
                  singleText={shouldShowRecordingLink ? 'Rec' : '-'}
                  color={shouldShowRecordingLink ? '#880CE8' : 'black'}
                  textDecoration={ shouldShowRecordingLink ? 'underline' : 'none'} />
              </div>
            )
          }
        }
      } else if (columnName === 'available' || columnName === 'active') {
        return {
          field: columnName,
          headerName: 'Availability',
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          renderCell: (params: any) => (
            <div>
              {params.row.available || params.row.active
                ? (
                  <SVGHandler static={true} svg={greenDot}/>)
                : (
                  <SVGHandler static={true} svg={redDot}/>
                  )}
            </div>
          )
        }
      } else if (columnName === 'fullName') {
        return {
          headerName: columnName,
          width: 250,
          disableColumnMenu: true,
          renderCell: (params: any) => (
            <div>
              {(params.row?.firstName ?? '') + ' ' + (params.row?.lastName ?? '')}
            </div>
          )
        }
      } else if (columnName === 'reason') {
        return {
          field: columnName,
          headerName: columnName,
          width: 250,
          disableColumnMenu: true,
          renderCell: (params: any) => (
            <div>
              {params.row?.takerRejection?.reason ?? ''}
            </div>
          )
        }
      } else if (columnName === 'absoluteStatus') {
        return {
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          renderCell: (params: any) => (
            <div style={{ position: 'absolute', top: -10, right: 0, zIndex: 100000000 }}>
              {params.row.status === GetInterviewDtoStatusEnum.Started
                ? <InterviewStatus
                    type={'modal'}
                    interviewStatusString={'Ongoing'}/> : ''}
            </div>
          )
        }
      } else if (columnName === 'corporate_invitation_status') {
        return {
          field: 'corporateInvitationStatus',
          headerName: 'Status',
          width: 250,
          disableColumnMenu: true,
          renderCell: (params: any) => (
            <div>
              {params.row?.status ?? ''}
            </div>
          )
        }
      } else if (columnName?.startsWith('corporate.')) {
        return {
          field: columnName,
          headerName: columnName.split('.')[1],
          width: 250,
          disableColumnMenu: true,
          renderCell: (params: any) => {
            let link
            const corporateKey = columnName.split('.')[1]
            if (corporateKey === 'link') {
              link = `${makeCorporateLink(params.row?.corporate?.domain)}/login`
            }
            return (
              <div>
                {link ? (
                  <a target="_blank" href={link}
                    rel="noreferrer">
                    link
                  </a>) : params.row?.corporate?.[corporateKey] ?? ''}
              </div>
            )
          }
        }
      } else if (columnName === 'corporateInvitationActions') {
        return {
          field: 'corporateActions',
          headerName: 'Actions',
          disableColumnMenu: true,
          disableColumnFilter: true,
          sortable: false,
          width: 250,
          renderCell: (params: any) => <CorporateInvitationActions invitation={params.row as GetCorporateInvitationDto}/>
        }
      } else {
        return {
          field: columnName,
          headerName: columnName,
          width: 250,
          disableColumnMenu: true
        }
      }
    })
  }
  if (props.deleteButton) {
    if (!(props.columnNames.includes('checkbox'))) {
      props.columnNames.unshift('checkbox')
    }
  }
  // Usage:
  const columns = generateColumns(props.columnNames, props)
  const handleDeleteSelectedRows = () => {
    const updatedRows = rows.filter((row: any) => !rowSelectionModel.includes(row.id))
    setRows(updatedRows)
    setRowSelectionModel([])
  }
  if (!columns) {
    return (
      <div>no rows</div>
    )
  }

  // TODO add custom selected index
  if (!rows.length) {
    if (withArrow) {
      if (props.SetIsUpcomingBlank) {
        props.SetIsUpcomingBlank(true)
      }
    }
  } else {
    if (props.SetIsUpcomingBlank) {
      props.SetIsUpcomingBlank(false)
    }
    return (
      <>
        <div
          className={'container'}
          style={{
            height: '75vh',
            marginTop: '50px'
          }}>
          <DataGrid
            rows={rows}
            getRowSpacing={getRowSpacing}
            columns={columns}
            hideFooter={true}
            disableColumnSelector={true}
            disableRowSelectionOnClick={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            checkboxSelection={false}
            localeText={{ noRowsLabel: 'No Upcoming Interviews' }}
            onRowSelectionModelChange={setRowSelectionModel}
            rowSelectionModel={rowSelectionModel}
            rowHeight={48}
            sx={{
              '& .MuiDataGrid-row:focus, & .MuiDataGrid-row:hover, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:hover': {
                outline: 'none',
                border: 'none'
              },
              '&.Mui-checked': {
                backgroundColor: 'transparent'
              },
              '& .MuiDataGrid-cell': {
                cursor: 'default'
              },
              // '& .MuiOutlinedInput-notchedOutline': { borderStyle: 'none' },
              '& .MuiDataGrid-row': {
                borderRadius: '12px',
                background: '#FDFDFD',
                margin: '2px',
                marginTop: '20px',
                position: 'relative',
                width: 'calc(100% - 10px)',
                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
              },
              '&, [class^=MuiDataGrid]': { border: 'none' },
              '.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none'
              },
              '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus & .MuiDataGrid-withBorderColor':
                                {
                                  outline: 'none'
                                },
              '& .MuiDataGrid-columnSeparator': { display: 'none' },
              '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus & .MuiDataGrid-columnHeader:focus-within & .MuiDataGrid-columnHeader:focus': {
                outline: 'none',
                border: 'none'
              },
              '& .MuiDataGrid-row.Mui-selected': {
                background: 'transparent'
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'transparent',
                background: 'none'
              },
              '& .MuiDataGrid-row.Mui-selected.MuiDataGrid-row:hover': {
                background: 'transparent'
              },
              '& .MuiDataGrid-columnHeaderDraggableContainer:focus': {
                border: 'none',
                outline: 'none'
              },
              '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none', // Remove the focus outline
                border: 'none', // Remove the border
                userSelect: 'none'
              },
              '&:hover': {
                backgroundColor: 'inherit',
                cursor: 'default'
              }
            }}
                    />
        </div>
        {(props.deleteButton) &&
                    (
                    <>
                      <div
                        className={'container'}
                        style={{
                          width: '100%',
                          height: '1px',
                          backgroundColor: '#D9D9D9'
                        }}></div>
                      <div
                        className="container d-flex justify-content-end"
                        style={{ marginTop: '15px' }}>
                        <button
                          style={{
                            height: '32px',
                            width: '188px',
                            backgroundColor: '#F1F0F0',
                            borderRadius: '5px'
                          }}
                          onClick={handleDeleteSelectedRows}>
                          <span style={{ color: '#000000A6' }}>
                            Cancel Interview
                          </span>
                        </button>
                      </div>
                    </>)
                }
      </>
    )
  }
}

export default StandardList
