import React, { useCallback, useLayoutEffect, useState } from 'react'
import Navigation from '../components/navigation/Navigation'
import { NavigationModeValues } from '../components/modes/NavigationMode'
import ColoredText from '../components/text/ColoredText'
import MarginWrapper from '../components/util/MarginWrapper'
import { COLORS } from '../util/Consts'
import ProfilePicture from '../components/profilePicture/ProfilePicture'
import { CropperType } from '../enumeration/CropperType'
import VerifyModal from '../components/VerifyModal/VerifyModal'
import InfoSections from '../components/TakerProfil/InfoSections'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../features/DeviceTypeInformationSlice'
import { Skeleton } from '@mui/material'
import { GetICUserDtoTakerStatusEnum as TakerStatus } from '../api/ic'
import { scrollTo } from '../components/VerifyModal/utils'
import { GetAccessToken } from '../features/AccessTokenSlice'
import { isGiver } from '../shared/role_validation_functions'

export function ProfilePage () {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile

  const [isOpen, setIsOpen] = useState(false)
  const [verified, setVerified] = useState(false)
  const body = useSelector(GetAccessToken) ?? {}
  const takerValidationRequested = body?.takerStatus === TakerStatus.RequestedForValidation
  const takerValidationRejected = body?.takerStatus === TakerStatus.Rejected
  const [firstName, setFirstName] = useState<string>(body.firstName)
  const [lastName, setLastName] = useState<string>(body.lastName)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [isloading, setIsLoading] = useState<boolean>(true)
  window.onload = function () {
    checkForToast()
  }
  const removeQueryParameter = (key: string) => {
    if (history.replaceState) {
      const url = new URL(window.location.href)
      url.searchParams.delete(key)
      const newUrl = url.toString()
      history.replaceState({}, '', newUrl)
    }
  }
  const checkForToast = () => {
    const { search } = window.location
    const isToastNeeded = new URLSearchParams(search).get('RequestAgain')
    if (isToastNeeded === 'true') {
      // The page was just reloaded, display the toast:
      toast.success('Requested for validation successfully')
      removeQueryParameter('RequestAgain')
    }
  }
  useLayoutEffect(() => {
    scrollTo('top')
  }, [])
  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <>
      <span id={'top'}></span>
      <Navigation
        navigationMode={NavigationModeValues.FIXED_MAIN_WITH_PLAN_AND_PROFILE}
        height={'30px'}/>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div
          className={'container-fluid flex-column d-flex justify-content-center align-items-center'}
          style={{
            width: !isMobile ? '71%' : '100%',
            background: COLORS.MAIN_COLOR,
            borderRadius: '0px 0px 10px 10px',
            height: '300px',
            boxSizing: 'border-box',
            marginTop: '1%'
          }}>
          {isloading &&
          <Skeleton
            animation="wave"
            variant="circular"
            width={120}
            height={120}
            sx={{ borderRadius: '50%' }} />
          }
          {!isloading &&
          <ProfilePicture
            pictureSrc = {'DEFAULT'}
            isChangeable = {false}
            width ={'130px'}
            name={firstName}
            surname={lastName}
            cropperType={CropperType.WITH_MODAL}/>
          }
          <MarginWrapper
            top = {'10px'}>
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
              {isloading &&
              <Skeleton
                animation="wave"
                variant="text"
                width={180}
                height={40}
                sx={{ borderRadius: '10px' }} />
              }
              {!isloading &&
              <ColoredText
                singleText={firstName + ' ' + lastName}
                color='white'
                fontSize='40px'
                fontWeight={300}/>
              }

            </div>
          </MarginWrapper>
          <MarginWrapper top={'20px'}>
            <>
              {isloading &&
              <Skeleton
                animation="wave"
                variant="text"
                width={180}
                height={40}
                sx={{ borderRadius: '10px' }} />
            }
              {!isloading &&
              <ColoredText
                color='white'
                fontSize='24px'
                singleText= {email}/>
            }
            </>
          </MarginWrapper>
        </div>
        <MarginWrapper
          bottom='20px'
          top={verified ? '50px' : '80px'}>
          <div>
            {isGiver(body) && (
            <div className={'d-flex justify-content-end mb-2 me-2'}>
              <ColoredText
                color={takerValidationRequested ? 'grey' : COLORS.MAIN_COLOR}
                cursor={takerValidationRequested ? 'auto' : 'pointer'}
                userSelect={'none'}
                onClick={takerValidationRequested ? () => {} : handleOpen}
                singleText={takerValidationRejected ? 'Request Again' : takerValidationRequested ? 'Taker validation pending...' : 'Become a Taker'}
                textDecoration={'underline'} />
            </div>
            )}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div
                style = {{
                  width: isMobile ? '95%' : '71vw',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '50px'
                }}>
                <InfoSections
                  setIsLoading={setIsLoading}
                  setEmail={setEmail}
                  setName={setFirstName}
                  setSurname={setLastName}/>
              </div>
            </div>
          </div>
        </MarginWrapper>
      </div>
      {
        !verified
          ? (isOpen)
              ? <VerifyModal
                  setVerified = {setVerified}
                  onClose={handleClose}
                  show = {isOpen}/>
              : <></>
          : null
      }
    </>
  )
}
