import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import ColoredText from '../../../components/text/ColoredText'
import { COLORS } from '../../../util/Consts'
import StandardButton from '../../../components/buttons/StandardButton'
import { WidthModeValues } from '../../../components/modes/WidthMode'
import { HeightModeValues } from '../../../components/modes/HeightMode'
import SVGHandler from '../../../components/svg/SVGHandler'
import greenDot from '../../../components/svg/resources/green_dot.svg'
import { useNavigate } from 'react-router'
import { NavigationRoute } from '../../../enumeration/NavigationRoute'
import ApiManager from '../../../manager/ApiManager'
import { ICExceptionDtoReasonEnum, type LoginSessionDto, type ResponseError, SessionControllerApi } from '../../../api/ic'
import { useSelector } from 'react-redux'
import { GetAccessToken } from '../../../features/AccessTokenSlice'

function LoginDevices () {
  const [loginSessions, setLoginSessions] = useState<LoginSessionDto[]>([])
  const body = useSelector(GetAccessToken) ?? {}
  const currentSessionId = body.sessionId
  const navigate = useNavigate()

  const sessionApi = ApiManager.getInstance(SessionControllerApi)

  useEffect(() => {
    void sessionApi.getAllSessions().then(res => {
      const result = []
      if (res) {
        for (const session of res) {
          if (session.uuid === currentSessionId) {
            result.unshift(session)
          } else {
            result.push(session)
          }
        }
        setLoginSessions(result)
      }
    }).catch((err: ResponseError) => {
      if (err.response.reason === ICExceptionDtoReasonEnum.AccessToEndpointDeniedException) {
        navigate(NavigationRoute.LOG_IN)
      }
    })
  }, [])

  return (
    <div className={'d-flex justify-content-center'} style={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: '16px'
    }}>
      {
        loginSessions.length && loginSessions.map(e => {
          const date = new Date(e.expirationDate ?? 0)
          return (
            <div key={e.uuid}>
              <div style={{
                border: `2px solid ${COLORS.MAIN_COLOR}`,
                borderRadius: '10px',
                padding: '15px',
                minWidth: '330px',
                gap: '10px',
                flex: '1 1 calc(50% - 16px)' // 50% width minus the gap
              }}>
                { currentSessionId === e.uuid
                  ? <div className={'d-flex flex-row'} style={{ gap: '10px' }}>
                    <SVGHandler static={true} svg={greenDot}/>
                    <ColoredText singleText={'Current'}/>
                  </div>
                  : <></>}
                <div className={'d-flex flex-row'} style={{ gap: '30px' }}>
                  <ColoredText singleText={e.browser}/>
                  <ColoredText singleText={`${date.getDay()}/${date.getMonth()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`}/>
                </div>
                <div className={'d-flex flex-row'} style={{ gap: '30px' }}>
                  <ColoredText singleText={e.operatingSystem}/>
                  <ColoredText
                    singleText={e.uuid}
                    fontSize={'8px'}
                    color={'grey'}/>
                </div>
                <div className={'d-flex flex-row justify-content-start'}>
                  <StandardButton
                    text={currentSessionId === e.uuid ? 'Log out' : 'Terminate'}
                    onClick={() => {
                      void sessionApi.terminate({ uuid: e.uuid }).then(res => {
                        toast.success(`Session with uuid ${res.uuid} was terminated`)
                        setLoginSessions(loginSessions.filter(el => res.uuid !== el.uuid))
                      }).catch(() => {})
                    }}
                    widthMode={WidthModeValues.DOUBLE_EXTRA_SMALL}
                    heightMode={HeightModeValues.SMALL}
                  />
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default LoginDevices
