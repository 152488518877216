import React, { useState, useRef, useEffect } from 'react'
import edit_text from '../../components/svg/resources/edit_text.svg'
import deleteSVG from '../../components/svg/resources/delete.svg'
import { EditableSpan } from './EditableSpan'

interface InlineEditableTextProps {
  /** The current text value (e.g. section title or question text) */
  value: string
  /** Called when the user saves changes (finishes editing) */
  onChange: (newValue: string) => void
  /** If true, show the delete icon; otherwise hide it */
  canDelete?: boolean
  /** Called when the user clicks the delete icon */
  onDelete?: () => void
  /** Optional placeholder for the input field */
  placeholder?: string
  /**
   * If set to true, the input will be a multi-line <textarea>.
   * If false (default), it's a single-line <input>.
   */
  multiline?: boolean
  /** Is Initially Editable */
  isInitiallyEditable?: boolean
  /** Is the text bold */
  isBold?: boolean
  /** Text font size */
  fontSize?: string
  /** Font Weight */
  fontWeight?: number
}

export const InlineEditableText: React.FC<InlineEditableTextProps> = ({
  value,
  onChange,
  canDelete = true,
  onDelete = () => {},
  placeholder = '',
  multiline = false,
  isInitiallyEditable = false,
  isBold = false,
  fontSize,
  fontWeight
}) => {
  const [isEditing, setIsEditing] = useState(isInitiallyEditable)
  const [ignoreInitialState, setIgnoreInitialState] = useState(false)
  const [tempValue, setTempValue] = useState(value)
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)

  // When toggling into edit mode, copy the current 'value' into 'tempValue'
  // and focus the input field automatically
  useEffect(() => {
    if ((isEditing || (!ignoreInitialState && isInitiallyEditable)) && inputRef.current) {
      setTempValue(value)
      inputRef.current.focus()
    } else {
      setTempValue(value)
    }
  }, [isEditing, value])

  const handleStartEditing = () => {
    setIsEditing(true)
    setIgnoreInitialState(true)
  }

  const handleFinishEditing = () => {
    // Save the changes and exit edit mode
    onChange(tempValue)
    setIsEditing(false)
    setIgnoreInitialState(true)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !multiline) {
      e.preventDefault()
      // For single-line input, pressing Enter saves
      handleFinishEditing()
    } else if (e.key === 'Escape') {
      // Cancel edits if user presses Escape
      setIsEditing(false)
      setIgnoreInitialState(true)
      setTempValue(value)
    }
  }

  return (
    <span
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        gap: '6px'
      }}
    >
      {(isEditing || (!ignoreInitialState && isInitiallyEditable)) ? (
        multiline ? (
          <EditableSpan
            wrapperStyle={{ fontWeight: isBold ? 'bold' : fontWeight || 'normal', fontSize: fontSize || 'normal' }}
            value={tempValue}
            placeholder={placeholder}
            onChange={(e) => { setTempValue(e) } }
            onBlur={handleFinishEditing}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <input
            ref={inputRef as React.RefObject<HTMLInputElement>}
            type="text"
            style={{
              backgroundColor: 'transparent',
              display: 'inline-flex',
              userSelect: 'none',
              outline: 'none',
              fontSize: fontSize || 'normal',
              fontWeight: fontWeight || 'normal',
              border: 'none',
              width: `${Math.max(
                placeholder.length,
                tempValue.length
              ) + 1}ch`
            }}
            value={tempValue}
            placeholder={placeholder}
            onChange={(e) => { setTempValue(e.target.value) }}
            onBlur={handleFinishEditing}
            onKeyDown={handleKeyDown}
          />
        )
      ) : (
        // Not in edit mode: show the text
        <span style={{
          fontSize: fontSize || 'normal',
          fontWeight: isBold ? 'bold' : fontWeight || 'normal'
        }}>
          {!value || value === '' ? placeholder : value}
        </span>
      )}

      {
        !isEditing &&
        (
          <div className={'d-flex align-items-center gap-2'}>
            <img
              src={edit_text}
              alt="Edit"
              style={{
                cursor: 'pointer',
                display: 'inline-block'
              }}
              width={20}
              height={20}
              onClick={handleStartEditing}
          />
            {canDelete && onDelete && (
              <img
                src={deleteSVG}
                alt="Delete"
                style={{ cursor: 'pointer' }}
                width={16}
                height={19}
                onClick={onDelete}
              />
            )}
          </div>
        )
      }

    </span>
  )
}
