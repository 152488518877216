/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GoogleAuthRequest,
  RefreshableAuthenticationResponseDto,
} from '../models/index';
import {
    GoogleAuthRequestFromJSON,
    GoogleAuthRequestToJSON,
    RefreshableAuthenticationResponseDtoFromJSON,
    RefreshableAuthenticationResponseDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface RefreshTokenRequest {
    authorization: string;
}

export interface VerifyGoogleTokenRequest {
    googleAuthRequest: GoogleAuthRequest;
}

/**
 * 
 */
export class AuthenticationControllerApi extends runtime.BaseAPI {

    /**
     * Gets taker session
     * Get taker session
     */
    async getTakerSessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefreshableAuthenticationResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/auth/create/session`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshableAuthenticationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Gets taker session
     * Get taker session
     */
    async getTakerSession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefreshableAuthenticationResponseDto> {
        const response = await this.getTakerSessionRaw(initOverrides);
        return await response.value();
    }

    /**
     * If the refresh token is valid, retrieves a new access token
     * Refresh token
     */
    async refreshTokenRaw(requestParameters: RefreshTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefreshableAuthenticationResponseDto>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling refreshToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/auth/refresh/token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshableAuthenticationResponseDtoFromJSON(jsonValue));
    }

    /**
     * If the refresh token is valid, retrieves a new access token
     * Refresh token
     */
    async refreshToken(requestParameters: RefreshTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefreshableAuthenticationResponseDto> {
        const response = await this.refreshTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async verifyGoogleTokenRaw(requestParameters: VerifyGoogleTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefreshableAuthenticationResponseDto>> {
        if (requestParameters['googleAuthRequest'] == null) {
            throw new runtime.RequiredError(
                'googleAuthRequest',
                'Required parameter "googleAuthRequest" was null or undefined when calling verifyGoogleToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/google/auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GoogleAuthRequestToJSON(requestParameters['googleAuthRequest']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshableAuthenticationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async verifyGoogleToken(requestParameters: VerifyGoogleTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefreshableAuthenticationResponseDto> {
        const response = await this.verifyGoogleTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
