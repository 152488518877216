import React from 'react'
import SVGHandler from './svg/SVGHandler'
import target_question_action_icon from './svg/resources/target_question_action_icon.svg'
import back_to_current_question_action from './svg/resources/sync_with_video_question_action.svg'
import ColoredText from './text/ColoredText'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../features/DeviceTypeInformationSlice'

interface QuestionAnswerActionsBarProps {
  navigationBackOnClick: () => void
  navigationToCurrentOnClick: () => void
  visible?: boolean
  position?: 'left' | 'middle' | 'right'
}

function QuestionAnswerActionsBar (props: QuestionAnswerActionsBarProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const visible = props.visible ?? true
  const position = props.position ?? 'middle'

  const getTriangleLeftPosition = () => {
    switch (position) {
      case 'left':
        return '30px'
      case 'right':
        return 'calc(100% - 50px)'
      case 'middle':
      default:
        return 'calc(50% - 10px)'
    }
  }

  return (
    visible && (<div
      style={{
        display: 'inline-block',
        position: 'relative',
        paddingTop: 15,
        zIndex: 10
      }}>
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: 15,
          boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.25)',
          position: 'relative',
          width: 'fit-content'
        }}>
        <div
          style={{
            position: 'absolute',
            top: -10,
            left: getTriangleLeftPosition(),
            width: 0,
            height: 0,
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderBottom: '10px solid #fff'
          }}
        />
        <div style={{ padding: 7 }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            alignItems: 'stretch'
          }}>
            <div
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 7 }}
              onClick={props.navigationBackOnClick}>
              <SVGHandler
                position="relative"
                svg={target_question_action_icon}
                width="19px"
              />
              {!isMobile && <ColoredText singleText="Navigate here"/>}
            </div>
            <div style={{ width: '2px', backgroundColor: 'black', borderRadius: 1 }} />
            <div
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 7 }}
              onClick={props.navigationToCurrentOnClick}>
              <SVGHandler
                position="relative"
                svg={back_to_current_question_action}
                width="17px"
              />
              {!isMobile && <ColoredText singleText="Sync with video"/>}
            </div>
          </div>
        </div>
      </div>
    </div>)
  )
}

export default QuestionAnswerActionsBar
