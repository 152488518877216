import React, { useEffect, useState } from 'react'
import arrow from '../../svg/resources/white_down_arrow.svg'
import microphoneIcon from '../../svg/resources/microphone.svg'
import microphoneOffIcon from '../../svg/resources/microphoneOff.svg'
import cameraIcon from '../../svg/resources/videoCamera.svg'
import cameraOffIcon from '../../svg/resources/cameraOff.svg'
import { checkMicPermission, checkVideoPermission } from '../../../util/VideoMicUtils'
import blocked_warn from '../../svg/resources/blocked_video_mic_warn.svg'
import MicVideoBlockedModal from '../MicVideoBlockedModal'

interface VideoMicComponentInterface {
  mode: 'video' | 'mic'
  arrowClick?: any
  click?: any
  byDefaultEnabled?: boolean
  disabled?: boolean
}

function VideoMicComponent (props: VideoMicComponentInterface) {
  const [enabled, setEnabled] = useState(props.byDefaultEnabled ?? true)
  const [arrowClicked, setArrowClicked] = useState(false)
  const icon = props.mode === 'video' ? cameraIcon : microphoneIcon
  const offIcon = props.mode === 'video' ? cameraOffIcon : microphoneOffIcon

  // Video, audio permissions
  const [hasPermission, setHasPermission] = useState(false)
  const [isBlockedModalOpen, setIsBlockedModalOpen] = useState(false)

  useEffect(() => {
    if (props.mode === 'video') {
      void checkVideoPermission().then(setHasPermission)
    } else {
      void checkMicPermission().then(setHasPermission)
    }
  }, [])
  const handleToggleClick = async () => {
    if (!hasPermission) {
      setIsBlockedModalOpen(true)
      return
    }
    if (!props.disabled) {
      if (props.click) {
        props.click()
      }
    }
  }
  return (
    <div
      className={'d-flex flex-row justify-content-center align-items-center gap-2'}
      style={{
        backgroundColor: '#3C4043',
        borderRadius: '20px',
        height: '100%',
        width: '100%',
        position: 'relative'
      }}>
      <div
        className={'d-flex justify-content-center align-items-center'}
        style={{
          paddingLeft: '10px',
          width: '30px',
          height: '30px',
          opacity: hasPermission ? '100%' : '50%',
          cursor: 'pointer'
        }}
        onClick={(e) => {
          if (!hasPermission) {
            setIsBlockedModalOpen(true)
            return
          }
          setArrowClicked(!arrowClicked)
          if (props.arrowClick) {
            props.arrowClick(e)
          }
        }}>
        <img src={arrow} alt={'arrow'}
          style={{ userSelect: 'none' }}/>
      </div>
      <div
        style={{
          backgroundColor: '#494E51',
          borderRadius: '20px',
          width: '40px',
          height: '40px',
          opacity: hasPermission ? '100%' : '50%',
          display: 'flex',
          padding: '5px',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
          userSelect: 'none'
        }}
        onClick={() => {
          handleToggleClick().then(() => {
            if (!props.disabled) {
              setEnabled(!enabled)
            }
          }).catch(() => {})
        }}>
        <img
          style={{ userSelect: 'none' }}
          src={enabled ? icon : offIcon}
          alt={props.mode}/>
      </div>
      {!hasPermission && <div style={{ position: 'absolute', right: -8, top: -8 }}>
        <img src={blocked_warn} alt={'blocked'}/>
      </div>}
      <div style={{ position: 'absolute' }}>
        <MicVideoBlockedModal
          mode={props.mode === 'video' ? 'camera' : 'mic'}
          isOpen={isBlockedModalOpen}
          setIsOpen={setIsBlockedModalOpen}/>
      </div>
    </div>
  )
}

export default VideoMicComponent
