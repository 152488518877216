/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateFeedbackDto
 */
export interface UpdateFeedbackDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedbackDto
     */
    interviewId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedbackDto
     */
    feedback?: string;
}

/**
 * Check if a given object implements the UpdateFeedbackDto interface.
 */
export function instanceOfUpdateFeedbackDto(value: object): value is UpdateFeedbackDto {
    return true;
}

export function UpdateFeedbackDtoFromJSON(json: any): UpdateFeedbackDto {
    return UpdateFeedbackDtoFromJSONTyped(json, false);
}

export function UpdateFeedbackDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFeedbackDto {
    if (json == null) {
        return json;
    }
    return {
        
        'interviewId': json['interviewId'] == null ? undefined : json['interviewId'],
        'feedback': json['feedback'] == null ? undefined : json['feedback'],
    };
}

export function UpdateFeedbackDtoToJSON(value?: UpdateFeedbackDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'interviewId': value['interviewId'],
        'feedback': value['feedback'],
    };
}

