import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import dashjs from 'dashjs'
import ApiManager from '../../manager/ApiManager'
import { type GetInterviewAnalyzingResultDto, InterviewControllerApi } from '../../api/ic'
import FlipList from './FlipList'
import playIcon from '../../components/svg/resources/play-video-icon.svg'
import pauseIcon from '../../components/svg/resources/pause-video-icon.svg'

interface SyncOffsetVideosProps {
  interviewId: string
  setAvgPoints: (points: number) => void
}

const SyncOffsetVideos = (props: SyncOffsetVideosProps) => {
  const giverRef = useRef<HTMLVideoElement | null>(null)
  const takerRef = useRef<HTMLVideoElement | null>(null)
  // Known start-time offsets (in seconds)
  const [giverOffset, setGiverOffset] = useState(0)
  const [takerOffset, setTakerOffset] = useState(0)
  const [initiallyPlayed, setInitiallyPlayed] = useState(false)
  const [contentDuration, setContentDuration] = useState(63)
  const [scrubTime, setScrubTime] = useState(0)

  const interviewApi = ApiManager.getInstance(InterviewControllerApi)
  const [analyzedResults, setAnalyzedResults] = useState<GetInterviewAnalyzingResultDto | null>(null)
  const [activeQuestionId, setActiveQuestionId] = useState<any>(null)
  const [timeLine, setTimeline] = useState<any>([])
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  useLayoutEffect(() => {
    void interviewApi.getInterviewAnalyzingResult({ interviewId: props.interviewId })
      .then(res => {
        setAnalyzedResults(res)
        props.setAvgPoints(res.averagePoints)
        setActiveQuestionId(res.questions[0].id)
        const giverStartTime = res.interview.analyzingResult.giver?.startTime
        const takerStartTime = res.interview.analyzingResult.taker?.startTime
        const giverEndTime = res.interview.analyzingResult.giver?.endTime
        const takerEndTime = res.interview.analyzingResult.taker?.endTime
        let gap = 0
        if (giverStartTime && takerStartTime) {
          gap = (giverStartTime.getTime() - takerStartTime.getTime()) / 1000
          if (giverEndTime && takerEndTime) {
            let giverDuration = (giverEndTime.getTime() - giverStartTime.getTime()) / 1000
            let takerDuration = (takerEndTime.getTime() - takerStartTime.getTime()) / 1000
            if (gap < 0) {
              giverDuration = giverDuration + gap
            } else {
              takerDuration = takerDuration - gap
            }
            const totalDuration = Math.min(takerDuration, giverDuration)
            setContentDuration(totalDuration)

            const referenceStartTime = gap <= 0 ? takerStartTime : giverStartTime
            const timeMap = res.questions.map((question, index) => {
              if (question.questionOrder === 0) {
                return {
                  ...question,
                  startTime: 0,
                  endTime: (new Date(res.questions[0].answerTime).getTime() - referenceStartTime.getTime()) / 1000
                }
              }
              const startTime = (new Date(res.questions[index - 1].answerTime).getTime() - referenceStartTime.getTime()) / 1000
              const endTime = index < res.questions.length - 1
                ? (new Date(res.questions[index].answerTime).getTime() - referenceStartTime.getTime()) / 1000
                : totalDuration
              return {
                ...question,
                startTime: Math.max(startTime, 0),
                endTime: Math.min(endTime, totalDuration)
              }
            })
            // Store Timeline
            setTimeline(timeMap)
          } else {
            throw Error('end time not defined')
          }
          if (gap <= 0) {
            setGiverOffset(Math.abs(gap))
          } else {
            setTakerOffset(gap)
          }
        } else {
          throw Error('start time not defined')
        }
      })
  }, [])

  useEffect(() => {
    if (!analyzedResults) {
      return
    }
    const giverMpdUrl = analyzedResults.interview.analyzingResult.giver?.url
    const takerMpdUrl = analyzedResults.interview.analyzingResult.taker?.url
    let playerA: dashjs.MediaPlayerClass | null = null
    let playerB: dashjs.MediaPlayerClass | null = null
    if (giverRef.current) {
      playerA = dashjs.MediaPlayer().create()
      playerA.initialize(giverRef.current, giverMpdUrl, false)
      playerA.updateSettings({
        streaming: {
          gaps: { jumpGaps: true }
        }
      })
      if (giverOffset >= 0 && takerOffset === 0) {
        playerA.on(dashjs.MediaPlayer.events.PLAYBACK_TIME_UPDATED, (e: any) => {
          const relativeTime = e.time - giverOffset
          if (relativeTime >= 0 && relativeTime <= contentDuration) {
            setScrubTime(relativeTime)
            const activeQuestion = timeLine.find(
              (q: any) => relativeTime >= q.startTime && relativeTime < q.endTime
            )

            // Set active question ID
            if (activeQuestion) {
              setActiveQuestionId(activeQuestion.id)
            } else {
              // Edge case: If time is beyond the last question, set the last question as active
              setActiveQuestionId(timeLine[timeLine.length - 1].id)
            }
          }
        })
      }
    }

    if (takerRef.current) {
      playerB = dashjs.MediaPlayer().create()
      playerB.initialize(takerRef.current, takerMpdUrl, false)
      playerB.updateSettings({
        streaming: {
          gaps: { jumpGaps: true }
        }
      })
      if (takerOffset >= 0 && giverOffset === 0) {
        playerB.on(dashjs.MediaPlayer.events.PLAYBACK_TIME_UPDATED, (e: any) => {
          const relativeTime = e.time - takerOffset
          if (relativeTime >= 0 && relativeTime <= contentDuration) {
            setScrubTime(relativeTime)
            const activeQuestion = timeLine.find(
              (q: any) => relativeTime >= q.startTime && relativeTime < q.endTime
            )

            // Set active question ID
            if (activeQuestion) {
              setActiveQuestionId(activeQuestion.id)
            } else {
              // Edge case: If time is beyond the last question, set the last question as active
              setActiveQuestionId(timeLine[timeLine.length - 1].id)
            }
          }
        })
      }
    }
    return () => {
      if (playerA) {
        playerA.off(dashjs.MediaPlayer.events.PLAYBACK_TIME_UPDATED, () => {})
        playerA.reset()
      }
      if (playerB) {
        playerB.off(dashjs.MediaPlayer.events.PLAYBACK_TIME_UPDATED, () => {})
        playerB.reset()
      }
    }
  }, [giverOffset,
    takerOffset,
    contentDuration,
    analyzedResults])

  useEffect(() => {
    if (giverRef.current) {
      giverRef.current.currentTime = giverOffset
    }
    if (takerRef.current) {
      takerRef.current.currentTime = takerOffset
    }
  }, [giverOffset, takerOffset])

  useEffect(() => {
    if (scrubTime >= contentDuration - 0.3) {
      giverRef.current?.pause()
      takerRef.current?.pause()
      setIsPlaying(false)
    }
  }, [scrubTime, contentDuration])

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = parseFloat(e.target.value)
    setScrubTime(newTime)

    if (giverRef.current) {
      giverRef.current.currentTime = giverOffset + newTime
    }
    if (takerRef.current) {
      takerRef.current.currentTime = takerOffset + newTime
    }
    const activeQuestion = timeLine.find(
      (q: any) => newTime >= q.startTime && newTime < q.endTime
    )

    // Set active question ID
    if (activeQuestion) {
      setActiveQuestionId(activeQuestion.id)
    } else {
      // Edge case: If time is beyond the last question, set the last question as active
      setActiveQuestionId(timeLine[timeLine.length - 1].id)
    }
  }

  const playBoth = () => {
    if (scrubTime >= contentDuration - 0.3) {
      if (giverRef.current) {
        giverRef.current.currentTime = giverOffset
      }
      if (takerRef.current) {
        takerRef.current.currentTime = takerOffset
      }
      setScrubTime(0)
    }
    if (!initiallyPlayed) {
      if (giverRef.current) {
        giverRef.current.currentTime = giverOffset
      }
      if (takerRef.current) {
        takerRef.current.currentTime = takerOffset
      }
      setInitiallyPlayed(true)
    }
    giverRef.current?.play().catch(() => {})
    takerRef.current?.play().catch(() => {})
    setIsPlaying(true)
  }
  const stopBoth = () => {
    giverRef.current?.pause()
    takerRef.current?.pause()
    setIsPlaying(false)
  }
  const getMarkerPosition = (time: number) => (time / contentDuration) * 100

  const formatTime = (seconds: number) => {
    const sec = Math.floor(seconds)
    const hours = Math.floor(sec / 3600)
    const minutes = Math.floor((sec % 3600) / 60)
    const secs = sec % 60

    if (hours > 0) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`
    } else {
      return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`
    }
  }

  const withHours = (seconds: number) => {
    const sec = Math.floor(seconds)
    const hours = Math.floor(sec / 3600)
    return hours > 0
  }

  return (
    <div style={{ background: 'rgba(32, 33, 36, 1)' }}>
      <style>
        {`
        /* For Chrome, Safari, Edge */
        input[type='range']::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          cursor: pointer;
          background-color: #faf9f5;
          transition: background-color 0.3s ease;
        }

        /* For Firefox */
        input[type='range']::-moz-range-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          cursor: pointer;
          background-color: #faf9f5; /* Custom Thumb Color */
          transition: background-color 0.3s ease;
        }

        /* Optional: Hover effect */
        input[type='range']::-webkit-slider-thumb:hover {
          background-color: #cfcdc6; /* Darker Red on Hover */
        }

        input[type='range']::-moz-range-thumb:hover {
          background-color: #cfcdc6; /* Darker Red on Hover */
        }
      `}
      </style>
      <div style={{ maxWidth: '100vw', width: '100%', height: '90%' }} className={'d-flex flex-row justify-content-evenly flex-grow-1 gap-3'}>
        <div style={{
          display: 'flex',
          gap: '1rem',
          width: '50%'
        }} className={'d-flex flex-column justify-content-evenly align-items-center ms-5'}>
          <div style={{ width: '80%' }}>
            <video
              playsInline
              ref={giverRef}
              width={'100%'}
              style={{ objectFit: 'cover', borderRadius: '10px', aspectRatio: '16/9' }}
              controls={false}
              autoPlay={false}
            ></video>
          </div>
          <div style={{ width: '80%' }}>
            <video
              playsInline
              ref={takerRef}
              controls={false}
              width={'100%'}
              style={{ objectFit: 'cover', borderRadius: '10px', aspectRatio: '16/9' }}
              autoPlay={false}
            ></video>
          </div>
          <div className={'d-flex flex-row gap-2 w-100 align-items-center justify-content-start ms-3'}>
            <img alt={isPlaying ? 'Pause' : scrubTime >= contentDuration - 0.3 ? 'Play Again' : 'Play'}
              src={isPlaying ? pauseIcon : playIcon}
              onClick={isPlaying ? stopBoth : playBoth}/>
            <div className={'d-flex flex-row align-items-center ms-2 gap-2 w-100'} style={{ position: 'relative' }}>
              <input
                type="range"
                min={0}
                max={contentDuration}
                step={0.01}
                value={scrubTime}
                onChange={handleSliderChange}
                style={{
                  width: '100%',
                  WebkitAppearance: 'none',
                  appearance: 'none',
                  height: '6px',
                  background: `linear-gradient(to right, #faf9f5 ${(
                    (scrubTime / contentDuration) *
                    100
                  ).toFixed(2)}%, #cfcdc6 ${(scrubTime / contentDuration) * 100}%)`,
                  borderRadius: '5px',
                  outline: 'none'
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  width: '100%',
                  height: '10px',
                  pointerEvents: 'none', // Allow slider interaction
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                {/* TODO: Add hover effect with popup that will give info about the question */}
                {timeLine.map((item: any, idx: number) => (
                  <div
                    key={idx}
                    style={{
                      position: 'absolute',
                      left: `${getMarkerPosition(item.startTime)}%`,
                      width: '2px',
                      height: '14px',
                      backgroundColor: 'darkgray',
                      borderRadius: '50%',
                      transform: 'translateX(-50%)', // Center marker on tick
                      pointerEvents: 'none' // Allow slider interaction
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="d-flex flex-row gap-1" style={{ minWidth: withHours(contentDuration) ? '146px' : '101px' }}>
              <div style={{ color: 'white', width: '49%' }}>
                {formatTime(scrubTime)}
              </div>
              <div style={{ color: 'white', width: '2%' }}>
                /
              </div>
              <div style={{ color: 'white', width: '49%' }}>
                {formatTime(contentDuration)}
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '70%' }} className={'d-flex justify-content-center me-5 flex-grow-1'}>
          <FlipList
            setActiveQuestionId={setActiveQuestionId}
            giverOffset={giverOffset}
            takerOffset={takerOffset}
            giverRef={giverRef}
            takerRef={takerRef}
            setScrubTime={setScrubTime}
            timeLine={timeLine}
            activeId={activeQuestionId}
            items={analyzedResults?.questions ?? []}/>
        </div>
      </div>
    </div>
  )
}

export default SyncOffsetVideos
