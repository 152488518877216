import React, { useState } from 'react'
import { COLORS } from '../../util/Consts'
import ColoredText from '../text/ColoredText'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import StandardModal from '../modal/StandardModal'
import ApiManager from '../../manager/ApiManager'
import { ICUserControllerApi } from '../../api/ic'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import MarginWrapper from '../util/MarginWrapper'

interface GiverTakerTumblerProps {
  isTakerModeEnabled: boolean
}

function GiverTakerTumbler (props: GiverTakerTumblerProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const userApi = ApiManager.getInstance(ICUserControllerApi)

  const [isTakerModeEnabled, setIsTakerModeEnabled] = useState(props.isTakerModeEnabled)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleToggle = () => {
    setIsModalOpen(true)
  }

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    fontFamily: 'sans-serif'
  }

  const textStyle = {
    fontSize: '1.1rem',
    cursor: 'pointer'
  }

  const toggleContainerStyle = {
    width: '40px',
    height: '20px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    padding: '2px',
    cursor: 'pointer',
    transition: 'background-color 0.25s',
    backgroundColor: isTakerModeEnabled ? COLORS.MAIN_COLOR : '#888'
  }

  const sliderStyle = {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    transition: 'transform 0.25s',
    transform: isTakerModeEnabled ? 'translateX(18px)' : 'translateX(0)'
  }

  return (
    <div style={containerStyle}>
      <span
        style={{
          ...textStyle,
          color: isTakerModeEnabled ? 'black' : COLORS.MAIN_COLOR
        }}
        onClick={handleToggle}
      >
        Giver
      </span>

      <div style={toggleContainerStyle} onClick={handleToggle}>
        <div style={sliderStyle} />
      </div>

      <span
        style={{
          ...textStyle,
          color: isTakerModeEnabled ? COLORS.MAIN_COLOR : 'black'
        }}
        onClick={handleToggle}
      >
        Taker
      </span>
      <StandardModal isOpen={isModalOpen} closeWhenClickingOutOfModal={true}
        title={'Change Mode in UI'}
        width={isMobile ? '90%' : '50%'}
        footerComponent={
          <>
            <ColoredText singleText={'Cancel'} cursor={'pointer'}
              onClick={ () => { setIsModalOpen(false) } } />
            <StandardButton
              text={'Switch'}
              sizeMode={ButtonModeValues.SMALL}
              onClick={ () => {
                if (isTakerModeEnabled) {
                  void userApi.disableTakerModeEnabled().then(r => {
                    setIsModalOpen(false)
                    setIsTakerModeEnabled(false)
                    window.location.reload()
                    console.log(r)
                  }).catch(e => {
                    setIsModalOpen(false)
                    console.log(e)
                  })
                } else {
                  void userApi.enableTakerModeEnabled().then(r => {
                    setIsModalOpen(false)
                    setIsTakerModeEnabled(true)
                    console.log(r)
                    window.location.reload()
                  }).catch(e => {
                    setIsModalOpen(false)
                    console.log(e)
                  })
                }
              }} />
          </>}>
        <MarginWrapper top={'20px'}>
          <ColoredText singleText={'Are you sure you want to change your mode? It will affect only UI and you can change your mode whenever you want'} />
        </MarginWrapper>
      </StandardModal>
    </div>
  )
}

export default GiverTakerTumbler
