/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * User work experiences
 * @export
 * @interface WorkExperienceDto
 */
export interface WorkExperienceDto {
    /**
     * 
     * @type {string}
     * @memberof WorkExperienceDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkExperienceDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkExperienceDto
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkExperienceDto
     */
    position?: string;
    /**
     * User ID
     * @type {string}
     * @memberof WorkExperienceDto
     */
    fromDate: string;
    /**
     * User ID
     * @type {string}
     * @memberof WorkExperienceDto
     */
    toDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkExperienceDto
     */
    stillWorking?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkExperienceDto
     */
    description?: string;
}

/**
 * Check if a given object implements the WorkExperienceDto interface.
 */
export function instanceOfWorkExperienceDto(value: object): value is WorkExperienceDto {
    if (!('fromDate' in value) || value['fromDate'] === undefined) return false;
    if (!('toDate' in value) || value['toDate'] === undefined) return false;
    return true;
}

export function WorkExperienceDtoFromJSON(json: any): WorkExperienceDto {
    return WorkExperienceDtoFromJSONTyped(json, false);
}

export function WorkExperienceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkExperienceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'location': json['location'] == null ? undefined : json['location'],
        'position': json['position'] == null ? undefined : json['position'],
        'fromDate': json['fromDate'],
        'toDate': json['toDate'],
        'stillWorking': json['stillWorking'] == null ? undefined : json['stillWorking'],
        'description': json['description'] == null ? undefined : json['description'],
    };
}

export function WorkExperienceDtoToJSON(value?: WorkExperienceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'location': value['location'],
        'position': value['position'],
        'fromDate': value['fromDate'],
        'toDate': value['toDate'],
        'stillWorking': value['stillWorking'],
        'description': value['description'],
    };
}

