import React, { type CSSProperties, useEffect, useState } from 'react'
import { WIDTH_MODES, WidthModeValues } from '../modes/WidthMode'
import { HEIGHT_MODES, HeightModeValues } from '../modes/HeightMode'
import ColoredText, { type ColoredTextItemConfig } from '../text/ColoredText'
import { StandardInputModes, StandardInputModeValues } from '../modes/StandardInputMode'
import StandardTooltip from '../util/StandardTooltip'
import infoIcon from '../svg/resources/info.svg'
import { INPUT_MODES, type InputModeValues } from '../modes/InputModes'
import { FontModeValues } from '../modes/FontMode'
import eyeIcon from '../svg/resources/eye.svg'
import linedEyeIcon from '../svg/resources/lined_eye.svg'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import { useSelector } from 'react-redux'
import { Popover } from '@mui/material'
import Calendar from 'react-calendar'
import './calendar.css'

interface StandardInputProps {
  label: string
  labelFontSize?: number
  placeHolder: string
  inputValue: string | undefined
  setInputValue: React.Dispatch<React.SetStateAction<any>>
  widthMode?: WidthModeValues
  heightMode?: HeightModeValues
  errorMessage?: string
  onClick?: any
  type?: StandardInputModeValues
  maxLength?: number
  backgroundColor?: string
  width?: string
  hintText?: ColoredTextItemConfig[]
  onChange?: any
  sizeMode?: InputModeValues
  showPassword?: boolean
  triggerValidate?: boolean
  id?: string | undefined
  labelColor?: string
  textColor?: string
  setTriggerValidate?: React.Dispatch<React.SetStateAction<boolean>>
  setIsValid?: React.Dispatch<React.SetStateAction<boolean>>
  autocomplete?: string
  disabled?: boolean
  isRequired?: boolean
  showDateWhenDisabled?: boolean
  triggerDisableError?: boolean
  triggerEnableError?: boolean
  regex?: RegExp
  rightIcon?: string
  dontAlignStart?: boolean
  textAreaCountColor?: string
}
function StandardInput (props: StandardInputProps) {
  const textColor = props.inputValue && !props.disabled ? props.textColor ?? 'black' : 'rgb(0,0,0,1)'
  const [show, setShow] = useState(false)
  const [error, setError] = useState(false)
  const type = props.type ?? StandardInputModeValues.TEXT
  const isRequired = props.isRequired ?? false
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  let height, width
  // TODO: create function for returning this sizes for input and button modes
  if (props.sizeMode) {
    if (INPUT_MODES[props.sizeMode]?.mobile) {
      if (isMobile) {
        height = INPUT_MODES[props.sizeMode].mobile.height
        width = INPUT_MODES[props.sizeMode].mobile.width
      } else {
        height = INPUT_MODES[props.sizeMode].browser.height
        width = INPUT_MODES[props.sizeMode].browser.width
      }
    } else {
      height = INPUT_MODES[props.sizeMode].height
      width = INPUT_MODES[props.sizeMode].width
    }
  } else {
    height = isMobile
      ? HEIGHT_MODES[props.heightMode ?? HeightModeValues.DEFAULT].mobile
      : HEIGHT_MODES[props.heightMode ?? HeightModeValues.DEFAULT].browser

    width = isMobile
      ? WIDTH_MODES[props.widthMode ?? WidthModeValues.DEFAULT].mobile
      : WIDTH_MODES[props.widthMode ?? WidthModeValues.DEFAULT].browser
  }

  useEffect(() => {
    if (props.regex) {
      if (props.regex.test(props.inputValue ?? '')) {
        if (props.setIsValid) { props.setIsValid(true) }
      } else {
        if (props.setIsValid) { props.setIsValid(false) }
      }
    } else {
      if (StandardInputModes[type].validation.test(props.inputValue ?? '')) {
        if (props.setIsValid) {
          props.setIsValid(true)
        }
      } else {
        if (props.setIsValid) {
          props.setIsValid(false)
        }
      }
    }
    if (props.triggerEnableError && !props.triggerDisableError) {
      setError(true)
    }
    if (props.triggerDisableError && !props.triggerEnableError) {
      setError(false)
    }
  }, [props.inputValue])
  const handleInputChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e)
    } else {
      if (props.type === StandardInputModeValues.DATE) {
        const { value } = e.target
        if (!value) {
          return undefined
        }
        props.setInputValue(new Date(value))
      } else {
        props.setInputValue(e.target.value)
      }
    }
    if (props.regex) {
      if (props.regex.test(props.inputValue ?? '')) {
        if (props.setIsValid) { props.setIsValid(true) }
      } else {
        if (props.setIsValid) { props.setIsValid(false) }
      }
    } else {
      if (StandardInputModes[type].validation.test(props.inputValue ?? '')) {
        if (props.setIsValid) {
          props.setIsValid(true)
        }
      } else {
        if (props.setIsValid) {
          props.setIsValid(false)
        }
      }
    }
  }

  useEffect(() => {
    setError(false)
    if (props.triggerEnableError && !props.triggerDisableError) {
      setError(true)
    }
    if (props.triggerDisableError && !props.triggerEnableError) {
      setError(false)
    }
  }, [props.inputValue, props.triggerDisableError])

  useEffect(() => {
    if (props.triggerValidate) {
      if (props.regex) {
        if (!props.regex.test(props.inputValue ?? '')) {
          setError(true)
          if (props.setTriggerValidate) {
            props.setTriggerValidate(false)
          }
          return
        }
      } else if (!StandardInputModes[type].validation.test(props.inputValue ?? '')) {
        setError(true)
        if (props.setTriggerValidate) {
          props.setTriggerValidate(false)
        }
        return
      }
      if (props.setTriggerValidate) {
        props.setTriggerValidate(false)
      }
      setError(false)
    }
    // TODO: WRONG
    if (props.triggerEnableError) {
      setError(true)
    }
  }, [props.triggerValidate, props.triggerEnableError])

  const inputStyle: CSSProperties = {
    borderRadius: '5px',
    paddingLeft: '10px',
    background: props.disabled ? '#f5f5f5' : props.backgroundColor ?? 'rgba(255, 255, 255, 0.80)',
    boxShadow: error ? '0px 0px 4px 0px #FF0001' : '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
    fontFamily: 'Inter, sans-serif',
    color: props.inputValue ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0.25)',
    fontSize: '15px',
    lineHeight: 'normal',
    userSelect: 'none',
    border: 'none',
    outline: 'none'
  }

  return (
    <div
      className={`d-flex flex-column ${props.dontAlignStart ? '' : 'align-items-start'}`}
      style={{ position: 'relative', width }}>
      <span
        className={'d-flex flex-row align-items-center'}>
        <span
          style={{
            marginBottom: '5px',
            marginLeft: '2px',
            fontSize: props.labelFontSize ? `${props.labelFontSize}px` : '14px',
            color: props.labelColor ?? '#000000'
          }}>
          <>
            {props.label}
            {isRequired && <span style={{ color: 'red', fontSize: '14px', userSelect: 'none' }}>*</span>}
          </>
        </span>
        {props.hintText &&
        <StandardTooltip texts={props.hintText}>
          <img
            src={infoIcon}
            alt={'Info'}
            style={{ marginLeft: '2px', marginBottom: '4px' }}/>
        </StandardTooltip>}
      </span>
      {props.type !== StandardInputModeValues.TEXT_AREA && props.type !== StandardInputModeValues.ONLYREAD && props.type !== StandardInputModeValues.DATE
        ? <div
            style={{
              display: props.showPassword ? 'flex' : 'block',
              flexDirection: 'row',
              position: 'relative'
            }}>
          <input
            id = {props.id}
            type={ props.type ? props.type === StandardInputModeValues.PASSWORD ? show ? 'text' : 'password' : StandardInputModes[props.type].type : 'text' }
            value={props.inputValue}
            onChange={handleInputChange}
            placeholder={props.disabled ? !props.showDateWhenDisabled ? 'dddd/mm/dd' : props.inputValue : props.placeHolder}
            style={{ ...inputStyle, width, height, resize: 'none', color: textColor }}
            maxLength={props.maxLength ?? 120}
            autoComplete={props.autocomplete ?? undefined}
            disabled={props.disabled}
            />
          {props.showPassword && <div>
            <img
              src = {show ? eyeIcon : linedEyeIcon}
              alt = 'eye'
              style = {{
                position: 'absolute',
                left: '90%',
                top: '30%',
                cursor: 'pointer'
              }}
              onClick = {() => { setShow(prev => !prev) }}/>
          </div>}
          {props.rightIcon && <div>
            <img
              src = {props.rightIcon}
              alt = ''
              width={'20px'}
              height={'20px'}
              style = {{
                position: 'absolute',
                left: '85%',
                top: '25%',
                cursor: 'pointer'
              }}
              onClick = {() => { }}/>
          </div>}
        </div>
        : props.type === StandardInputModeValues.TEXT_AREA
          ? <div>
            <textarea
              value={props.inputValue}
              id = {props.id}
              onChange={handleInputChange}
              disabled={props.disabled}
              placeholder={props.placeHolder}
              style={{
                ...inputStyle,
                paddingTop: '20px',
                paddingRight: '30px',
                fontSize: '16px',
                width,
                color: textColor,
                height,
                resize: 'none'
              }}
              maxLength={props.maxLength ? props.maxLength : 500}/>
            {(!props.disabled && props.maxLength !== 0) &&
            <div
              style={{ position: 'relative', width, height: '15px' }}>
              <div
                style={{ position: 'absolute', right: '0.1vw' }}>
                <ColoredText
                  color={props.textAreaCountColor ?? 'rgba(0, 0, 0, 0.65)'}
                  singleText={`${props.inputValue?.length}/${props.maxLength ?? 500}`}/>
              </div>
            </div>}
          </div>
          : props.type !== StandardInputModeValues.DATE ? <input
              id = {props.id}
              onClick={props.onClick}
              type={'text'}
              value={props.inputValue}
              placeholder={props.placeHolder}
              style={{ ...inputStyle, width, height, resize: 'none', cursor: 'not-allowed', background: '#f5f5f5' }}
              disabled={props.disabled}
              readOnly/>
            : <span style={{ width, height, position: 'relative', ...inputStyle }}>
              <button hidden={true} onClick={handleClick}
                className={'d-inline-flex'}
                style={{ position: 'absolute', width, height, zIndex: 2, ...inputStyle, paddingLeft: '0px', boxShadow: 'none', background: 'none', display: 'none', resize: 'none' }}>
                <input
                  id = {props.id}
                  value={props.inputValue}
                  onChange={handleInputChange}
                  placeholder={props.type === StandardInputModeValues.DATE && props.disabled ? !props.showDateWhenDisabled ? 'yyyy-mm-dd' : props.inputValue ?? 'yyyy-mm-dd' : props.placeHolder ? props.placeHolder : 'yyyy-mm-dd'}
                  style={{ ...inputStyle, width: '100%', height: '100%', resize: 'none', color: textColor, border: 'none', boxShadow: 'none', background: 'transparent', paddingLeft: '0px' }}
                  maxLength={props.maxLength ?? 120}
                  autoComplete={props.autocomplete ?? undefined}
                  disabled={props.disabled}
                  readOnly={true}/>
              </button>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{ borderRadius: '10px', background: 'transparent' }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
            >
                <Calendar onChange={props.setInputValue} onClickDay={handleClose}
                  value={props.inputValue} />
              </Popover>
            </span>
            }
      <div
        style={{ height: '25px' }}>
        <ColoredText
          fontWeight={300}
          fontSize={FontModeValues.EXTRA_SMALL}
          color={'#FF0001'}
          singleText={error ? props.errorMessage : ''}
          userSelect={'none'}/>
      </div>
    </div>
  )
}

StandardInput.defaultProps = {
  errorMessage: 'Invalid input',
  onClick: () => {
  },
  setError: () => {
  }
}

export default StandardInput
