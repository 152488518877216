import React from 'react'

interface BoxWithBorderProps {
  header?: React.ReactNode
  content?: React.ReactNode
  notScrollableContent?: React.ReactNode
  footer?: React.ReactNode
  showFooter?: boolean
  showHeader?: boolean
  growContent?: boolean
  width?: string
}
function BoxWithBorder (props: BoxWithBorderProps) {
  const showHeader = props.showHeader === undefined ? true : props.showHeader
  return (
    <>
      <style>
        {`
          /* Hide scrollbar for Chrome, Safari, and Opera */
          div::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
      <div className={`d-flex flex-column ${props.growContent ? 'flex-grow-1' : ''}`}
        style={{
          minHeight: '200px',
          width: props.width ? props.width : 'fit-content',
          padding: '10px',
          margin: '10px',
          border: '1px solid rgba(0, 0, 0, 0.25)',
          borderRadius: '10px'
        }}>
        {(props.header && showHeader) && (
          <span
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
              marginBottom: '20px',
              paddingBottom: '10px',
              minHeight: '50px',
              overflowX: 'scroll',
              overflowY: 'hidden',
              whiteSpace: 'nowrap'
            }}>
            {props.header}
          </span>
        )}
        {props.notScrollableContent && (
          props.notScrollableContent
        )}
        <div
          className={'d-inline-flex flex-column'}
          style={{
            overflowX: 'scroll',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch', // For smooth scrolling on iOS
            scrollbarWidth: 'none' // Firefox
          }}>
          {props.content}
        </div>
        {(props.footer && props.showFooter) && (
          <span
            style={{
              borderTop: '1px solid rgba(0, 0, 0, 0.25)',
              marginTop: '20px',
              paddingTop: '20px',
              whiteSpace: 'nowrap'
            }}>
            {props.footer}
          </span>
        )}
      </div>
    </>

  )
}

export default BoxWithBorder
