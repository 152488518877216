/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberInvitationDto
 */
export interface MemberInvitationDto {
    /**
     * 
     * @type {string}
     * @memberof MemberInvitationDto
     */
    email?: string;
}

/**
 * Check if a given object implements the MemberInvitationDto interface.
 */
export function instanceOfMemberInvitationDto(value: object): value is MemberInvitationDto {
    return true;
}

export function MemberInvitationDtoFromJSON(json: any): MemberInvitationDto {
    return MemberInvitationDtoFromJSONTyped(json, false);
}

export function MemberInvitationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberInvitationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'] == null ? undefined : json['email'],
    };
}

export function MemberInvitationDtoToJSON(value?: MemberInvitationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
    };
}

