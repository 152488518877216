import React from 'react'
import { type GetInterviewDto, RecordingControllerApi } from '../api/ic'
import StandardButton from '../components/buttons/StandardButton'
import { ButtonModeValues } from '../components/modes/ButtonMode'
import ApiManager from '../manager/ApiManager'
import ColoredText from '../components/text/ColoredText'

interface RecordingManagementActionsProps {
  interview: GetInterviewDto
}

const RecordingManagementActions = (props: RecordingManagementActionsProps) => {
  const { interview } = props
  const [paymentCancelled, setPaymentCancelled] = React.useState(interview.recordingPaymentCancelled)

  const recordingApi = ApiManager.getInstance(RecordingControllerApi)

  const cancelPayment = async () => {
    await recordingApi.cancelPayment({ interviewId: interview.id }).then((res) => {
      setPaymentCancelled(res.recordingPaymentCancelled)
    })
  }

  const restorePayment = async () => {
    await recordingApi.restorePayment({ interviewId: interview.id }).then((res) => {
      setPaymentCancelled(res.recordingPaymentCancelled)
    })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
      {paymentCancelled &&
        <StandardButton
          text={'Restore'}
          onClick={restorePayment}
          sizeMode={ButtonModeValues.SMALL}/>
      }
      {!paymentCancelled &&
        <ColoredText
          singleText={'Cancel'}
          cursor={'pointer'}
          onClick={cancelPayment}/>
      }
    </div>
  )
}

export default RecordingManagementActions
